import React, { useEffect, useState } from "react";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import { fileUpload, getConfig, logoUploadUrlGet, saveConfig } from "../../../services/config";
import FieldGroup from "../_components/field-group";
import TextBox from "../_components/textbox";
import TextArea from "../_components/textarea";
import TextLabel from "../_components/label";
import ColorPicker from "../_components/color-picker";
import UploadAvatar from "../_components/upload-avatar";
import { Switch } from "../_components/switch";
import { Spinner } from 'flowbite-react';
import Message from "../../../components/toast-message";

const defaultSettings = {
  title: null,
  subTitle: null,
  welcomeMessage: null,
  inputPlaceHolder: null,
  logo: null,
  headerBackgroundColor: "#6366f1",
  headerTextColor: "#f9fafb",
  chatWindowBackgroundColor: "#f9fafb",
  sendBarBackgroundColor: "#e2e8f0",
};

const WebChatAppeareance = () => {
  const { appId } = useAppInfo();
  const [app, setApp] = useState({});

  const [chatBotSetting, setChatBotSetting] = useState(defaultSettings);

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  const [logoUploadFormData, setLogoUploadFormData] = useState({
    fileExt: "",
  });
  const [logoUploadUrl, setLogoUploadUrl] = useState();

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const [logoUrlGenState, setLogoUrlGenState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const [logoFileObj, setLogoFileObj] = useState();
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(null);

  const webIntegrationConfigType = `app_${appId}`;
  const webIntegrationConfigName = `web_integration`;

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(Object.assign({}, { processing, success, failed, message }));
  };

  const updateLogoUrlGenState = (processing, success, failed, errorMessage) => {
    setLogoUrlGenState(
      Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  useEffect(() => {
    const fetchSettings = async () => {
      updateDataLoadingState(true, false, false);
      const resp = await getConfig(
        webIntegrationConfigType,
        webIntegrationConfigName
      );

      if (resp.data) {
        setChatBotSetting(resp.data);
      }
      updateDataLoadingState(false, true, false);
    };
  
    if (!dataLoadingState.success) {
      fetchSettings();
    }
  }, []);

  const handleSelectFile = async (event) => {
    console.log("event.target.files", event.target.files);
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      logoUploadFormData.fileExt = file.name.split(".")[1];
      setLogoFileObj(file);
      setLogoUploadFormData({ ...logoUploadFormData });
    }
  };

  const fetchLogoUploadUrlGet = async () => {
    if (logoUploadFormData && logoUploadFormData.fileExt && !logoUrlGenState.processing) {
        updateLogoUrlGenState(true, false, false);
        try {
            const {response, status} = await logoUploadUrlGet(appId, logoUploadFormData);
            if (status === 200) {
                const {uploadUrl, logoUrl} = response;
                setLogoUploadUrl(uploadUrl);
                chatBotSetting.logo = logoUrl;
                updateLogoUrlGenState(false, true, false);
                uploadLogo(logoUploadUrl)
            } else {
                throw new Error(response)
            }
        } catch (err) {
            console.error("unable to create logo gen url cause", err);
            updateLogoUrlGenState(false, false, true, 'Upload failed');
        }
      }
    }

    useEffect(() => {
        fetchLogoUploadUrlGet()
    }, [logoUploadFormData]);

    const handleSaveSettings = async () => {
      if (!formState.processing) {
          try {
              updateFormState(true, false, false);
              const {response, status} = await saveConfig(appId, chatBotSetting);
              if (status === 200 || status === 201) {
                  updateFormState(false, true, false, 'Successfully saved settings');
              } else {
                  updateFormState(false, false, true, 'Unable to save settings');
              }
          } catch (e) {
              updateFormState(false, false, true, 'Unable to save settings');
          }
      }
  };

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, {processing, success, failed, message}))
  }

  const uploadLogo = async (logoUploadUrl) => {
    if (logoFileObj) {
      await fileUpload(logoUploadUrl, logoFileObj);
    }
  };

  useEffect(() => {
    if (!dataLoadingState.processing) {
      uploadLogo();
    }
  }, [chatBotSetting]);

  const handlePoweredByToggle = (event) => {
    console.log("PoweredBy::", event.target.checked);
  };

  const handleChange = (event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    const newChatBotSetting = { ...chatBotSetting };
    newChatBotSetting[targetName] = targetValue;
    setChatBotSetting(newChatBotSetting);
  };

  const handleChangeComplete = (field, color) => {
    const newSettings = { ...chatBotSetting, [field]: color };
    setChatBotSetting(newSettings);
  };

  useEffect(() => {
    if (logoFileObj) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreviewUrl(reader.result);
      };
      reader.readAsDataURL(logoFileObj);
    }
  }, [logoFileObj]);

  useEffect(() => {
    if (logoPreviewUrl) {
      const newSettings = { ...chatBotSetting };
      newSettings.logo = logoPreviewUrl;
      setChatBotSetting(newSettings);
    }
  }, [logoPreviewUrl]);

  useEffect(() => {
    fetchLogoUploadUrlGet();
  }, [logoUploadFormData]);

  const enableUpload = !logoUrlGenState.processing;

  return (
    <>
      {dataLoadingState.processing ? (
        <div className="flex justify-center items-center">
          <Spinner aria-label="Loading settings" />
        </div>
      ) : (
        <div className="appearence_form flex flex-col space-y-8 my-4">
          {formState.processing &&  <div className="flex justify-center items-center">
            <Spinner aria-label="Loading settings" />
          </div>}
          {formState.success && <div className=""><Message text="Your settings have been successfully saved" type="success" /> </div>}
          <FieldGroup>
            <TextLabel className="w-auto lg:w-44">Name</TextLabel>
            <TextBox
              id="title"
              type="text"
              name="title"
              autoComplete={false}
              placeholder="Home Depot"
              value={chatBotSetting.title}
              onChange={handleChange}
            />
          </FieldGroup>

          <FieldGroup>
            <TextLabel className="w-auto lg:w-44">Widget skin color</TextLabel>
            <ColorPicker
              key="headerBackgroundColor"
              chatBotSetting={chatBotSetting}
              field="headerBackgroundColor"
              fieldTitle="Header Background Color"
              handleChangeComplete={handleChangeComplete}
            />
          </FieldGroup>

          <FieldGroup>
            <TextLabel className="w-auto lg:w-44">Logo</TextLabel>
            <UploadAvatar
              formState={formState}
              enableUpload={enableUpload}
              onChange={handleSelectFile}
              value={chatBotSetting?.logo}
            />
          </FieldGroup>

          {/* <FieldGroup>
            <TextLabel className="w-auto lg:w-44">Powered By</TextLabel>
            <div className="flex w-full">
              <Switch
                id="poweredByState"
                value={chatBotSetting?.poweredBy}
                onChange={handlePoweredByToggle}
              />
            </div>
          </FieldGroup> */}

          {/* Save Button */}
          <div className="flex justify-end mt-4">
            <button
              onClick={handleSaveSettings}
              className="inline-flex items-center justify-center gap-2 text-sm leading-6 rounded-md transition py-2 px-5 whitespace-nowrap 
                  bg-indigo-600 hover:bg-indigo-700 hover:ring-4 ring-indigo-200 dark:ring-indigo-900 text-white tracking-wider"
            >
              Save Settings
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default WebChatAppeareance;
