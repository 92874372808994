import {Toast} from "flowbite-react";
import {HiCheck, HiExclamation, HiX} from 'react-icons/hi';
import {useEffect, useState} from "react";

const iconClass = "h-5 w-5"
const colorPlaceHolder = "#COLOR"
export default function ToastMessage({text, type, timeout= 5000}) {

    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        if (timeout > 0) {
            setTimeout(()=>{
                setIsOpen(false);
            }, timeout)
        }
    }, []);

    if (!isOpen) {
        // exit
        return <></>;
    }

    let iconComp;
    let color;
    if (type === "success") {
        iconComp = <HiCheck className={iconClass} />
        color = "green";
    } else if (type === "failure"){
        iconComp = <HiExclamation className={iconClass}/>
        color = "red";
    } else if (type === "warn"){
        iconComp = <HiX className={iconClass}/>
        color = "orange";
    }

    const iconBgClass = `inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-${color}-100 text-${color}-500 dark:bg-${color}-800 dark:text-${color}-200`
    const bgClass = `bg-${color}-400`


    return (
      <div
        className="fixed top-16 left-1/2 transform -translate-x-1/2 cursor-pointer"
        onClick={() => setIsOpen(false)}
      >
        <Toast className={bgClass}>
          <div className={iconBgClass}>{iconComp}</div>
          <div className="ml-3 text-sm text-white font-bold">{text}</div>
          {/*<Toast.Toggle />*/}
        </Toast>
      </div>
    );
}