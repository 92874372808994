export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_STR_FORMAT = 'LLLL dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm aa';
export const DEFAULT_APP_VERSION = 'v1';

export const formDataRequestType = { label: "Form Data", value: "form_data" };
export const rawRequestType = { label: "Raw", value: "raw" };
export const timeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
};
export const requestTypes = [
    rawRequestType,
    formDataRequestType,
];
export const fetchFrequencyOptions = ['Daily', 'Weekly', 'Monthly', 'Yearly'];
