import {useEffect, useState} from 'react';
import Button from '../../../../components/button/button';
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import {useAppInfo} from '../../../../helpers/hooks/common-hook';
import {deleteAccessKey, getAllAccessKeys} from '../../../../services/app/app-access-key-service';
import AppAccessKeyCreateDialog from '../app-access-key-create/app-access-key-create-page';
import {APP_NAVIGATION} from "../../consts/navigations";
import PageContainer from "../../../../components/page-container";
import Table from "../../../../components/table";
import TableReloadButton from "../../../../components/button/table-reload-button";

function AppAccessKeyListPage() {
    const { appId } = useAppInfo();
    const [accessKeys, setAccessKeys] = useState([]);
    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [revokeKeyId, setRevokeKeyId] = useState();
    const [openRevokeConfirmDialog, setOpenRevokeConfirmDialog] = useState();

    async function fetchAccessKeys() {
        updateFormState(true, false, false);
        const { data } = await getAllAccessKeys(appId);
        setAccessKeys(data);
        updateFormState(false, true, false);
    }

    useEffect(() => {
        fetchAccessKeys();
    }, []);

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    // access-key create dialog
    const [openCreateAccessKey, setOpenCreateAccessKey] = useState(false);

    const handleOpenCreateAccessKey = () => {
        setOpenCreateAccessKey(true);
    }

    const handleOnSuccessCreateAccessKey = async () => {
        await fetchAccessKeys();
        handleOnCancelCreateAccessKey();
    }

    const handleOnCancelCreateAccessKey = () => {
        setOpenCreateAccessKey(false);
    }

    const handleSelectRevoke = keyId => {
        setRevokeKeyId(keyId);
        setOpenRevokeConfirmDialog(true);
    }

    const handleConfirmRevoke = async confirmed => {
        setOpenRevokeConfirmDialog(false);
        if (confirmed) {
            await deleteAccessKey(appId, revokeKeyId);
            setRevokeKeyId();
            await fetchAccessKeys();
        }
    }

    const processDataList = accessKeys.map(item => {
        return {
            record: item,
            columnsData: [
                {data: item.keyId, isIdField: true},
                {data: item.createdAt, isUpdatedField: true},
                {
                    options: [
                        {
                            name: 'Revoke',
                            onClick: () => handleSelectRevoke(item.keyId)
                        }
                    ]
                }
            ]
        }
    })

    return (
        <PageContainer
            title="Access Keys"
            subtitle="Access keys to invoke runtime api."
            headerAction={<div className="flex flex-row items-center gap-4">
                <TableReloadButton onClick={()=>fetchAccessKeys()}/>
                <Button onClick={handleOpenCreateAccessKey} text="Create Access Key" />
            </div>}
            navigation={APP_NAVIGATION}>
            {openCreateAccessKey &&
                <AppAccessKeyCreateDialog
                    appId={appId}
                    open={openCreateAccessKey}
                    onCompletion={handleOnSuccessCreateAccessKey}
                    onCancel={handleOnCancelCreateAccessKey}
                />
            }

            {openRevokeConfirmDialog &&
                <ConfirmDialog
                    appId={appId}
                    open={openRevokeConfirmDialog}
                    onCompletion={handleConfirmRevoke}
                    message="Do you want to revoke this Access Key"
                />
            }

            <Table
                items={processDataList}
                headers={
                    ["key Id"]
                }
                dataLoadingState={formState}
                itemsNotFoundMessage="You don't have any access keys"
            />
        </PageContainer>)
}

export default AppAccessKeyListPage;