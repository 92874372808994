import { useEffect, useState } from 'react';
import Button from '../../../components/button/button';
import Message from "../../../components/message/message-comp";
import Loader from "../../../components/form-loader/form-loader";
import {
    getDashboardResponse,
    cancelSubscription,
    upgradeSubscription,
    resumeSubscription
} from '../../../services/billing';
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { Badge } from 'flowbite-react';
import {useNavigate} from "react-router-dom";
import { Alert } from 'flowbite-react';
import { HiInformationCircle } from 'react-icons/hi';

function NotifyTag({text, state}) {
    let span;
    if (state === 'success') {
        span = <span className="p-2 rounded text-sm text-green-600 font-semibold bg-green-100">{text}</span>
    } else if (state === 'error') {
        span = <span className="p-2 rounded text-sm text-red-600 font-semibold bg-red-100">{text}</span>
    } else {
        span = <span className="p-2 rounded text-sm text-yellow-600 font-semibold bg-yellow-100">{text}</span>
    }
    return (<div className="flex flex-row">
            {span}
            </div>)
}

function CurrentPeriod({dashboardData})  {
    return (
        <div className="flex flex-col gap-2">
            <div className="divide-y">
                <p className="text-gray-900 text-lg font-bold">Current Period</p>
                <div/>
            </div>

            <div className="flex flex-row gap-2 items-baseline divide-x">
                <div  className="flex flex-col gap-2">
                    <p className="text-gray-500 text-sm">Started</p>
                    <p className="text-gray-700 text-sm">{dashboardData.currentPeriod.start.month}</p>
                </div>

                <div  className="flex flex-col gap-2 px-2">
                    <p className="text-gray-500 text-sm">End</p>
                    <p className="text-gray-700 text-sm">{dashboardData.currentPeriod.end.month}</p>
                </div>
            </div>
        </div>
    );
}
function Pricing({dashboardData})  {
    return (
        <div className="flex flex-col gap-2">
            <div className="divide-y">
                <p className="text-gray-900 text-lg font-bold">Pricing</p>
                <div/>
            </div>

            <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white">
                    <tr>
                        <td className="whitespace-nowrap text-gray-700 text-sm"><span className="font-semibold">
                            {dashboardData.plan.fixedAmountPerMonth}/Month</span> for {dashboardData.plan.executionCountPerFixedPlan} requests</td>
                    </tr>
                    <tr>
                        <td className="py-2 whitespace-nowrap text-gray-700 text-sm">
                            <span className="font-semibold">{dashboardData.plan.perRequestAmount}/Request</span> for the rest</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

function UpcomingInvoice({dashboardData})  {
    const { planRow, initialRequests, exceededRequests } = dashboardData.upcomingInvoice.invoiceTable;
    return (<div className="flex flex-col gap-2">
                <div className="divide-y">
                    <p className="text-gray-900 text-lg font-bold">Upcoming invoice</p><div/>
                </div>

                <p className="text-gray-700 text-xs pb-4">This is a preview of the invoice that will be billed on <span className="font-semibold">{dashboardData.upcomingInvoice.nextPaymentDate}</span>. It may change if the subscription is updated.</p>

                <div className="flex flex-row gap-2 items-baseline">
                    <div  className="flex flex-col gap-2">
                        <p className="text-gray-500 text-sm">Next Invoice</p>
                        <p className="text-gray-700 text-sm">{dashboardData.upcomingInvoice.nextPayment} on {dashboardData.upcomingInvoice.nextPaymentDate}</p>
                    </div>
                </div>

                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Description
                        </th>
                        <th
                            scope="col"
                            className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Requests
                        </th>
                        <th
                            scope="col"
                            className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Unit Price
                        </th>
                        <th
                            scope="col"
                            className="py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Amount
                        </th>
                    </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-500">
                                {dashboardData.currentPeriod.start.month} - {dashboardData.currentPeriod.end.month}, {dashboardData.currentPeriod.end.year}
                            </td>
                        </tr>
                        <tr>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">{planRow.title}</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-500 font-semibold">{planRow.requests}</td>
                        </tr>
                        <tr>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700">{initialRequests.title}</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-500">{initialRequests.requests}</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-500">{initialRequests.unitPrice}</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-500 font-semibold">{initialRequests.amount}</td>
                        </tr>

                        {exceededRequests &&
                            <tr>
                                <td className="py-4 whitespace-nowrap text-sm text-gray-700">{exceededRequests.title}</td>
                                <td className="py-4 whitespace-nowrap text-sm text-gray-500">{exceededRequests.requests}</td>
                                <td className="py-4 whitespace-nowrap text-sm text-gray-500">{exceededRequests.unitPrice}</td>
                                <td className="py-4 whitespace-nowrap text-sm text-gray-500 font-semibold">{exceededRequests.amount}</td>
                            </tr>
                        }

                        <tr>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700"></td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-500"></td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">Sub Total</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">{dashboardData.upcomingInvoice.subtotal}</td>
                        </tr>
                        <tr>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700"></td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700"></td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">Total</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">{dashboardData.upcomingInvoice.total}</td>
                        </tr>
                        <tr>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700"></td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700"></td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">Amount due</td>
                            <td className="py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">{dashboardData.upcomingInvoice.amountDue}</td>
                        </tr>
                    </tbody>
                </table>

            </div>)
}

export default function Index() {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({});
    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [subscriptionChecking, setSubscriptionChecking] = useState(false);

    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState();

    async function fetchData() {
        updateFormState(true, false, false);
        const { response, status } = await getDashboardResponse();
        if (status === 200) {
            setDashboardData(response);
            updateFormState(false, true, false);
        } else if (status === 404) {
            // TODO redirect to plans
            setDashboardData(response);
            updateFormState(false, false, true, 'Unable to found Subscription. Please upgrade your plan');
        } else {
            updateFormState(false, false, true, 'Unable to found Billing Data. Please try again');
        }
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const onCancelSubscription = async () => {
        setOpenCancelConfirmDialog(true);
    }

    const handleConfirmCancelSubscription = async confirmed => {
        setOpenCancelConfirmDialog(false);
        if (confirmed) {
            // TODO
            await cancelSubscription();
            await fetchData();
        }
    }

    const onClickResumeSubscription = async () => {
        setSubscriptionChecking(false);
        updateFormState(true, false, false);
        const { status } = await resumeSubscription();
        if (status === 200) {
            updateFormState(false, true, false);
            setSubscriptionChecking(true)
        } else {
            updateFormState(false, false, true, 'Unable to subscribe. Please try again');
            setTimeout(async ()=>{
                await fetchData();
            }, 2000);
        }
    }

    const redirectToSelectPlans = ()=> {
        navigate('/user-settings/subscription/plans')
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let interval;
        if (subscriptionChecking) {
            interval = setInterval(async () => {
                const { response, status } = await getDashboardResponse();
                if (status === 200 && !response.isCanceled) {
                    await fetchData()
                    setSubscriptionChecking(false)
                    clearInterval(interval);
                }
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [subscriptionChecking]);

    let submitButton;
    if (dashboardData.isTrail) {
        submitButton = <Button text="Upgrade your plan" onClick={redirectToSelectPlans} />;
    } else if (dashboardData.cancelAt) {
        submitButton = <Button text="Resume Subscription" onClick={onClickResumeSubscription} />;
    } else {
        submitButton = <Button text="Cancel Subscription" onClick={onCancelSubscription} />;
    }

    return (
        <div className="flex flex-col gap-8 h-screen">

            {formState.processing && <div className="py-6"><Loader /></div>}

            {(!formState.processing && formState.failed) &&
                <Message text={formState.errorMessage} timeout={-1} />
            }

            {(!formState.processing && formState.failed) &&
                <div className="flex flex-row justify-end px-2">
                    <Button text="Upgrade your plan" onClick={redirectToSelectPlans} />
                </div>

            }

            {(!formState.processing && formState.success) &&
                <>
                    {openCancelConfirmDialog &&
                        <ConfirmDialog
                            open={openCancelConfirmDialog}
                            onCompletion={handleConfirmCancelSubscription}
                            message="Are you sure. Do you want to Cancel Subscription ?"
                        />
                    }

                    <div className="grid grid-cols-2">
                        <div className="flex flex-row gap-4 justify-start items-baseline">
                            {dashboardData.isCanceled ?
                                <>
                                    <p className="text-gray-700 text-xl">Subscribe</p>
                                    <p className="text-gray-500 text-lg font-bold">to</p>
                                    <p className="text-gray-900 text-2xl font-bold">{dashboardData.plan.name}</p>
                                </>
                                :
                                <>
                                    <p className="text-gray-900 text-2xl font-bold">{dashboardData.plan.name} Plan</p>
                                    {dashboardData.cancelAt &&
                                        <>
                                        <p className="text-gray-500 text-lg font-bold">until</p>
                                        <p className="text-gray-700 text-2xl">{dashboardData.cancelAt}</p>
                                        </>
                                    }

                                </>
                            }

                        </div>
                        <div className="flex justify-end items-center">
                            {subscriptionChecking ?
                                <>
                                    <Button text="Subscription Preparing" isIndicated={true} />
                                </>
                                :
                                <>
                                    {submitButton}
                                </>
                            }
                        </div>
                    </div>

                    {dashboardData.freeTrailStatusMessage &&
                        <Message color="red" text={dashboardData.freeTrailStatusMessage} timeout={0} />
                    }

                    {dashboardData.upcomingSubscription &&
                        <Message color="success" text={`${dashboardData.upcomingSubscription.plan} plan will starts on ${dashboardData.upcomingSubscription.starts}`} timeout={0} />
                    }

                    {/*<CurrentPeriod dashboardData={dashboardData} />*/}
                    {/*<Pricing dashboardData={dashboardData} />*/}

                    {dashboardData.upcomingInvoice &&
                        <UpcomingInvoice dashboardData={dashboardData}/>
                    }

                </>
            }

        </div>)
}