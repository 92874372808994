import React from "react";
import PropTypes from "prop-types";
import { cn } from "../../../../../helpers/utils/css-utils";

const ActionItem = ({ children, className, type = undefined, onAction }) => {
  return (
    <button
      onClick={() => onAction(type)}
      className={cn(
        `
      my-0.5 p-3 py-2 text-sm text-gray-700 dark:text-gray-300 w-full flex items-center justify-between gap-1 cursor-pointer transition-all duration-300
      border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-900 hover:border-indigo-500 rounded-md`,
        className
      )}
    >
      {children}
    </button>
  );
};

ActionItem.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default ActionItem;
