import React from 'react';
import {Handle} from "reactflow";

const BlockChildHandle = ({type, onMouseOver, onMouseLeave, id, position, style}) => {

    return (
        <Handle
            type={type}
            position={position}
            id={id}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            style={style}
        />
    );
};

export default BlockChildHandle;
