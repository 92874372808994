import React, {useCallback} from "react";
import DynamicRows from "../../../../../components/dynamic-rows";
import SelectInput from "../../../../../components/forms/select";
import TextBox from "../../../../../components/forms/text-box";
import {useEventBus} from "../../../../../helpers/hooks/event-bus";
import {WORKFLOW_OPTION_SLOT} from "../../const/settings-const";
import {BUTTON_GO_TO_STEP_RULE_NAME} from "../../const/settings-type";
import journeyStmStore from "../../service/journey-stm-store";
import SlotSelector from "../slot-selector";

const WEB_URL_TYPE = 'web_url';
const SET_VALUE_TYPE = 'set_value';
const GO_TO_STEP_TYPE = 'go_to_step';
const SET_INPUT_TYPE = 'set_input';

const getCurrentSteps = myId => {
    return Object.keys(journeyStmStore.getAll()).filter(id => id !== myId).map(id => ({
        id,
        name: journeyStmStore.get(id).stateConfig.name
    }));
}

function SetValueOptions({rootClassName = "flex flex-col", defaultSlot, button, metadata, handleInputChange}) {
    return (
        <div className={rootClassName}>
            {(!defaultSlot || (defaultSlot === WORKFLOW_OPTION_SLOT)) &&
                <SlotSelector
                    rootClass="pt-4"
                    title="Variable"
                    value={button.slot}
                    autoFillFromEntity={false}
                    slots={metadata.slots}
                    onChange={handleInputChange}/>
            }
            <div className="mt-4">
                <TextBox
                    label="Value"
                    type="text"
                    name="payload"
                    placeholder="Enter Value"
                    value={button.payload}
                    onChange={handleInputChange}
                />
            </div>
        </div>
    )
}

const ActionButtons = React.memo(({
                                      stepId, dataField, configs, defaultSlot, metadata, onChange = () => {
    }, displayTopBorder = true
                                  }) => {
    const renderRow = useCallback((button, handleInputChange, rawId) => {

        button.actionType = button.actionType || SET_VALUE_TYPE;
        return <div className="flex flex-col gap-2" key={rawId}>
            <TextBox
                rootClass=""
                label="Display Text"
                type="text"
                name="title"
                placeholder="Enter Display Text"
                value={button.title}
                onChange={handleInputChange}
            />
            <SelectInput
                label="Action"
                id="actionType"
                name="actionType"
                autoComplete="actionType"
                value={button.actionType}
                onChange={handleInputChange}
                optionsComp={<>
                    <option value={SET_VALUE_TYPE}>Set Variable Value</option>
                    <option value={SET_INPUT_TYPE}>Set Input Value</option>
                    <option value={WEB_URL_TYPE}>Open URL</option>
                    <option value={GO_TO_STEP_TYPE}>Go To Step</option>
                </>}
            />

            {button.actionType === SET_VALUE_TYPE &&
                <SetValueOptions
                    button={button}
                    metadata={metadata}
                    handleInputChange={handleInputChange}
                    defaultSlot={defaultSlot}
                />
            }

            {button.actionType === WEB_URL_TYPE &&
                <div className="flex flex-col gap-2">
                    <TextBox
                        rootClass="pt-4"
                        label="URL"
                        type="url"
                        name="url"
                        placeholder="Enter Url"
                        value={button.url}
                        onChange={handleInputChange}
                    />
                    <SetValueOptions
                        button={button}
                        metadata={metadata}
                        handleInputChange={handleInputChange}
                        defaultSlot={defaultSlot}
                    />
                </div>
            }

            {button.actionType === GO_TO_STEP_TYPE &&
                <SelectInput
                    rootClass="pt-2 pb-4"
                    label="Step"
                    required
                    name="goToStep"
                    value={button.goToStep}
                    onChange={event => {
                        handleInputChange(event);
                        // onChangeGoToStep(event)
                    }}
                    optionsComp={<>
                        <option className="text-gray-500" value="" selected={true}>Select step</option>
                        {getCurrentSteps(stepId).map((state, si) => (
                            <option key={si} value={state.id}>{state.name}</option>
                        ))}
                    </>}
                />
            }

            {button.actionType === SET_INPUT_TYPE &&
                <TextBox
                    rootClass="pt-4"
                    label="Input Value"
                    type="text"
                    name="inputValue"
                    placeholder="Enter Value"
                    value={button.inputValue}
                    onChange={handleInputChange}
                />
            }
        </div>
    }, [])
    return (
        <div className="flex flex-col pt-4 gap-2 pb-4">
            <DynamicRows
                title="Buttons"
                className=""
                data={configs}
                onChange={onChange}
                defaultThumbnail="Button"
                showTopDivider={displayTopBorder}
                showBottomDivider={false}
                rowsTemplate={renderRow}
                rowAccordingPanel={true}
            />
        </div>
    )
})

export default ActionButtons
