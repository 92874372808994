import { useState, useEffect } from 'react'
import {listShopifyConnes} from "../../../../../services/model/integration-shopify-service";
import SwitchComponent from "../../../../../components/switch";
import {SHOPIFY_DEFAULT_ACTION_CONFIG} from "../common-consts";
import SelectInput from "../../../../../components/forms/select";
import TabOpen from "../../../../../components/tab-open";

// TODO need to fetch from DB
const ORDER_TRACK = {name: 'order_track', value: 'Order Track'};
const ORDER_CANCEL = {name: 'order_cancel', value: 'Order Cancel'};
const PRODUCT_SEARCH = {name: 'product_search', value: 'Product Search'};

const TASKS = [
    ORDER_TRACK,
    ORDER_CANCEL,
    PRODUCT_SEARCH
]

export default function ShopifyAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=SHOPIFY_DEFAULT_ACTION_CONFIG,
                                           error
}) {
    const { appId } = config;

    const [ shopifyConnsList, setShopifyConnsList ] = useState([]);
    const [ shopifyConnsLoadingState, setShopifyConnsLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateShopifyConnsLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setShopifyConnsLoadingState({...processing, success, failed, message, errorMessage})
    }

    async function fetchShopifyConns() {
        updateShopifyConnsLoadingState({
            processing: true
        });
        const { data } = await listShopifyConnes(appId);
        if (data && Array.isArray(data)) {
            setShopifyConnsList(data);
        }
        updateShopifyConnsLoadingState({
            processing: false
        });
    }

    const setParam = (parameters, name, description, type, required, defaultValue) => {
        parameters[name] = { id: name, name, description, type, required, defaultValue};
    };

    const setDefaultParams = () => {
        const parameters = actionConfig.parameters || {};

        setParam(parameters, 'orderId', 'order id', 'number', true);
        setParam(parameters, 'shopifyTask', null, 'string', true, actionConfig.task);

        setActionConfig({...actionConfig, parameters });
    };

    useEffect( () => {
        fetchShopifyConns();
        setDefaultParams();
    }, []);

    return (
        <>
            <SelectInput
                id="resourceId"
                label="Store"
                rootClass="pt-4 w-1/2"
                required
                value={actionConfig.resourceId}
                onChange={event => { onActionConfigUpdate('resourceId', event)}}
                error={error.resourceId}
                optionsComp={
                    <>
                        <option>Select Store</option>
                        {shopifyConnsList.map((store)=>
                            <option key={store.id} value={store.id}>{store.storeAddress}</option>
                        )}
                    </>
                }
            />

            <TabOpen
                text="Create Store"
                url={`/app/${appId}/integrations/shopify`}
                onReload={fetchShopifyConns}
            />

            <SelectInput
                id="task"
                label="Task"
                rootClass="pt-4 w-1/2"
                required
                value={actionConfig.task}
                onChange={event => { onActionConfigUpdate('task', event)}}
                optionsComp={
                    <>
                        <option>Select Task</option>
                        {TASKS.map((task)=>
                            <option key={task.name} value={task.name}>{task.value}</option>
                        )}
                    </>
                }
            />
        </>
    )
}
