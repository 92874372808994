import {Switch} from "@headlessui/react";
import React from "react";

export default function SentToUserSwitch({skipNotify, formData, setFormData}) {
    const sendToUser = !skipNotify;
    return (
        <div className="pt-4 flex flex-row justify-between items-center">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Send result to user
            </label>
            <Switch
                checked={sendToUser}
                onChange={val=>setFormData({...formData, skipNotify: !val})}
                className={`${
                    sendToUser ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
                <span className="sr-only">Send to User</span>
                <span
                    className={`${
                        sendToUser ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
    )
}
