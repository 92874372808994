import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FaCaretDown } from "react-icons/fa";

import { createAppBasePath } from "../../../consts/route-consts";
import { useAppContext } from "../../../helpers/hooks/common-hook";

import MenuItem from "./menu-item";

function prepHref(href, appInfo) {
  if (appInfo) {
    return `${createAppBasePath(appInfo.appId, appInfo.version)}${href}`;
  } else {
    return href;
  }
}

const DropdownListMenuItem = ({ element }) => {
  const { appInfo } = useAppContext();

  const [subItems, setSubItems] = useState(null);

  return (
    <div
      onMouseEnter={() => setSubItems(element)}
      onMouseLeave={() => setSubItems(null)}
      className="relative hover:bg-gray-50 dark:bg-gray-800"
    >
      <MenuItem
        element={element}
        actionElement={
          <span>
            <FaCaretDown size={14} />
          </span>
        }
      />
      {subItems && (
        <ul className="border-t border-gray-200 dark:border-gray-700">
          {subItems.children.map((el, i) => {
            return (
              <li className="px-4 py-3 cursor-pointer text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-indigo-700">
                <Link
                  to={prepHref(el?.href, appInfo)}
                  className="text-sm w-full flex items-center justify-between"
                >
                  <span className="font-normal tracking-wide">{el?.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default DropdownListMenuItem;
