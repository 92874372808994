export const WEBHOOK_ACTION_TYPE = "webhook";
export const CODEHOOK_ACTION_TYPE = "codehook";
export const DOCUMENT_ACTION_TYPE = "document";
export const FINE_TUNED_KNOWLEDGE_ACTION_TYPE = "fine-tuned-knowledge";
export const SHOPIFY_ACTION_TYPE = "shopify";
export const LIVE_AGENT_CONNECT_ACTION_TYPE = "liveAgentConnect";
export const WORKFLOW_ACTION_TYPE = "workflow";
export const MESSAGE_ACTION_TYPE = "message";

export const DEFAULT_ACTION_CONFIG = {
    name: undefined,
    actionType: undefined,
    resourceId: undefined,
    description: undefined,
    preInstruction: undefined,
    parameters: [],
}

export const CODEHOOK_DEFAULT_ACTION_CONFIG = {
    ...DEFAULT_ACTION_CONFIG,
    actionType: CODEHOOK_ACTION_TYPE,
    parameters: [],
    authRequired: false,
    returnDirect: false,
    preInstructionDisplay: true,
    authOptionDisplay: true,
}

export const SHOPIFY_DEFAULT_ACTION_CONFIG = {
    actionType: SHOPIFY_ACTION_TYPE,
    parameters: [],
    preInstructionDisplay: true,
    authOptionDisplay: true
}

export const DOCUMENT_DEFAULT_ACTION_CONFIG = {
    actionType: DOCUMENT_ACTION_TYPE,
    parameters: [],
    returnDirect: true,
    inputPrompt: '',
    outputPrompt: '',
    returnOriginal: false,
    preInstructionDisplay: false,
    authOptionDisplay: true
}

export const FINE_TUNED_KNOWLEDGE_BASE_DEFAULT_ACTION_CONFIG = {
    ...DEFAULT_ACTION_CONFIG,
    actionType: FINE_TUNED_KNOWLEDGE_ACTION_TYPE,
    parameters: [],
    authRequired: false,
    returnDirect: true,
    resourceId: 'fineTunedKnowledge',
    preInstructionDisplay: false,
    authOptionDisplay: true
}

export const WORKFLOW_ACTION_CONFIG = {
    ...DEFAULT_ACTION_CONFIG,
    actionType: WORKFLOW_ACTION_TYPE,
    parameters: [],
    authRequired: false,
    returnDirect: true,
    resourceId: 'workflow',
    preInstructionDisplay: true,
    authOptionDisplay: true
}

export const MESSAGE_ACTION_CONFIG = {
    ...DEFAULT_ACTION_CONFIG,
    actionType: MESSAGE_ACTION_TYPE,
    parameters: [],
    authRequired: false,
    returnDirect: true,
    resourceId: 'message',
    message: '',
    authOptionDisplay: true
}


export const LIVE_AGENT_DEFAULT_ACTION_CONFIG = {
    actionType: LIVE_AGENT_CONNECT_ACTION_TYPE,
    parameters: [],
    resourceId: 'liveAgentConnect',
    returnDirect: true,
    message: "",
    preInstructionDisplay: false,
    authOptionDisplay: true
}

export const WEBHOOK_DEFAULT_ACTION_CONFIG = {
    ...DEFAULT_ACTION_CONFIG,
    actionType: WEBHOOK_ACTION_TYPE,
    parameters: [],
    authRequired: false,
    returnDirect: false,
    resourceId: 'webhook',
    httpMethod: "GET",
    url: "",
    queryParameters: {},
    headers: {},
    requestType: "raw",
    requestBody: "{}",
    requestFormData: {},
    preInstructionDisplay: true,
    authOptionDisplay: true
}

export const DEFAULT_ACTION_FACTORY = {
    [CODEHOOK_ACTION_TYPE]: CODEHOOK_DEFAULT_ACTION_CONFIG,
    [SHOPIFY_ACTION_TYPE]: SHOPIFY_DEFAULT_ACTION_CONFIG,
    [DOCUMENT_ACTION_TYPE]: DOCUMENT_DEFAULT_ACTION_CONFIG,
    [FINE_TUNED_KNOWLEDGE_ACTION_TYPE]: FINE_TUNED_KNOWLEDGE_BASE_DEFAULT_ACTION_CONFIG,
    [WEBHOOK_ACTION_TYPE]: WEBHOOK_DEFAULT_ACTION_CONFIG,
    [LIVE_AGENT_CONNECT_ACTION_TYPE]: LIVE_AGENT_DEFAULT_ACTION_CONFIG,
    [WORKFLOW_ACTION_TYPE]: WORKFLOW_ACTION_CONFIG,
    [MESSAGE_ACTION_TYPE]: MESSAGE_ACTION_CONFIG
}

export const ACTION_TYPES_DISPLAY = {
    [WEBHOOK_ACTION_TYPE]: "API Execute",
    [CODEHOOK_ACTION_TYPE]: "Function Invoke",
    [WORKFLOW_ACTION_TYPE]: "Workflow Trigger",
    [DOCUMENT_ACTION_TYPE]: "Document Search",
    // [FINE_TUNED_KNOWLEDGE_ACTION_TYPE]: "Fine-Tuned Knowledge",
    [SHOPIFY_ACTION_TYPE]: "Shopify Connect",
    [LIVE_AGENT_CONNECT_ACTION_TYPE]: "Live Agent Connect",
    [MESSAGE_ACTION_TYPE]: "Message Display",
}
