import PropTypes from "prop-types";
import React from "react";
import InternalHeader from "../internal-header";
import InternalChannelsComponent from "../internal-router-component/channels";
import InternalContentsComponent from "../internal-router-component/contents";

const INTERNAL_ROUTER_COMPONENTS = {
  channels: <InternalChannelsComponent />,
  contents: <InternalContentsComponent />,
};

export default function PageContainerV2(props) {
  const { title, subtitle, navigation, pageType, pageSidebar = false, children } = props;

  const isInternalSidebarComponent = !!pageSidebar;

  const RenderRouterComponent = () => {
    if (!pageType) return;

    const Component = INTERNAL_ROUTER_COMPONENTS[pageType];

    if (!Component) return;

    return Component;
  };

  return (
    <div className="bg-inherit h-full w-full px-6 pt-4">
      <div className="w-full h-full flex gap-4">
        {isInternalSidebarComponent && (
          <aside className="flex h-[calc(100vh-90px)] bg-white dark:bg-slate-800 w-full flex-col overflow-y-hidden sm:w-[260px] xl:w-[260px] border rounded-lg dark:border-gray-700 shadow-md">
            <RenderRouterComponent />
          </aside>
        )}
        <div className="border bg-white h-[calc(100vh-90px)] dark:bg-slate-800 dark:border-gray-700 rounded-lg flex flex-col flex-1 relative shadow-md">
          {navigation?.length && (
            <InternalHeader navigation={navigation} />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

PageContainerV2.propTypes = {
  pageType: PropTypes.oneOf(["channels", "contents", "analytics"]),
  pageNavigation: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
};

/**
 * 
  headerAction: PropTypes.any,
  navigation: PropTypes.array,
  breadcrumbNav: PropTypes.any,
  titleMarker: PropTypes.string,
  tooltip: PropTypes.string,
  titleMarkerColor: PropTypes.string,
 */
