import PropTypes from "prop-types";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";

import { useAppContext } from "../../../helpers/hooks/common-hook";
import { CHANNELS_NAVIGATION } from "../consts/navigations";
import PlusButton from "./plus-button";

import Badge from "../_components/badge";
import DropdownListMenuItem from "../_components/ddl-menu-item";
import MenuItem from "../_components/menu-item";

const navigations = CHANNELS_NAVIGATION;

export default function InternalChannelsComponent() {
  const { appInfo } = useAppContext();
  const isUpgradeFeatureVoicePlugin = true;

  return (
    <div className="w-full h-full">
      {/* Header */}
      <div className="px-5 pr-2 dark:border-gray-700 h-14 flex items-center justify-between">
        <h4 className="font-bold text-lg dark:text-white">Channels</h4>
        {/*<PlusButton onClick={() => window.alert("Clicked Plus!")}>*/}
        {/*  <AiOutlinePlus size={17} />*/}
        {/*</PlusButton>*/}
      </div>
      {/* Navigations */}
      <ul>
        {navigations?.map((el, i) => {
          const itemActionElement =
            el.name === "Voice" && isUpgradeFeatureVoicePlugin ? (
              <Badge text={"Upgrade"} color="purple" />
            ) : null;

          if (!el.children) {
            return (
              <MenuItem
                key={i}
                element={el}
                actionElement={itemActionElement}
                border={i !== navigations.length - 1}
              />
            );
          }
          return <DropdownListMenuItem key={i} element={el} />;
        })}
      </ul>
    </div>
  );
}

InternalChannelsComponent.propTypes = {
  headerAction: PropTypes.element,
  navigation: PropTypes.array,
};
