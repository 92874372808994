import {del, get, post, put} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function saveAgentCoreSettings(appId, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/agent/core-settings`, data);
    } catch (err) {
        console.debug('saveAgentCoreSettings failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getAgentCoreSettings(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/agent/core-settings`);
        if (status !== 200) {
            return null;
        }
        return response;
    } catch (err) {
        console.debug('getAgentCoreSettings failed cause', err.message, err.code);
        return null;
    }
}

export async function getAgentActions(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/agent/actions`);
        if (status !== 200) {
            return {
                data: []
            };
        }
        return response;
    } catch (err) {
        console.debug('getAgentActions failed cause', err.message, err.code);
        return {
            data: []
        };
    }
}

export async function getAgentAction(appId, name) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/agent/actions/${name}`);
        if (status !== 200) {
            return null;
        }
        return response;
    } catch (err) {
        console.debug('getAgentAction failed cause', err.message, err.code);
        return null;
    }
}

export async function saveAgentActionSettings(appId, action) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/agent/actions`,  action);
    } catch (err) {
        console.debug('saveAgentActionSettings failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function runtimeExecute(appId, sessionId, inputText) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/prompt/runtime/execute`,
            {
                sessionId,
                inputText
            });
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('runtimeExecute failed cause', err.message, err.code);
        return [];
    }
}

export async function deleteAgentActionSettings(appId, actionId) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/agent/actions/${actionId}`);
    } catch (err) {
        console.debug('deleteAgentActionSettings failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function disableAgentActionSettings(appId, actionId, deactivate) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/agent/actions/${actionId}/disable`, { deactivate });
    } catch (err) {
        console.debug('disableAgentActionSettings failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}