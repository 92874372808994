import {del, get, post, put} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function journeyList(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/journey`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('journey list failed cause', err.message, err.code);
        return [];
    }
}

export async function journeyGet(appId, id) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/journey/${id}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('journey get failed cause', err.message, err.code);
        return {};
    }
}

export async function journeyCreate(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey`, request);
    } catch (err) {
        console.debug('journey create failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeyDefinitionSave(appId, journeyId, stmDefinition ,metadata) {
    try {
        return await put(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}/definition`, {
            stmDefinition,
            metadata
        });
    } catch (err) {
        console.debug('journey definition save failed cause', err.message, err.code);
        throw err
    }
}

export async function journeySlotSave(appId, journeyId, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}/slots`, data);
    } catch (err) {
        console.debug('journeySlotSave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeyContextSave(appId, journeyId, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}/context`, data);
    } catch (err) {
        console.debug('journeyContextSave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function journeyDelete(appId, id) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/journey/${id}`);
    } catch (err) {
        console.debug('journey delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function uploadUrlGet(appId, journeyId, request) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}/resources/url/upload`, request);
        if (status === 200) {
            return response;
        } else {
            throw new Error(`Error api ${response}, status ${status}`)
        }
    } catch (err) {
        console.debug('uploadUrlGet failed cause', err);
        throw err;
    }

}

export async function viewUrlGet(appId, journeyId, request) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}/resources/url/view`, request);
        if (status === 200) {
            return response;
        } else {
            throw new Error(`Error api ${response}, status ${status}`)
        }
    } catch (err) {
        console.debug('viewUrlGet failed cause', err);
        throw err;
    }
}

export async function fileUpload(preSignedUrl, file) {
    try {
        const headers = new Headers();
        const requestOptions = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
            headers,
        };
        return await fetch(preSignedUrl, requestOptions)
    } catch (err) {
        console.debug('file upload failed cause', err);
        return null;
    }
}
