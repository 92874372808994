import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";

export function LiveAgentActionNode({ id, data }) {
    return (
        <NodeComponent title="Live Agent Action" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <div></div>
        </NodeComponent>
    );
}

