import React from "react";
import { HiOutlinePlayCircle } from "react-icons/hi2";


const PreviewButton = ({ redirectToWebPlugin }) => {
  return (
    <button
      onClick={redirectToWebPlugin}
      className="
      text-md inline-flex items-center justify-center gap-1 rounded-md transition-all h-10 ring-indigo-100
      pl-4 pr-5 whitespace-nowrap bg-indigo-100 border-indigo-200 dark:bg-indigo-950 border hover:ring-4
      hover:bg-indigo-200 text-indigo-800 dark:border-indigo-900 dark:text-gray-50 dark:ring-indigo-900"
    >
      <span className="sr-only">Preview button</span>
      <HiOutlinePlayCircle size={20} />
      Preview
    </button>
  );
};

export default PreviewButton;


/***
 * <button
      title="Preview"
      onClick={redirectToWebPlugin}
      className="
      w-10 h-10 text-gray-500 flex items-center justify-center rounded-md transition-all
      hover:bg-gray-100 dark:hover:bg-gray-800  hover:text-indigo-500 active:scale-90"
    >
      <span className="sr-only">Preview button</span>
      <CgPlayTrackNextO  size={24} />
    </button>
 */