import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import { FaRegCopy } from "react-icons/fa";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import Message from "../../../components/toast-message";

let htmlContent = `<script src="https://web-plugin.botcircuits.com/plugin/sdk.min.js"></script>
<script >
    botcircuits_init('<APP_ID>', '<ACCESS_KEY>')
</script>`;

const WebScriptSnippet = () => {
  const { appId } = useAppInfo();
  htmlContent = htmlContent.replace("<APP_ID>", appId);

  const webIntegrationConfigType = `app_${appId}`;
  const webIntegrationConfigName = `web_integration`;

  const [webPluginCode, setWebPluginCode] = useState(htmlContent);
  const [copySuccess, setCopySuccess] = useState();

  const copyToClipboard = async (e) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(webPluginCode);
    } else {
      document.execCommand("copy", true, webPluginCode);
    }

    setCopySuccess(true);
  };

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    }
  }, [copySuccess]);

  return (
    <>
      <p className="text-sm leading-8 text-gray-500">
        Copy this code snippet into your web app
      </p>
      <div className="flex items-start space-x-4">
        <Editor
          height="10vh"
          defaultLanguage="html"
          value={webPluginCode}
          theme="vs-dark"
          readOnly={true}
        />

        <button
          type="button"
          onClick={copyToClipboard}
          className="inline-flex items-center justify-center gap-2 text-sm leading-6 rounded-md transition py-2 px-5 whitespace-nowrap 
          bg-indigo-600 hover:bg-indigo-700 hover:ring-4 ring-indigo-200 dark:ring-indigo-900 text-white tracking-wider"
        >
          Copy
          <span className="sr-only">copy to clipboard</span>
        </button>
      </div>
      <div className="flex flex-row justify-center items-center">
        {copySuccess && <Message text="Copied" type="success" />}
      </div>
    </>
  );
};

export default WebScriptSnippet;
