import {useCallback, useState} from 'react';
import {useAppInfo} from '../../../../helpers/hooks/common-hook';
import Message from "../../../../components/toast-message";
import {saveAccessKeyByChannel} from '../../../../services/integrate/fb-integrate-service';
import TextBox from "../../../../components/forms/text-box";
import Button from "../../../../components/button/button";
import {useNavigate} from "react-router-dom";

const channel = 'twilio';

function SaveAccessKey({onSaveComplete}) {
    const { appId } = useAppInfo();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        accountSID: '',
        authToken: ''
    });

    const [error, setError] = useState({
        accountSID: false,
        authToken: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const validateForm = () => {
        let valid = true;
        if (!formData.accountSID) {
            error.accountSID = true;
            valid = false;
        } else {
            error.accountSID = false;
        }

        if (!formData.authToken) {
            error.authToken = true;
            valid = false;
        } else {
            error.authToken = false;
        }

        setError({...error})
        return valid;
    }

    const handleChange = useCallback(event => {
        formData[event.target.name] = event.target.value
        setFormData({...formData});
        validateForm()
    });

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const {response, status} = await saveAccessKeyByChannel(appId, channel, {
                accessKey: JSON.stringify(formData)
            });
            if (status === 200) {
                onSaveComplete(true)
                updateFormState(false, true, false);
            } else {
                updateFormState(false, false, true, response);
            }
        }
    };

    return (
        <div className="py-4 flex flex-col gap-4">
            {formState.failed && <Message text={formState.errorMessage} type="failure"/>}
            {formState.success && <Message text="Successfully save auth token" type="success"/>}

            <div className="grid grid-cols-3 h-1/2 gap-2">
                <TextBox
                    rootClass="col-span-4"
                    label="Account SID"
                    type="text"
                    name="accountSID"
                    value={formData.accountSID}
                    placeholder="Enter Account SID"
                    onChange={handleChange}
                    error={error.accountSID}
                />

                <TextBox
                    rootClass="col-span-4"
                    label="Auth Token"
                    type="password"
                    name="authToken"
                    value={formData.authToken}
                    placeholder="Auth Token"
                    onChange={handleChange}
                    error={error.authToken}
                />
            </div>

            <div className="grid grid-cols-2 h-1/2 gap-2">
                <div></div>
                <div className="flex flex-row justify-end gap-2">
                    <Button
                        color="default"
                        text="Cancel"
                        onClick={()=>{ onSaveComplete() }}
                    >
                    </Button>
                    {formState.processing ?
                        <Button
                            text="Save Auth Token"
                            loading={formState.processing}
                        >
                        </Button>
                        :
                        <Button
                            text="Save Auth Token"
                            onClick={handleOnSubmit}
                        >
                        </Button>
                    }

                </div>
                <div></div>
            </div>

        </div>)
}

export default SaveAccessKey;