export default function TabOpen({ rootClass="pt-3 w-1/2", text, url, onReload }) {
    return (
        <div className={rootClass}>
            <div className="flex flex-row justify-end items-center gap-2">
                {onReload &&
                    <svg onClick={onReload} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                }

                <a className="text text-sm underline text-blue-600 font-bold" rel="noreferrer" target="_blank" href={url} >{text}</a>
            </div>
        </div>

    )
}