import React, { useCallback } from "react";
import TextBox from "../../../../components/forms/text-box";
import DynamicRows from "../../../../components/dynamic-rows";

export default function WebhookConfigList({
                                              onDynamicRowChange,
                                              savedSettings,
                                              fieldName,
                                              title,
                                              showTopDivider,
                                              showBottomDivider
                                          }) {
    const onChangeData = useCallback(
        (rowsData = []) => {
            onDynamicRowChange(rowsData, fieldName);
        },
        [onDynamicRowChange, fieldName]
    );

    const renderRow = useCallback(
        (valuesMap, handleInputChange) => (
            <>
                <TextBox
                    rootClass=""
                    label="Name"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={valuesMap.name}
                    onChange={handleInputChange}
                />
                <TextBox
                    rootClass=""
                    label="Value"
                    type="text"
                    name="value"
                    placeholder="{slot}"
                    value={valuesMap.value}
                    onChange={handleInputChange}
                />
            </>
        ),
        []
    );

    const data = savedSettings.webhookConfig[fieldName] || [];

    return (
        <DynamicRows
            title={title}
            className=""
            data={data}
            onChange={onChangeData}
            showTopDivider={showTopDivider}
            showBottomDivider={showBottomDivider}
            rowsTemplate={renderRow}
        />
    );
}
