import PageContainer from "../../components/page-container";
import {useEffect, useState} from "react";
import {analyticsQuery} from "../../services/analytics";
import {useAppInfo} from "../../helpers/hooks/common-hook";
import {Table} from 'flowbite-react';

function AnalyticCard({value, name}) {
    return <div className="mx-auto flex max-w-xs flex-col gap-y-4">
        <dt className="text-base leading-7 text-gray-600">{name}</dt>
        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            {value}
        </dd>
    </div>
}

function AnalyticCardLoader() {
    return <div role="status" className="max-w-sm animate-pulse">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
        <span className="sr-only">Loading...</span>
    </div>
}

function AnalyticsTable({data = []}) {
    return (
        <div className="">
            <Table>
                <Table.Head>
                    <Table.HeadCell><p className="">Action</p></Table.HeadCell>
                    <Table.HeadCell><p className="flex flex-row justify-center items-start">Executions</p>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {data.map((record, index) =>
                        <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell><p className="">{record.action}</p></Table.Cell>
                            <Table.Cell><p className="flex flex-row justify-center items-start">{record.executions}</p>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
}

function AnalyticsTableLoader() {
    return <div className="p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
        <div className="flex items-center justify-between">
            <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
            <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
            <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
            <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <div className="flex items-center justify-between pt-4">
            <div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
        <span className="sr-only">Loading...</span>
    </div>
}

export default function Analytics() {
    const {appId} = useAppInfo();
    const [sessionInfoData, setSessionInfoData] = useState({
        totalSessionsCount: 0,
        totalHumanSessionsCount: 0,
        totalAiSessionsCount: 0
    });

    const [sessionInfoDataLoadingState, setSessionInfoDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    async function fetchSessionInfoData() {
        updateSessionInfoDataLoadingState(true, false, false);
        const {response, status} = await analyticsQuery(appId, {'queryType': 'sessionInfo'});
        if (status === 200) {
            setSessionInfoData(response.result);
        }
        updateSessionInfoDataLoadingState(false, false, false);
    }

    const updateSessionInfoDataLoadingState = (processing, success, failed, message) => {
        setSessionInfoDataLoadingState({processing, success, failed, message})
    }

    // ---
    const [actionsCountData, setActionsCountData] = useState([]);

    const [actionsCountDataDataLoadingState, setActionsCountDataDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    async function fetchActionsCountData() {
        updateActionsCountDataDataLoadingState(true, false, false);
        const {response, status} = await analyticsQuery(appId, {'queryType': 'actionsCount'});
        if (status === 200) {
            setActionsCountData(response.result);
        }
        updateActionsCountDataDataLoadingState(false, false, false);
    }

    const updateActionsCountDataDataLoadingState = (processing, success, failed, message) => {
        setActionsCountDataDataLoadingState({processing, success, failed, message})
    }

    useEffect(() => {
        fetchSessionInfoData();
        fetchActionsCountData();
    }, []);

    return (
        <PageContainer
            title="Analytics"
            subtitle="Analyzing Conversations for Deeper Insights"
        >

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                        {sessionInfoDataLoadingState.processing ?
                            <>
                                <AnalyticCardLoader/>
                                <AnalyticCardLoader/>
                                <AnalyticCardLoader/>
                            </>
                            :
                            <>
                                <AnalyticCard value={sessionInfoData.totalSessionsCount} name="Total Sessions"/>
                                <AnalyticCard value={sessionInfoData.totalAiSessionsCount}
                                              name="Total Sessions handle by Bot"/>
                                <AnalyticCard value={sessionInfoData.totalHumanSessionsCount}
                                              name="Total Sessions handle by Human"/>
                            </>
                        }
                    </dl>
                </div>
            </div>

            <div>
                {actionsCountDataDataLoadingState.processing?
                    <AnalyticsTableLoader/>
                :
                    <AnalyticsTable data={actionsCountData}/>
                }
            </div>

        </PageContainer>
    )
}
