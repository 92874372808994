import { Button, Modal } from "flowbite-react";
import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import ConfirmDialog from "../../components/confirm-dialog/confirm-dialog";
import { SLOT_LABEL } from "../../consts/common-label";
import { journeyGet, journeySlotSave } from "../../services/model/journey-service";
import Table from "../table";
import TextBox from "../forms/text-box";
import { HiPlus, HiSearch } from "react-icons/hi";
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";

function Page({ appId, journeyId, handleOpenSlotCreate, handleSetEditData, open, onCancel }) {
    const [isOpen, setIsOpen] = useState(open);
    const workflowCanvasContext = useContext(WorkflowCanvasContext);
    const [originalSlots, setOriginalSlots] = useState([]);
    const [slotsForView, setSlotsForView] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [savedJourney, setSavedJourney] = useState({
        slots: {},
        contextConfig: {
            inputContexts: []
        }
    });
    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState(false);
    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    async function fetchSavedJourney() {
        updateFormState(true, false, false)
        const savedJourney = await journeyGet(appId, journeyId);
        setSavedJourney(savedJourney);
        /* saving a copy of slots to handle the search and table render */
        const { slots } = savedJourney;
        if (slots && Object.keys(slots).length) {
            const slotsArray = Object.keys(slots).map(key => ({ key, ...slots[key] }));
            setSlotsForView([...slotsArray]); // for table render
            setOriginalSlots([...slotsArray]); // for keyword search
            workflowCanvasContext.setMetadata({
                ...workflowCanvasContext.matadata,
                slots: savedJourney.slots || {}
            })
        }
        updateFormState(false, false, false)
    }

    useEffect(() => {
        fetchSavedJourney()
    }, []);

    const handleSelectDelete = id => {
        setDeletingData(id);
        setDeleteRevokeConfirmDialog(true);
    }

    const handleConfirmDelete = async confirmed => {
        if (confirmed) {
            const newSlots = { ...savedJourney.slots }
            delete newSlots[deletingData]
            await handleDeleteSlot(newSlots);
        }
        setDeleteRevokeConfirmDialog(false);
        setDeletingData()
    }

    const handleDeleteSlot = async slots => {
        updateFormState(true, false, false);
        const { response, status } = await journeySlotSave(appId, savedJourney.journeyId, { slots });
        if (status === 200) {
            updateFormState(false, true, false);
            await fetchSavedJourney();
        } else {
            updateFormState(false, false, true, response);
        }
    }

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCancel();
    });

    const handleSearch = (e) => {
        const { value } = e?.target; // read input
        setSlotsForView(() => {
            const filteredArray = originalSlots.filter((slot) => slot.key.includes(value)); // get options from original slots
            if (filteredArray?.length) {
                return ([ ...filteredArray ]); // update the slots used for viewing. This will not change the original set
            }
            return ([]);
        });
        setSearchKey(value);
    }

    const processDataList = slotsForView?.map(slot => {
        const { key, dependencies } = slot;
        return {
            record: key,
            columnsData: [
                { data: key, isIdField: true },
                {
                    data: <>
                        {dependencies && dependencies.length > 0 ?
                            <div className="flex flex-wrap gap-2">
                                {dependencies.map((dependency, di) =>
                                    <div
                                        key={di}
                                        className="flex text-orange-500 rounded-2xl bg-yellow-100 px-3 h-6 flex-row items-center border border-yellow-200"
                                    >
                                        <span>{dependency}</span>
                                    </div>
                                )}
                            </div> :
                            <div className="flex flex-wrap gap-2">
                                <div className="flex flex-row items-center gap-2">
                                    <p className="text-slate-300">
                                        No Dependent Slots
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                },
                {
                    options: [
                        {
                            name: 'Edit',
                            onClick: () => handleSetEditData({ slot: key, ...slot })
                        },
                        {
                            name: 'Delete',
                            onClick: () => handleSelectDelete(key)
                        }
                    ]
                },
            ]
        }
    })

    const rootRef = useRef(null);

    return (
        <div ref={rootRef}>
            <Modal show={true} size="3xl" popup onClose={handleOnCancel} root={rootRef.current ?? undefined}>
                <Modal.Header className="pl-6 pr-3 pt-4">
                    Variables
                </Modal.Header>
                <Modal.Body>
                    <p className="text-sm text-gray-500 w-3/4">{SLOT_LABEL}</p>
                    <div className="mt-6 -mb-5 flex flex-row justify-between">
                        <div className="basis-1/2 -mt-2 relative">
                            <TextBox extraInputClass="pl-8" value={searchKey} onChange={handleSearch} type="text" placeholder="Type and search" />
                            <HiSearch size={20} className="absolute left-2 top-1/2 -translate-y-1/2 text-slate-300" />
                        </div>
                        <div>
                            <Button size="sm" color="light" onClick={handleOpenSlotCreate}>
                                <span className="inline-flex gap-1 items-center text-blue-700"><HiPlus size={18} /> Add Variable</span>
                            </Button>
                        </div>
                    </div>
                    <div className="col-span-6">
                        {openDeleteConfirmDialog &&
                            <ConfirmDialog
                                key="context"
                                open={openDeleteConfirmDialog}
                                onCompletion={handleConfirmDelete}
                                message="Are you sure Do you want to delete this Variable ?"
                            />
                        }
                    </div>
                    <Table
                        headers={['Slot', 'Dependent Slots']}
                        items={processDataList}
                        dataLoadingState={formState}
                        itemsNotFoundMessage="You don't have any variables"
                        modifiedColumn={false}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Page;
