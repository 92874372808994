import { get, post, put } from "../../helpers/utils/http-client";
import { USERS_ENDPOINT, API_PREFIX } from '../../consts/api-endpoints';

export async function getMyUserProfile(request) {
    try {
        return await get(`${USERS_ENDPOINT}/me`, request);
    } catch (err) {
        console.debug('getMyUserProfile failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function updateUserProfile(request) {
    try {
        return await put(`${USERS_ENDPOINT}`, request);
    } catch (err) {
        console.debug('updateUserProfile failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function contactUs(request) {
    try {
        return await post(`${USERS_ENDPOINT}/contact-us`, request);
    } catch (err) {
        console.debug('contact-us failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function requestEarlyAccess(request) {
    try {
        return await post(`${API_PREFIX}/request-early-access`, request);
    } catch (err) {
        console.debug('requestEarlyAccess failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}