import React from "react";
import PropTypes from "prop-types";
import { CONTENTS_NAVITAION } from "../consts/navigations";
import MenuItem from "../_components/menu-item";

const navigations = CONTENTS_NAVITAION;

export default function InternalContentsComponent() {
  return (
    <div className="w-full h-full">
      {/* Header */}
      <div className="px-4 pr-2 dark:border-gray-700 h-14 flex items-center justify-between">
        <h4 className="font-bold text-lg dark:text-white">Contents</h4>
      </div>
      {/* Navigations */}
      <ul>
        {navigations?.map((el, i) => {
          return (
            <MenuItem
              key={i}
              element={el}
              border={i !== navigations.length - 1}
            />
          );
        })}
      </ul>
    </div>
  );
}

InternalContentsComponent.propTypes = {
  headerAction: PropTypes.element,
  navigation: PropTypes.array,
};
