import {useLocation} from "react-router-dom";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import {CHOICE_NODE_TYPE} from "../pages/action-journey-studio/common/const/node-types";



export const getErrorMessage = (error) => {

    if (error.response) {

        if (error.response.data) {
            return error.response.data.message || error.response.data.error || error.response.data.detail;
        }

        return error.response.data;
    } else {
        return error.message;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {

        let param = query.get(key);

        if (Array.isArray(data[key])) {

            if (param) {
                param = param.split(",");
            } else {
                param = [];
            }
        }

        data = {...data, [key]: param || ""}
    }
    return data;
}

export const dateTimeFormat = date => {
    if (date) {
        return dayjs(date).format("YYYY-MM-DD, h:mm A");
    } else {
        return "N/A"
    }
}

export const fileDownloadDateFormat = date => {
    if (date) {
        return dayjs(date).format("YYYY-MM-DD_h:mm_A");
    } else {
        return "N/A"
    }
}

export const enumFormatter = value => {

    if (!value) return "";

    return value.replaceAll("_", " ");
}

export const longToDate = date => {
    if (!date) return;
    return new Date(date);
}







export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length < 1) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        let param = params[key];

        if (Array.isArray(params[key])) {
            param = param.toString();
        }

        if (index === 0) {
            result += `?${key}=${param}`;
        } else {
            result += `&${key}=${param}`;
        }

    });

    return result;
}

export const getNameFromPath = (path) => {

    if (path) {
        const split = path.split("/");
        return split[split.length - 1];
    }

}



// swap positions of array object
export const swapPositions = (array, id1, id2) => {
    // Find the index of the first and second IDs
    const index1 = array.findIndex(item => item.id === id1);
    const index2 = array.findIndex(item => item.id === id2);

    // If both IDs are found, swap their positions
    if (index1 !== -1 && index2 !== -1) {
        [array[index1], array[index2]] = [array[index2], array[index1]];
    } else {
        console.log('One or both IDs not found');
    }
    return array;
};


export const enumToCapitalize = value => {
    if (!value) return "";
    const splitWord = value.split("_");
    return splitWord.map(word => word[0].toUpperCase() + word.substring(1).toLowerCase()).join(" ");
}

export const moveDefaultOperatorToEnd = (arr) => {
    // Separate "Default" operator objects from others
    const nonDefaultOperators = arr.filter(item => item.operator !== "DEFAULT");
    const defaultOperators = arr.filter(item => item.operator === "DEFAULT");

    // Concatenate non-default operators first, followed by default operators
    return [...nonDefaultOperators, ...defaultOperators];
}

export function moveConditionToLast(arr) {
    const index = arr.findIndex(item => item.nodeType === CHOICE_NODE_TYPE);

    if (index !== -1) {
        const [item] = arr.splice(index, 1); // Remove the item with id 2
        arr.push(item); // Add it to the end
    }

    return arr;
}
