import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";

export function PauseActionNode({ id, data }) {
    return (
        <NodeComponent title="Pause" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <div></div>
        </NodeComponent>
    );
}

