import React, {useCallback, useEffect, useRef, useState} from "react";
import { useAppInfo } from "../../../../../helpers/hooks/common-hook";
import { textGen } from "../../../../../services/gen-ai-utils";
import { Button } from 'flowbite-react';
import { HiOutlineSparkles } from "react-icons/hi2";
import DynamicRows from "../../../../../components/dynamic-rows";
import TextArea from "../../../../../components/forms/text-area";
import ResponseAIModify from "../response-ai-modify-select";
import DynamicRowManager from "../../../../../components/dynamic-rows-v3";
import isEqual from 'lodash/isEqual';

const prepareRawsData = (data = []) => {
  return data.map(header => ({ inputs: header }))
}

const DisplayTexts = React.memo(({
  displayTopBorder = true,
  parameterName,
  displayTextOptionsProps = [],
  rootClassName = "",
  responseModifyByAI,
  onChangeData
}) => {
  const { appId } = useAppInfo();
  const [displayTextData, setDisplayTextData] = useState([]);
  const [numOfOptions, setNumOfOptions] = useState(3);

  useEffect(() => {
    if (displayTextOptionsProps && displayTextOptionsProps.length === 0) {
      setDisplayTextData([{ displayText: "" }]);
    } else if (!isEqual(displayTextOptionsProps, displayTextData)) {
      setDisplayTextData(displayTextOptionsProps);
    }
  }, [displayTextOptionsProps]);

  const prevDisplayTextData = useRef(displayTextData);

  useEffect(() => {
    if (!isEqual(displayTextData, prevDisplayTextData.current)) {
      onChangeData({
        target: {
          name: 'displayTextOptions',
          value: displayTextData
        }
      });
      prevDisplayTextData.current = displayTextData;
    }
  }, [displayTextData]);

  const [textGenResult, setTextGenResult] = useState();

  const [textGenState, setTextGenState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null
  });

  const updateDataLoadingState = (processing, success, failed, message) => {
    setTextGenState({ processing, success, failed, message })
  }

  function getPrompt() {
    let prompt;
    if (displayTextData
        &&
        displayTextData.length > 0 && displayTextData[0].displayText) {
      prompt = `rewrite and fix grammar of this text '${displayTextData[0].displayText}'`
    } else if (parameterName) {
      prompt = `write question asking ${parameterName}`
    } else {
      return null;
    }
    return `${prompt}. need ${numOfOptions} variants`;
  }

  const onHandleSubmit = async () => {
    const prompt = getPrompt()
    if (prompt) {
      updateDataLoadingState(true, false, false);
      const response = await textGen(
        appId,
        { prompt }
      )
      if (response) {
        updateDataLoadingState(false, true, false);
        setTextGenResult(response);
      } else {
        updateDataLoadingState(false, false, true, 'Unable to generate text');
      }
    }
  }

  useEffect(() => {
    try {
      const formData = textGenResult.result.map(r => ({ displayText: r }))
      setDisplayTextData(formData)
    } catch (err) { }
  }, [textGenResult]);

  const renderComponent = useCallback(({ key, item, onChange }) => (
      <div className="flex flex-row items-center gap-2 w-full pr-2">
        <TextArea
            key={key}
            rootClass="w-full"
            label={""}
            value={item.value}
            onChange={onChange}
            variant="outlined"
            name={item.name}
            placeHolder='Add use display text'
        />
      </div>
  ), []);

  return (
    <div className={rootClassName}>
      <DynamicRowManager
          title="Display Text Variants"
          className="pt-2 pb-4"
          removeButtonClassName="pt-0 flex-row justify-end"
          showTopDivider={displayTopBorder}
          showBottomDivider={false}
          initialData={displayTextData}
          setInitialData={setDisplayTextData} // Pass setter to allow updates
          renderComponent={renderComponent}
          rowSpec={[{field: 'displayText', label: 'Display Text'}]}
      />
      <div>
        <Button
          color="blue"
          className="border border-indigo-400 w-full hover:text-indigo-700"
          isProcessing={textGenState.processing}
          onClick={onHandleSubmit}
        >
          {!textGenState.processing &&
            <HiOutlineSparkles size={18} className="mr-2" />
          }
          Generate Text with AI
        </Button>
      </div>

      <div className="pt-4">
        <ResponseAIModify
            responseModifyByAI={responseModifyByAI}
            onChange={onChangeData}/>
      </div>
    </div>)
})

export default DisplayTexts;