import dayjs from 'dayjs';
export const botMessageType = 'bot';
export const selfMessageType = 'self';

export function createMessagePayload({
    userId,
    senderType,
    type,
    messageId,
    message,
    data,
    slots,date

}) {
    return {
        userId,
        senderType,
        type,
        messageId,
        message,
        data,
        slots,date
    }
}

export function createSelfMessagePayload(message, date) {
    return createMessagePayload({
        senderType: selfMessageType,
        type: message.type,
        messageId: "user",
        message: message.content,
        date: getMessageTime(date)
    })
}

export function createBotMessagePayload(message, date) {
    return createMessagePayload({
        senderType: botMessageType,
        type: message.type,
        messageId: "bot",
        message: message.content,
        date: getMessageTime(date)
    })
}

function getMessageTime(date) {
    try {
        return (dayjs(date) || dayjs()).format("hh:mm A");
    } catch (e) {
        console.error("getMessageTime failed", e);
        return date;
    }
}