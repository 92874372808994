import { get, post } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

export async function runtimeText(appId, request) {
    try {
        console.log("runtimeText", request)
        return await post(`${APPS_ENDPOINT}/${appId}/runtime/test`, request);
    } catch (err) {
        console.debug('runtime post-text failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getRuntimeDebugSession(appId, sessionId) {
    try {
        return await get(`${APPS_ENDPOINT}/${appId}/runtime/debugger/get-session/${sessionId}`);
    } catch (err) {
        console.debug('getRuntimeDebugSession failed cause', err.message, err.code);
        return null
    }
}
