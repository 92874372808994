import API, { graphqlOperation } from '@aws-amplify/api'
import appConfig from "./../../../config";

export const config = {
    "aws_appsync_graphqlEndpoint": appConfig.REALTIME_API_BASE_URL,
    "aws_appsync_region": appConfig.REGION,
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "null",
}

export const subscribeBotMessageDoc = /* GraphQL */ `
    subscription Subscribe($appId: String!, $sessionId: String!) {
        subscribeBotMessage(appId: $appId, sessionId: $sessionId) {
            data
            appId
            sessionId
        }
    }
`

export const subscribeUserMessageDoc = /* GraphQL */ `
    subscription Subscribe($appId: String!, $sessionId: String!) {
        subscribeUserMessage(appId: $appId, sessionId: $sessionId) {
            data
            appId
            sessionId
        }
    }
`

export const sendBotMessageDoc = /* GraphQL */ `
    mutation Publish($data: AWSJSON!, $appId: String!, $sessionId: String!) {
        sendBotMessage(data: $data, appId: $appId, sessionId: $sessionId) {
            data
            appId
            sessionId
        }
    }
`

export const sendUserMessageDoc = /* GraphQL */ `
    mutation Publish($data: AWSJSON!, $appId: String!, $sessionId: String!) {
        sendUserMessage(data: $data, appId: $appId, sessionId: $sessionId) {
            data
            appId
            sessionId
        }
    }
`

export async function sendBotMessage(authToken, appId, sessionId, data) {
    console.log("sendBotMessage", authToken, sessionId, data)
    return await API.graphql(graphqlOperation(sendBotMessageDoc, { appId, sessionId, data }, authToken))
}

export async function sendUserMessage(authToken, appId, sessionId, data) {
    return await API.graphql(graphqlOperation(sendUserMessageDoc, { appId, sessionId, data }, authToken))
}

export function subscribeBotMessage(authToken, appId, sessionId, next, error) {
    return API.graphql(graphqlOperation(subscribeBotMessageDoc, { appId, sessionId }, authToken)).subscribe({
        next: ({ provider, value }) => {
            console.log("subscribeBotMessage", value)
            next(value.data.subscribeBotMessage, provider, value)
        },
        error: error || console.log,
    })
}

export function subscribeUserMessage(authToken, appId, sessionId, next, error) {
    return API.graphql(graphqlOperation(subscribeUserMessageDoc, { appId, sessionId }, authToken)).subscribe({
        next: ({ provider, value }) => {
            console.log("subscribeUserMessage", value)
            next(value.data.subscribeUserMessage, provider, value)
        },
        error: error || console.log,
    })
}