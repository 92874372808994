import React, {useEffect, createContext, useCallback, useState} from 'react';
import {addEdge, MarkerType, useEdgesState, useNodesState} from "reactflow";
import {journeyDefinitionSave} from "../services/model/journey-service";
import { debounce } from 'lodash'
import {CHOICE_NODE_TYPE} from "../pages/action-journey-studio/common/const/node-types";

const DEBOUNCE_DELAY = 300; // Set a delay in milliseconds (500ms in this example)


export const WorkflowCanvasContext = createContext("WorkflowContext");

const WorkflowCanvasContextProvider = ({children}) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [nodeDrawerVisible, setNodeDrawerVisibility] = useState(false);
    const [nodeAttribute, setNodeAttribute] = useState(null);

    const [edgeLabelModalVisible, setEdgeLabelModalVisible] = useState(false);
    const [selectedEdgeConnection, setSelectedEdgeConnection] = useState(null);
    const [edgeLabel, setEdgeLabel] = useState("");

    const [metadata, setMetadata] = useState(null);

    const [selectedStepId, setSelectedStepId] = useState(null);

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [backgroundSyncActive, setBackgroundSyncActive] = useState(false);

    const [deletableChildId, setDeletableChildId] = useState(null);

    const [saveForDelete, setSaveForDelete] = useState(false);

    const [isPreview, setIsPreview] = useState(false);

    const [currentJourneySession, setCurrentJourneySession] = useState({});
    const [currentJourneyConfig, setCurrentJourneyConfig] = useState({});

    const onDragStart = (event, nodeData) => {
        event.dataTransfer.setData("application/reactflow", nodeData.nodeType);
        event.dataTransfer.setData('nodeData', JSON.stringify(nodeData));
    };

    const onDragOver = (event) => {
        event.preventDefault(); // Allow dropping by preventing default behavior
    };

    const calculateHandleSource = (sourcePos, targetPos) => {
        const dx = sourcePos.x - targetPos.x;
        const dy = sourcePos.y - targetPos.y;
        const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
        const normalizedAngle = (angle + 360) % 360;

        if (normalizedAngle >= 337.5 || normalizedAngle < 22.5) {
            return 'left';
        } else if (normalizedAngle >= 22.5 && normalizedAngle < 67.5) {
            return 'left';
        } else if (normalizedAngle >= 67.5 && normalizedAngle < 112.5) {
            return 'right';
        } else if (normalizedAngle >= 112.5 && normalizedAngle < 157.5) {
            return 'right';
        } else if (normalizedAngle >= 157.5 && normalizedAngle < 202.5) {
            return 'right';
        } else if (normalizedAngle >= 202.5 && normalizedAngle < 247.5) {
            return 'right';
        } else if (normalizedAngle >= 247.5 && normalizedAngle < 292.5) {
            return 'left';
        } else if (normalizedAngle >= 292.5 && normalizedAngle < 337.5) {
            return 'left';
        } else {
            return 'right';// Default handle
        }
    };

    const onEdgeUpdateForMove = (event, node) => {
        setEdges((eds) => {
            return eds.map((edge) => {
                const sourceNode = nodes.find((n) => n.id === edge.source);
                const targetNode = nodes.find((n) => n.id === edge.target);

                console.log('edge', edge)
                if (sourceNode && targetNode) {
                    // const targetHandleId = calculateHandle(
                    //     sourceNode.positionAbsolute || sourceNode.position,
                    //     targetNode.positionAbsolute || targetNode.position
                    // );

                    const sourceHandleClass = calculateHandleSource(
                        sourceNode.positionAbsolute || sourceNode.position,
                        targetNode.positionAbsolute || targetNode.position
                    );
                    let sourceHandleId = edge.sourceHandle.replaceAll('left', '')
                    sourceHandleId = sourceHandleId.replaceAll('right', '')
                    sourceHandleId = sourceHandleId + sourceHandleClass
                    console.log('!!!! sourceHandleId', sourceHandleId)
                    return {
                        ...edge,
                        // targetHandle: targetHandleId,
                        sourceHandle: sourceHandleId
                    };
                }
                return edge;
            });
        });
    }

    const onConnect = useCallback(
        (connection) => {

            const sourceNode = nodes.find((node) => node.id === connection.source);

            const edge = {
                ...connection,
                type: 'custom',
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 20,
                    height: 20,
                    color: '#969b9f',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#969b9f',
                },
            };

            /*
            * Start of maximum one output connection
            * */

            let filteredEdges = edges;

            // if (!sourceNode?.data?.children[sourceNode?.data?.children?.length - 1]?.stateConfig?.choices) {
            // // if (!sourceNode?.data?.children[sourceNode?.data?.children?.length - 1]?.children) {
            //     filteredEdges = edges.filter(edg => edg.source !== connection.source);
            // }

            /*
            * End of maximum one output connection
            * */


            setEdges(() => addEdge(edge, filteredEdges));

        }, [nodes, setEdges]
    );

    const openNodeDrawer = (item, parentId) => {

        setNodeAttribute({...item, parentId});
        setNodeDrawerVisibility(true);

    };

    const closeNodeDrawer = () => {

        setNodeDrawerVisibility(false);

    }

    const handleNodeConfigChange = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                [name]: value,
            }
        }

        setNodeAttribute(_nodeAttribute);
    }

    const handleNodeFullConfigChange = newConfig => {
        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                ...newConfig,
            }
        }

        console.log('handleNodeFullConfigChange nodeAttribute', nodeAttribute)
        console.log('handleNodeFullConfigChange newConfig', newConfig)

        const blockIndex = nodes
            .findIndex(item => item.id === nodeAttribute.parentId);
        const blockNode = nodes[blockIndex]
        blockNode.data.children = blockNode.data.children.map(step => {
            if (step.id === nodeAttribute.id) {
                return {
                    ...step,
                    stateConfig: newConfig
                }
            }
            return step
        })
        nodes[blockIndex] = blockNode
        setNodes([...nodes])
        setNodeAttribute(_nodeAttribute);
    }

    const addNodeAttributeTextVariants = () => {

        let _nodeAttributeTextVariants = [];

        if (nodeAttribute?.stateConfig?.displayTextOptions?.length > 0) {
            _nodeAttributeTextVariants = nodeAttribute.stateConfig.displayTextOptions;
        }

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: [
                    ..._nodeAttributeTextVariants,
                    {id: new Date().getMilliseconds()}
                ]
            },
        }

        setNodeAttribute(_nodeAttribute);

    }

    const deleteNodeAttributeTextVariants = id => {

        const _nodeAttributeTextVariants = nodeAttribute?.stateConfig?.displayTextOptions?.filter(nodeAttributeTextVariant => nodeAttributeTextVariant.id !== id);

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: _nodeAttributeTextVariants
            }
        }

        setNodeAttribute(_nodeAttribute);

    }

    const nodeAttributeTextVariantOnchange = (e, id) => {

        const {name, value} = e.target;

        const _nodeAttributeTextVariants = nodeAttribute?.stateConfig?.displayTextOptions?.map(nodeAttributeTextVariant => {
            if (nodeAttributeTextVariant.id === id) {
                return {
                    ...nodeAttributeTextVariant,
                    [name]: value
                }
            }
            return nodeAttributeTextVariant;
        });

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                displayTextOptions: _nodeAttributeTextVariants,
            }
        }

        setNodeAttribute(_nodeAttribute)

    }

    const handleDevWebhookConfig = e => {

        const {name, value} = e.target;

        const _nodeAttribute = {
            ...nodeAttribute,
            stateConfig: {
                ...nodeAttribute.stateConfig,
                webhookConfig: {
                    ...nodeAttribute.stateConfig.webhookConfig,
                    [name]: value,
                }
            }
        }

        setNodeAttribute(_nodeAttribute);
    }

    const closeEdgeLabelModal = () => {
        setEdgeLabelModalVisible(false);
    }

    const handleEdgeLabelAdd = () => {
        if (selectedEdgeConnection) {
            // When the user clicks 'OK' in the modal, add the edge with the provided label
            const edge = {
                ...selectedEdgeConnection,
                type: 'custom',
                label: edgeLabel, // Use the input value as the label
                markerEnd: {
                    type: MarkerType.Arrow,
                    width: 20,
                    height: 20,
                    color: '#00A89E',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#00A89E',
                },
            };

            setEdges((eds) => addEdge(edge, eds));
            setEdgeLabel('');
            setSelectedEdgeConnection(null);

            closeEdgeLabelModal();

        }
    }

    const handleEdgeLabelChange = value => {
        setEdgeLabel(value);
    }


    function transformSchema(initialSchema) {
        console.log('initialSchema', initialSchema)
        const targetSchema = {
            startAt: null,
            states: {}
        };

        // Helper function to process children nodes recursively
        function processNode(node, nextNodeId = null, blockId) {
            const { id, nodeType, type, stateConfig } = node;
            stateConfig.blockId = blockId

            if (!stateConfig) {
                return
            }

            if (stateConfig && nodeType === CHOICE_NODE_TYPE && stateConfig.choices.length === 0) {
                return
            }

            // Assign stateConfig and next (next only if it exists)
            targetSchema.states[id] = {
                type,
                stateConfig,
                ...(nextNodeId ? { next: nextNodeId } : {})
            };

            if (!targetSchema.startAt) {
                targetSchema.startAt = String(id);
            }
        }

        // Traverse the nodes and build the target schema
        initialSchema.nodes.forEach(mainNode => {
            if (mainNode.type === 'StartNode') {
                targetSchema.startAt = mainNode.id
                mainNode.data.stateConfig.blockId = mainNode.id
                targetSchema.states[mainNode.id] = {
                    stateConfig: mainNode.data.stateConfig
                };
            } else {
                const children = mainNode.data.children;

                children.forEach((childNode, index) => {
                    const nextChild = children[index + 1] ? String(children[index + 1].id) : null;
                    processNode(childNode, nextChild, mainNode.id);
                });
            }
        });

        // Handle edges to connect different blocks
        initialSchema.edges.forEach(edge => {
            const { source, target } = edge;
            let { sourceHandle } = edge;

            const firstTargetChild = initialSchema.nodes
                .find(node => node.id === target)
                .data.children[0];

            sourceHandle = sourceHandle.replaceAll('-right', '').replaceAll('-left', '')
            if (sourceHandle.startsWith('source-condition')) {
                const splits = sourceHandle.replaceAll('source-condition-', '').split('-')
                const stepId = +splits[0]
                const choicePathId = +splits[1]

                const choice = targetSchema.states[stepId].stateConfig.choices
                    .find(choice => choice.id === choicePathId)
                choice.next = String(firstTargetChild.id);
            } else if (sourceHandle.startsWith('source-else-condition')) {
                const stepId = sourceHandle.replaceAll('source-else-condition-', '')
                targetSchema.states[stepId].stateConfig.next = String(firstTargetChild.id);
            } else if (sourceHandle === 'start') {
                targetSchema.states[source].stateConfig.next = String(firstTargetChild.id);
            } else {
                const node = initialSchema.nodes
                    .find(node => node.id === source)
                if (node && node.data && node.data.children) {
                    const lastSourceChild = node.data.children.slice(-1)[0];
                    targetSchema.states[lastSourceChild.id].next = String(firstTargetChild.id);
                } else {
                    targetSchema.states[+node.id].next = String(firstTargetChild.id);
                }
            }
        });

        return targetSchema;
    }

    const saveWorkflow = async () => {
        if (!metadata || !metadata.appId || !metadata.journeyId || nodes.length === 0){
            return
        }
        setBackgroundSyncActive(true)
        try {
            setSaveSuccess(false)
            const stmDefinition = transformSchema({
                nodes,
                edges
            })
            console.log("stmDefinition", stmDefinition)

            await journeyDefinitionSave(
                metadata.appId,
                metadata.journeyId,
                stmDefinition,
                {
                    nodes,
                    edges,
                }
            );
            setSaveSuccess(true)
        } catch (e) {
            console.error('saved workflow failed', e)
        }
        setBackgroundSyncActive(false)
    }

    const debouncedSaveWorkflow = useCallback(debounce(saveWorkflow, DEBOUNCE_DELAY), [nodeAttribute]);

    // TODO
    // useEffect(() => {
    //     debouncedSaveWorkflow();
    //
    //     // Cleanup function to cancel debounce if component unmounts
    //     return () => {
    //         debouncedSaveWorkflow.cancel();
    //     };
    // }, [
    //     nodes,
    //     edges,
    //     debouncedSaveWorkflow
    // ]);

    // useEffect(() => {
    //     saveWorkflow()
    // }, [
    //     nodeAttribute
    // ]);

    useEffect(() => {
        if (nodeAttribute) {
            debouncedSaveWorkflow();
        }
    }, [nodes, edges, debouncedSaveWorkflow]);

    useEffect(() => {
        if (saveForDelete) {
            saveWorkflow()
        }
        setSaveForDelete(false)
    }, [
        saveForDelete
    ]);

    const handleDelete = (id) => {
        if (deletableChildId) {
            const selectedBlock = nodes.find(item => item.id === id);
            selectedBlock.data.children = selectedBlock.data.children.filter(item => item.id !== deletableChildId);
            if (selectedBlock.data.children.length > 0) {
                const findIndexBlock = nodes.findIndex(item => item.id === id);
                nodes[findIndexBlock] = selectedBlock;
                setNodes([...nodes]);
            } else {
                const updateNodes = nodes.filter(item => item.id !== id);
                setNodes(updateNodes);
            }
            setDeletableChildId(null)
        } else {
            const updatedData = nodes.filter(item => item.id !== id);
            setEdges(eds => eds.filter((edge) => edge.source !== id && edge.target !== id));

            setNodes(updatedData)
        }
        setSaveForDelete(true)
    }

    return (
        <WorkflowCanvasContext.Provider
            value={{
                nodes,
                setNodes,
                edges,
                setEdges,
                onNodesChange,
                onEdgesChange,
                nodeDrawerVisible,
                edgeLabelModalVisible,
                onConnect,
                nodeAttribute,
                setNodeAttribute,
                setMetadata,
                selectedStepId,
                setSelectedStepId,
                metadata,
                saveSuccess,
                backgroundSyncActive,
                deletableChildId,
                setDeletableChildId,
                handleDelete,
                handleDevWebhookConfig,
                openNodeDrawer,
                onDragStart,
                onDragOver,
                closeNodeDrawer,
                handleNodeConfigChange,
                handleNodeFullConfigChange,
                handleEdgeLabelAdd,
                handleEdgeLabelChange,
                closeEdgeLabelModal,
                addNodeAttributeTextVariants,
                deleteNodeAttributeTextVariants,
                nodeAttributeTextVariantOnchange,
                isPreview,
                setIsPreview,
                currentJourneySession,
                setCurrentJourneySession,
                currentJourneyConfig,
                setCurrentJourneyConfig,
                onEdgeUpdateForMove
            }}
        >
            {children}
        </WorkflowCanvasContext.Provider>
    );
}

export default WorkflowCanvasContextProvider;
