// CONTENTS_NAVIGATION
export const CONTENTS_NAVITAION = [
  {
    name: "Functions",
    href: "/functions",
    current: false,
  },
  {
    name: "Knowledge Base",
    href: "/knowledge-base/docs/data-sources",
    current: false,
  },
  {
    name: "Instructions",
    href: "/agent/settings",
    current: false,
  }
]

// INTEGRATIONS_NAVIGATION
export const CHANNELS_NAVIGATION = [
  {
    name: "Web Chat",
    href: "/channels/web",
    current: false,
  },
  // {
  //   name: "Voice",
  //   current: false,
  //   href: "/channels/voice",
  // },
  {
    name: "Facebook Messenger",
    current: false,
    href: "/channels/fb",
  },
  {
    name: "Twilio",
    current: false,
    href: "/channels/twilio",
  },
  {
    name: "Whatsapp",
    current: false,
    href: "/channels/whatsapp",
  },
  {
    name: "Slack",
    current: false,
    href: "/channels/slack",
    // children: [
    //   { name: "API Settings", current: false, href: "/channels/slack/api" },
    //   {
    //     name: "Two Factor Authentication",
    //     current: false,
    //     href: "/channels/slack/two-factor",
    //   },
    // ],
  }
];
