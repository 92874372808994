import { Dropdown } from "flowbite-react";
import React from "react";
import { IoChevronDown } from "react-icons/io5";

export default function DropDown({
  className,
  labelClassName,
  selectedOptionClassName,
  optionClassName,
  label,
  placeHolder,
  name,
  value,
  options = [],
  error,
  errorMessage,
  onChange,
  optional,
    inline
}) {
  const labelClass = error
    ? "block text-sm font-medium text-red-700 dark:text-red-500"
    : "block text-sm font-medium text-gray-900 dark:text-white";
  return (
    <div className={className}>
      <div className="flex flex-col gap-2">
        {label &&
          <label className={labelClassName || labelClass}>
            {label}
            {optional &&
              <span className="pl-2 text-gray-400 italic">- Optional</span>
            }
          </label>
        }
        <Dropdown
          name={name}
          renderTrigger={() =>
            <div className={`flex flex-row gap-2 items-center cursor-pointer bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${inline? 'border-none': ''}`}>
              <div className="w-full">
                <p className={`${value ? 'text-gray-900' : 'text-gray-500'} ${selectedOptionClassName}`}>
                  {value || placeHolder}
                </p>
              </div>
              <IoChevronDown />
            </div>
          }
        >
          {options.map((option, i) => (
            <Dropdown.Item key={i} onClick={() => {
              onChange({
                target: {
                  name,
                  value: option.value || option
                }
              })
            }}>
              <p className={optionClassName}>
                {option.label || option}
              </p>

            </Dropdown.Item>
          ))}
        </Dropdown>
        {error && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            <span className="font-medium">
              {errorMessage || "Please fill out this field"}
            </span>
          </p>
        )}
      </div>
    </div>
  )
}
