import {del, get, post} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function listShopifyConnes(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/integrations/shopify`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('listAuthConnes cause', err.message, err.code);
        return {};
    }
}
export async function getShopifyConn(appId, id) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/integrations/shopify/${id}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('getAuthConnes failed cause', err.message, err.code);
        return {};
    }
}

export async function shopifyConnSave(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/integrations/shopify`, request);
    } catch (err) {
        console.debug('authConnSave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function shopifyConnDelete(appId, id) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/integrations/shopify/${id}`);
    } catch (err) {
        console.debug('authConnDelete failed cause', err.message, err.code);
        return {response: err.displayError, status: err.code};
    }
}