import React, { useState } from "react";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import { Auth } from "aws-amplify";
import PageContainerV2 from "../../../components/page-container/page-container-v2";
import FormLoader from "../../../components/form-loader/form-loader";
import SectionHeading from "../_components/section-heading";
import WebChatAppeareance from "./web-chat-appeareance";
import WebVoiceAssistant from "./web-voice-assistant";
import WebScriptSnippet from "./web-script-snippt";
import WebChatAssistant from "./web-chat-assistant";
import Accordion from "../_components/accordion";
import Badge from "../_components/badge";
import config from "../../../config";
import { useEffect } from "react";

let htmlContent = `<script src="https://web-plugin.botcircuits.com/plugin/sdk.min.js"></script>
<script >
    botcircuits_init('<APP_ID>')
</script>`;

const defaultSettings = {
  title: null,
  subTitle: null,
  welcomeMessage: null,
  inputPlaceHolder: null,
  logo: null,
  headerBackgroundColor: "#6366f1",
  headerTextColor: "#f9fafb",
  chatWindowBackgroundColor: "#f9fafb",
  // chatWindowTextColor: null,
  sendBarBackgroundColor: "#e2e8f0",
  // sendBarTextColor: null,
};

function IntegrationWebchatPage() {
  const { appId } = useAppInfo();
  const appInfo = useAppInfo();
  const [app, setApp] = useState({});
  const [iframeSrc, setIframeSrc] = useState("");
  const [copySuccess, setCopySuccess] = useState();
  const [logoFileObj, setLogoFileObj] = useState();
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(null);
  const [chatBotSetting, setChatBotSetting] = useState(defaultSettings);

  const [webPluginCode, setWebPluginCode] = useState(htmlContent);

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }));
  };

  useEffect(() => {
      try {
        setIframeSrc(`${config.WEB_PLUGIN_URL}/?app_id=${appId}&test_mode=1`);
      } catch (error) {
        console.error('Error fetching access key:', error);
      }

  }, [appId]);
  
  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  const [logoUploadFormData, setLogoUploadFormData] = useState({
    fileExt: "",
  });

  const [logoUploadUrl, setLogoUploadUrl] = useState();

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const [logoUrlGenState, setLogoUrlGenState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(Object.assign({}, { processing, success, failed, message }));
  };

  const updateLogoUrlGenState = (processing, success, failed, errorMessage) => {
    setLogoUrlGenState(
      Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  htmlContent = htmlContent.replace("<APP_ID>", appId);
  const webIntegrationConfigType = `app_${appId}`;
  const webIntegrationConfigName = `web_integration`;

  if (dataLoadingState.processing) {
    return <FormLoader fullScreen={true} text="Loading..." />;
  }

  return (
    <PageContainerV2
      title="WebChat"
      pageType="channels"
      pageSidebar={true}
      subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    >
      <div className="flex h-full flex-1 flex-grow overflow-auto lg:overflow-hidden">
        <div className="flex flex-col flex-1">
          {/* TOP */}
          <SectionHeading title="Web Chat" />
          {/* CONTENT */}
          <div className="relative px-6 pt-3 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
            <Accordion
              initial={true}
              title="Add to website"
              subtitle="Copy this code snippet and paste into your website header tags"
            >
              <WebScriptSnippet />
            </Accordion>
            {/* #Customize */}
            <Accordion
              title="Customize"
              subtitle="Customize the chat widget appeareance"
            >
              <WebChatAppeareance />
            </Accordion>
            {/* #Handoff */}
            {/* <Accordion
              title="Handoff"
              subtitle="Enable transfer to live person"
            >
              <WebChatAssistant />
            </Accordion> */}

            {/* #Voice */}
            {/* TODO : Conditinaly show <Badge/> */}
            {/* <Accordion
              title="Voice"
              subtitle="Add voice AI to provide another way to communicate"
              notifyElement={<Badge text={"Upgrade"} color="purple" />}
            >
              <WebVoiceAssistant />
            </Accordion> */}
          </div>
        </div>
        <div className="h-full border-l dark:border-gray-700 md:w-80 2xl:w-96">
          <SectionHeading title="Preview" />
          {/*  iFrame Widget plugin goes here.*/}
          <div className="mock-ui h-[calc(100%-58px)] flex flex-col overflow-hidden">
            <iframe
              title="webchat plugin"
              src= {iframeSrc}
              className="flex flex-1 flex-grow"
            ></iframe>
          </div>
          {/*  iFrame Widget plugin goes here.*/}
        </div>
      </div>
    </PageContainerV2>
  );
}

export default IntegrationWebchatPage;
