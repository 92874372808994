import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getNotifications} from '../../../services/notification';
import {DATE_FORMAT} from '../../../consts/common-consts';
import {parseAndFormat} from '../../../helpers/utils/date-util';
import Message from "../../../components/message/message-comp";
import Loader from "../../../components/form-loader/form-loader";
import PageContainer from "../../../components/page-container";
import {Accordion} from 'flowbite-react';

const mapsStateToProps = (state, ownProps) => {
    return {
        appCreateNotifyMessage: state.appCreateNotifyMessage
    };
}

const mapDispatchToProps = dispatch => {
    return {};
}

function Index({appCreateNotifyMessage}) {
    const [notifications, setNotifications] = useState([]);
    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    async function fetchData() {
        updateFormState(true, false, false);
        const {status, response} = await getNotifications();
        if (status === 200) {
            const {data, pagination} = response;
            setNotifications(data);
        }

        updateFormState(false, false, false);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [appCreateNotifyMessage]);

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    return (
        <PageContainer title="Notifications">
            <div className="pt-8">

                {formState.processing && <div className="py-6"><Loader/></div>}

                {(!formState.processing && notifications.length === 0) &&
                    <Message text="Don't have any Notifications"/>
                }

                {(!formState.processing && notifications.length > 0) &&
                    <Accordion>
                        {notifications.map(
                            (notification, index) =>
                                <Accordion.Panel key={index}>
                                    <Accordion.Title className="">
                                        <div>
                                            {notification.message}
                                        </div>
                                        <div className="text-xs text-gray-400">
                                            {parseAndFormat(notification.createdAt, DATE_FORMAT)}
                                        </div>
                                    </Accordion.Title>
                                    <Accordion.Content>
                                        <div className="bg-white p-8 rounded-lg shadow-md w-full">
                                            {Object.keys(notification.metadata || {}).map((metadataKey, mi) => {
                                                const element = notification.metadata[metadataKey];
                                                return (<div className="">
                                                        {(element.type === 'url')?
                                                            <p className="mb-2">
                                                                <strong className="font-medium">{element.title}:</strong>
                                                                <a href={element.value} className="text-blue-500 underline">Click Here</a>
                                                            </p>:
                                                            <p className="mb-2">
                                                                <strong className="font-medium">{element.title}:</strong> {element.value}
                                                            </p>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Accordion.Content>
                                </Accordion.Panel>
                            )}
                    </Accordion>
                }
            </div>
        </PageContainer>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)