import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectInput from "../../../../../components/forms/select";
import { stmSlotCreateRequest } from "../../../../../redux/journey-toolbox/journey-toolbox-actions";
import { HiPlus } from 'react-icons/hi';

const getAutoFillFromEntity = autoFillFromEntity => {
  return (autoFillFromEntity === undefined || autoFillFromEntity === null) ? true : autoFillFromEntity;
}

function Page({ value, autoFillFromEntity, slots = {}, onChange, title = 'Variable', slotCreatRequest, showCaptureFromUserInput = true }) {
  console.log('slots', slots)
  const [valueSt, setValueSt] = useState(value || '');
  const [valueAutoFillFromEntity, setValueAutoFillFromEntity] = useState(getAutoFillFromEntity(autoFillFromEntity));

  useEffect(() => {
    setValueSt(value);
  }, [value]);

  useEffect(() => {
    setValueAutoFillFromEntity(getAutoFillFromEntity(autoFillFromEntity));
  }, [autoFillFromEntity]);

  const isEntityTypeSlot = () => {
    // return !!(valueSt && slots[valueSt] && slots[valueSt].captureFromUserInput);
    // slot always captureFromUserInput
    return true
  }

  return (
    <div className="space-y-4 pt-3">
      <div className="relative">
        <div className="absolute top-0 right-0 z-10">
          <button onClick={slotCreatRequest} className="text-sm inline-flex gap-2 items-center text-blue-700 font-semibold">
            <HiPlus />
            Create Variable
          </button>
        </div>
        <SelectInput
          label={title}
          id="slot"
          name="slot"
          autoComplete="slot"
          value={valueSt}
          onChange={event => {
            console.log("slot select", event.target.value)
            onChange({ target: { name: 'slot', value: event.target.value } })
          }}
          optionsComp={<>
            {valueSt ?
              <option value="">Unselect Variable</option>
              :
              <option value="">Select Variable</option>
            }
            {Object.keys(slots).map((slotName, si) => (
              <option key={si} value={slotName}>{slotName}</option>
            ))}
          </>}
        />
      </div>
      {(showCaptureFromUserInput && isEntityTypeSlot()) &&
        <div className="flex flex-row justify-between items-center">
          <label htmlFor="slot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Capture from user input
          </label>
          <Switch
            checked={valueAutoFillFromEntity}
            onChange={val => onChange({ target: { name: 'autoFillFromEntity', value: val } })}
            className={`${valueAutoFillFromEntity ? 'bg-blue-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span className="sr-only">Auto Fill</span>
            <span
              className={`${valueAutoFillFromEntity ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>
      }
    </div>

  )
}
const mapsStateToProps = (state, ownProps) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    slotCreatRequest: () => {
      dispatch(stmSlotCreateRequest());
    },
  };
}

export default connect(mapsStateToProps, mapDispatchToProps)(Page)