import { ToggleSwitch, Tooltip } from "flowbite-react";
import { useEffect, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import Button from "../../../../components/button/button";
import defaultImage from "../../../../assets/images/icons/integration.png";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import FormLoader from "../../../../components/form-loader/form-loader";
import NoDataMessage from "../../../../components/no-data-message";
import PageContainer from "../../../../components/page-container";
import { INTEGRATION_NAVIGATION } from "../../../../consts/integration-navigations";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import {
  scenariosList
} from "../../../../services/model/integration-workflow-service";
import {
  authConnDelete
} from "../../../../services/model/model-config-service";
import ConnectorSave from "../workflow-setup";
import { useSearchParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const IntegrationCard = ({ scenarioId, scenarioName, scenarioDescription, templateImageUrl, active, onToggle, onDelete }) => {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-lg p-4 shadow-sm border border-gray-200 dark:border-gray-800" key={scenarioId}>
      <div className={`w-full rounded-lg p-3 mb-3 flex justify-center items-center`}>
        <img className="w-[80%]" src={templateImageUrl || defaultImage} alt={`${scenarioName}_thumbnail`} />
      </div>
      <div>
        <h2 className="font-medium text-lg dark:text-white">{scenarioName}</h2>
        <p className="text-gray-500 dark:text-gray-300">{scenarioDescription}</p>
      </div>
      <div className="flex justify-between items-center pt-5 pb-2">
        <Tooltip content={`Enable here to use ${scenarioName} in workflow`} placement="right">
          <ToggleSwitch color="indigo" checked={active} onChange={(value) => onToggle(value, scenarioId)} />
        </Tooltip>
        <div onClick={() => onDelete(scenarioId)} className="text-slate-500 hover:text-red-500 transition-colors cursor-pointer"><HiOutlineTrash size={24} /></div>
      </div>
    </div>
  )
}

export default function WorkflowList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const needToClose = searchParams.get("close");

  useEffect(() => {
    if (needToClose) {
      window.opener?.postMessage('completed', window.location.origin);
      setTimeout(() => {
        window.close();
      }, 1500);
    }
  }, [needToClose]);

  const { appId } = useAppInfo();
  const [dataList, setDataList] = useState([]);
  const [editData, setEditData] = useState();

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [deletingData, setDeletingData] = useState();
  const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(
      Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  async function fetchConfigs() {
    updateFormState(true, false, false);
    const { data } = await scenariosList(appId);
    if (data && Array.isArray(data)) {
      setDataList(data);
    }
    updateFormState(false, true, false);
  }

  useEffect(() => {
    fetchConfigs();
  }, []);

  const handleOpenCreate = () => {
    setOpenCreateDialog(true);
    setEditData();
  };

  const handleOnCompletionCreate = async (state) => {
    if (state) {
      await fetchConfigs();
    }
    setOpenCreateDialog(false);
    setEditData();
  };

  const handleSetEditData = (data) => {
    setEditData(data);
    setOpenCreateDialog(true);
  };

  const handleSelectDelete = (id) => {
    setDeletingData(id);
    setDeleteRevokeConfirmDialog(true);
  };

  const handleConfirmDelete = async (confirmed) => {
    setDeleteRevokeConfirmDialog(false);
    if (confirmed) {
      updateFormState(true, false, false);
      await authConnDelete(appId, deletingData);
      setDeletingData(null);
      await fetchConfigs();
      updateFormState(false, true, false);
    }
  };

  const handleOnToggle = async (value, id) => {
    updateFormState(true, false, false);
    console.log(value, id);
    updateFormState(false, true, false);
  };

  return (
    <PageContainer
      title="Workflow Integrations"
      headerAction={
        <div className="flex flex-row items-center gap-4">
          <svg
            onClick={() => fetchConfigs()}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-900"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
          <Button onClick={handleOpenCreate} text="New Integration" />
        </div>
      }
      navigation={INTEGRATION_NAVIGATION}
    >
      {openCreateDialog && (
        <ConnectorSave
          appId={appId}
          savedData={editData}
          open={openCreateDialog}
          onCompletion={handleOnCompletionCreate}
        />
      )}
      {openDeleteConfirmDialog && (
        <ConfirmDialog
          appId={appId}
          open={openDeleteConfirmDialog}
          onCompletion={handleConfirmDelete}
          title="Delete Confirmation"
          message="Are you sure. Do you want to delete this Integration ?"
        />
      )}
      {formState.processing && !dataList?.length && <div className="py-6"><FormLoader text="Loading..." /></div>}
      {!!dataList?.length && (
        <div className="grid grid-cols-6 gap-4 pt-6">
          {dataList.map((data) => (
            <IntegrationCard key={data.id} onToggle={handleOnToggle} onDelete={handleSelectDelete} {...data} />
          ))}
        </div>
      )}
      {formState.success && !dataList?.length && <NoDataMessage title="No Integrations found" message="Let's create a new Integration" />}
    </PageContainer>
  );
}
