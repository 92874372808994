export default function TextBox(props) {
  const labelClass = props.error
    ? "block text-sm font-medium text-red-700 dark:text-red-500"
    : "block text-sm font-medium text-gray-900 dark:text-white";

  const inputClass = props.error
    ? "mb-2 bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
    : "mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

  return (
    <div className={props.rootClass}>
      <div>
        <label htmlFor={props.id} className={labelClass}>
          {props.label}
          {props.optional &&
            <span className="pl-2 text-gray-400 italic">- Optional</span>
          }
        </label>
        {props.subLabel &&
          <span as="span" className="text-sm text-gray-500 dark:text-gray-500">
            {props.subLabel}
          </span>
        }

        <div className="pt-2">
          <input id={props.id} className={`${inputClass} ${props.extraInputClass}`} autoComplete="off" {...props} />

          {props.error && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className="font-medium">
                {props.errorMessage || "Please fill out this field"}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}