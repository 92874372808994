import React, {useContext, useEffect, useState} from 'react';
import {Flex, Input, Modal, Radio, Space} from "antd";
import { Switch } from '@headlessui/react'

import {WorkflowCanvasContext} from "../../../../contexts/WorkflowCanvasContextProvider";
import {FaPlus as Plus} from "react-icons/fa6";
import {TiMinus as Minus} from "react-icons/ti";
import {moveDefaultOperatorToEnd} from "../../../../helpers/Utils";
import SettingTip from "../../common/components/setting-tip";
import TextBox from "../../../../components/forms/text-box";
import { Button } from 'flowbite-react';
import {IoIosAdd, IoIosRemove} from "react-icons/io";
import { Dropdown } from "flowbite-react";
import {IoChevronDown} from "react-icons/io5";
import DropDown from "../../../../components/forms/dropdown";
import {CHOICE, CONDITION_OPERATORS} from "../../common/const/settings-type";

export const customAddRowTheme = {
    "size": {
        "xs": "text-xs px-0 py-0",
    }
};

const ConditionNodeForm = ({metadata}) => {
    const workflowCanvasContext = useContext(WorkflowCanvasContext);

    const [stateName, setStateName] = useState('');

    const [choiceList, setChoiceList] = useState([]);
    const [selectedChoice, setSelectedChoice] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);

    const [expressionType, setExpressionType] = useState("AND");

    const { slots } = metadata

    useEffect(() => {
        if (workflowCanvasContext.nodes) {
            const selectedChild = workflowCanvasContext.nodes.find(item => item.id === workflowCanvasContext.nodeAttribute.parentId).data.children.find(item => item.id === workflowCanvasContext.nodeAttribute.id);
            console.log({selectedChild})
            if (selectedChild?.stateConfig?.choices?.length > 0) {
                setChoiceList(selectedChild?.stateConfig?.choices);
            }
            else {
                setChoiceList([]);
            }
            setStateName(selectedChild?.stateConfig?.name)
        }
    }, [workflowCanvasContext.nodeAttribute.parentId]);

    const handleStateNameChange = event => {
        const value = event.target.value
        const selectedBlock = workflowCanvasContext.nodes.find(item => item.id === workflowCanvasContext.nodeAttribute.parentId)
        selectedBlock.data.children = selectedBlock.data.children.map(item => {

            if (item.id === workflowCanvasContext.nodeAttribute.id) {
                return {
                    ...item,
                    stateConfig: {
                        ...item.stateConfig,
                        name: value
                    }
                }
            }
            return item;
        });

        const findSelectedNodeIndex = workflowCanvasContext.nodes.findIndex(item => item.id === selectedBlock.id);
        workflowCanvasContext.nodes[findSelectedNodeIndex] = selectedBlock;

        setStateName(value);
        workflowCanvasContext.setNodes([...workflowCanvasContext.nodes]);
    }

    const handleNodeAdd = () => {

        const choice = [{
            id: new Date().getMilliseconds(),
            operator: "OR"
        }]

        let _choiceList;
        if (choiceList.length === 0) {
            const elseChoice = {
                id: new Date().getMilliseconds() + Math.random(),
                operator: "DEFAULT",
                active: true,
            }
            choice.push(elseChoice)
            _choiceList = moveDefaultOperatorToEnd([...choiceList, ...choice]);
        } else {
            _choiceList = moveDefaultOperatorToEnd([...choiceList, ...choice]);
        }
        handleUpdateState(_choiceList)


    }

    const handleUpdateState = ( _choiceList) =>{
        const selectedBlock = workflowCanvasContext.nodes.find(item => item.id === workflowCanvasContext.nodeAttribute.parentId)
        selectedBlock.data.children = selectedBlock.data.children.map(item => {

            if (item.id === workflowCanvasContext.nodeAttribute.id) {
                return {
                    ...item,
                    type: CHOICE,
                    stateConfig: {
                        ...item.stateConfig,
                        choices: [
                            // ...item.stateConfig.choices,
                            // ...choice
                            ..._choiceList
                        ]
                    }
                }
            }
            return item;
        });

        const findSelectedNodeIndex = workflowCanvasContext.nodes.findIndex(item => item.id === selectedBlock.id);
        workflowCanvasContext.nodes[findSelectedNodeIndex] = selectedBlock;

        setChoiceList(_choiceList);
        workflowCanvasContext.setNodes([...workflowCanvasContext.nodes]);
    }

    const deleteChoice = choiceId => {

        const selectedBlock = workflowCanvasContext.nodes
            .find(item => item.id === workflowCanvasContext.nodeAttribute.parentId);
        const updatedChoiceList = choiceList.filter(choice => choice.id !== choiceId)
        setChoiceList(updatedChoiceList.length === 1 ? [] : updatedChoiceList);

        selectedBlock.data.children = selectedBlock.data.children.map(item => {

            if (item.id === workflowCanvasContext.nodeAttribute.id) {
                const updatedList = item.stateConfig.choices
                    .filter(choice => choice.id !== choiceId)
                return {
                    ...item,
                    type: CHOICE,
                    stateConfig: {
                        ...item.stateConfig,
                        choices: updatedList.length === 1 ? [] : updatedList
                    }
                }
            }
            return item;
        });

        const findSelectedNodeIndex = workflowCanvasContext.nodes
            .findIndex(item => item.id === selectedBlock.id);
        workflowCanvasContext.nodes[findSelectedNodeIndex] = selectedBlock;

        workflowCanvasContext.setNodes([...workflowCanvasContext.nodes]);

    }

    const openConditionModal = choice => {
        console.log(choice);
        if (choice?.operator !== "OR") {
            setExpressionType(choice.operator)
        }

        setSelectedChoice(choice);
        setModalVisible(true);
    }

    const closeConditionModal = () => {

        const findChoice = choiceList.find(choice => choice.id === selectedChoice.id);

        const _choice = {
            ...findChoice,
            operator: findChoice?.expressionList?.length > 1 ? expressionType : "OR"
        }

        const findChoiceIndex = choiceList.findIndex(choice => choice.id === selectedChoice.id);

        choiceList[findChoiceIndex] = _choice;

        const selectedBlock = workflowCanvasContext.nodes
            .find(item => item.id === workflowCanvasContext.nodeAttribute.parentId);

        selectedBlock.data.children = selectedBlock.data.children.map(item => {

            if (item.id === workflowCanvasContext.nodeAttribute.id) {
                return {
                    ...item,
                    stateConfig: {
                        ...item.stateConfig,
                        choices: choiceList,
                        stateType: CHOICE
                    }
                }
            }
            return item;
        });

        const findSelectedNodeIndex = workflowCanvasContext.nodes
            .findIndex(item => item.id === selectedBlock.id);
        workflowCanvasContext.nodes[findSelectedNodeIndex] = selectedBlock;

        workflowCanvasContext.setNodes([...workflowCanvasContext.nodes]);

        setSelectedChoice(null);
        setExpressionType("AND")
        setModalVisible(false);
    }

    const addExpression = () => {

        const _expressionList = selectedChoice?.expressionList ? selectedChoice.expressionList : [];

        const _selectedChoice = {
            ...selectedChoice,
            expressionList: [
                ..._expressionList,
                {
                    id: new Date().getMilliseconds(),
                    operator: "is",
                }

            ]
        }

        setSelectedChoice(_selectedChoice);

    }

    const handleConditionChange = (expressionId, e) => {

        const {name, value} = e.target;

        selectedChoice.expressionList = selectedChoice?.expressionList?.map(expression => {
            if (expression.id === expressionId) {
                return {
                    ...expression,
                    [name]: value
                }
            }
            return expression
        })


        const findChoiceListIndex = choiceList
            .findIndex(choice => choice.id === selectedChoice.id);

        choiceList[findChoiceListIndex] = selectedChoice;

        setChoiceList([...choiceList]);

    }

    const deleteExpression = expressionId => {

        const _filteredSelectedExpressionList = selectedChoice?.expressionList?.filter(expression => expression.id !== expressionId);

        const _selectedChoice = {
            ...selectedChoice,
            expressionList: _filteredSelectedExpressionList
        }

        const findChoiceIndex = choiceList.findIndex(choice => choice.id === selectedChoice.id);

        choiceList[findChoiceIndex] = _selectedChoice;

        setSelectedChoice(_selectedChoice);
        setChoiceList([...choiceList]);


    }

    const handleElse = (checked) => {
        if(checked){
            // choiceList
            const updatedChoiceList = choiceList.map(choice => {
                if(choice.operator === "DEFAULT"){
                    choice.active = true;
                    return choice;
                }
                return choice;
            });
            handleUpdateState(updatedChoiceList);
        }
        else {
            const updatedChoiceList = choiceList.map(choice => {
                if(choice.operator === "DEFAULT"){
                    choice.active = false;
                    return choice;
                }
                return choice;
            });
            handleUpdateState(updatedChoiceList);
        }
    }

    return (
        <div className="flex flex-col gap-2">
            <SettingTip text="Add conditional path for your workflow" />
            {/*<TextBox*/}
            {/*    label="Name"*/}
            {/*    type="text"*/}
            {/*    name="name"*/}
            {/*    placeholder="State name"*/}
            {/*    value={stateName}*/}
            {/*    onChange={handleStateNameChange}*/}
            {/*/>*/}

            <div className="flex flex-row items-center justify-between">
                <label className="block text-sm font-medium text-gray-900 dark:text-white">
                    Paths
                </label>
                <Button size="xs" theme={customAddRowTheme} color="gray" onClick={handleNodeAdd}>
                    <IoIosAdd className="h-6 w-6" />
                </Button>
            </div>

            <div className="pt-1 flex flex-col gap-2">
            {
                choiceList.map(item => (
                    <div
                        key={item.id}
                    >
                        {item.operator !== "DEFAULT" ?
                            <div className="flex flex-row gap-2 justify-between w-full">
                                <div
                                    className={`rounded-lg flex flex-row cursor-pointer items-center hover:px-2 active:px-2 w-full block text-sm font-medium text-gray-500 dark:text-white hover:bg-blue-500 hover:text-white ${selectedChoice?.id === item.id? 'bg-blue-500 text-white': ''}`}
                                    onClick={() => openConditionModal(item)}
                                >
                                    {
                                        item?.expressionList?.length > 0 ?
                                            item?.expressionList?.map(expression => `${expression?.variable ? `{${expression?.variable}}` : ""} ${expression?.operator || ""} ${expression?.value || ""}`).join(` ${modalVisible && selectedChoice.id === item.id ? expressionType : item.operator} `) :
                                            <span>Empty Condition</span>
                                    }
                                </div>
                                <Button size="xs" theme={customAddRowTheme} color="gray" onClick={() => deleteChoice(item.id)}>
                                    <IoIosRemove className="h-6 w-6" />
                                </Button>
                            </div> :
                            <div className="pt-4 flex flex-row justify-between items-center">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Else
                                </label>
                                <Switch
                                    checked={item?.active}
                                    onChange={handleElse}
                                    className={`${
                                        item?.active ? 'bg-blue-600' : 'bg-gray-200'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                    <span className="sr-only">Send to User</span>
                                    <span
                                        className={`${
                                            item?.active ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                            </div>}
                    </div>
                ))
            }
            </div>

            <Modal
                title={
                    <Space size="large">
                        <Radio.Group
                            value={expressionType}
                            buttonStyle="solid"
                            onChange={e => setExpressionType(e.target.value)}
                        >
                            <Radio.Button value="AND">
                                Match All
                            </Radio.Button>
                            <Radio.Button value="OR">
                                Match Any
                            </Radio.Button>
                        </Radio.Group>

                        <Button size="xs" color="gray" onClick={addExpression}>
                            <IoIosAdd className="h-6 w-6" />
                        </Button>
                    </Space>
                }
                closeIcon={null}
                open={modalVisible}
                onCancel={closeConditionModal}
                footer={null}
            >
                <div>
                    {
                        selectedChoice && selectedChoice?.expressionList?.map(expression =>
                            <div className="flex flex-row gap-4 items-center" key={expression.id}>
                                <p className="text-gray-900 font-semibold">if</p>

                                <div className="grid grid-cols-3 gap-4 gap-1 items-center">
                                    <DropDown
                                        className=""
                                        placeHolder="variable"
                                        name="variable"
                                        value={expression.variable}
                                        options={Object.keys(metadata.slots)}
                                        onChange={e => handleConditionChange(expression.id, e)}
                                        inline={false}
                                    />
                                    <DropDown
                                        className=""
                                        placeHolder="operator"
                                        name="operator"
                                        value={expression?.operator}
                                        options={CONDITION_OPERATORS}
                                        onChange={e => handleConditionChange(expression.id, e)}
                                        inline={false}
                                    />
                                    {/*<Switch*/}
                                    {/*    style={{width: "250px"}}*/}
                                    {/*    checkedChildren="is"*/}
                                    {/*    unCheckedChildren="is not"*/}
                                    {/*    checked={expression?.operator === "is"}*/}
                                    {/*    onChange={v => handleConditionChange(expression.id, {*/}
                                    {/*        target: {name: "operator", value: v ? "is" : "is not"}*/}
                                    {/*    })*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <TextBox
                                        rootClass=""
                                        type="text"
                                        name="value"
                                        placeholder="value or {var}"
                                        value={expression.value}
                                        onChange={e => handleConditionChange(expression.id, e)}
                                    />
                                </div>


                                <Button size="xs" theme={customAddRowTheme} color="gray" onClick={() => deleteExpression(expression.id)}>
                                    <Minus/>
                                </Button>
                            </div>
                        )
                    }

                </div>
            </Modal>

        </div>
    );
};

export default ConditionNodeForm;
