import { post, get } from "../../../helpers/utils/http-client";
import {APPS_ENDPOINT} from '../../../consts/api-endpoints';

export async function save(appId, request) {
    return await post(`${APPS_ENDPOINT}/${appId}/knowledge/data-sources/text`, request);
}

export async function getData(appId, dataSourceId) {
    try {
        const { response } = await get(`${APPS_ENDPOINT}/${appId}/knowledge/data-sources/text/${dataSourceId}`);
        return response;
    } catch (err) {
        console.error("getEditorBlocks failed", err);
        return { editorBlocks: [], draft: true }
    }
}