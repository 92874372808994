import { Modal } from "flowbite-react";
import { React, useEffect, useRef, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button/button";
import Loader from "../../../../components/form-loader/form-loader";
import TextArea from "../../../../components/forms/text-area";
import TextBox from "../../../../components/forms/text-box";
import Stepper from "../../../../components/stepper";
import Message from "../../../../components/toast-message";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import { appCreateNotify } from "../../../../redux/app/app-actions";
import {
  flowInit,
  templatesList,
} from "../../../../services/model/integration-workflow-service";

const mapsStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    appCreateNotifyHandle: (appId) => {
      dispatch(appCreateNotify(appId));
    },
  };
};

const integrationSteps = [
  {
    id: 0,
    name: "Integration Type",
    desc: "Choose the type of integration",
  },
  {
    id: 1,
    name: "Name & Description",
    desc: "Please enter name and description",
  },
];

function Index({ onCompletion }) {
  const navigate = useNavigate();
  const rootRef = useRef(null);
  const { appId } = useAppInfo();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    templateId: "",
  });

  const [error, setError] = useState({
    name: false,
    description: false,
    templateId: false,
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  const [templateList, setTemplateList] = useState([]);

  const [templateListState, setTemplateListState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((preData) => {
      const currData = preData;
      currData[name] = value;

      return { ...currData };
    });
  };

  useEffect(() => {
    if (formData?.templateId) {
      setActiveStep(1);
    }
  }, [formData?.templateId]);

  const validateForm = () => {
    let valid = true;
    const newError = { ...error };
    if (!formData.name) {
      newError.name = true;
      valid = false;
    }

    if (!formData.description) {
      newError.description = true;
      valid = false;
    }

    if (!formData.templateId) {
      newError.templateId = true;
      valid = false;
    }

    setError(newError);
    return valid;
  };

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }));
  };

  const updateTemplateListFormState = (
    processing,
    success,
    failed,
    message
  ) => {
    setTemplateListState(
      Object.assign({}, { processing, success, failed, message })
    );
  };

  const handleOnSubmit = async () => {
    if (!formState.processing && validateForm()) {
      updateFormState(true, false, false);
      const { response, status } = await flowInit(appId, {
        templateId: formData.templateId,
        scenarioName: formData.name,
        scenarioDescription: formData.description,
      });
      if (status === 200) {
        updateFormState(false, true, false, "Successfully created");
        // Open the URL in a new tab
        // eslint-disable-next-line no-unused-vars
        const newWindow = window.open(response.setupUrl, '_blank', 'noopener,noreferrer');
        // Listen for messages from the new tab (child window)
        window.addEventListener('message', (event) => {
          // Ensure the message is coming from the expected origin
          if (event.origin === window.location.origin) {
            console.log('triggered');
            navigate(`/app/${appId}/integrations/workflow`);
            onCompletion();
          }
        }, false);
      } else {
        updateFormState(false, false, true, response);
      }
    }
  };

  async function fetchTemplates() {
    updateTemplateListFormState(true, false, false);
    const { data } = await templatesList(appId);
    if (data && Array.isArray(data)) {
      setTemplateList(data);
    }
    updateTemplateListFormState(false, true, false);
  }

  const renderMessages = () => {
    let message;
    let type;

    if (formState.success) {
      message = "Successfully Saved Configs";
      type = "success";
    } else if (formState.failed) {
      message = formState.message;
      type = "failure";
    }

    return message && type ? <Message text={message} type={type} /> : null;
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return (
    <div ref={rootRef}>
      <Modal
        show={true}
        size="4xl"
        popup
        onClose={() => onCompletion(false)}
        root={rootRef.current ?? undefined}
      >
        <Modal.Header className="pl-6 pt-4 pb-4">New Integration</Modal.Header>
        <Modal.Body>
        <div className="space-y-6">
              <Stepper
                steps={integrationSteps}
                activeStep={activeStep}
                onChange={setActiveStep}
              />
              {renderMessages()}
              {activeStep === 0 && (
                <div className="pt-2 flex flex-col gap-4">
                  <div className="relative">
                    <TextBox
                      extraInputClass="pl-8"
                      type="text"
                      placeholder="Type and search"
                      name="templateId"
                      label="Select Integration Type"
                      error={error.templateId}
                    />
                    <HiSearch
                      size={20}
                      className="absolute left-2 bottom-5 -mb-0.5 text-slate-300"
                    />
                  </div>
                  {templateListState.processing && (
                    <div className="p-4">
                      <Loader />
                    </div>
                  )}
                  <ul className="flex flex-wrap gap-4">
                    {templateList.map((template) => {
                      const { imageUrl, templateId, name, description } =
                        template;
                      const selected =
                        formData.templateId === template.templateId;
                      return (
                        <li
                          key={templateId}
                          className="basis-1/4 flex max-w-[20%] relative"
                        >
                          <button
                            type="button"
                            className={`
                              flex flex-wrap group gap-2 w-full rounded-lg border-gray-300 shadow-sm bg-white p-3 transition-all
                              ${
                                selected
                                  ? "text-indigo-700 border-indigo-700 border-2 cursor-default"
                                  : "text-gray-900 border hover:border-indigo-400 cursor-pointer hover:shadow-md"
                              }
                            `}
                            onClick={() =>
                              handleChange({
                                target: {
                                  name: "templateId",
                                  value: templateId,
                                },
                              })
                            }
                          >
                            <img
                              alt={`${name}_thumbnail`}
                              src={imageUrl}
                              className="w-10 h-10"
                            />
                            <span className="block max-w-[calc(100%-48px)]">
                              <span className="block font-medium text-left text-sm">
                                {name}
                              </span>
                            </span>
                            <span className="w-full">
                              <span className="block text-xs">
                                {description}
                              </span>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {activeStep === 1 && (
                <>
                  {formState.processing && (
                    <div className="p-4">
                      <Loader />
                    </div>
                  )}
                  <div className="flex flex-col gap-3">
                    <div className="grid grid-cols-1 gap-4">
                      <TextBox
                        label="Integration Name"
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        placeholder="Enter Name"
                        onChange={handleChange}
                        error={error.name}
                      />
                      <TextArea
                        type="text"
                        name="description"
                        id="description"
                        label="Description"
                        value={formData.description}
                        placeholder="Enter description"
                        onChange={handleChange}
                        rows={3}
                        error={error.description}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button
            color="default"
            text="Cancel"
            onClick={() => onCompletion(false)}
          />
          <Button
            onClick={handleOnSubmit}
            type="button"
            disabled={formState.processing}
            text="Setup Integration"
            loading={formState.processing}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index);
