import React from "react";
import PropTypes from "prop-types";

const Header = ({ children }) => {
  return (
    <header
      className="
      fixed top-0 inset-x-0 z-10 border-b border-gray-200
      dark:border-b-gray-700 bg-slate-50 dark:bg-slate-950"
    >
      <nav
        className="
        w-full 
        px-4 
        p-4 
        h-14 
        flex 
        items-center 
        justify-end"
      >
        {children}
      </nav>
    </header>
  );
};

export default Header;

Header.propTypes = {
  children: PropTypes.any,
};
