import {useEffect, useState} from 'react';
import Button from '../../../components/button/button';
import Message from "../../../components/message/message-comp";
import Loader from "../../../components/form-loader/form-loader";
import {getPaymentMethods, detachPaymentMethod, checkoutSessionCreate} from '../../../services/billing';
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import AddNewPaymentMethod from './add-new-payment-method';
import {Link} from "react-router-dom";
import {createAppBasePath} from "../../../consts/route-consts";
import Table from "../../../components/table";
import {useQuery} from "../../../helpers/hooks/common-hook";

export default function Index() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const setup = useQuery().get("setup")

    const [fetchState, setFetchState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [openCreate, setOpenCreate] = useState(false);

    const [deletingPaymentId, setDeletingPaymentId] = useState();
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState();

    async function fetchData() {
        updateFetchState(true, false, false);
        const {response, status} = await getPaymentMethods();
        if (status === 200) {
            setPaymentMethods(response);
            updateFetchState(false, true, false);
        } else {
            updateFetchState(false, false, true);
        }
    }

    const updateFetchState = (processing, success, failed, errorMessage) => {
        setFetchState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const handleCheckoutSessionCreate = async () => {
        updateFormState(true, false, false);
        const {response, status} = await checkoutSessionCreate();
        if (status === 200) {
            window.location = response.url;
        } else {
            updateFormState(false, false, true, 'Unable to initiate checkout');
        }
    }

    const handleOnCompletionCreate = async state => {
        setOpenCreate(false);
        if (state) {
            await fetchData();
        }
    }

    const handleSelectDelete = (paymentId) => {
        setDeletingPaymentId(paymentId);
        setOpenDeleteConfirmDialog(true)
    }

    const handleConfirmDelete = async confirmed => {
        setOpenDeleteConfirmDialog(false);
        if (confirmed) {
            await detachPaymentMethod(deletingPaymentId);
            await fetchData();
        }
        setDeletingPaymentId();
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (setup === '1') {
            handleCheckoutSessionCreate()
        }
    }, [setup]);

    const processDataList = paymentMethods.map(pm=>{
        return {
            record: pm,
            columnsData: [
                { data: pm.card.brand, isIdField: true },
                { data: pm.card.last4 },
                { data: `Expires ${pm.card.exp_month}/${pm.card.exp_year}` },
                { options: [
                        {
                            name: 'Delete',
                            onClick: ()=>handleSelectDelete(pm.id)
                        }
                    ]
                },
            ]
        }
    })

    return (
        <div className="flex flex-col gap-8 h-screen">

            <div className="grid grid-cols-2">
                <div className="flex flex-row gap-4 justify-start items-baseline">
                    <p className="text-gray-900 text-2xl font-bold">Payment Methods</p>
                </div>
                <div className="flex justify-end items-center">
                    <Button text="Add New Payment Method" onClick={handleCheckoutSessionCreate}/>
                </div>
            </div>

            {formState.processing && <div className="py-6"><Loader/></div>}

            {(!formState.processing && formState.failed) &&
                <Message text={formState.errorMessage || "Unable to fetch Payment methods. Please try again"} timeout={-1}/>
            }

            {openCreate &&
                <AddNewPaymentMethod open={openCreate} onCompletion={handleOnCompletionCreate}/>}

            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    open={openDeleteConfirmDialog}
                    onCompletion={handleConfirmDelete}
                    message="Are you sure. Do you want to delete this payment method ?"
                />
            }

            <Table
                items={processDataList}
                headers={
                    ["Type", "Last Digits", "Expires"]
                }
                dataLoadingState={fetchState}
                itemsNotFoundMessage="You don't have any payment methods yet"
                modifiedColumn={false}
            />

        </div>)
}