import {
    DOCUMENT_DEFAULT_ACTION_CONFIG,
} from "../common-consts";
import TextBox from "../../../../../components/forms/text-box";
import TextArea from "../../../../../components/forms/text-area";
import TabOpen from "../../../../../components/tab-open";

export default function LiveAgentConnectAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=DOCUMENT_DEFAULT_ACTION_CONFIG,
                                           error
}) {
    const { appId } = config;

    return (
        <>
            <div className="flex flex-col gap-2 pt-4">
                <TabOpen
                    rootClass="flex flex-row justify-end"
                    text="Update Live Agent Connector"
                    url={`/app/${appId}/live-agent`}
                />
                <TextArea
                    rootClass="pt-4 w-1/2"
                    rows={5}
                    label="Message*"
                    placeholder="Message to the customer before connecting to a live agent. Eg: Sure, I will transfer our conversation to a live agent. Please wait for a moment."
                    value={actionConfig.message}
                    onChange={event => { onActionConfigUpdate('message', event)}}
                    error={error.message}
                />
            </div>

        </>
    )
}
