import PropTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";

import Action from "./action";
import {connect} from "react-redux";
import {stmNodeSelectRest} from "../../../../redux/journey-toolbox/journey-toolbox-actions";

export const _JourneyLink = ({ data, onDeleteComplete, stmNodeSelectRest }) => {
  const { id: journeyId, appId } = data;
  const navigate = useNavigate()

  const onClickNavigate = () => {
      stmNodeSelectRest()
      navigate(`/app/${appId}/journey/${journeyId}`)
  }

  return (
    <div className="group w-full flex items-center justify-between rounded text-gray-900 dark:text-white hover:bg-gray-100 hover:text-indigo-600 dark:hover:bg-gray-700">
      <a
        onClick={onClickNavigate}
        className="cursor-pointer relative flex-1 flex items-center justify-between p-3 py-2"
      >
        <span>{data.name}</span>
      </a>
      {/* journey's action popover list */}
      <span
          // className="hidden group-hover:inline-block transition-all"
      >
        <Action appId={appId} journeyId={journeyId} onDeleteComplete={onDeleteComplete} />
      </span>
    </div>
  );
};

_JourneyLink.propTypes = {
  data: PropTypes.object,
};

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        stmNodeSelectRest: (message) => {
            dispatch(stmNodeSelectRest(message));
        },
    };
}

const JourneyLink = connect(mapsStateToProps, mapDispatchToProps)(_JourneyLink)
export {
    JourneyLink
}
