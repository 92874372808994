import { useState } from 'react';
import Dashboard from "./dashboard";
import Invoices from "./invoices";
import Plans from "./plans";
import PaymentMethods from "./payment-methods";
import BusinessInfo from "./business-info";
import PageContainer from "../../components/page-container";


const sections = [
    { name: 'Usage', href: 'usage', component: Dashboard },
    // { name: 'Business Info', href: 'businessInfo', component: BusinessInfo },
    { name: 'Plans', href: 'plans', component: Plans },
    { name: 'Invoices', href: 'invoices', component: Invoices },
    { name: 'Payment Methods', href: 'payment-methods', component: PaymentMethods }
];

export default function AppIntegrationPage() {
    const [expandedSection, setExpandedSection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const sectionContainerStyle = {
        marginBottom: '1rem',
        border: '1px solid #e5e7eb',
        borderRadius: '0.375rem',
        overflow: 'hidden', // Ensure content does not overflow
    };

    const sectionHeaderStyle = {
        backgroundColor: '#f9fafb',
        padding: '0.5rem 1rem',
        borderBottom: '1px solid #e5e7eb',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    };

    const sectionContentStyle = {
        padding: '1rem',
        backgroundColor: '#ffffff',
        display: expandedSection ? 'block' : 'none' // Ensure the content takes space
    };

    const plansContentStyle = {
        padding: '1rem',
        backgroundColor: '#ffffff',
        display: expandedSection === 'plans' ? 'block' : 'none',
        maxHeight: '80vh', // Set a maximum height based on viewport height
        overflowY: 'auto' // Enable vertical scrolling if content exceeds maxHeight
    };

    return (
        <PageContainer title='Subscription'>
            <div className="grid grid-cols-10 gap-4">
                <div className="col-span-10 bg-white overflow-hidden sm:rounded py-6">
                    {sections.map((section, index) => (
                        <div key={index} style={sectionContainerStyle}>
                            <div
                                style={sectionHeaderStyle}
                                onClick={() => toggleSection(section.href)}
                            >
                                <h2>{section.name}</h2>
                                <span>{expandedSection === section.href ? '▲' : '▼'}</span>
                            </div>
                            {expandedSection === section.href && (
                                <div style={section.href === 'plans' ? plansContentStyle : sectionContentStyle}>
                                    <section.component />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </PageContainer>
    );
}
