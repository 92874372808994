export function truncate(str, length=100) {
  return str?`${str.substring(0,length)}...`:str;
}

export function validateActionName(name) {
  const pattern = /^[a-zA-Z0-9_-]+$/;
  return pattern.test(name);
}

export function isValidURL(url) {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // Protocol (http or https)
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP address (IPv4)
    '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
    '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
    '(\\#[-a-zA-Z\\d_]*)?$', // Fragment locator
    'i'
  );
  return urlPattern.test(url);
};

