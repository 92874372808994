import {useEffect, useState} from 'react'
import {FINE_TUNED_KNOWLEDGE_BASE_DEFAULT_ACTION_CONFIG,} from "../common-consts";
import SelectInput from "../../../../../components/forms/select";
import TextArea from "../../../../../components/forms/text-area";
import BaseModelProvidersSelect from "../../../../fine-tune/common/base-model-providers-select";
import {RAG_FINE_TUNE_TYPE} from "../../../../fine-tune/const/common-consts";
import {getFineTunedModels} from "../../../../../services/model/finetune-service";
import Loader from "../../../../../components/form-loader/form-loader";
import Message from "../../../../../components/message/message-comp";
import ActionParameters from "../../../../../components/action-parameter-row";

function FineTuneModelSelector({
                                   appId,
                                   fineTuneType,
                                   baseModelProvider,
                                   fineTuneModelId,
                                   onChangeProvider,
                                   onChangeFineTunedModel
}) {

    const [formData, setFormData] = useState({
        baseModelProvider: baseModelProvider,
        fineTuneModelId: fineTuneModelId,
    });

    const [error, setError] = useState({
        baseModelProvider: false,
        fineTuneModelId: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [selectDataLoadingState, setSelectDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateSelectDataLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setSelectDataLoadingState({...processing, success, failed, message, errorMessage})
    }

    const [ baseModels, setBaseModels ] = useState([]);
    const [ selectedBaseModels, setSelectedBaseModels ] = useState([]);

    async function fetchFineTunedModels() {
        updateSelectDataLoadingState({
            processing: true
        });
        const data= await getFineTunedModels(appId, fineTuneType);
        setBaseModels(data);
        updateSelectDataLoadingState({
            processing: false
        });
    }

    async function fetchData() {
        await fetchFineTunedModels();
    }

    useEffect( () => {
        fetchData();
    }, []);

    useEffect( () => {
        if (formData.baseModelProvider) {
            if (baseModels[formData.baseModelProvider]) {
                setSelectedBaseModels(baseModels[formData.baseModelProvider])
            } else if (!fineTuneModelId) {
                setSelectedBaseModels([]);
            }
        }
    }, [formData.baseModelProvider]);

    const onDataUpdate = (field, event) => {
        setFormData({...formData, [field]: event.target.value});
        onChangeProvider(event);
    };

    return (
        <form>
            <div className="pt-2 flex flex-col gap-6">

                {(formState.processing || selectDataLoadingState.processing) && <div className="p-4"><Loader/></div>}

                {formState.success &&
                    <Message text={`Successfully Executed`} color="success"/>
                }

                {formState.failed && <Message text={formState.errorMessage} color="failure"/>}

                <BaseModelProvidersSelect
                    value={formData.baseModelProvider}
                    onDataUpdate={onDataUpdate}
                    error={error.baseModelProvider}
                    updateSelectDataLoadingState={updateSelectDataLoadingState}
                    fineTuneType={fineTuneType}
                />

                <SelectInput
                    id="baseModel"
                    label="Finetuned Model"
                    required
                    value={formData.fineTuneModelId}
                    onChange={event => {
                        onDataUpdate('fineTuneModelId', event)
                        onChangeFineTunedModel(event);
                    }}
                    error={error.fineTuneModelId}
                    optionsComp={
                        <>
                            <option>Select Finetuned Model</option>
                            {selectedBaseModels.map((model)=>
                                <option key={model.fineTuneModelId} value={model.fineTuneModelId}>{model.label}</option>
                            )}
                        </>
                    }
                />

            </div>
        </form>
    )
}

export default function DocumentAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=FINE_TUNED_KNOWLEDGE_BASE_DEFAULT_ACTION_CONFIG,
                                           error
}) {
    const { appId } = config;

    return (
        <>
            <div className="flex flex-col gap-2 pt-4">
                <div className="">
                    <FineTuneModelSelector
                        appId={appId}
                        fineTuneType={RAG_FINE_TUNE_TYPE}
                        baseModelProvider={actionConfig.baseModelProvider}
                        fineTuneModelId={actionConfig.fineTuneModelId}
                        onChangeProvider={event => { onActionConfigUpdate('baseModelProvider', event)}}
                        onChangeFineTunedModel={event => { onActionConfigUpdate('fineTuneModelId', event)}}
                    />
                </div>
            </div>

            <div className="pt-4 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>

            <TextArea
                rootClass="pt-4 w-1/2"
                rows={5}
                label="Input Prompt"
                subLabel="Override user's input with predefined placed holders {userInput}"
                placeholder="Eg: check leave balance of {empId}"
                value={actionConfig.inputPrompt}
                onChange={event => { onActionConfigUpdate('inputPrompt', event)}}
            />

            <TextArea
                rootClass="pt-4 w-1/2"
                rows={5}
                label="Output Prompt"
                subLabel="Provide instruction to extract relevant information"
                placeholder="Eg: Return 'Yes' if employee has available leave"
                value={actionConfig.outputPrompt}
                onChange={event => { onActionConfigUpdate('outputPrompt', event)}}
            />

            <ActionParameters actionConfig={actionConfig} setActionConfig={setActionConfig}/>

        </>
    )
}
