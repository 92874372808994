import {useEffect, useState} from 'react'
import {listCodehook} from "../../../../../services/model/model-config-service";
import {createUniqueId} from "../../../../../helpers/utils/object-util";
import TextBox from "../../../../../components/forms/text-box";
import SelectInput from "../../../../../components/forms/select";
import {WEBHOOK_DEFAULT_ACTION_CONFIG} from "../common-consts";
import ReturnDirectSwitch from "../../common/components/return-direct-switch";
import {RadioButtonGroup} from "../../../../../components/radio-button";
import {formDataRequestType, requestTypes} from "../../../../../consts/common-consts";
import Editor from "@monaco-editor/react";
import ActionParameters from "../../../../../components/action-parameter-row";

function QueryParameterRow({ id, name, value, onUpdate, onDelete }) {
    const [ paramConfig, setParamConfig ] = useState({
        id,
        name,
        value,
    })

    const onParamConfigUpdate = (field, event) => {
        const newActionConfig = {...paramConfig, [field]: event.target.value}
        setParamConfig(newActionConfig);
    };

    const onClickDelete = ()=> {
        onDelete(id);
    }

    useEffect( () => {
        if (paramConfig.name) {
            onUpdate(paramConfig)
        }
    }, [paramConfig]);

    return (
        <div className="flex flex-row gap-1">
            <div className="flex-grow grid grid-cols-5 gap-3">
                <TextBox
                    rootClass="col-span-1"
                    type="text"
                    label="Name"
                    placeholder="Enter parameter name"
                    value={paramConfig.name}
                    onChange={event => { onParamConfigUpdate('name', event)}}
                />
                <TextBox
                    rootClass="col-span-2"
                    type="text"
                    label="Value"
                    placeholder="Value with {action parameter}"
                    value={paramConfig.value}
                    onChange={event => { onParamConfigUpdate('value', event)}}
                />

            </div>

            <div className="flex flex-row justify-start items-center gap-2 pt-4 underline text-sm text-gray-700 font-semibold cursor-pointer">
                <svg onClick={onClickDelete} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 hover:text-red-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>

        </div>

    )
}

function HeaderRow({ id, name, value, onUpdate, onDelete }) {
    const [ paramConfig, setParamConfig ] = useState({
        id,
        name,
        value,
    })

    const onParamConfigUpdate = (field, event) => {
        const newActionConfig = {...paramConfig, [field]: event.target.value}
        setParamConfig(newActionConfig);
    };

    const onClickDelete = ()=> {
        onDelete(id);
    }

    useEffect( () => {
        if (paramConfig.name) {
            onUpdate(paramConfig)
        }
    }, [paramConfig]);

    return (
        <div className="flex flex-row gap-1">
            <div className="flex-grow grid grid-cols-5 gap-3">
                <TextBox
                    rootClass="col-span-1"
                    type="text"
                    label="Name"
                    placeholder="Enter parameter name"
                    value={paramConfig.name}
                    onChange={event => { onParamConfigUpdate('name', event)}}
                />
                <TextBox
                    rootClass="col-span-2"
                    type="text"
                    label="Value"
                    placeholder="Value with {action parameter}"
                    value={paramConfig.value}
                    onChange={event => { onParamConfigUpdate('value', event)}}
                />

            </div>

            <div className="flex flex-row justify-start items-center gap-2 pt-4 underline text-sm text-gray-700 font-semibold cursor-pointer">
                <svg onClick={onClickDelete} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 hover:text-red-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>

        </div>

    )
}

function FormDataRow({ id, name, value, onUpdate, onDelete }) {
    const [ paramConfig, setParamConfig ] = useState({
        id,
        name,
        value,
    })

    const onParamConfigUpdate = (field, event) => {
        const newActionConfig = {...paramConfig, [field]: event.target.value}
        setParamConfig(newActionConfig);
    };

    const onClickDelete = ()=> {
        onDelete(id);
    }

    useEffect( () => {
        if (paramConfig.name) {
            onUpdate(paramConfig)
        }
    }, [paramConfig]);

    return (
        <div className="flex flex-row gap-1">
            <div className="flex-grow grid grid-cols-5 gap-3">
                <TextBox
                    rootClass="col-span-1"
                    type="text"
                    label="Name"
                    placeholder="Enter field name"
                    value={paramConfig.name}
                    onChange={event => { onParamConfigUpdate('name', event)}}
                />
                <TextBox
                    rootClass="col-span-2"
                    type="text"
                    label="Value"
                    placeholder="Value with {action parameter}"
                    value={paramConfig.value}
                    onChange={event => { onParamConfigUpdate('value', event)}}
                />

            </div>

            <div className="flex flex-row justify-start items-center gap-2 pt-4 underline text-sm text-gray-700 font-semibold cursor-pointer">
                <svg onClick={onClickDelete} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 hover:text-red-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>

        </div>

    )
}

export default function WebhookAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=WEBHOOK_DEFAULT_ACTION_CONFIG,
                                           error
}) {
    const { appId } = config;

    const [ codeHookList, setCodeHookList ] = useState([]);
    const [codeHookLoadingState, setCodeHookLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateCodeHookLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setCodeHookLoadingState({...processing, success, failed, message, errorMessage})
    }

    async function fetchCodeHooks() {
        updateCodeHookLoadingState({
            processing: true
        });
        const { data } = await listCodehook(appId);
        if (data && Array.isArray(data)) {
            setCodeHookList(data);
        }
        updateCodeHookLoadingState({
            processing: false
        });
    }

    useEffect( () => {
        fetchCodeHooks();
    }, []);

    const onQueryParameterAdd = () => {
        const queryParameters = actionConfig.queryParameters || {};
        const id = createUniqueId();
        queryParameters[id] = { id, name: '', value: ''};
        setActionConfig({...actionConfig, queryParameters });
    };

    const onQueryParameterUpdate = paramConfig => {
        const queryParameters = actionConfig.queryParameters || {};
        queryParameters[paramConfig.id] = paramConfig;
        setActionConfig({...actionConfig, queryParameters });
    };

    const onQueryParameterDelete = id => {
        const queryParameters = actionConfig.queryParameters;
        delete queryParameters[id];
        setActionConfig({...actionConfig, queryParameters });
    };

    const onFormDataAdd = () => {
        const requestFormData = actionConfig.requestFormData || {};
        const id = createUniqueId();
        requestFormData[id] = { id, name: '', value: ''};
        setActionConfig({...actionConfig, requestFormData });
    };

    const onFormDataUpdate = paramConfig => {
        const requestFormData = actionConfig.requestFormData || {};
        requestFormData[paramConfig.id] = paramConfig;
        setActionConfig({...actionConfig, requestFormData });
    };

    const onFormDataDelete = id => {
        const requestFormData = actionConfig.requestFormData;
        delete requestFormData[id];
        setActionConfig({...actionConfig, requestFormData });
    };

    const onHeaderAdd = () => {
        const headers = actionConfig.headers || {};
        const id = createUniqueId();
        headers[id] = { id, name: '', value: ''};
        setActionConfig({...actionConfig, headers });
    };

    const onHeaderUpdate = paramConfig => {
        const headers = actionConfig.headers || {};
        headers[paramConfig.id] = paramConfig;
        setActionConfig({...actionConfig, headers });
    };

    const onHeaderDelete = id => {
        const headers = actionConfig.headers;
        delete headers[id];
        setActionConfig({...actionConfig, headers });
    };

    return (
        <>
            <ReturnDirectSwitch actionConfig={actionConfig} onActionConfigUpdate={onActionConfigUpdate} />

            <ActionParameters actionConfig={actionConfig} setActionConfig={setActionConfig}/>

            <div className="pt-4 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>

            <div className="flex flex-row justify-start">
                <label htmlFor="actionType" className="pt-4 block text-sm font-medium text-gray-500">
                    API Details
                </label>
            </div>


            <div className="grid grid-cols-4 gap-2 pt-6 w-1/2">
                <div className="col-span-3">
                    <TextBox
                        rootClass=""
                        type="text"
                        label="URL*"
                        placeholder="Enter URL with {parameters}"
                        value={actionConfig.url}
                        onChange={event => { onActionConfigUpdate('url', event)}}
                        error={error.url}
                    />
                </div>
                <div>
                    <div className="flex flex-col text-sm">
                        <SelectInput
                            id="httpMethod"
                            required
                            rootClass=""
                            label="HTTP Method"
                            value={actionConfig.httpMethod}
                            onChange={event => { onActionConfigUpdate('httpMethod', event)}}
                            error={error.httpMethod}
                            optionsComp={
                                <>
                                    <option>Select Method</option>
                                    {["GET", "POST", "PUT", "DELETE"].map((method)=>
                                        <option key={method} value={method}>{method}</option>
                                    )}
                                    }
                                </>}
                        />
                    </div>

                </div>
            </div>

            <div className="pt-4 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>

            <div className="pt-4 flex flex-col">
                <div className="flex flex-row justify-between items-center pb-2">
                    <label htmlFor="actionType" className="block text-sm font-medium text-gray-500">
                        Query Parameters
                    </label>
                    <button type="button"
                            onClick={onQueryParameterAdd}
                            className="rounded bg-white py-1 px-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Add New
                    </button>
                </div>

                <div className="flex flex-col gap-2">
                    {Object.keys(actionConfig.queryParameters || {}).length === 0?
                        <QueryParameterRow id={createUniqueId()} onUpdate={onQueryParameterUpdate} onDelete={onQueryParameterDelete}/>
                        :<>{Object.values(actionConfig.queryParameters).map((paramConfig)=>
                            <QueryParameterRow key={paramConfig.id} onUpdate={onQueryParameterUpdate} onDelete={onQueryParameterDelete} {...paramConfig} />)}</>}


                </div>
            </div>

            <div className="pt-4 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>

            {actionConfig.httpMethod !== "GET" &&
                <>

                    <div className="pt-4">
                        <div className="pb-4 flex flex-row justify-end items-center">
                            <RadioButtonGroup
                                selectedValue={actionConfig.requestType || formDataRequestType.value}
                                options={requestTypes}
                                onChange={event => { onActionConfigUpdate('requestType', event)}}
                                name="requestType"/>
                        </div>

                        {actionConfig.requestType === formDataRequestType.value ?
                            <div className="pt-4 flex flex-col">
                                <div className="flex flex-row justify-between items-center pb-2">
                                    <label htmlFor="actionType" className="block text-sm font-medium text-gray-500">
                                        Form Data
                                    </label>
                                    <button type="button"
                                            onClick={onFormDataAdd}
                                            className="rounded bg-white py-1 px-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                        Add New
                                    </button>
                                </div>

                                <div className="flex flex-col gap-2">
                                    {Object.keys(actionConfig.requestFormData || {}).length === 0?
                                        <FormDataRow id={createUniqueId()} onUpdate={onFormDataUpdate} onDelete={onFormDataDelete}/>
                                        :<>{Object.values(actionConfig.requestFormData).map((paramConfig)=>
                                            <FormDataRow key={paramConfig.id} onUpdate={onFormDataUpdate} onDelete={onFormDataDelete} {...paramConfig} />)}</>}
                                </div>
                            </div>
                            :
                            <div>
                                <Editor
                                    height="20vh"
                                    defaultLanguage='json'
                                    value={actionConfig.requestBody || '{}'}
                                    theme="vs-dark"
                                    onChange={value=>{
                                        onActionConfigUpdate('requestBody', { target: { value } })
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div className="pt-4 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300"></div>
                    </div>
                </>
            }




            <div className="pt-4 flex flex-col">
                <div className="flex flex-row justify-between items-center pb-2">
                    <label htmlFor="actionType" className="block text-sm font-medium text-gray-500">
                        Headers
                    </label>
                    <button type="button"
                            onClick={onHeaderAdd}
                            className="rounded bg-white py-1 px-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Add New
                    </button>
                </div>

                <div className="flex flex-col gap-2">
                    {Object.keys(actionConfig.headers || {}).length === 0?
                        <HeaderRow id={createUniqueId()} onUpdate={onHeaderUpdate} onDelete={onHeaderDelete}/>
                        :<>{Object.values(actionConfig.headers).map((paramConfig)=>
                            <HeaderRow key={paramConfig.id} onUpdate={onHeaderUpdate} onDelete={onHeaderDelete} {...paramConfig} />)}</>}


                </div>
            </div>

        </>
    )
}
