import React from "react";
import PropTypes from "prop-types";
import defaultImage from "../../../assets/images/chatapp.png";

const UploadAvatar = ({
  id = "fileContainer",
  name = "fileContainer",
  value,
  formState,
  placeholder,
  enableUpload,
  onChange,
  ...props
}) => {
  
  return (
    <div className="flex w-full items-end">
      <div>
        {formState?.processing && !value ? (
          <img
            className="inline-block h-14 w-14"
            src={defaultImage}
            alt="defaultImage"
          />
        ) : (
          <img
            className="inline-block h-14 w-14"
            src={value || defaultImage}
            alt="chatbotImage"
          />
        )}
      </div>
      <input
        id={id}
        name={name}
        type="file"
        className="sr-only"
        onChange={onChange}
        {...props}
      />
      <label
        htmlFor={id}
        className="cursor-pointer w-6 h-6 bg-gray-100 dark:bg-gray-800 text-gray-500 border-gray-300 active:bg-indigo-100 ml-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
          />
        </svg>
      </label>
    </div>
  );
};

export default UploadAvatar;

UploadAvatar.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  formState: PropTypes.any.isRequired,
  value: PropTypes.string,
  enableUpload: PropTypes.bool,
  onChange: PropTypes.func,
};
