import React, { useEffect, useRef, useState } from "react";
import { cn } from "../../../helpers/utils/css-utils";
import { MdKeyboardArrowDown } from "react-icons/md";

const Select = React.forwardRef(
  (
    {
      className,
      options = [],
      selected,
      onChangeSelected,
      placeholder = "Choose an option",
      align = "bottom",
    },
    ref
  ) => {
    const dropRef = useRef(null);

    const [value, setValue] = useState(selected);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      const onBodyClick = (event) => {
        if (dropRef?.current && dropRef.current.contains(event.target)) {
          return;
        }
        setIsOpen(false);
      };

      document.body.addEventListener("click", onBodyClick);

      return () => {
        document.body.removeEventListener("click", onBodyClick);
      };
    }, []);

    const handleSelectedChange = (option) => {
      setValue(option);
      onChangeSelected(option);
      setIsOpen(false);
    };

    const toggleDropdown = () => {
      setIsOpen((prevState) => !prevState);
    };

    return (
      <div ref={dropRef}>
        <div className={cn("w-full min-w-[240px]", className)}>
          <div ref={ref} className="relative w-full">
            <button
              onClick={toggleDropdown}
              className="
              flex w-full border cursor-pointer bg-gray-50 dark:bg-gray-700 rounded-md border-gray-300 dark:border-gray-700 p-2.5 text-sm text-left
              focus:outline-none ring-offset-0 focus:ring-indigo-500 focus:ring-2 focus:right-0 focus:border-transparent"
            >
              <div className="w-full flex items-center justify-between gap-2">
                {value ? (
                  <div className="flex gap-x-2 justify-between">
                    <span className="font-normal block truncate text-gray-900 dark:text-gray-100">
                      {value.label}
                    </span>
                  </div>
                ) : (
                  <span className="font-normal text-sm block truncate text-gray-600 dark:text-gray-300 placeholder-gray-300">
                    {placeholder}
                  </span>
                )}
                <MdKeyboardArrowDown className="w-5 h-5 text-gray-500" />
              </div>
            </button>

            {/* DropList */}
            {isOpen && (
              <ul
                className={cn(
                  `absolute z-50 my-1 w-full border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-700 shadow-lg max-w-full
                  overflow-auto focus:outline-none text-sm h-[180px] py-2`,
                  {
                    "bottom-full left-0": align === "top",
                    "top-full left-0": align === "bottom",
                    "top-0 left-full ml-1": align === "right",
                    "top-0 right-full": align === "left",
                  }
                )}
              >
                {options.map((item) => (
                  <li
                    key={item.value}
                    onClick={() => handleSelectedChange(item)}
                    className={cn(
                      "p-5 py-2.5 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-gray-100 cursor-pointer transition-all duration-300 text-gray-900 dark:text-gray-50",
                      {
                        "bg-gray-200 text-gray-900 dark:!text-gray-50 dark:bg-gray-900":
                          value?.value === item.value,
                      }
                    )}
                  >
                    <div className="flex items-center gap-x-2">
                      <span className="font-normal block truncate">
                        {item.label}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export { Select };
