import { useRef, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { ReactFlowProvider } from "reactflow";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import { stmNodeSelect } from "../../../redux/journey-toolbox/journey-toolbox-actions";

import JourneyFlow from "./journey/journey-flow";

// Custome stylesheet
import "reactflow/dist/style.css";
import "./index.css";
import SectionHeading from "../section-heading";
import WorkflowCanvas from "../../../components/workflow/WorkflowCanvas";
import {useEffect} from "react";
// import {useContext} from "@types/react";
// import {WorkflowCanvasContext} from "../../../contexts/WorkflowCanvasContextProvider";

function Page({
                currentJourneyConfig,
                currentJourneySession
}) {
  return <div className="flex flex-col flex-1">
    {/* TOP */}
    <SectionHeading title={`${currentJourneyConfig.name? `Flow ( ${currentJourneyConfig.name} )`: `Flow`}`} />

    {/* CONTENT */}
    <div className="h-screen relative px-6 pt-3 overflow-x-hidden overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
      <ReactFlowProvider>
        <WorkflowCanvas
            key={currentJourneySession?.sessionId}
            journeyId={currentJourneySession?.journeyId}
            currentJourneySession={currentJourneySession}
            currentJourneyConfig={currentJourneyConfig}
            isPreview={true}
        />
      </ReactFlowProvider>
    </div>
  </div>
}

const mapsStateToProps = (state, ownProps) => {
  return {
    journeyToolboxItemAddMessage: state.journeyToolboxItemAddMessage,
    stmNodeSelectMessage: state.stmNodeSelectMessage,
    stmEdgeAddMessage: state.stmEdgeAddMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stmNodeSelectHandle: (message) => {
      dispatch(stmNodeSelect(message));
    },
  };
};

export default connect(mapsStateToProps, mapDispatchToProps)(Page);
