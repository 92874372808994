import './style.css';
import NodeComponent from "../node";
import {LogicNodeIcon} from "./icons";

export function ChoiceNode({ id, data }) {
    return (
        <NodeComponent title="Condition" id={id} data={data} nodeTypeIcon={<LogicNodeIcon/>}>
            <div></div>
        </NodeComponent>
    );
}

