import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import ChatDate from "../../../chat-date";

export default function SwapCardList(props) {
  const {message} = props;
  function substrText(string) {
    let cpText = string;
    const length = string.length;
    if (length >= 100) {
      return `${cpText.substr(0, 100)} ..`;
    }
    return cpText;
  }
  return (
    <div className='w-full my-4 flex flex-col gap-2'>
      <Swiper
        autoplay={true}
        grabCursor={true}
        effect='fade'
        centeredSlides={false}
        roundLengths={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
        }}
      >
        {message.map((data, index) => (
          <SwiperSlide className='w-full max-w-[325px]' key={index}>
            <div className='border rounded-lg'>
              <div className='w-full h-[225px]'>
                <img
                  className='w-full h-full object-contain'
                  src={data.image}
                  alt={data.title}
                />
              </div>
              <div className='content p-4'>
                <h2 className='text-lg font-bold'>{data.title}</h2>
                <h3 className='text-[16px] font-normal'>{data.subtitle}</h3>
                <p className='py-2 text-sm text-gray-500'>
                  {substrText(data.description)}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
        <ChatDate date={props.date}/>
    </div>
  );
}

// <div className='lg:max-w-[800px] xl:max-w-[990px] lg:absolute lg:-right-24'>
