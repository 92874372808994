import React, { useState } from "react";
import FieldGroup from "../_components/field-group";
import TextBox from "../_components/textbox";
import TextLabel from "../_components/label";
import UploadAvatar from "../_components/upload-avatar";
import { Switch } from "../_components/switch";

const defaultSettings = {
  title: null,
  subTitle: null,
  welcomeMessage: null,
  inputPlaceHolder: null,
  logo: null,
  headerBackgroundColor: "#6366f1",
  headerTextColor: "#f9fafb",
  chatWindowBackgroundColor: "#f9fafb",
  sendBarBackgroundColor: "#e2e8f0",
};

const WebChatAssistant = () => {
  const [chatBotSetting, setChatBotSetting] = useState(defaultSettings);

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  const [logoUrlGenState, setLogoUrlGenState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const [logoUploadFormData, setLogoUploadFormData] = useState({
    fileExt: "",
  });
  const [logoUploadUrl, setLogoUploadUrl] = useState();

  const handleChange = (event) => {
    //  TODO
  };

  const handleSelectFile = async (event) => {
    // TODO
  };

  const handleHandoffToggle = (event) => {
    console.log("Handoff::", event.target.checked);
  };

  const enableUpload = !logoUrlGenState.processing;

  return (
    <>
      <div className="appearence_form flex flex-col space-y-8 my-4">
        <FieldGroup>
          <TextLabel className="w-auto lg:w-44">Name</TextLabel>
          <TextBox
            id="title"
            type="text"
            name="title"
            autoComplete={false}
            placeholder="Hannah"
            value={chatBotSetting.title}
            onChange={handleChange}
          />
        </FieldGroup>

        <FieldGroup>
          <TextLabel className="w-auto lg:w-44">Profile image</TextLabel>
          <UploadAvatar
            formState={formState}
            enableUpload={enableUpload}
            onChange={handleSelectFile}
            value={chatBotSetting?.logo}
          />
        </FieldGroup>

        <FieldGroup>
          <TextLabel className="w-auto lg:w-44">Enable handoff</TextLabel>
          <div className="flex w-full">
            <Switch
              id="poweredByState"
              value={chatBotSetting?.poweredBy}
              onChange={handleHandoffToggle}
            />
          </div>
        </FieldGroup>
      </div>
    </>
  );
};

export default WebChatAssistant;
