import React, { useEffect, useState } from 'react';
import Message from "../../../components/message/message-comp";
import Loader from "../../../components/form-loader/form-loader";
import { getInvoicesResponse } from '../../../services/billing';

const cssColorStatusPallet = {
    'open': 'gray',
    'paid': 'green',
    'uncollectible': 'red',
    'unpaid': 'red',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Table({ invoices = [] }) {
    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
            <tr>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Invoice
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Amount
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Status
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Created
                </th>
                <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Due
                </th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {invoices.map((invoice) => (
                <tr key={invoice.invoiceNumber}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.invoiceNumber}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 font-semibold">{invoice.amount}</td>
                    <td className="px-6 py-4 whitespace-nowrap capitalize">
                            <span
                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-md bg-${cssColorStatusPallet[invoice.status]}-100 text-${cssColorStatusPallet[invoice.status]}-800`}>
                                {invoice.status}
                            </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.created}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.due}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default function Index() {
    const [invoicesData, setInvoicesData] = useState([]);
    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    async function fetchData() {
        updateFormState(true, false, false);
        const { response, status } = await getInvoicesResponse();
        if (status === 200) {
            setInvoicesData(response);
            updateFormState(false, true, false);
        } else {
            updateFormState(false, false, true);
        }
    }

    const updateFormState = (processing, success, failed, errorMessage = '') => {
        setFormState({ processing, success, failed, errorMessage });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="flex flex-col gap-8 h-screen">
            {formState.processing && <div className="py-6"><Loader /></div>}

            {(!formState.processing && formState.failed) &&
                <Message text="Unable to fetch Invoice Data. Please try again" timeout={-1} />
            }

            {(!formState.processing && formState.success) &&
                <>
                    <div className="flex flex-row gap-4 justify-start items-baseline">
                        <p className="text-gray-900 text-2xl font-bold">Invoices</p>
                    </div>

                    <div className="mt-2">
                        <Table invoices={Object.values(invoicesData).flat()} />
                    </div>
                </>
            }
        </div>
    );
}
