import SwitchComponent from "../../../../../../components/switch";

export default function ReturnDirectSwitch ({actionConfig, onActionConfigUpdate}) {
    return <div className="pt-4 w-1/2">
        <SwitchComponent
            title="Return Direct"
            subTitle="Return the original response"
            enabled={actionConfig.returnDirect}
            onChange={state => {
                onActionConfigUpdate('returnDirect', { target: { value: state } })
            }}
        />
    </div>
}