import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { JourneyLink } from "./journey-link";
import { SearchInput } from "./search-input";
import { Skeleton } from "../../ui/skeleton";

export const List = ({
  title,
  items = [],
  status,
  filterKey,
  actionButton, onDeleteComplete
}) => {
  const [filterByParamItems, setFilterByParamItems] = useState(items);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const lowerCaseSearchText = searchText.toLowerCase();
    if (filterKey) {
      const newDataList = items.filter((item) =>
        item[`${filterKey}`].toLowerCase().includes(lowerCaseSearchText)
      );
      setFilterByParamItems(newDataList);
    }
  }, [filterKey, searchText, items]);

  if (status?.processing) {
    return <ListSkeleton />;
  }

  if (status?.failed) {
    return (
      <Alert color="failure" icon={HiInformationCircle}>
        <span className="font-medium">Error!</span> Journeys could not be
        loaded.
      </Alert>
    );
  }

  return (
    <div className="list flex flex-col space-y-3">
      <div className="flex items-center justify-between">
        <h5 className="capitalize font-bold dark:font-semibold text-lg xl:text-xl dark:text-white">
          {title}
        </h5>
        {actionButton ? <>{actionButton}</> : null}
      </div>

      <SearchInput
        onChangeParam={(e) => setSearchText(e.target.value || "")}
        className="border-none bg-gray-100/75 placeholder:text-gray-400 placeholder:text-base focus:bg-transparent"
      />

        {/*
        <ul
        style={{ scrollbarGutter: "stable" }}
        className="scrollbars h-[30vh] flex flex-col w-full overflow-y-auto overflow-x-hidden"
      >
        */}
      <ul
        style={{ scrollbarGutter: "stable" }}
        className="scrollbars h-[80vh] flex flex-col w-full overflow-y-auto overflow-x-hidden"
      >
        {filterByParamItems?.length === 0 ? (
          <li className="text-gray-400 p-3 mx-2">No journeys found.</li>
        ) : (
          filterByParamItems.map((data, index) => {
            return (
              <li key={index} className="block w-full">
                <JourneyLink key={index} data={data} onDeleteComplete={onDeleteComplete} />
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};

List.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  filterKey: PropTypes.string,
  actionButton: PropTypes.element,
  status: PropTypes.shape({
    processing: PropTypes.bool,
    success: PropTypes.bool,
    failed: PropTypes.bool,
    message: PropTypes.any,
  }),
};

function ListSkeleton() {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <Skeleton className="w-44 h-4" />
        <Skeleton className="w-9 h-9" />
      </div>

      <ul className="h-[30vh] py-4 flex flex-col space-y-4 w-full overflow-y-auto overflow-x-hidden">
        {Array.from({ length: 5 }).map((_, i) => (
          <li key={i} className="flex items-center gap-2">
            <Skeleton className="w-6 h-6" />
            <Skeleton className="w-[220px] h-6" />
          </li>
        ))}
      </ul>
    </div>
  );
}
