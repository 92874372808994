import { PopoverButton } from "./popover-button";
import { toolboxFlowsTriggerButtons } from "./constants";
import JourneySlotHandleDialog from "../../../components/journey-slot-handle-dialog";
import { useState, useEffect, useContext } from "react";
import {getAgentAction} from "../../../services/prompt-ai";
import {WorkflowCanvasContext} from "../../../contexts/WorkflowCanvasContextProvider";

export default function Toolbox({appId, journeyId, onSlotsUpdated}) {
    const workflowContext = useContext(WorkflowCanvasContext);
    const [currentEvent, setCurrentEvent] = useState();
    const [actionConfig, setActionConfig] = useState({ name: '...' });

    const onEventTrigger = event=> {
      console.log('onEventTrigger', event)
      setCurrentEvent(event)
    }

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null,
    });

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoading({ processing, success, failed, message });
    };

    const fetchActionConfig = async ()=> {
        updateDataLoadingState(true, false, false);
        const resp = await getAgentAction(appId, journeyId);
        if (resp) {
            setActionConfig(resp)
        }
        updateDataLoadingState(false, true, false);
    }

    const onDragStart = (event, nodeData) => {
        workflowContext.onDragStart(event, nodeData)
    }

    useEffect(() => {
        if (journeyId) {
            fetchActionConfig();
        }
    }, [journeyId]);

  return (
      <div className="grid grid-cols-3 border-b dark:border-gray-700">
          <div className="pt-5 pl-4 capitalize font-semibold dark:font-semibold text-sm xl:text-lg dark:text-white text-gray-500">
              <p>{actionConfig.name}</p>
          </div>
          <menu className="flex items-center w-full justify-center">
              {toolboxFlowsTriggerButtons.map((button, index) => {
                  return <PopoverButton key={index} option={button} eventTrigger={onEventTrigger} onDragStart={onDragStart} />;
              })}
              {currentEvent === 'variable' &&
                  <JourneySlotHandleDialog
                      appId={appId}
                      journeyId={journeyId}
                      onSlotsUpdated={onSlotsUpdated}
                      propOpenSlotsTableDialog={true}
                      onContainerClose={()=>setCurrentEvent(null)}
                  />
              }
          </menu>
          <div></div>
      </div>

  );
}
