import { post, get } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

export async function activePhoneNumbersList(appId) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/channels/twilio/phone-numbers/active-numbers`);
        if (status !== 200) {
            throw new Error('API failed')
        }
        return response;
    } catch (err) {
        console.debug('connectedPhoneNumbersList failed cause', err.message, err.code);
        return { phoneNumbers: [], voiceEnabled: false };
    }
}

export async function connectedPhoneNumbersList(appId) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/channels/twilio/phone-numbers/connected-numbers`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('connectedPhoneNumbersList failed cause', err.message, err.code);
        return [];
    }
}

export async function connectPhoneNumber(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/channels/twilio/phone-numbers/connect`, request);
    } catch (err) {
        console.debug('connectPhoneNumber failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function releasePhoneNumber(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/channels/twilio/phone-numbers/release`, request);
    } catch (err) {
        console.debug('releasePhoneNumber failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}