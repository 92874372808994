import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../../../../components/button/button';
import Message from "../../../../components/message/message-comp";
import Loader from "../../../../components/form-loader/form-loader";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {attachPaymentMethod} from "../../../../services/billing";

export default function Index({ open, onCompletion }) {
    const stripe = useStripe();
    const elements = useElements();
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        name: '',
    });

    const [error, setError] = useState({
        name: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    useEffect(() => {
        if (formState.success) {
            setTimeout(()=>{
                setIsOpen(false);
                onCompletion(true);
            }, 1500)
        }
    }, [formState.success]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'name') {
            formData.name = targetValue;
        }

        setFormData(Object.assign({}, formData));
    });

    const validateForm = () => {
        let valid = true;
        if (formData.name === '') {
            error.name = true;
            valid = false;
        } else {
            error.name = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        console.log("handleOnSubmit")
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            // Use your card Element with other Stripe.js APIs
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details:{
                    name: formData.name,
                },
            });

            if (error) {
                console.log('[error]', error);
                updateFormState(false, false, true, error.message);
            } else {
                console.log('[PaymentMethod]', paymentMethod.id);
                const { response, status } = await attachPaymentMethod(paymentMethod.id);
                if (status === 200) {
                    updateFormState(false, true, false);
                    onCompletion(true)
                } else {
                    updateFormState(false, false, true, response);
                }
            }
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCompletion(false);
    });

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                onClose={handleOnCancel}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <form onSubmit={handleOnSubmit} className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="flex items-start justify-center">
                                    <div className="w-full">
                                        <Dialog.Title as="h3" className="text-center text-2xl text-gray-600 font-bold">
                                            New Payment Method
                                        </Dialog.Title>
                                        <div>

                                            {formState.processing && <div className="p-4"><Loader/></div>}

                                            {formState.success && <Message text="Successfully Add new payment method" type="success"/>}

                                            {formState.failed && <Message text={formState.errorMessage} type="error"/>}

                                            <div>
                                                <label className="text-gray-800 font-semibold block my-3 text-md" for="name">Card Holder Name</label>
                                                <input
                                                    className="w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none"
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Enter card holder name"
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="py-6">
                                                <CardElement
                                                    options={{
                                                        hidePostalCode: true,
                                                        style: {
                                                            base: {
                                                                fontSize: '16px',
                                                                color: '#424770',
                                                                '::placeholder': {
                                                                    color: 'rgba(107, 114, 128, 1)',
                                                                },
                                                            },
                                                            invalid: {
                                                                // color: '#9e2146',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gap-2 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <Button variant="button" type="submit" text="Create" />
                                <Button variant="button" color="default" text="Cancel" onClick={handleOnCancel} />
                            </div>
                        </form>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}