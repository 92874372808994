import {useEffect, useState} from 'react';
import ChatDate from "../../../chat-date";

export default function ButtonOption(props) {
  const {message = {}} = props;
  return (
    <div className='w-full my-2'>
      <div className='message-text'>
        <p className='px-1 pb-1'>{message.text}</p>
        <ChatDate date={props.date}/>
        <div className='bot-option-wrap my-4 flex items-center flex-wrap gap-2'>
          {message?.options.map((option, index) => (
            <button
              className='px-4 py-1.5 inline-block rounded-full
              text-sm border border-indigo-400 border-dashed text-indigo-400
              hover:text-white hover:bg-indigo-400 hover:shadow-lg'
              type='button'
              key={index}
              onClick={option.fn}
            >
              {option.btnText}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
