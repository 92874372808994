import {useEffect, useState} from 'react';
import { getTrainingJobs, trainingCancel, deleteModel } from '../../../../services/model/finetune-service';
import {useAppInfo} from '../../../../helpers/hooks/common-hook';
import TrainingStart from "../training-start";
import Button from "../../../../components/button/button";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import Table from "../../../../components/table";
import {Badge} from "flowbite-react";

const statusMap = {
    'training_pending': { color: 'gray', title: 'Training Pending' },
    'training_running': { color: 'gray', title: 'Training Running' },

    'training_cancelled': { color: 'failure', title: 'Training Cancelled' },
    'training_failed': { color: 'failure', title: 'Training Failed' },

    'inference_pending': { color: 'gray', title: 'Inference Pending' },
    'inference_stopped': { color: 'failure', title: 'Inference Stopped' },
    'inference_failed': { color: 'failure', title: 'Inference Failed' },

    'succeeded': { color: 'success', title: 'Succeeded' },
    'deleted': { color: 'failure', title: 'Deleted' },
}
function ModelStatusComp({modelStatus}) {
    return <Badge className="justify-center w-1/2 sm:w-full" color={statusMap[modelStatus].color}>
        {statusMap[modelStatus].title}
    </Badge>
}
export default function Index({fineTuneType}) {
    const { appId } = useAppInfo();
    const [ dataList, setDataList ] = useState([]);
    const [ editData, setEditData ] = useState();

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const [cancelingData, setCancelingData] = useState();
    const [openCancelingConfirmDialog, setCancelingRevokeConfirmDialog] = useState();

    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

    async function fetchConfigs() {
        updateFormState(true, false, false);
        const { data } = await getTrainingJobs(appId);
        if (data && Array.isArray(data)) {
            setDataList(data);
        }
        updateFormState(false, true, false);
    }

    useEffect( () => {
        fetchConfigs();
    }, []);

    const handleOpenCreate = () => {
        setOpenCreateDialog(true);
        setEditData()
    }

    const handleOnCompletionCreate = async state => {
        if (state) {
            await fetchConfigs();
        }
        setOpenCreateDialog(false);
        setEditData()
    }

    const handleSetEditData = data => {
        setEditData(data);
        setOpenCreateDialog(true);
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const handleSelectCancel = id => {
        setCancelingData(id);
        setCancelingRevokeConfirmDialog(true);
    }

    const handleConfirmCancel = async confirmed => {
        setCancelingRevokeConfirmDialog(false);
        if (confirmed) {
            updateFormState(true, false, false);
            await trainingCancel(appId, cancelingData);
            setCancelingData(null);
            await fetchConfigs();
            updateFormState(false, true, false);
        }
    }

    const handleSelectDelete = id => {
        setDeletingData(id);
        setDeleteRevokeConfirmDialog(true);
    }

    const handleConfirmDelete = async confirmed => {
        setDeleteRevokeConfirmDialog(false);
        if (confirmed) {
            updateFormState(true, false, false);
            await deleteModel(appId, deletingData);
            setDeletingData(null);
            await fetchConfigs();
            updateFormState(false, true, false);
        }
    }

    const processDataList = dataList.map(dt=>{
        return {
            record: dt,
            columnsData: [
                { data: dt.baseModelProvider },
                { data: dt.label},
                { data: <ModelStatusComp modelStatus={dt.modelStatus}/> },
                { data: dt.modifiedAt, isUpdatedField: true },
                { options: [
                        {
                            name: 'Cancel Training',
                            onClick: ()=>handleSelectCancel({ baseModelProvider: dt.baseModelProvider, modelId: dt.modelId } )
                        },
                       {
                            name: 'Delete Model',
                            onClick: ()=>handleSelectDelete( { baseModelProvider: dt.baseModelProvider, modelId: dt.modelId })
                        }
                    ]
                },
            ]
        }
    })

    return (
        <div>
            {(openCreateDialog) &&
                <TrainingStart
                    appId={appId}
                    savedData={editData}
                    open={openCreateDialog}
                    onCompletion={handleOnCompletionCreate}
                    fineTuneType={fineTuneType}
                />
            }

            {openCancelingConfirmDialog &&
                <ConfirmDialog
                    appId={appId}
                    open={openCancelingConfirmDialog}
                    onCompletion={handleConfirmCancel}
                    title="Training Cancel Confirmation"
                    message="Are you sure. Do you want to cancel the training ?"
                />
            }

            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    appId={appId}
                    open={openDeleteConfirmDialog}
                    onCompletion={handleConfirmDelete}
                    title="Delete Confirmation"
                    message="Are you sure. Do you want to delete model ?"
                />
            }

            <div className="flex justify-end">
                <div className="flex flex-row items-center gap-4">
                    <svg onClick={()=>fetchConfigs()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    <Button onClick={handleOpenCreate} text="Start Training" />
                </div>
            </div>

            <Table
                headers={['Provider', 'Label', 'Status']}
                items={processDataList}
                dataLoadingState={formState}
                itemsNotFoundMessage="You don't have any Data sets yet"
            />
        </div>
    )
}