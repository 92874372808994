import {useCallback, useEffect, useRef, useState} from 'react';
import Button from '../../../../components/button/button';
import {createAccessKey} from '../../../../services/app/app-access-key-service';
import Message from "../../../../components/toast-message";
import Loader from "../../../../components/form-loader/form-loader";
import TextBox from "../../../../components/forms/text-box";
import {FaRegCopy} from "react-icons/fa";
import {Modal} from "flowbite-react";

function AppAccessKeyCreatePage({ appId, open, onCompletion, onCancel, appCreateNotifyHandle }) {
    const rootRef = useRef(null);
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        appId,
        keyId: ''
    });

    const [error, setError] = useState({
        keyId: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [accessKey, setAccessKey] = useState();
    const [copySuccess, setCopySuccess] = useState();

    useEffect(() => {
        if (copySuccess) {
            setTimeout(()=>{
                setCopySuccess(false);
            }, 1000)
        }
    }, [copySuccess]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'keyId') {
            formData.keyId = targetValue;
        }

        setFormData(Object.assign({}, formData));
    });

    const validateForm = () => {
        let valid = true;
        if (formData.keyId) {
            error.keyId = false;
        } else {
            error.keyId = true;
            valid = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const { response, status } = await createAccessKey(appId, formData);
            if (status === 200) {
                updateFormState(false, true, false);
                setAccessKey(response.accessKey);
            } else {
                updateFormState(false, false, true, response);
            }
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        if (onCancel) {
            onCancel();
        } else {
            onCompletion();
        }
    });

    const copyToClipboard = async e => {
        if ('clipboard' in navigator) {
            console.log("copyToClipboard navigator");
            await navigator.clipboard.writeText(accessKey);
        } else {
            console.log("copyToClipboard execCommand")
            document.execCommand('copy', true, accessKey);
        }
        setCopySuccess(true);
    };

    return (<div ref={rootRef}>
        <Modal show={true} size="md" popup onClose={onCancel} root={rootRef.current ?? undefined}>
            <Modal.Header></Modal.Header>
            <Modal.Body>
                <form onSubmit={handleOnSubmit}>
                    <div className="space-y-6">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">New Access Key</h3>

                        {formState.processing && <div className="p-4"><Loader/></div>}

                        {formState.failed && <div className="p-4"> <Message text={formState.errorMessage} type="failure"/></div>}

                        {copySuccess &&
                            <div className="p-4"><Message type="success" text="Copied"/></div>
                        }

                        {formState.success && <div className="p-4"><Message text="The access key will be shown only once. Please copy and save it" type="success"/></div>}


                        <TextBox
                            rootClass="w-full"
                            label="Key Id"
                            type="text"
                            name="keyId"
                            id="keyId"
                            placeholder="Key Identifier"
                            onChange={handleChange}
                            required
                        />

                        {accessKey &&
                            <div className="flex flex-col w-full gap-2">
                                <TextBox
                                    rootClass="col-span-2"
                                    label="Access Key"
                                    type="text"
                                    name="accessKey"
                                    id="accessKey"
                                    placeholder="Access Key"
                                    value={accessKey}
                                    readOnly
                                />
                                <div className="h-full flex flex-row items-end justify-end">
                                    <button type="button"
                                            className="h-10 w-10 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                        <FaRegCopy onClick={copyToClipboard}
                                                   className="w-5 h-5"
                                        />
                                        <span className="sr-only">Icon description</span>
                                    </button>
                                </div>
                            </div>
                        }

                        <div className="w-full flex flex-row gap-2 justify-end">
                            {formState.success &&
                                <Button variant="button" text="Done" onClick={handleOnCancel} />
                            }

                            {!formState.success &&
                                <>
                                    <Button variant="button" type="submit" text="Create" />
                                    <Button variant="button" color="default" text="Cancel" onClick={handleOnCancel} />
                                </>
                            }
                        </div>

                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </div>)
}

export default AppAccessKeyCreatePage;