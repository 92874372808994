import SelectInput from "../../../../components/forms/select";
import {getBaseModelProviders} from "../../../../services/model/finetune-service";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {useEffect, useState} from "react";

export default function Index({rootClass, value, onDataUpdate, error, updateSelectDataLoadingState, fineTuneType}) {

    const { appId } = useAppInfo();
    const [ baseModelProviders, setBaseModelProviders ] = useState([]);

    async function fetchData() {
        updateSelectDataLoadingState({
            processing: true
        });
        const data = await getBaseModelProviders(appId, fineTuneType);
        setBaseModelProviders(data);
        updateSelectDataLoadingState({
            processing: false
        });
    }

    useEffect( () => {
        fetchData();
    }, []);

    return <SelectInput
        rootClass={rootClass}
        id="baseModelProvider"
        label="Model Provider"
        required
        value={value}
        onChange={event => { onDataUpdate('baseModelProvider', event)}}
        error={error.baseModelProvider}
        optionsComp={
            <>
                <option>Select Provider</option>
                {baseModelProviders.map((provider)=>
                    <option key={provider.id} value={provider.id}>{provider.displayValue}</option>
                )}
            </>
        }
    />
}