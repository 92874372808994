import React from "react";
import { cn } from "../../../../helpers/utils/css-utils";

const Input = React.forwardRef(
  ({ className, type = "text", placeholder, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={cn(
          `block w-full bg-gray-50 dark:bg-transparent border rounded-md border-gray-300 py-2.5 px-3 text-sm text-gray-900
           focus:outline-indigo-500 focus:ring-indigo-500 focus:border-indigo-500
           dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
           dark:focus:ring-indigo-500  dark:focus:border-indigo-500 dark:focus:ring-1 dark:outline-none`,
          className
        )}
        placeholder={placeholder}
        {...props}
      />
    );
  }
);

export { Input };
