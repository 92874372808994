import { Button } from "flowbite-react";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect, useMemo, useState, useContext } from "react";
import { IoIosTrash } from "react-icons/io";
import { connect } from "react-redux";
import { useReactFlow } from "reactflow";

import { Menu, Transition } from "@headlessui/react";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { customAddRowTheme } from "../../../components/dynamic-rows";
import Message from "../../../components/toast-message";
import { classNames } from "../../../helpers/utils/css-utils";
import { stmSettingSave } from "../../../redux/journey-toolbox/journey-toolbox-actions";
import journeyStmStore from "../common/service/journey-stm-store";
import { settingsTypes } from "./settings-types-registry";
import {WorkflowCanvasContext} from "../../../contexts/WorkflowCanvasContextProvider";


const ChildNode = React.memo((props) => {
  // useEffect(() => {
  //   console.log('ChildNode change')
  // }, [props.onChangeData]);

  return React.Children.map(props.children, (child) => {
    const childProps = {
      id: props.id,
      name: props.name,
      onChangeData: props.onChangeData,
      savedSettings: props.savedSettings,
      metadata: props.metadata,
      closeSettingPage: props.closeSettingPage,
    };

    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }

    return child;
  });
});

function Settings({ closeSettingPage }) {
  const workflowCanvasContext = useContext(WorkflowCanvasContext);
  const nodeAttribute = workflowCanvasContext.nodeAttribute
  const metadata = workflowCanvasContext.metadata
  const { id, nodeType, label: name } = nodeAttribute

  const [dialogSettings, setDialogSettings] = useState({
    open: false,
    message: null,
  });
  const { deleteElements } = useReactFlow();

  const [settingMenuExpand, setSettingMenuExpand] = useState(false);

  let content;
  let title = "";

  const settingsType = settingsTypes[nodeType];

  if (settingsType) {
    title = settingsType.title;
    content = settingsType.component;
  }

  const onChangeData = useCallback((data) => {
    workflowCanvasContext.handleNodeFullConfigChange(data)
  }, []);

  const onClickDelete = (nodeTitle) => {
    setDialogSettings({
      open: true,
      message: `Are you sure you want to delete "${nodeTitle}"`,
    })
  }

  const onDeleteConfirm = (yes) => {
    if (yes) {
      deleteElements({ nodes: [{ id }] })
    } else {
      setDialogSettings((preSettings) => ({
        ...preSettings,
        open: false,
      }));
    }
    closeSettingPage();
  }

  const [copySuccess, setCopySuccess] = useState();

  const copyToClipboard = async e => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(id);
    } else {
      document.execCommand('copy', true, id);
    }
    setCopySuccess(true);
  };

  useEffect(() => {
    if (copySuccess) {
      setTimeout(()=>{
        setCopySuccess(false);
      }, 2000)
    }
  }, [copySuccess]);


  const memoizedMetadata = useMemo(() => metadata, [metadata.journeyId, metadata.slots]);

  const options = [
    {
      name: 'Copy Step Id',
      onClick: ()=>{copyToClipboard()}
    }
  ]

  return (
      <>
        <div className="p-0 box-border scrollbars h-[calc(100vh-4rem)] overflow-hidden overflow-y-auto">
          <div className="p-3 pr-4 pl-5 bg-slate-100 border-t border-b dark:bg-gray-800 sticky top-0 border-gray-200 dark:border-gray-700 z-10">
            <div className="flex justify-between">
              <p className="text-lg font-semibold text-gray-900 dark:text-white">
                {title}
              </p>
              <div className="flex justify-between gap-2" >
                <Button size="xs" theme={customAddRowTheme} color="gray" onClick={() => onClickDelete(title)}>
                  <IoIosTrash className="h-6 w-6" />
                </Button>

                <div className="">
                  <Menu as="div" className="">
                    <div>
                      <Menu.Button
                          className="flex items-center text-center font-medium focus:z-10 focus:outline-none text-gray-900 bg-white border border-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700 :ring-cyan-700 focus:text-cyan-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700 rounded-lg focus:ring-2">
                        <span className="sr-only">Open options</span>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24"
                             strokeWidth={1.5} stroke="currentColor"
                             className="w-7 h-7">
                          <path strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"/>
                        </svg>
                      </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                          className="absolute z-50 right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {options.map((option, oi) =>
                              <Menu.Item key={oi}>
                                {({active}) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                        )}
                                        onClick={() => {
                                          option.onClick();
                                        }}
                                    >
                                      {option.name}
                                    </a>
                                )}
                              </Menu.Item>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
          {/* Setting options */}
          {content ? (
              <div className="p-5 block">
                <ChildNode
                    key={id}
                    id={id}
                    name={name}
                    metadata={memoizedMetadata}
                    savedSettings={nodeAttribute.stateConfig}
                    onChangeData={onChangeData}
                    closeSettingPage={closeSettingPage}
                >
                  {content}
                </ChildNode>
              </div>
          ) : (
              <div
                  className="
          h-[90%] text-sm text-gray-300 dark:text-gray-500
          flex flex-1 flex-grow justify-center items-center"
              >
                No settings in {title}
              </div>
          )}

          <div className="flex flex-row justify-center items-center">
            {copySuccess &&
                <Message text={`Copied ${id}`} type="success"/>
            }
          </div>
        </div>
        <ConfirmDialog
            onCompletion={onDeleteConfirm}
            {...dialogSettings}
        />
      </>
  );
}

const mapsStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    stmSettingSaveHandle: (data) => {
      dispatch(stmSettingSave(data));
    },
  };
};

Settings.propTypes = {
  selectedNode: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    nodeIndex: PropTypes.number,
    type: PropTypes.string,
  }),
  metadata: PropTypes.shape({
    journeyId: PropTypes.string,
    slots: PropTypes.object,
  }),
};

export default connect(mapsStateToProps, mapDispatchToProps)(Settings);
