import {Label, Tabs, Badge} from "flowbite-react";
import DataSetList from "./data-set/data-set-list";
import ModelList from "./models/model-list";
import Inference from "./inference";
import {INTENT_CLASSIFY_FINE_TUNE_TYPE, RAG_FINE_TUNE_TYPE} from "./const/common-consts";

export default function Index({fineTuneType}) {
    console.log("fineTuneType", fineTuneType)

    const tabList = [
        {
            title: "Models",
            comp: <ModelList fineTuneType={fineTuneType}/>
        },
        {
            title: "Data set",
            comp: <DataSetList fineTuneType={fineTuneType}/>
        }
    ]

    if (fineTuneType === INTENT_CLASSIFY_FINE_TUNE_TYPE) {
        tabList.unshift({
            title: "Inference",
            comp: <Inference fineTuneType={fineTuneType}/>
        })
    }

    return (
        <div className="pt-2">

            <Tabs.Group
                aria-label="Pills"
                style="underline"
                theme={{
                    "tablist": {
                        "tabitem": {
                            "styles": {
                                "underline": {
                                    "base": "rounded-t-lg",
                                    "active": {
                                        "on": "text-indigo-600 rounded-t-lg border-b-2 border-indigo-600 active dark:text-indigo-500 dark:border-indigo-500",
                                        "off": "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
                                    }
                                }
                            }
                        }
                    }
                }
                }
            >
                {tabList.map((tab, i)=>
                    <Tabs.Item active title={tab.title} key={i}>
                        {tab.comp}
                    </Tabs.Item>
                )}
            </Tabs.Group>
        </div>
    )
}