import {useEffect, useState} from 'react';
import Table from "../../../../components/table";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import Button from "../../../../components/button/button";

import {Checkbox, Label, TextInput} from "flowbite-react";
import TableReloadButton from "../../../../components/button/table-reload-button";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {connectedPhoneNumbersList, releasePhoneNumber} from "../../../../services/integrate/twilio-integrate-service";
import ConnectPhoneNumber from "../connect-phone-number";


function PhoneNumbersListPage({ appCreateNotifyMessage }) {
    const { appId } = useAppInfo();
    const [dataList, setDataList] = useState([]);
    const [openCreateAdd, setOpenCreateAdd] = useState(false);
    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [deletingData, setDeletingData] = useState(false);
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState(false);
    const [searchText, setSearchText] = useState("");

    async function fetchApps() {
        updateFormState(true, false, false);
        setDataList(await connectedPhoneNumbersList(appId));
        updateFormState(false, false, false);
    }

    async function deleteStore(status) {
        if (status) {
            updateFormState(true, false, false);
            await releasePhoneNumber(appId, { phoneNumberSid: deletingData })
            updateFormState(false, false, false);
            await fetchApps();
            setDeletingData()
        }
        setDeleteRevokeConfirmDialog(false);
    }

    useEffect(() => {
        fetchApps();
    }, []);

    useEffect(() => {
        fetchApps();
    }, [appCreateNotifyMessage]);

    useEffect(() => {
        if (deletingData) {
            setDeleteRevokeConfirmDialog(true);
        }
    }, [deletingData]);

    const handleOpenCreateApp = () => {
        setOpenCreateAdd(true);
    }

    const handleOnCompletionCreateApp = fetch => {
        setOpenCreateAdd(false);
        console.log("handleOnCompletionCreateApp", fetch)
        if (fetch) {
            fetchApps();
        }
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const processDataList = dataList.map(dt=>{
        return {
            record: dt,
            columnsData: [
                { data: dt.phoneNumber, isIdField: true },
                { data: dt.friendlyName },
                { data: <div className="flex items-center gap-2">
                        <Checkbox id="accept" checked={dt.smsEnabled} />
                    </div> },
                { data: <div className="flex items-center gap-2">
                        <Checkbox id="voiceEnabled" checked={dt.voiceEnabled} />
                    </div> },
                { options: [
                        {
                            name: 'Release Phone Number',
                            onClick: ()=>setDeletingData(dt.sid)
                        }
                    ]
                },
            ]
        }
    })

    return (
        <div className="flex flex-col gap-2">

            {openCreateAdd && <ConnectPhoneNumber
                onCompletion={handleOnCompletionCreateApp}
                onCancel={handleOnCompletionCreateApp} />}

            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    appId={deletingData}
                    open={openDeleteConfirmDialog}
                    onCompletion={deleteStore}
                    title="Delete Confirmation"
                    message="Are you sure you want to release this Phone number?"
                />
            }


            <div className="flex flex-row justify-between items-center">
                <div></div>
                <div className="flex flex-row items-center gap-4 justify-end">
                    <TableReloadButton onClick={()=>fetchApps()}/>
                    <Button
                        text="Connect Phone Number"
                        onClick={handleOpenCreateApp}
                    >
                    </Button>
                </div>
            </div>


            <Table
                items={processDataList}
                headers={
                    ["Phone Number", "Friendly Name", "SMS", "Voice"]
                }
                dataLoadingState={formState}
                itemsNotFoundMessage="You don't have any phone numbers connected"
                modifiedColumn={false}
            />
        </div>

    )
}

export default PhoneNumbersListPage