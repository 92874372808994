import React, { useEffect, useState } from "react";
import { StmStateDto } from "../../common/dto/stm-state-dto";
import { ACTION_PROMPT } from "../../common/const/settings-type";
import { scenariosList } from "../../../../services/model/integration-workflow-service";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import Loader from "../../../../components/form-loader/form-loader";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";
import { HiOutlineRefresh, HiPlus } from "react-icons/hi";
import TextArea from "../../../../components/forms/text-area";

function Page({ name, onChangeData, savedSettings, metadata = {} }) {
  const { appId } = useAppInfo();
  const [formData, setFormData] = useState(
    savedSettings
  );

  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const [integrations, setIntegrations] = useState([]);
  const [integrationsLoadingState, setIntegrationsLoadingState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
    errorMessage: "",
  });

  const updateIntegrationsLoadingState = ({
    processing,
    success,
    failed,
    message,
    errorMessage,
  }) => {
    setIntegrationsLoadingState({
      processing,
      success,
      failed,
      message,
      errorMessage,
    });
  };

  const onActionConfigUpdate = (field, event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  async function fetchIntegrations() {
    updateIntegrationsLoadingState({
      processing: true,
    });
    const { data } = await scenariosList(appId);
    if (data && Array.isArray(data)) {
      setIntegrations(data);
    }
    updateIntegrationsLoadingState({
      processing: false,
    });
  }

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const notifyOnchangeData = (data) => {
    data.stateType = ACTION_PROMPT
    onChangeData(data);
  };

  useEffect(() => {
    notifyOnchangeData(formData);
  }, [formData]);

  useEffect(() => {
    if (selectedIntegration) {
      const integrationConfig = integrations.find(
        (s) => s.scenarioId === +selectedIntegration
      );
      if (integrationConfig) {
        const newFormData = { ...formData };
        newFormData.integrationConfig = integrationConfig;
        setFormData(newFormData);
      }
    }
  }, [selectedIntegration]);

  const onChangeInputData = (key, value) => {
    const newFormData = { ...formData };
    newFormData.runtimeInput[key] = value;
    setFormData(newFormData);
  };

  return (
    <div className="space-y-3">
      <SettingTip text="Workflow Integration" />
      {integrationsLoadingState.processing && <Loader />}
      <TextBox
        label="Name"
        type="text"
        name="name"
        placeholder="State name"
        value={formData.name}
        onChange={(event) => {
          onActionConfigUpdate("name", event);
        }}
      />
      <div className="py-3">
        <div className="relative">
          <div className="absolute top-0 right-0 z-10">
            <a
              href={`/app/${appId}/integrations/workflow`}
              target="_blank"
              className="text-sm inline-flex gap-2 items-center text-blue-700 font-semibold"
              rel="noreferrer"
            >
              <HiPlus />
              New Integration
            </a>
          </div>
          <div className="flex gap-2 items-end">
            <div className="flex-grow">
              <SelectInput
                label="Integration"
                id="scenarioId"
                name="scenarioId"
                autoComplete="scenarioId"
                value={formData.integrationConfig?.scenarioId}
                onChange={(event) => {
                  setSelectedIntegration(event.target.value);
                }}
                optionsComp={
                  <>
                    <option value="">Select Integration</option>
                    {}
                    {integrations.map((conn, si) => (
                      <option key={si} value={conn.scenarioId}>
                        {conn.scenarioName}
                      </option>
                    ))}
                  </>
                }
              />
            </div>
            <div className="flex h-10 flex-shrink-0 items-center">
              <span className="sr-only">Refresh integrations</span>
              <HiOutlineRefresh
                className="cursor-pointer text-gray-600 hover:text-indigo-700 dark:text-gray-400"
                size={22}
                onClick={async () => {
                  await fetchIntegrations();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4 flex flex-col gap-2 border-gray-100 border-t-2 pb-1">
        <label className="block text-md font-medium text-gray-900 dark:text-white">
          Input Mapping
        </label>
        {formData.integrationConfig?.input?.map((_in, key) => (
          <div key={key} className="flex flex-row gap-2">
            <TextBox
              rootClass=""
              label="Field"
              type="text"
              name="name"
              value={_in.name}
              readOnly={true}
            />
            {/*<TextBox*/}
            {/*  rootClass=""*/}
            {/*  label="Value"*/}
            {/*  type="text"*/}
            {/*  name="value"*/}
            {/*  placeholder="Value"*/}
            {/*  value={formData.runtimeInput[_in.name]}*/}
            {/*  onChange={(event) =>*/}
            {/*    onChangeInputData(_in.name, event.target.value)*/}
            {/*  }*/}
            {/*/>*/}
            <TextArea
                key={key}
                rows={1}
                rootClass="w-full"
                label={"Value"}
                value={formData.runtimeInput[_in.name]}
                onChange={(event) =>
                    onChangeInputData(_in.name, event.target.value)
                }
                variant="outlined"
                name="value"
                placeholder="Value"
            />
          </div>
        ))}
      </div>
      {!!formData.integrationConfig?.output?.length && (
        <div className="flex flex-col gap-2 border-gray-100 border-t-2 pt-4">
          <label className="block font-medium text-gray-900 dark:text-white">
            Output Variables
          </label>
          <div className="flex flex-col gap-2">
            {formData.integrationConfig?.output?.map((_in, key) => (
              <div key={key} className="flex flex-col w-1/3">
                <span className="flex items-center rounded-md bg-indigo-50 px-2 py-1 text-sm font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                  {_in.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Page;
