import {del, get, post, put} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function getSession(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/sessions`);
        if (status !== 200) {
            return {
                data: []
            };
        }
        return response;
    } catch (err) {
        console.debug('sessionList failed cause', err.message, err.code);
        return {
            data: []
        };
    }
}

export async function getMessages(appId, sessionId) {
    try {
        console.log("!!! getMessages", appId, sessionId)
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/sessions/${sessionId}/messages`);
        if (status !== 200) {
            return {
                data: []
            };
        }
        return response;
    } catch (err) {
        console.debug('sessionList failed cause', err.message, err.code);
        return {
            data: []
        };
    }
}

export async function setHandleBy(appId, sessionId, handleBy) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/sessions/${sessionId}/handle-by`,
            {handleBy});
        if (status !== 200) {
            return {
                data: {}
            };
        }
        return response;
    } catch (err) {
        console.debug('sessionList failed cause', err.message, err.code);
        return {
            data: {}
        };
    }
}
