const FULLFILLMENT_CODEHOOK_TYPE = 'fullfillment';
const VALIDATION_CODEHOOK_TYPE = 'validation';

const CODEHOOK_FUNCTION_DEFAULT_RUNTIME = 'nodejs18.x';
const CODEHOOK_FUNCTION_DEFAULT_HANDLER = 'index.handler';

export const CODEHOOK_FUNCTION_INLINE_CODE_TYPE = 'inline';
export const CODEHOOK_FUNCTION_ARCHIVE_CODE_TYPE = 'archive';


export class TrainingDataConfig {
    constructor({
                    appId,
                    version,
                    intent,
                    data,
                    createdAt,
                    modifiedAt,
                }) {
        this.appId = appId;
        this.version = version;
        this.intent = intent;
        this.data = data;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }
}

export class FunctionCodeInfo {
    constructor({
                    type = CODEHOOK_FUNCTION_INLINE_CODE_TYPE,
                    bucket = '',
                    key = '',
                    objectVersion = '', // not use for now
                    archiveLocation = '', // not use for now
                    inlineCode = '' // even inlineCode, code stored in archive s3
                }) {
        this.type = type;
        this.bucket = bucket;
        this.key = key;
        this.objectVersion = objectVersion;
        this.archiveLocation = archiveLocation;
        this.inlineCode = inlineCode;
    }
}

export class FunctionInfo {
    constructor({
                    name = '',
                    arn = '',
                    runtime = CODEHOOK_FUNCTION_DEFAULT_RUNTIME,
                    handler = CODEHOOK_FUNCTION_DEFAULT_HANDLER,
                    code = new FunctionCodeInfo({}),
                    environmentVariables = {}
                }) {
        this.name = name;
        this.arn = arn;
        this.runtime = runtime;
        this.handler = handler;
        this.code = code;
        this.environmentVariables = environmentVariables;
    }
}

export class CodeHookConfig {
    constructor({
                    appId,
                    version,
                    codehookId,
                    validationFunction = new FunctionInfo({}),
                    fullfillmentFunction = new FunctionInfo({}),
                }) {
        this.appId = appId;
        this.version = version;
        this.codehookId = codehookId;
        this.validationFunction = validationFunction;
        this.fullfillmentFunction = fullfillmentFunction;
    }
}

export class ContextEntry {
    constructor({
                    sourceIntent,
                    sourceEntity,
                    targetEntity,
                    expire = 5, // minutes
                }) {
        this.sourceIntent = sourceIntent;
        this.sourceEntity = sourceEntity;
        this.targetEntity = targetEntity;
        this.expire = expire;
    }
}

export class ContextConfig {
    constructor({
                    inputContexts = [] // type ContextEntry
                }) {
        this.inputContexts = inputContexts;
    }
}

export class RequiredEntityConfig {
    constructor({
                    entity,
                    prompt,
                    maxRequestsToFill = 5,
                }) {
        this.entity = entity;
        this.prompt = prompt;
        this.maxRequestsToFill = maxRequestsToFill;
    }
}

export class EntityParserConfig {
    constructor({
                    entity,
                    parser
                }) {
        this.entity = entity;
        this.parser = parser;
    }
}

/*
entityParserConfig = {
    'ENTITY_1': EntityParserConfig,
    'ENTITY_2': EntityParserConfig
}
 */
export class IntentConfig {
    constructor({
                    appId,
                    version,
                    intent,
                    codeHookConfig = new CodeHookConfig({}),
                    contextConfig = new ContextConfig({}),
                    requiredEntityConfig = [],
                    entityParserConfig = {}
                }) {
        this.appId = appId;
        this.version = version;
        this.intent = intent;
        this.codeHookConfig = codeHookConfig;
        this.contextConfig = contextConfig;
        this.requiredEntityConfig = requiredEntityConfig;
        this.entityParserConfig = entityParserConfig;
    }
}