import { Button } from 'flowbite-react';
import React from 'react';
import { IoIosAdd, IoIosRemove } from "react-icons/io";


export const customAddRowTheme = {
  "size": {
    "xs": "text-xs px-0 py-0",
  }
};

const getDividerCss = (showTopDivider, showBottomDivider) => {
  let dividerCss = 'border-gray-100';

  if (showTopDivider) {
    dividerCss = `${dividerCss} border-t-2 pt-4 mt-4`
  }

  if (showBottomDivider) {
    dividerCss = `${dividerCss} border-b-2 pb-4 mb-4`
  }

  return dividerCss;
}

const renderRow = (row, rowsTemplate, handleInputChange) => {
  if (typeof rowsTemplate === 'function') {
    const { id, value } = row;
    return rowsTemplate(
      value,
      (event) => { handleInputChange(row.id, event.target.name, event.target.value) },
      id
    );
  }
  return rowsTemplate;
};

const DynamicRows = ({
  title,
  rowsData,
  onChange,
  rowsTemplate,
  className,
  removeButtonClassName,
  showTopDivider = true,
  showBottomDivider = true }) => {

  const handleAddRow = () => {
    const newRow = {
      id: rowsData.length + 1,
      value: '',
    };
    onChange([ ...rowsData, newRow ]);
  };

  const handleRemoveRow = (rowId) => {
    onChange([ ...rowsData.filter(row => row.id !== rowId) ]);
  };

  const handleInputChangeByRaw = (rowId, inputName, value) => {
    const updatedRows = rowsData.map(row => {
      if (row.id === rowId) {
        return { ...row, [inputName]: value };
      }
      return row;
    });
    onChange(updatedRows);
  };

  return (
    <div className={className}>
      <div className={getDividerCss(showTopDivider, showBottomDivider)}>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center justify-between">
            <label className="block text-sm font-medium text-gray-900 dark:text-white">
              {title}
            </label>
            <Button size="xs" theme={customAddRowTheme} color="gray" onClick={handleAddRow}>
              <IoIosAdd className="h-6 w-6" />
            </Button>
          </div>
          <div className="flex flex-col gap-2">
            {rowsData.map(row => (
              <div key={row.id} className="flex flex-row items-center">
                <div className="flex flex-1 flex-row gap-2 items-center">
                  {renderRow(row, rowsTemplate, handleInputChangeByRaw)}
                </div>
                <div className={removeButtonClassName || "pt-4 flex-row justify-end"}>
                  <Button size="xs" theme={customAddRowTheme} color="gray" onClick={() => handleRemoveRow(row.id)}>
                    <IoIosRemove className="h-6 w-6" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DynamicRows);
