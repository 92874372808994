import { get, post, del } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

export async function getAllAccessKeys(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/access-keys`);
        if (status !== 200) {
            return {pagination: {}, data: []};
        }
        return response;
    } catch (err) {
        console.debug('access-key get-all failed cause', err.message, err.code);
        return {pagination: {}, data: []};
    }
}

export async function createAccessKey(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/access-keys`, request);
    } catch (err) {
        console.debug('access-key create failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function deleteAccessKey(appId, keyId) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/access-keys/${keyId}`, );
    } catch (err) {
        console.debug('access-key delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getDefaultAccessKeys(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/access-keys/default-access-key`);
        if (status !== 200) {
            return null;
        }
        return response;
    } catch (err) {
        console.debug('default-access-keys failed cause', err.message, err.code);
        return null;
    }
}