import React, { useState } from "react";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import FieldGroup from "../_components/field-group";
import TextLabel from "../_components/label";
import TextBox from "../_components/textbox";
import { Switch } from "../_components/switch";
import { Select } from "../_components/select";
import { MdVolumeOff, MdVolumeUp } from "react-icons/md";

const defaultSettings = {
  title: null,
  subTitle: null,
  welcomeMessage: null,
  inputPlaceHolder: null,
  logo: null,
  headerBackgroundColor: "#6366f1",
  headerTextColor: "#f9fafb",
  chatWindowBackgroundColor: "#f9fafb",
  sendBarBackgroundColor: "#e2e8f0",
};

const defaultVoiceTypes = [
  {
    label: "British Male",
    value: "british-male",
  },
  {
    label: "American Female",
    value: "american-female",
  },
  {
    label: "Australian Male",
    value: "australian-male",
  },
  {
    label: "Canadian Female",
    value: "canadian-female",
  },
  {
    label: "Irish Male",
    value: "irish-male",
  },
  {
    label: "Scottish Female",
    value: "scottish-female",
  },
  {
    label: "South African Male",
    value: "south-african-male",
  },
  {
    label: "Indian Female",
    value: "indian-female",
  },
  {
    label: "New Zealand Male",
    value: "new-zealand-male",
  },
  {
    label: "Welsh Female",
    value: "welsh-female",
  },
];

const WebVoiceAssistant = () => {
  const { appId } = useAppInfo();
  const [app, setApp] = useState({});

  const [chatBotSetting, setChatBotSetting] = useState(defaultSettings);

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: "",
  });

  // Mock: voice enable option
  const [poweredBy, setPoweredBy] = useState(false);

  const [playSound, setPlaySound] = useState(false);

  const handleChange = (event) => {
    //  TODO
  };

  const handleVoiceEnableToggle = (event) => {
    console.log("Enable::", event.target.checked);
    setPoweredBy(event.target.checked);
  };

  const handleChangeSelected = (value) => {
    // console.log("Handoff::", event.target.checked);
    console.log("VOICE SELECETD : ", value);
  };

  const handlePlaySoundToggle = () => {
    setPlaySound((prev) => !prev);
  };

  return (
    <div className="voice_form flex flex-col space-y-8 my-4">
      <FieldGroup>
        <TextLabel className="w-auto lg:w-44">Enable voice</TextLabel>
        <div className="flex w-full">
          <Switch
            id="poweredByState"
            value={poweredBy || chatBotSetting?.poweredBy}
            onChange={handleVoiceEnableToggle}
          />
        </div>
      </FieldGroup>

      <FieldGroup>
        <TextLabel className="w-auto lg:w-44">Personality</TextLabel>
        <div className="flex items-center gap-2 w-full">
          <Select
            align="top"
            options={defaultVoiceTypes}
            placeholder="Choose personality"
            selected={chatBotSetting.accentType} // accentType = {label:"British Male",value:"british-male"}
            onChangeSelected={handleChangeSelected}
          />
          <button
            onClick={handlePlaySoundToggle}
            className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 dark:text-white"
          >
            {playSound ? (
              <MdVolumeUp size={24} />
            ) : (
              <MdVolumeOff size={24} className="text-gray-500" />
            )}
          </button>
        </div>
      </FieldGroup>
    </div>
  );
};

export default WebVoiceAssistant;
