import { useRef, useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { ReactFlowProvider } from "reactflow";
import { useAppInfo } from "../../helpers/hooks/common-hook";
import { stmNodeSelect } from "../../redux/journey-toolbox/journey-toolbox-actions";

import PageContainer from "../../components/page-container";
import JourneyList from "./journey/journey-list";
import Toolbox from "./toolbox";

// Custome stylesheet
import "reactflow/dist/style.css";
import "./index.css";
import WorkflowCanvas from "../../components/workflow/WorkflowCanvas";
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";

function Page({
  journeyToolboxItemAddMessage = {},
  stmNodeSelectMessage,
  stmEdgeAddMessage,
  stmNodeSelectHandle,
}) {
  const { appId } = useAppInfo();
  const { journeyId } = useParams();
  const workflowCanvasContext = useContext(WorkflowCanvasContext);

  const journeyEditorRef = useRef();
  const [formState, setFormState] = useState([]);
  const [journeySlotsUpdated, setJourneySlotsUpdated] = useState(0);

  const fetchJourneyData = () => {
    if (journeyEditorRef.current) {
      journeyEditorRef.current.fetchJourneyDataHandler();
    }
  };

  const onClickSave = () => {
    if (journeyEditorRef.current) {
      journeyEditorRef.current.onclickSaveHandler();
    }
  };

  const onSlotsUpdated = () => {
    setJourneySlotsUpdated((preValue) => preValue + 1);
  }

  useEffect( () => {
    workflowCanvasContext.setIsPreview(false)
  }, []);

  return (
    <PageContainer>
      <div className="flex flex-1 gap-4 xl:gap-4">
        <JourneyList />

        <ReactFlowProvider>
          <div className="border bg-white dark:bg-slate-800 dark:border-gray-700 rounded-lg flex flex-col flex-1 relative shadow-md">
            <Toolbox appId={appId} journeyId={journeyId} onSlotsUpdated={onSlotsUpdated} />
            <WorkflowCanvas key={journeyId} journeyId={journeyId} slotsUpdated={journeySlotsUpdated}/>
          </div>
        </ReactFlowProvider>
      </div>
    </PageContainer>
  );
}

const mapsStateToProps = (state, ownProps) => {
  return {
    journeyToolboxItemAddMessage: state.journeyToolboxItemAddMessage,
    stmNodeSelectMessage: state.stmNodeSelectMessage,
    stmEdgeAddMessage: state.stmEdgeAddMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    stmNodeSelectHandle: (message) => {
      dispatch(stmNodeSelect(message));
    },
  };
};

export default connect(mapsStateToProps, mapDispatchToProps)(Page);
