import React from "react";

const Badge = ({ text, color = "gray" }) => {
  return (
    <span
      className={`inline-block pb-0.5 leading-6 px-2 text-[0.768em] tracking-wider rounded-full bg-${color}-100 text-${color}-700`}
    >
      {text}
    </span>
  );
};

export default Badge;
