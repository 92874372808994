import config from "../config";
export const API_PREFIX = `${config.API_BASE_URL}`;

// apps
export const APPS_ENDPOINT = `${API_PREFIX}/apps`;

// users
export const USERS_ENDPOINT = `${API_PREFIX}/users`;

// ask-ai
export const ASK_AI_ENDPOINT = `${API_PREFIX}/ask`;

// billing
export const BILLING_ENDPOINT = `${API_PREFIX}/billing`;

// billing
export const NOTIFICATION_ENDPOINT = `${API_PREFIX}/notifications`;

// apps
export const ACCOUNTS_ENDPOINT = `${API_PREFIX}/accounts`;

// configs
export const CONFIGS_ENDPOINT = `${API_PREFIX}/configs`;

// error codes
export const HTTP_CLIENT_ERROR = '500';
