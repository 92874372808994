import {useState, useCallback, useEffect} from 'react';
import Button from '../../../components/button/button';
import Message from "../../../components/toast-message";
import Loader from "../../../components/form-loader/form-loader";
import PageContainer from "../../../components/page-container";
import {saveLLMSettings, validateLLMApiKey} from "../../../services/llm-settings";
import {useAppInfo} from "../../../helpers/hooks/common-hook";
import {classNames} from "../../../helpers/utils/css-utils";
import {APP_NAVIGATION} from "../consts/navigations";
import SelectInput from "../../../components/forms/select";
import TextBox from "../../../components/forms/text-box";

function Badge({text, indicate}) {
    const svgClass = classNames(indicate? "fill-green-500": "fill-red-500", "h-1.5 w-1.5")
    return (
        <span
            className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
            <svg className={svgClass} viewBox="0 0 6 6" aria-hidden="true">
                <circle cx={3} cy={3} r={3}/>
            </svg>
            {text}
        </span>
    )
}

export default function LLMSettings({}) {
    const {appId} = useAppInfo();
    const [formData, setFormData] = useState({
        provider: 'openai',
        apiKey: ''
    });

    const [validateStatus, setValidateStatus] = useState({
        status: false,
    });

    const [error, setError] = useState({
        apiKey: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData};
        newFormData[targetName] = targetValue;

        setError({ [targetName]: false });
        setFormData(newFormData);
    });

    const validateForm = () => {
        let valid = true;

        if (formData.apiKey === '') {
            error.apiKey = true;
            valid = false;
        } else {
            error.apiKey = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const {response, status} = await saveLLMSettings(appId, formData);
            if (status === 200) {
                updateFormState(false, true, false, "Successfully saved");
            } else {
                updateFormState(false, false, true, response);
            }
        }
    };

    const handleValidateApiKey = async () => {
        updateFormState(true, false, false);
        const {response, status} = await validateLLMApiKey(appId, formData.apiKey);
        if (status === 200) {
            updateFormState(false, true, false, "Validation completed");
            setValidateStatus(response)
        } else {
            updateFormState(false, false, true, response);
        }
    };

    useEffect( () => {
        handleValidateApiKey();
    }, []);

    return (
        <PageContainer
            title="LLM Settings"
            subtitle="General setting for Large Language Model (LLM)" navigation={APP_NAVIGATION}>


            <form className="pt-4 flex flex-col gap-4" onSubmit={handleOnSubmit}>

                {formState.processing && <div className="p-4"><Loader/></div>}
                {formState.success && <Message text={formState.message} type="success"/>}
                {formState.failed && <Message text={formState.message} type="error"/>}

                <SelectInput
                    rootClass="w-1/2"
                    label="LLM Provider*"
                    id="llm_provider"
                    name="llm_provider"
                    optionsComp={<>
                        <option value="openai">OpenAI</option>
                    </>}
                />

                <TextBox
                    rootClass="w-1/2"
                    label="API Key"
                    type="password"
                    name="apiKey"
                    id="apiKey"
                    value={formData.apiKey}
                    placeholder="Enter Your Own API Key"
                    onChange={handleChange}
                    error={error.apiKey}
                />

                <div className="bg-white flex flex-row">
                    <Badge text={validateStatus.status? "Valid API Key Provided": "Invalid API Key"} indicate={validateStatus.status}/>
                </div>

                <div className="flex justify-end gap-2">
                    <Button color="default" variant="button" type="button" onClick={handleValidateApiKey} text="Validate Key" />
                    <Button variant="button" type="submit" text="Save" />
                </div>
            </form>

        </PageContainer>
    )
}
