
export const START_NODE_COMPONENT = 'StartNode';

export const COMMON_NODE_TYPE = 'common';
export const QUESTION_PROMPT_NODE_TYPE = 'questionPrompt';
export const MESSAGE_PROMPT_NODE_TYPE = 'messagePrompt';
export const AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE = 'autoCaptureSlots';
export const IMAGE_PROMPT_NODE_TYPE = 'imageMessage';
export const BUTTONS_PROMPT_NODE_TYPE = 'buttonsPrompt';
export const CARDS_PROMPT_NODE_TYPE = 'cardsPrompt';
export const WEBHOOK_PROMPT_NODE_TYPE = 'webhookPrompt';
export const CODEHOOK_PROMPT_NODE_TYPE = 'codehookPrompt';
export const CODEHOOK_ACTION_NODE_TYPE = 'codehookAction';
export const WEBHOOK_ACTION_NODE_TYPE = 'webhookAction';
export const JOURNEY_ACTION_NODE_TYPE = 'journeyAction';
export const INTEGRATION_ACTION_NODE_TYPE = 'integrationAction';
export const LIVE_AGENT_ACTION_NODE_TYPE = 'liveAgentAction';
export const DOC_SEARCH_ACTION_NODE_TYPE = 'docSearchAction';
export const AI_TASK_ACTION_NODE_TYPE = 'aiTask';
export const PAUSE_ACTION_NODE_TYPE = 'pauseAction';
export const AUTH_NODE_TYPE = 'auth';
export const CHOICE_NODE_TYPE = 'choice';

export const TEXT_MESSAGE_NODE_TYPE = 'textMessage';

// node-type settings message
export const NODE_TYPE_SETTINGS_TITLE = {};
NODE_TYPE_SETTINGS_TITLE[COMMON_NODE_TYPE] = 'Common settings';
NODE_TYPE_SETTINGS_TITLE[QUESTION_PROMPT_NODE_TYPE] = 'Question prompt settings';
NODE_TYPE_SETTINGS_TITLE[MESSAGE_PROMPT_NODE_TYPE] = 'Message prompt settings';
NODE_TYPE_SETTINGS_TITLE[AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE] = 'Auto Capture Slots prompt settings';
NODE_TYPE_SETTINGS_TITLE[IMAGE_PROMPT_NODE_TYPE] = 'Image message settings';
NODE_TYPE_SETTINGS_TITLE[BUTTONS_PROMPT_NODE_TYPE] = 'Buttons prompt settings';
NODE_TYPE_SETTINGS_TITLE[CARDS_PROMPT_NODE_TYPE] = 'Cards prompt settings';
NODE_TYPE_SETTINGS_TITLE[WEBHOOK_PROMPT_NODE_TYPE] = 'Webhook prompt settings';
NODE_TYPE_SETTINGS_TITLE[CODEHOOK_PROMPT_NODE_TYPE] = 'Codehook prompt settings';
NODE_TYPE_SETTINGS_TITLE[CODEHOOK_ACTION_NODE_TYPE] = 'Codehook action settings';
NODE_TYPE_SETTINGS_TITLE[WEBHOOK_ACTION_NODE_TYPE] = 'Webhook action settings';
NODE_TYPE_SETTINGS_TITLE[JOURNEY_ACTION_NODE_TYPE] = 'Journey action settings';
NODE_TYPE_SETTINGS_TITLE[INTEGRATION_ACTION_NODE_TYPE] = 'Integration action settings';
NODE_TYPE_SETTINGS_TITLE[LIVE_AGENT_ACTION_NODE_TYPE] = 'Live Agent settings';
NODE_TYPE_SETTINGS_TITLE[DOC_SEARCH_ACTION_NODE_TYPE] = 'Doc Search settings';
NODE_TYPE_SETTINGS_TITLE[AI_TASK_ACTION_NODE_TYPE] = 'AI Task settings';
NODE_TYPE_SETTINGS_TITLE[PAUSE_ACTION_NODE_TYPE] = 'Pause action settings';
NODE_TYPE_SETTINGS_TITLE[AUTH_NODE_TYPE] = 'Auth settings';
NODE_TYPE_SETTINGS_TITLE[CHOICE_NODE_TYPE] = 'Choice settings';
