import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { cn } from "../../../../helpers/utils/css-utils";

import { SidebarContext } from ".";
import MenuItem from "./menu-item";

export default function MenuItemSubmenu({ name, icon, items = [] }) {
  const { expanded } = useContext(SidebarContext);

  const timeoutRef = useRef(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [animateRender, setAnimateRender] = useState(false);

  useEffect(() => {
    if (expanded) return;

    setSubMenuOpen(false);
  }, [expanded]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const toggleSubMenu = useCallback(() => {
    if (!expanded) return;

    if (subMenuOpen) {
      setAnimateRender(false);
      timeoutRef.current = setTimeout(() => {
        setSubMenuOpen(false);
      }, 400);
    } else {
      setSubMenuOpen(true);
      setTimeout(() => {
        setAnimateRender(true);
      }, 300);
    }
  }, [expanded, subMenuOpen]);

  const listHeightPx = useMemo(
    () => `${50 * parseInt(items.length)}px`,
    [items.length]
  );

  return (
    <div className="relative">
      <div
        onClick={toggleSubMenu}
        className={cn(
          "relative group flex items-center py-1.5 px-0 cursor-pointer transition-all hover:bg-gray-100 dark:hover:bg-gray-800",
          {
            "rounded-t-md  dark:!bg-gray-800 bg-gray-100": subMenuOpen,
            "rounded-md": !subMenuOpen,
          }
        )}
      >
        <div className="w-9 h-9 flex flex-none items-center justify-center rounded-b-md">
          <span className="text-[24px] text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-100">
            {icon}
          </span>
        </div>

        <span
          className={cn(
            "w-0 font-medium text-gray-800 dark:text-gray-100 overflow-hidden transition-all text-sm truncate",
            {
              "w-52 ml-3": expanded,
            }
          )}
        >
          {name}
        </span>

        {expanded && (
          <span className="w-9 h-9 flex items-center justify-center ml-auto flex-none">
            <MdOutlineKeyboardArrowRight
              size={22}
              className={cn("text-gray-500 transition duration-300", {
                "rotate-90": subMenuOpen,
              })}
            />
          </span>
        )}
      </div>

      {/* submenu items */}
      {subMenuOpen ? (
        <div
          style={{ height: animateRender ? listHeightPx : 0 }}
          className={cn(
            "block rounded-b-md transition-all h-0 opacity-0 duration-500 ease-in-out bg-gray-100 dark:bg-gray-800",
            {
              "opacity-100": animateRender,
            }
          )}
        >
          {items.map((item, index) => {
            return (
              <div className="scale-95 inline-block pl-8 opacity-70 dark:opacity-40 hover:opacity-100 dark:hover:opacity-100">
                <MenuItem
                  key={index}
                  icon={item.icon}
                  name={item.name}
                  href={item.href}
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

MenuItemSubmenu.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string,
  subitems: PropTypes.array,
};
