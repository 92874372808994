export const INTEGRATION_NAVIGATION = [
    // {
    //     name: 'Shopify',
    //     href: '/integrations/shopify',
    //     current: false,
    // }
    {
        name: 'Workflow',
        href: '/integrations/workflow',
        current: false,
    },
    {
        name: 'Auth Connectors',
        href: '/integrations/auth-connectors',
        current: false,
    },
    { name: 'Support Centers', href: '/live-agent', current: false }
]
