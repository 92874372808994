import {useEffect, useState} from 'react';
import {
    deleteAgentActionSettings,
    disableAgentActionSettings,
    getAgentActions
} from '../../../../services/prompt-ai';
import {useAppInfo} from '../../../../helpers/hooks/common-hook';
import CodehookSave from "../../../codehook/codehook-save/codehook-save-page";
import Button from "../../../../components/button/button";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import PageContainer from "../../../../components/page-container";
import Table from "../../../../components/table";
import AGENT_NAVIGATION from "../../../../consts/agent-navigations";
import {ACTION_TYPES_DISPLAY} from "../action-settings/common-consts";


export default function Index() {
    const { appId, intentLess } = useAppInfo();
    const [ dataList, setDataList ] = useState([]);
    const [ editData, setEditData ] = useState();
    const [enabled, setEnabled] = useState(true)

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

    async function fetchConfigs() {
        updateFormState(true, false, false);
        const settings = await getAgentActions(appId);
        if (settings.data) {
            setDataList(settings.data);
        }
        updateFormState(false, true, false);
    }

    useEffect( () => {
        fetchConfigs();
    }, []);

    const handleOpenCreate = () => {
        setOpenCreateDialog(true);
        setEditData()
    }

    const handleOnCompletionCreate = async state => {
        if (state) {
            await fetchConfigs();
        }
        setOpenCreateDialog(false);
        setEditData()
    }

    const handleSetEditData = data => {
        setEditData(data);
        setOpenCreateDialog(true);
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const handleSelectDelete = id => {
        setDeletingData(id);
        setDeleteRevokeConfirmDialog(true);
    }

    const handleConfirmDelete = async confirmed => {
        setDeleteRevokeConfirmDialog(false);
        if (confirmed) {
            updateFormState(true, false, false);
            await deleteAgentActionSettings(appId, deletingData);
            setDeletingData(null);
            await fetchConfigs();
            updateFormState(false, true, false);
        }
    }

    const handleDisable = async (actionId, deactivate) => {
        updateFormState(true, false, false);
        await disableAgentActionSettings(appId, actionId, deactivate);
        await fetchConfigs();
        updateFormState(false, true, false);
    }
    const processDataList = dataList.map(dt=>{


        return {
            record: dt,
            columnsData: [
                { data: dt.name, isIdField: true },
                { data: ACTION_TYPES_DISPLAY[dt.actionType] },
                { data: dt.description },
                { options: [
                        {
                            name: 'Edit Action',
                            link: `settings/${dt.id}`
                        }, {
                            name: 'Delete Action',
                            onClick: ()=>handleSelectDelete(dt.id)
                        }, {
                            name: dt.deactivate? 'Enable': 'Disable',
                            onClick: ()=>handleDisable(dt.id, !dt.deactivate)
                        }
                    ]
                },
            ]
        }
    })

    return (
        <PageContainer title="Actions" headerAction={
            <div className="flex flex-row items-center gap-4">
                <svg onClick={()=>fetchConfigs()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-900">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                <Button
                    variant="link"
                    to={`settings`}
                    text="New Action" />
            </div>
        }
                       //navigation={AGENT_NAVIGATION}
        >
            {(openCreateDialog) &&
                <CodehookSave
                    appId={appId}
                    savedKnowledgeBase={editData}
                    open={openCreateDialog}
                    onCompletion={handleOnCompletionCreate}
                />
            }

            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    appId={appId}
                    open={openDeleteConfirmDialog}
                    onCompletion={handleConfirmDelete}
                    title="Delete Confirmation"
                    message="Are you sure. Do you want to delete this Action ?"
                />
            }

            <Table
                headers={['Name', 'Action Type', 'Description']}
                items={processDataList}
                dataLoadingState={formState}
                itemsNotFoundMessage="You don't have any Actions"
                modifiedColumn={false}
                enableColumn={false}
            />

        </PageContainer>
    )
}