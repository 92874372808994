import React, {useCallback, useState, useContext} from "react";
import {useAppContext, useAppInfo} from "../../helpers/hooks/common-hook";
import FormLoader from "../../components/form-loader/form-loader";
import config from "../../config";
import { useEffect } from "react";
import SectionHeading from "./section-heading";
import PlusButton from "../../components/internal-router-component/channels/plus-button";
import {AiOutlinePlus} from "react-icons/ai";
import { MdOpenInNew } from "react-icons/md";
import WorkflowDebugger from "./workflow";
import {journeyGet} from "../../services/model/journey-service";
import {getRuntimeDebugSession} from "../../services/runtime/runtime-service";
import {createUniqueId} from "../../helpers/utils/object-util";
import { subscribeRuntimeDebugger } from "../../helpers/utils/real-time-api";
import ConversationData from "./conversation-data";
import {IoMdClose} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import Toolbox from "../action-journey-studio/toolbox";
import WorkflowCanvas from "../../components/workflow/WorkflowCanvas";
import {ReactFlowProvider} from "reactflow";
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";
import {CgSpinner} from "react-icons/cg";

function ChatPreview({ sessionId }) {
  const { appId } = useAppInfo();
  const [iframeSrc, setIframeSrc] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionId) {
      try {
        setIframeSrc(`${config.WEB_PLUGIN_URL}/?app_id=${appId}&test_mode=1&session_id=${sessionId}&test_voice_mode=1`);
      } catch (error) {
        console.error('Error fetching access key:', error);
      }
    }
  }, [sessionId]);

  return (
      <div className="h-full border-l dark:border-gray-700 md:w-80 2xl:w-96">
        <div className="px-5 pr-2 dark:border-gray-700 h-14 flex items-center justify-between">
          <h4 className="font-bold text-lg dark:text-white">Preview</h4>
          <div className="flex flex-row">
            <PlusButton
                onClick={() => {
                  if (iframeSrc) {
                    window.open(
                        iframeSrc,
                        "_blank"
                    );
                  }
                }
            }>
              <MdOpenInNew size={20} />
            </PlusButton>
            <PlusButton onClick={() => navigate(`/app/${appId}/journey`)}>
              <IoMdClose size={20} />
            </PlusButton>
          </div>
        </div>
        {/*  iFrame Widget plugin goes here.*/}
        <div className="mock-ui h-[calc(100%-58px)] flex flex-col overflow-hidden">
          {iframeSrc &&
              <iframe
                  title="webchat plugin"
                  src= {iframeSrc}
                  className="flex flex-1 flex-grow"
                  allow="microphone"
              ></iframe>
          }
        </div>
        {/*  iFrame Widget plugin goes here.*/}
      </div>
  );
}

function Index() {
  const { appId } = useAppInfo();
  const workflowCanvasContext = useContext(WorkflowCanvasContext);
  // TODO
  const [sessionId, setSessionId] = useState();
  const [debuggerData, setDebuggerData] = useState({
    agentConfig: null,
    journeySession: null
  });
  const [currentJourneySession, setCurrentJourneySession] = useState();
  const [currentJourneyConfig, setCurrentJourneyConfig] = useState();

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(Object.assign({}, { processing, success, failed, message }));
  };

  useEffect(() => {
    workflowCanvasContext.setNodes([]);
    workflowCanvasContext.setEdges([]);
    workflowCanvasContext.setIsPreview(true)
    if (!sessionId) {
      setSessionId(createUniqueId());
    }
  }, []);

  const fetchSessionData = async () => {
    try {
      const data = await getRuntimeDebugSession(appId, sessionId);
      if (data && data.response) {
        setDebuggerData(data.response)
      }
    } catch (error) {
      console.error("fetchSessionData failed", error);
    }
  }

  useEffect( () => {
    if (sessionId) {
      fetchSessionData();
    }
  }, [sessionId]);

  const [initReconnect, setInitReconnect] = useState({});

  const initSubscription = () => {
    const subscription = subscribeRuntimeDebugger(
        debuggerData.defaultAccessKey,
        appId,
        sessionId,
        async ({ data }) => {
          const newDebuggerData = JSON.parse(data)
          // console.log('ON DATA', newDebuggerData)
          await new Promise(resolve => setTimeout(resolve, 0.2));
          setDebuggerData({...debuggerData, ...newDebuggerData })
         // setCurrentJourneySession
        },
        (error) => {
          if (error.error.errors[0].message === "Connection closed") {
            console.error("CONN FAILED", error);
            setInitReconnect({});
          }
        }
    );
    console.log("subscription", subscription);
    return () => {
      console.log("unsubscribe......");
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    console.log("initReconnect");
    if (debuggerData) {
      return initSubscription();
    }
  }, [initReconnect]);

  useEffect( () => {
    if (debuggerData) {
      if (debuggerData.journeySession) {
        setCurrentJourneySession(debuggerData.journeySession)
      }
      return initSubscription();
    }
  }, [debuggerData]);

  useEffect( () => {
    if (currentJourneySession) {
      setCurrentJourneyConfig(debuggerData?.agentConfig?.journeys.find(config => config.journeyId === currentJourneySession.journeyId))
    }
  }, [currentJourneySession]);

  useEffect( () => {
    if (currentJourneySession && currentJourneyConfig) {
      workflowCanvasContext.setCurrentJourneySession(currentJourneySession)
      workflowCanvasContext.setCurrentJourneyConfig(currentJourneyConfig)
    }
  }, [currentJourneySession, currentJourneyConfig]);

  return (

      <div className="bg-inherit h-full w-full px-6 pt-4">
        <div className="w-full h-full flex gap-4">
          <aside className="flex h-[calc(100vh-90px)] bg-white dark:bg-slate-800 w-full flex-col overflow-y-hidden sm:w-[260px] xl:w-[260px] border rounded-lg dark:border-gray-700 shadow-md">
            <ConversationData agentConfig={debuggerData.agentConfig} journeySession={debuggerData.journeySession}/>
          </aside>
          <div className="border bg-white h-[calc(100vh-90px)] dark:bg-slate-800 dark:border-gray-700 rounded-lg flex flex-col flex-1 relative shadow-md">
            <div className="flex h-full flex-1 flex-grow overflow-auto lg:overflow-hidden">
              {(currentJourneySession && currentJourneyConfig) ?
                  <WorkflowDebugger
                      // key={currentJourneySession.eventId}
                      currentJourneySession={currentJourneySession}
                      currentJourneyConfig={currentJourneyConfig}
                  />
                  :
                  <div className="flex flex-grow items-center justify-center p-3">
                    <div className="flex items-center gap-1 text-gray-500">
                      <CgSpinner size={24} className="animate-spin" />
                      Loading..
                    </div>
                  </div>
              }

              <ChatPreview sessionId={sessionId}/>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Index;
