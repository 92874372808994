import { Modal } from "flowbite-react";
import { React, useEffect, useRef, useState } from 'react';
import { FaRegCopy } from "react-icons/fa";
import { connect } from 'react-redux';
import Button from '../../../../components/button/button';
import DynamicRows from "../../../../components/dynamic-rows-v2";
import Loader from "../../../../components/form-loader/form-loader";
import SelectInput from "../../../../components/forms/select";
import TextBox from "../../../../components/forms/text-box";
import Message from "../../../../components/toast-message";
import config from "../../../../config";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import { appCreateNotify } from "../../../../redux/app/app-actions";
import { authConnSave } from '../../../../services/model/model-config-service';

const mapsStateToProps = (state, ownProps) => {
  return {};
}

const mapDispatchToProps = dispatch => {
  return {
    appCreateNotifyHandle: (appId) => {
      dispatch(appCreateNotify(appId));
    },
  };
}

const OAUTH2_PROTOCOL = { value: 'oauth2', displayName: 'OAuth 2' };
const OAUTH2_RESPONSE_TYPES = { value: 'code', displayName: 'Code' };
const DEFAULT_AUTH_PROVIDER = 'default'

// TODO currently support only oauth2 authProtocol
function Index({ onCompletion, savedData }) {
  const rootRef = useRef(null);
  const { appId } = useAppInfo();

  const [savedId, setSavedId] = useState();
  const [formData, setFormData] = useState(savedData || {
    id: '',
    description: '',
    authProtocol: OAUTH2_PROTOCOL.value,
    authProvider: DEFAULT_AUTH_PROVIDER,
    authorizationUrl: '',
    responseType: OAUTH2_RESPONSE_TYPES.value,
    clientId: '',
    redirectUri: '',
    tokenEndpoint: '',
    userInfoEndpoint: '',
    scope: []
  });

  const [error, setError] = useState({
    id: false,
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: ''
  });

  const [copySuccess, setCopySuccess] = useState();

  useEffect(() => {
    if (savedId) {
      setTimeout(() => {
        onCompletion(true);
      }, 1500)
    }
  }, [savedId]);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000)
    }
  }, [copySuccess]);

  const handleChange = event => {
    const targetName = event.target.name;
    const targetValue = event.target.value;
    setFormData((preData) => {
      const currData = preData;
      currData[targetName] = targetValue;

      return ({ ...currData });
    });
  };

  const validateForm = () => {
    let valid = true;
    if (formData.id === '') {
      error.id = true;
      valid = false;
    } else {
      error.id = false;
    }

    setError(Object.assign({}, error))
    return valid;
  }

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }))
  }

  const handleOnSubmit = async (event) => {
    if (!formState.processing && validateForm()) {
      updateFormState(true, false, false);
      const { response, status } = await authConnSave(appId, formData);
      if (status === 200) {
        setSavedId(formData.id);
        updateFormState(false, true, false, "Successfully created");
      } else {
        updateFormState(false, false, true, response);
      }
    }
  };

  const prepareRawsData = (data = []) => {
    return data.map((value, i) => ({ id: i + 1, value }));
  };

  const onChangeScope = (rowsData = []) => {
    const newScopes = rowsData.map(raw => raw.value);
    setFormData((preData) => {
      const currData = preData;
      currData.scope = newScopes;

      return ({ ...currData });
    })
  };

  const copyToClipboard = async e => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(config.AUTH_CALLBACK_URL);
    } else {
      document.execCommand('copy', true, config.AUTH_CALLBACK_URL);
    }
    setCopySuccess(true);
  };

  const renderMessages = () => {
    let message;
    let type;

    if (formState.success) {
      message = 'Successfully Saved Configs';
      type = 'success';
    } else if (formState.failed) {
      message = formState.message;
      type = 'failure';
    }

    return message && type ? <Message text={message} type={type} /> : null;
  }

  return (
    <div ref={rootRef}>
      <Modal show={true} size="2xl" popup onClose={() => onCompletion(false)} root={rootRef.current ?? undefined}>
        <Modal.Header className="pl-6 pt-4 pb-4">{savedData ? 'Edit Connector' : 'Create New Connector'}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            {formState.processing && <div className="p-4"><Loader /></div>}
            {renderMessages()}
            <div className="flex flex-col gap-3">
              <div className="grid grid-cols-2 gap-4">
                <TextBox
                  label="Connector Id"
                  type="text"
                  name="id"
                  id="id"
                  value={formData.id}
                  placeholder="Enter connector id"
                  onChange={handleChange}
                  readOnly={!!savedData}
                  required
                />
                <TextBox
                  label="Client Id"
                  type="text"
                  name="clientId"
                  id="clientId"
                  value={formData.clientId}
                  placeholder="Enter oauth2 client-id"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-2">
                <SelectInput
                  label="Auth provider"
                  id="authProtocol"
                  name="authProtocol"
                  autoComplete="authProtocol"
                  value={formData.authProtocol}
                  onChange={handleChange}
                  optionsComp={
                    <>
                      <option selected
                        value={OAUTH2_PROTOCOL.value}>{OAUTH2_PROTOCOL.displayName}</option>
                    </>
                  }
                />
                <SelectInput
                  label="Response Type"
                  id="responseType"
                  name="responseType"
                  autoComplete="responseType"
                  value={formData.responseType}
                  onChange={handleChange}
                  optionsComp={
                    <>
                      <option selected
                        value={OAUTH2_RESPONSE_TYPES.value}>{OAUTH2_RESPONSE_TYPES.displayName}</option>
                    </>
                  }
                />
              </div>
              <div className="mb-1">
                <TextBox
                  label="Authorization Url"
                  type="text"
                  name="authorizationUrl"
                  id="authorizationUrl"
                  value={formData.authorizationUrl}
                  placeholder="Add authorization url"
                  onChange={handleChange}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <TextBox
                  label="Token Endpoint"
                  type="text"
                  name="tokenEndpoint"
                  id="tokenEndpoint"
                  value={formData.tokenEndpoint}
                  placeholder="Add Token Endpoint"
                  onChange={handleChange}
                />
                <TextBox
                  label="UserInfo Endpoint"
                  type="text"
                  name="userInfoEndpoint"
                  id="userInfoEndpoint"
                  value={formData.userInfoEndpoint}
                  placeholder="Add userInfo endpoint"
                  onChange={handleChange}
                />
              </div>
              <DynamicRows
                title="Set Scopes"
                removeButtonClassName="pt-0 flex-row justify-end"
                rowsData={prepareRawsData(formData.scope)}
                onChange={onChangeScope}
                showBottomDivider={false}
                rowsTemplate={(value, handleInputChange, id) => (
                  <div className="flex flex-row items-center gap-2 w-full pr-4">
                    <TextBox
                      // label="Scope"
                      rootClass="flex-grow"
                      type="text"
                      name="value"
                      id={id}
                      value={value}
                      placeholder="Add scope"
                      onChange={handleInputChange}
                    />
                  </div>
                )}
              />
              <div className="relative pr-14">
                <TextBox
                  rootClass="col-span-5"
                  label="Redirect Uri"
                  subLabel="copy redirect uri into your oauth2 app"
                  type="text"
                  name="redirectUri"
                  id="redirectUri"
                  value={config.AUTH_CALLBACK_URL}
                />
                <div className="absolute right-0 bottom-2">
                  <button type="button"
                    className="h-10 w-10 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <FaRegCopy onClick={copyToClipboard}
                      className="w-5 h-5" />
                    <span className="sr-only">Icon description</span>
                  </button>
                </div>
              </div>
            </div>
            {copySuccess &&
              <Message type="success" text="Copied" />
            }
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button color="default" text="Cancel" onClick={() => onCompletion(false)} />
          <Button onClick={handleOnSubmit} type="button" disabled={formState.processing} text="Save Settings" loading={formState.processing} />
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)