import {useEffect, useState} from 'react';
import { Button } from "flowbite-react";
import { MdNavigateNext } from "react-icons/md";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import FormLoader from "../../../components/form-loader/form-loader";
import PageContainerV2 from "../../../components/page-container/page-container-v2";
import { getAccessKeyByChannel } from "../../../services/integrate/fb-integrate-service";
import SaveAccessKey from "./save-access-key";
import PhoneNumbersListPage from "./phone-numbers-list";
import SectionHeading from "../_components/section-heading";

import { INTEGRATIONS_NAVIGATION } from "../consts/navigations";

const channel = "twilio";

function Index({}) {
  const { appId } = useAppInfo();
  const [isSetAccessKey, setIsSetAccessKey] = useState(false);

  const [checkAccessKeyState, setCheckAccessKeyState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  async function fetchAccessKey() {
    updateCheckAccessKeyState(true, false, false);
    const response = await getAccessKeyByChannel(appId, channel);
    setIsSetAccessKey(response.isSetAccessKey);
    updateCheckAccessKeyState(false, false, false);
  }

  useEffect(() => {
    fetchAccessKey();
  }, []);

  const updateCheckAccessKeyState = (processing, success, failed, message) => {
    setCheckAccessKeyState(
      Object.assign({}, { processing, success, failed, message })
    );
  };

  const onSaveComplete = (fetch) => {
    fetchAccessKey();
  };

  if (checkAccessKeyState.processing) {
    return <FormLoader fullScreen={true} text="Loading..." />;
  }

  return (
    <PageContainerV2
      title="Twilio Integration"
      pageType="channels"
      subtitle="Twilio"
      pageSidebar={true}
    >
      <div className="flex h-full flex-1 flex-grow">
        <div className="flex flex-col flex-1">
          <SectionHeading title="Twilio" />
          <div className="flex flex-col space-y-8 px-5 pt-6 w-full max-w-screen-md">
            {isSetAccessKey ? (
              <div className="pt-4">
                <Button
                  color="gray"
                  onClick={() => {
                    setIsSetAccessKey(false);
                  }}
                >
                  Reset Account Settings
                  <MdNavigateNext className="ml-2 h-5 w-5" />
                </Button>

                <PhoneNumbersListPage />
              </div>
            ) : (
              <div className="w-full">
                <SaveAccessKey onSaveComplete={onSaveComplete} />
              </div>
            )}
          </div>
        </div>
      </div>
    </PageContainerV2>
  );
}

export default Index;