import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { BsPencil, BsThreeDotsVertical, BsTrash } from "react-icons/bs";

import ActionMenuTrigger from "./action-menu-trigger";
import ActionMenuContent from "./action-menu-content";
import ActionItem from "./action-item";
import ConfirmDialog from "../../../../../components/confirm-dialog/confirm-dialog";
import {deleteAgentActionSettings} from "../../../../../services/prompt-ai";
import {useNavigate} from "react-router-dom";
import JourneyCreateDialog from "../../journey-create";

const Action = React.memo(({ appId, journeyId, onDeleteComplete }) => {
  const actionRef = useRef();
  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });
  const [open, setOpen] = useState(false);
  const [openJourneyUpdate, setOpenJourneyUpdate] = useState(false);
  const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

  const handleOnTrigger = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const handler = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [actionRef]);

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  const handleOnActionClick = (actionType) => {
    if (actionType === "rename") {
      handleOnClickJourneyUpdate()
    } else if (actionType === "remove") {
      setDeleteRevokeConfirmDialog(true);
    }
  };

  const handleOnClickJourneyUpdate = () => {
    setOpenJourneyUpdate(true);
  };

  const handleOnCancelJourneyUpdate = () => {
    setOpenJourneyUpdate(false);
  };

  const handleOnSuccessJourneyUpdate = () => {
    setOpenJourneyUpdate(false);
  }

  const handleConfirmDelete = async confirmed => {
    setDeleteRevokeConfirmDialog(false);
    if (confirmed) {
      updateFormState(true, false, false);
      await deleteAgentActionSettings(appId, journeyId);
      updateFormState(false, true, false);
      onDeleteComplete()
    }
  }

  return (
    <div onMouseLeave={() => setOpen(false)} className="relative">

      {openDeleteConfirmDialog &&
          <ConfirmDialog
              appId={appId}
              open={openDeleteConfirmDialog}
              onCompletion={handleConfirmDelete}
              title="Delete Confirmation"
              message="Are you sure. Do you want to delete this Journey ?"
          />
      }

      {openJourneyUpdate && (
          <JourneyCreateDialog
              onCompletion={handleOnSuccessJourneyUpdate}
              onCancel={handleOnCancelJourneyUpdate}
              journeyId={journeyId}
          />
      )}

      <ActionMenuTrigger onTrigger={handleOnTrigger}>
        <BsThreeDotsVertical size={17} />
      </ActionMenuTrigger>

      <ActionMenuContent open={open}>
        <ActionItem type="rename" onAction={handleOnActionClick}>
          <BsPencil size={16} className="w-4 h-4 flex-none mr-1" />
          Update
        </ActionItem>
        <ActionItem
          type="remove"
          onAction={handleOnActionClick}
          className="!text-red-700"
        >
          <BsTrash className="w-4 h-4 flex-none" />
          Remove
        </ActionItem>
      </ActionMenuContent>
    </div>
  );
});

Action.propTypes = {
  appId: PropTypes.string,
  journeyId: PropTypes.string,
};

export default Action;
