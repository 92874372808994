import {useState, useCallback, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {LockClosedIcon} from '@heroicons/react/solid';
import {Auth} from "aws-amplify";
import Message from "../../../components/toast-message";
import logo from '../../../assets/images/logo/main-color.png';
import PageContainer from "../../../components/page-container";
import TextBox from "../../../components/forms/text-box";
import Button from "../../../components/button/button";
import Loader from "../../../components/form-loader/form-loader";
import {getMyUserProfile} from "../../../services/user/user-service";

function Index() {
    const navigate = useNavigate();
    const [auth, setAuth] = useState({
        username: '',
        verifyCode: '',
        password: '',
    });

    const [resetState, setResetState] = useState();

    const [resetCompleted, setResetCompleted] = useState(false);

    const [error, setError] = useState({
        username: false,
        verifyCode: false,
        password: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'username')
            auth.username = targetValue;

        if (targetName === 'verifyCode')
            auth.verifyCode = targetValue;

        if (targetName === 'password')
            auth.password = targetValue;

        setAuth(Object.assign({}, auth));
    });

    const validateForm = () => {
        let valid = true;
        if (auth.username === '') {
            error.username = true;
            valid = false;
        } else {
            error.username = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const validateVerifyForm = () => {
        let valid = true;
        if (auth.verifyCode === '') {
            error.verifyCode = true;
            valid = false;
        } else {
            error.verifyCode = false;
        }

        if (auth.password === '') {
            error.password = true;
            valid = false;
        } else {
            error.password = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            setResetState(null)
            try {
                const resp = await Auth.forgotPassword(auth.username);
                updateFormState(false, true, false);
                setResetState(resp);
            } catch (err) {
                console.error("reset pwd failed cause", err);
                updateFormState(false, false, true, err.message);
            }
        }
    });

    const handleSetNewPassword = useCallback(async (event) => {
        event.preventDefault();
        if (!formState.processing && validateVerifyForm()) {
            updateFormState(true, false, false);
            try {
                await Auth.forgotPasswordSubmit(auth.username, auth.verifyCode, auth.password);
                updateFormState(false, true, false);
                setResetCompleted(true)
            } catch (err) {
                console.error("reset pwd failed cause", err);
                updateFormState(false, false, true);
            }
        }
    });

    async function fetchData() {
        updateFormState(true, false, false);
        const {response, status} = await getMyUserProfile();
        if (status === 200) {
            auth.username = response.email;
            setAuth({...auth});
            updateFormState(false, true, false);
        } else {
            updateFormState(false, false, true, 'Unable to fetch data');
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <PageContainer
            title="User Profile"
            subtitle="Reset your password">

            <div className="">

                {resetState &&
                    <Message
                        text={`We have sent verify code to your ${resetState.CodeDeliveryDetails.Destination} email`}
                        type="success"/>
                }

                {formState.failed &&
                    <Message
                        text={formState.message ? formState.message : 'Reset password failed. Please check your details'}
                        type="failure"/>
                }

                {formState.processing && <div className="p-4"><Loader/></div>}

                {resetCompleted && <Message text="Successfully updated your password" type="success"/>}

                <form className="pt-4 flex flex-col gap-4" onSubmit={handleSubmit}>
                    <input type="hidden" name="remember" defaultValue="true"/>
                    <div className="">

                        <TextBox
                            rootClass="w-1/2"
                            label="Email"
                            type="email"
                            name="username"
                            id="username"
                            placeholder="Email"
                            value={auth.username}
                            onChange={handleChange}
                            readOnly
                        />

                        {resetState &&
                            <TextBox
                                rootClass="w-1/2"
                                label="Verify Code"
                                type="text"
                                name="verifyCode"
                                id="verifyCode"
                                placeholder="Enter Verify Code"
                                value={auth.verifyCode}
                                onChange={handleChange}
                            />
                        }

                        {resetState &&
                            <TextBox
                                rootClass="w-1/2"
                                label="New Password"
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Enter New Password"
                                value={auth.password}
                                onChange={handleChange}
                            />
                        }

                    </div>

                        {!resetState &&
                            <div className="w-1/2 flex justify-end gap-2">
                                <Button color="default" variant="link" to="/settings/profile" text="User Settings"/>
                                <Button variant="button" type="submit" text="Reset password"/>
                            </div>
                        }

                        {resetState &&
                            <div className="w-1/2 flex justify-end gap-2">
                                <Button color="default" variant="button" onClick={handleSubmit}
                                        text="Resend Verification Code"/>
                                <Button variant="button" type="button" onClick={handleSetNewPassword}
                                        text="Set new password"/>
                            </div>
                        }

                </form>
            </div>
        </PageContainer>
    );
}

export default Index;
