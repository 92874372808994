import React from "react";
import { Link } from "react-router-dom";

import { createAppBasePath } from "../../../consts/route-consts";
import { useAppContext } from "../../../helpers/hooks/common-hook";

function prepHref(href, appInfo) {
  if (appInfo) {
    return `${createAppBasePath(appInfo.appId, appInfo.version)}${href}`;
  } else {
    return href;
  }
}

const MenuItem = ({ element, actionElement, border }) => {
  const { appInfo } = useAppContext();


  return (
    <li className={`${border && "border-b border-gray-200 dark:border-gray-700"} px-5 py-3 cursor-pointer text-gray-900 dark:text-gray-100 hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-indigo-700 hover:shadow-md transition-shadow`}>
      <Link
        to={prepHref(element?.href, appInfo)}
        className="text-sm w-full flex items-center justify-between"
      >
        <span className="font-normal tracking-wide">{element?.name}</span>
        {actionElement && actionElement}
      </Link>
    </li>
  );
};

export default MenuItem;
