class JourneyStmStore {
    constructor() {
        this.store = {}
    }

    add(id, data) {
        this.store[id] = data
    }

    get(id) {
        return this.store[id]
    }

    getAll() {
        return this.store
    }

    setData(store) {
        this.store = store;
    }

    remove(id) {
        delete this.store[id];
    }

    cleanAll(id) {
        this.store = {}
    }
}

const journeyStore = new JourneyStmStore()
export default journeyStore;