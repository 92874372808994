import { get } from "../../helpers/utils/http-client";
import { NOTIFICATION_ENDPOINT } from '../../consts/api-endpoints';

export async function getNotifications() {
    try {
        return await get(`${NOTIFICATION_ENDPOINT}`);
    } catch (err) {
        console.debug('getNotifications failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}