import { Alert } from "flowbite-react";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { HiInformationCircle } from "react-icons/hi";
import { connect } from "react-redux";
import ReactFlow, {
  Background,
  Controls,
  Position,
  useReactFlow,
} from "reactflow";
import {
  stmNodeSelect,
  toolboxItemAdd,
} from "../../../../../redux/journey-toolbox/journey-toolbox-actions";
import CustomEdge from "../../common/components/journey/edge";
import journeyStmStore from "../../common/service/journey-stm-store";
import { nodeTypes } from "../../node-types/node-types-registry";

const edgeTypes = {
  customEdge: CustomEdge,
};

const JourneyFlow = forwardRef((props, ref) => {
  const {
    currentJourneyConfig,
    currentJourneySession,
    addNodeTypeAddMessage,
    onChangeJourneyName,
  } = props;

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [slots, setSlots] = useState([]);

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(
        Object.assign({}, { processing, success, failed, message })
    );
  };

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(
        Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  useEffect(() => {
    if (
        reactFlowInstance &&
        currentJourneySession &&
        currentJourneySession.runningBlock
    ) {
      const targetNode = reactFlowInstance.getNode(
          currentJourneySession.runningBlock
      );

      console.log("found targetNode", targetNode);
      if (targetNode) {
        // Reset the current view before focusing on the new node
        reactFlowInstance.setViewport({ x: 0, y: 0, zoom: 1 });

        // Zoom into the node using fitView
        reactFlowInstance.fitView({
          duration: 800,
          nodes: [targetNode],
          padding: 0.8, // Adjust the padding to control how much the view is zoomed
          minZoom: 0.2, // Slight zoom for better focus
          maxZoom: 0.7, // Optional: limit the maximum zoom level
        });
      }
    }
  }, [currentJourneySession, reactFlowInstance]);

  const fetchJourneyData = async () => {
    try {
      updateDataLoadingState(true, false, false);
      const savedJourney = currentJourneyConfig;
      onChangeJourneyName(savedJourney.name);
      if (
          savedJourney.stm &&
          savedJourney.stm.metadata &&
          savedJourney.stm.metadata.nodes
      ) {
        let {
          nodes,
          edges,
          journeyStmStore: journeyStmStoreData,
        } = savedJourney.stm.metadata;
        journeyStmStore.setData(journeyStmStoreData);

        // prepare nodes
        nodes = nodes.map((node) => {
          const data = {
            ...node.data,
            metadata: {
              slots: savedJourney.slots,
              runningState: currentJourneySession.runningState,
            },
          };
          return { ...node, data };
        });

        setNodes(nodes);
        setEdges(edges);
      } else {
        setNodes([]);
        setEdges([]);
      }

      if (savedJourney.slots) {
        setSlots(savedJourney.slots);
      }

      updateDataLoadingState(false, true, false);
    } catch (error) {
      console.error(error);
      updateDataLoadingState(false, false, true);
    }
  };

  useEffect(() => {
    if (currentJourneyConfig) {
      fetchJourneyData();
    }
  }, [currentJourneyConfig]);

  useEffect(() => {
    if (
        reactFlowInstance &&
        addNodeTypeAddMessage &&
        addNodeTypeAddMessage.type
    ) {
      const node = createNode(
          reactFlowInstance,
          addNodeTypeAddMessage.id,
          addNodeTypeAddMessage.type,
          reactFlowInstance.getNodes().length + 1,
          addNodeTypeAddMessage.name,
          addNodeTypeAddMessage.position,
          {
            journeyId: currentJourneySession.journeyId,
            slots,
            runningState: currentJourneySession.runningState,
          }
      );
      reactFlowInstance.addNodes(node);
    }
  }, [addNodeTypeAddMessage, reactFlowInstance]);

  const createNode = (
      reactFlowInstance,
      id,
      type,
      nodeIndex,
      name,
      position,
      metadata = {}
  ) => {
    const flowPosition = reactFlowInstance
        ? reactFlowInstance.project(position)
        : position;
    return {
      id,
      type,
      data: { id, type, nodeIndex, name, metadata },
      position: flowPosition,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    };
  };

  if (dataLoadingState.processing) {
    return (
        <div className="flex flex-grow items-center justify-center p-3">
          <div className="flex items-center gap-1 text-gray-500">
            <CgSpinner size={24} className="animate-spin" />
            Loading..
          </div>
        </div>
    );
  }

  if (!currentJourneyConfig) {
    return (
        <Alert color="success" icon={HiInformationCircle}>
          <span className="font-medium">No active flow detected!</span>
        </Alert>
    );
  }

  return (
      <>
        <div className="flex-grow h-full" ref={reactFlowWrapper}>
          <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onInit={setReactFlowInstance}
              snapToGrid={true}
              fitView
          >
            <Controls />
            <Background />
          </ReactFlow>
        </div>
      </>
  );
});

const mapStateToProps = (state, ownProps) => {
  return {
    journeyToolboxItemAddMessage: state.journeyToolboxItemAddMessage,
    stmNodeSelectMessage: state.stmNodeSelectMessage,
    stmEdgeAddMessage: state.stmEdgeAddMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toolboxItemAddHandle: (data) => {
      dispatch(toolboxItemAdd(data));
    },
    stmNodeSelectHandle: (message) => {
      dispatch(stmNodeSelect(message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JourneyFlow);
