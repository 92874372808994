import React, {memo, useEffect, useState} from 'react';
import {BaseEdge, EdgeLabelRenderer, getSmoothStepPath, useReactFlow,} from 'reactflow';

import './custom_edge.scss';
import ReactDOM from "react-dom";

const CustomEdge = props => {

    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        label,
        markerEnd,
    } = props;

    const {setEdges} = useReactFlow();
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
    });

    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
    });

    const onEdgeClick = () => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY,
        });
    };

    useEffect(() => {
        const handleClickOutside = () => {
            closeContextMenu();
        };

        if (contextMenu.visible) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [contextMenu.visible]);

    const closeContextMenu = () => {
        setContextMenu({ visible: false, x: 0, y: 0 });
    };

    const renderContextMenu = () => (
        <div
            style={{
                position: 'fixed',
                top: contextMenu.y + 10,
                left: contextMenu.x + 10,
                zIndex: 1000,
            }}
            className="w-[150px] bg-white rounded-md border border-[#DDE4EA] p-1"
            onClick={(e) => e.stopPropagation()} // Prevent click outside close on menu click
        >
            <div
                onClick={onEdgeClick}
                className="px-1.5 py-1.5 rounded flex items-center justify-between bg-whtie duration-300 hover:bg-[#F3F4F6] cursor-pointer">
                <p className="text-xs text-[#DF2E2E]">Delete</p>
                <p className="text-[10px] text-[#5D6B82]">Del</p>
            </div>
        </div>
    );

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
            <path
                d={edgePath}
                fill="none"
                stroke="transparent"
                strokeWidth={20}
                onContextMenu={handleContextMenu}
            />

            {/* Render the context menu in a portal */}
            {contextMenu.visible &&
                ReactDOM.createPortal(renderContextMenu(), document.body)}
            {/*<EdgeLabelRenderer>*/}
            {/*    {*/}
            {/*        label ? <div*/}
            {/*            style={{*/}
            {/*                position: 'absolute',*/}
            {/*                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,*/}
            {/*                background: "#FFFFFF",*/}
            {/*                zIndex: 9999999,*/}
            {/*                padding: "4px 8px",*/}
            {/*                borderRadius: "4px",*/}
            {/*                color: '#000',*/}
            {/*                pointerEvents: 'all', // To make label interactive*/}
            {/*            }}*/}
            {/*            className="nodrag nopan"*/}
            {/*        >*/}
            {/*            {label}*/}
            {/*        </div> : <div*/}
            {/*            style={{*/}
            {/*                position: 'absolute',*/}
            {/*                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,*/}
            {/*                fontSize: 12,*/}
            {/*                // everything inside EdgeLabelRenderer has no pointer events by default*/}
            {/*                // if you have an interactive element, set pointer-events: all*/}
            {/*                pointerEvents: 'all',*/}
            {/*            }}*/}
            {/*            className="nodrag nopan"*/}
            {/*        >*/}
            {/*            <button className="w-5 h-5 bg-[#969b9f] border border-white text-white cursor-pointer rounded-full text-xs leading-none" onClick={onEdgeClick}>*/}
            {/*                ×*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</EdgeLabelRenderer>*/}
        </>
    );
}

export default memo(CustomEdge);
