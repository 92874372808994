import React from "react";
import { HiOutlineRocketLaunch } from "react-icons/hi2";

const PublishButton = ({ openPublishConfirm }) => {
  return (
    <button
      onClick={openPublishConfirm}
      className="
      inline-flex items-center justify-center gap-2 text-sm rounded-md transition h-10 pl-4 pr-5 whitespace-nowrap 
      bg-indigo-600 hover:bg-indigo-700 hover:ring-4 ring-indigo-200 dark:ring-indigo-900 text-white"
    >
      <span className="sr-only">Publish button</span>
      <HiOutlineRocketLaunch size={20} />
      Publish
    </button>
  );
};

export default PublishButton;
