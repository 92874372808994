import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { cn } from "../../../../../helpers/utils/css-utils";

const ActionMenuContent = forwardRef(({ children, open = false }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        `p-2 bg-white dark:bg-gray-800 z-40 opacity-0 absolute top-full right-2 min-w-full flex flex-col overflow-y-scroll pointer-events-none transform -translate-y-4 transition-all duration-300  overflow-hidden
          shadow-2xl shadow-gray-200 dark:shadow-gray-800 rounded-lg border border-gray-200 dark:border-gray-700`,
        {
          "opacity-100 -translate-y-0  pointer-events-auto": open,
        }
      )}
    >
      {children}
    </div>
  );
});

ActionMenuContent.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool,
};

export default memo(ActionMenuContent);
