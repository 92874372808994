const PlusButton = ({ children, ...props }) => {
  return (
    <button
      className="
       w-10 h-10 rounded-md bg-transparent inline-flex items-center justify-center text-gray-500 dark:text-gray-400
      hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 ring-0 
      focus:ring-offset-0 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 transition-all"
      {...props}
    >
      {children}
    </button>
  );
};

export default PlusButton;
