import {createUniqueId} from "../../helpers/utils/object-util";

export function toolboxItemAdd({ type, name, position }){
    return {type:'JOURNEY_TOOLBOX_ITEM_ADD', payload: { id: createUniqueId(), type, name, position }};
}

export function stmNodeSelect(message){
    return {type:'STM_NODE_SELECT', payload: message};
}

export function stmNodeSelectRest(){
    return {type:'STM_NODE_SELECT_RESET', payload: null};
}

export function stmSettingSave(message){
    return {type:'STM_SETTING_SAVE', payload: message};
}

export function stmEdgeAdd(message){
    return {type:'STM_EDGE_ADD', payload: message};
}

export function stmSlotCreateRequest(){
    return {type:'STM_SLOT_CREATE_REQUEST', payload: true};
}