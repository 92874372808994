import { Modal, Tabs } from "flowbite-react";
import { useEffect, useRef, useState } from 'react';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import { HiOutlineDocument } from "react-icons/hi2";
import Button from "../../../../components/button/button";
import TextBox from "../../../../components/forms/text-box";
import Message from "../../../../components/toast-message";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import { isValidURL, validateActionName } from "../../../../helpers/utils/text-util";
import {
  fileDataSourceCreate,
  fileUpload,
  uploadUrlGet
} from "../../../../services/knowledge/ds-connector/file-connector";
import { fetchFrequencyOptions } from "../../../../consts/common-consts";
import DropDown from "../../../../components/forms/dropdown";

const initErrorObj = {
  dataSourceId: false,
  file: false,
  url: false,
};

export default function FileDSConnector({
  savedDataSet = {
    dataSourceId: '',
    dataSourceType: 'file',
    dataSourceUrl: '',
    dataType: 'text',
    idField: '',
    searchField: '',
    descriptionForModel: '',
    returnOriginal: false
  },
  onCancel
}) {
  const rootRef = useRef(null);
  const { appId } = useAppInfo();

  const [formData, setFormData] = useState({
    fileExt: '',
    metadata: savedDataSet
  });

  const [fileObj, setFileObj] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState({ ...initErrorObj });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const createUploadUrl = async () => {
    if (formData.metadata.dataSourceId && formData.metadata.dataType) {
      try {
        const formDataRequest = { ...formData };
        if (!Array.isArray(formDataRequest.metadata.searchableFields) && formDataRequest.metadata.searchableFields) {
          formDataRequest.metadata.searchableFields = formDataRequest.metadata.searchableFields.split(",")
        }
        const { response, status } = await uploadUrlGet(appId, formDataRequest);
        if (status === 200) {
          return response
        } else {
          throw new Error(response)
        }
      } catch (err) {
        console.error("get-utl call failed cause", err);
        throw new Error("createUploadUrl failed")
      }
    }
  }

  useEffect(() => {
    if (formState.success) {
      setTimeout(() => {
        onCancel(true)
      }, 1500)
    }
  }, [formState.success]);

  const handleChange = event => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    setFormData((preData) => {
      const currData = preData;
      currData.metadata[targetName] = targetValue;

      return ({ ...currData })
    });
  };

  const handleTabChange = tabIndex => {
    setFormData((preData) => {
      const currData = preData;
      if (currData) {
        currData.metadata.dataSourceType = tabIndex ? 'url' : 'file';
      }
      return ({ ...currData });
    });
    setError({ ...initErrorObj });
    setFileObj(null);
    setFileName(null);
    setFormData({
      fileExt: '',
      metadata: savedDataSet
    })
  };

  const handleSelectFile = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      formData.fileExt = file.name.split('.')[1];
      formData.metadata.dataType = formData.fileExt;
      setFileName(file.name);
      setFileObj(file)
      setFormData({ ...formData });
    }
  }

  const validateForm = () => {
    let valid = true;
    if ((!formData.metadata.dataSourceId && formData.metadata.dataSourceType === 'file') || !validateActionName(formData.metadata.dataSourceId)) {
      error.dataSourceId = true;
      valid = false;
    }

    if (formData.metadata.dataSourceType === 'url' && formData.metadata.dataSourceUrl && !isValidURL(formData.metadata.dataSourceUrl)) {
      error.dataSourceUrl = true;
      valid = false;
    }

    if (!formData.metadata.dataSourceUrl && formData.metadata.dataSourceType === 'url') {
      error.url = true;
      valid = false;
    }

    if (!fileObj && formData.metadata.dataSourceType === 'file') {
      error.file = true;
      valid = false;
    }

    setError(Object.assign({}, error))
    return valid;
  }

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validateForm()) {
      try {
        updateFormState(true, false, false)
        const { uploadUrl, metadata: uploadMetadata } = await createUploadUrl()
        const response = await fileUpload(uploadUrl, fileObj);
        await fileDataSourceCreate(appId, uploadMetadata);
        if (response) {
          updateFormState(false, true, false)
        } else {
          throw new Error("fileUpload failed")
        }
      } catch (e) {
        updateFormState(false, false, true, 'Unable to upload. Please try again');
      }
    }
  };

  return (
    <div ref={rootRef}>
      <Modal show={true} size="md" popup onClose={onCancel} root={rootRef.current ?? undefined}>
        <Modal.Header className="-mb-5 z-10" />
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              {formState.success &&
                <Message
                  text={`Successfully ${formData.id ? 'Updated' : 'Saved'} Uploaded Data`}
                  type="success" />
              }
              {formState.failed && <Message text={formState.errorMessage} type="failure" />}
            </div>
            <Tabs.Group
              onActiveTabChange={handleTabChange}
              aria-label="Pills"
              // eslint-disable-next-line react/style-prop-object
              style="underline"
              theme={{
                "tablist": {
                  "tabitem": {
                    "styles": {
                      "underline": {
                        "base": "rounded-t-lg focus:ring-0",
                        "active": {
                          "on": "text-indigo-600 rounded-t-lg border-b-2 border-indigo-600 active dark:text-indigo-500 dark:border-indigo-500",
                          "off": "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
                        }
                      }
                    }
                  }
                }
              }}
            >
              <Tabs.Item active title="Upload">
                <h3 className="pt-2 text-center pb-4 text-lg font-medium text-gray-900 dark:text-white">Upload New Document</h3>
                <div className="flex flex-col gap-2 pb-6">
                  <TextBox
                    rootClass=""
                    label="Name"
                    type="text"
                    name="dataSourceId"
                    id="dataSourceId"
                    extraInputClass="placeholder:text-center"
                    value={formData?.metadata?.dataSourceId}
                    placeholder="Name of the data source"
                    onChange={handleChange}
                    error={error.dataSourceId}
                    errorMessage="Name must only contain letters, numbers, underscores (_), hyphens (-). no any spaces"
                  />
                  <div>
                    <label className="block text-sm font-medium text-gray-900 dark:text-white mb-2">File(s)</label>
                    <div className="relative">
                      <label className="flex gap-2 h-10 w-full rounded-lg border items-center cursor-pointer bg-gray-50 dark:bg-gray-70 text-sm border-gray-300  justify-center" htmlFor="fileContainer">
                        <span className={`text-[24px] ${fileName ? 'text-gray-800' : 'text-gray-400'}`}>{fileName ? <HiOutlineDocument /> : <HiOutlineCloudUpload />}</span>
                        <span className={fileName ? 'text-gray-800' : 'text-gray-500'}>{fileName ? fileName : 'Drop files here or browse'}</span>
                      </label>
                      <input
                        className="opacity-0 w-full absolute z-10 h-full top-0 left-0 cursor-pointer"
                        aria-describedby="user_avatar_help"
                        id="fileContainer"
                        type="file"
                        accept=".pdf, .doc, .docx, .csv"
                        onChange={handleSelectFile}
                      />
                    </div>
                    <div className="text-sm text-gray-800 pt-2">Files supported .pdf, .doc, .docx, .txt</div>
                  </div>
                </div>
                {error.file &&
                  <p className="text-sm text-red-600 dark:text-red-500">
                    <span className="font-medium">
                      Please select file
                    </span>
                  </p>
                }
              </Tabs.Item>
              {/* <Tabs.Item className="space-y-6" title="URL">
                <h3 className="pt-2 text-center pb-4 text-lg font-medium text-gray-900 dark:text-white">Add New URL</h3>
                <div className="flex flex-col gap-2 pb-6">
                  <TextBox
                    rootClass=""
                    label="URL"
                    type="text"
                    name="dataSourceUrl"
                    id="dataSourceUrl"
                    value={formData?.metadata?.dataSourceUrl}
                    placeholder="Enter URL(s)"
                    onChange={handleChange}
                    extraInputClass="placeholder:text-center"
                    error={error.dataSourceUrl}
                    errorMessage="Please enter a valid URL"
                  />
                  <div>
                    <DropDown
                      className=""
                      selectedOptionClassName="capitalize text-center"
                      optionClassName="capitalize"
                      label="Fetch Frequency"
                      placeHolder="Choose Frequency"
                      name="dataSourceFrequency"
                      value={formData?.metadata?.dataSourceFrequency}
                      options={fetchFrequencyOptions.map(freq=>({label: freq, value: freq}))}
                      onChange={handleChange}
                    />
                    <div className="text-sm text-gray-800 pt-2">How often will the data sync.</div>
                  </div>
                  {error.url &&
                    <p className="text-sm text-red-600 dark:text-red-500">
                      <span className="font-medium">
                        Please enter URL
                      </span>
                    </p>
                  }
                </div>
              </Tabs.Item> */}
            </Tabs.Group>
            <div className="space-y-6 p-t-6">
              <div className="w-full flex flex-row gap-2 justify-end">
                <Button color="default" text="Cancel" onClick={onCancel} />
                <Button
                  type={formState.processing ? 'button' : 'submit'}
                  text={formData.metadata?.dataSourceType === 'file' ? 'Save Document' : 'Save URL'}
                  loading={formState.processing}
                />
              </div>
            </div>
          </form>


        </Modal.Body>
      </Modal>
    </div>
  )
}