import {useEffect, useState, useCallback, useRef} from 'react';
import {connect} from 'react-redux';
import Button from '../../../components/button/button';
import {createApp} from '../../../services/app/app-service';
import {appCreateNotify} from "../../../redux/app/app-actions";
import TextBox from "../../../components/forms/text-box";
import TextArea from "../../../components/forms/text-area";
import {Select, Modal, Tooltip, Button as FLButton, Badge, Card} from "flowbite-react";
import Loader from "../../../components/form-loader/form-loader";
import Message from "../../../components/toast-message";
import SelectInput from "../../../components/forms/select";
import {HiInformationCircle} from 'react-icons/hi';
import SwitchComponent from "../../../components/switch";
import { IoIosClose } from "react-icons/io";
import {useNavigate} from "react-router-dom";
import AppTemplateSelector from "../agent-template-selector";

const mapsStateToProps = (state, ownProps) => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {
        appCreateNotifyHandle: (appId) => {
            dispatch(appCreateNotify(appId));
        },
    };
}

const JOURNEY_EDITOR_MODE = 'journey-editor';
const STORY_EDITOR_MODE = 'story-editor';

const JOURNEY_NOTE = "Customized workflows using a No-Code flow editor for designing sequential steps. Fine-tuned AI models using your own data";
const STORY_NOTE = "Describe actions using textual prompt. Application answer questions by calling actions - function, api, etc";

function Index({onCompletion, onCancel, appCreateNotifyHandle, selectedTemplateProp}) {
    const rootRef = useRef(null);
    const navigate = useNavigate();
    const [savedAppId, setSavedAppId] = useState();
    const [formData, setFormData] = useState({
        name: '',
        storeAddress: '',
        description: '',
        intentLess: true,
        templateId: null,
        nluSettings: {
            provider: 'OpenAI',
            apiKey: ''
        }
    });
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [error, setError] = useState({
        name: false,
        description: false,
        nluSettings: {
            apiKey: false
        }
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    useEffect(() => {
        if (savedAppId) {
            setTimeout(() => {
                navigate(`/app/${savedAppId}/journey?init=1`)
            }, 1500)
        }
    }, [savedAppId]);

    useEffect(() => {
        if (selectedTemplateProp) {
            setSelectedTemplate({...selectedTemplateProp})
        }
    }, [selectedTemplateProp]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'name') {
            formData.name = targetValue;
        } else if (targetName === 'description') {
            formData.description = targetValue;
        } else if (targetName === 'storeAddress') {
            formData.storeAddress = targetValue;
        } else if (targetName === 'intentLess') {
            formData.intentLess = targetValue;
        } else if (targetName === 'llm_apikey') {
            formData.nluSettings.apiKey = targetValue;
        }

        setFormData(Object.assign({}, formData));
    });

    const handleChangeIntentLess = useCallback(state => {
        const newFormData = {...formData};
        newFormData.intentLess = !state;
        setFormData(newFormData);
    });

    const validateForm = () => {
        let valid = true;
        if (formData.name === '') {
            error.name = true;
            valid = false;
        } else {
            error.name = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);

            formData.templateId = selectedTemplate? selectedTemplate.appId: null;

            const {response, status} = await createApp(formData);
            if (status === 201) {
                setSavedAppId(response.appId)
                appCreateNotifyHandle(response.appId);
                updateFormState(false, true, false, "Successfully created");
            } else {
                updateFormState(false, false, true, "Unable to create Assistant. Please try again");
            }
        }
    };

    const [openTemplateSelector, setOpenTemplateSelector] = useState(false);
    const handleOnOpenTemplateSelector = () => {
        setOpenTemplateSelector(true);
    }

    const handleOnCompleteTemplateSelector = template => {
        setOpenTemplateSelector(false);
        if (template) {
            setSelectedTemplate({...template})
        }
    }

    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={onCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    {openTemplateSelector && <AppTemplateSelector
                        onCompletion={handleOnCompleteTemplateSelector}/>}

                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Create New Agent</h3>

                            {formState.success && <Message text="Successfully Created New Agent" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            <TextBox
                                type="text"
                                name="name"
                                id="name"
                                label="Name"
                                value={formData.name}
                                placeholder="Enter your agent name"
                                onChange={handleChange}
                                error={error.name}
                            />

                            <TextArea
                                type="text"
                                name="description"
                                id="description"
                                label="Description"
                                value={formData.description}
                                placeholder="Enter description"
                                onChange={handleChange}
                                rows={3}
                                error={error.description}
                            />

                            {/*<SelectInput*/}
                            {/*    label="Execution Mode"*/}
                            {/*    onChange={handleChangeIntentLess}*/}
                            {/*    value={formData.intentLess? STORY_EDITOR_MODE: JOURNEY_EDITOR_MODE}*/}
                            {/*    options={[*/}
                            {/*    {value: STORY_EDITOR_MODE, label: "Standard"},*/}
                            {/*    {value: JOURNEY_EDITOR_MODE, label: "Advance"}*/}
                            {/*    ]}*/}
                            {/*/>*/}

                            {/*<div className="flex flex-row items-center justify-end">*/}
                            {/*    <SwitchComponent*/}
                            {/*        title="Advance mode"*/}
                            {/*        subTitle="Customized workflows, fine-tuned conversational ai models"*/}
                            {/*        enabled={!formData.intentLess}*/}
                            {/*        onChange={state => {*/}
                            {/*            handleChangeIntentLess(state)*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            {/*{!formData.intentLess &&*/}
                            {/*    <Message text={JOURNEY_NOTE} color="info" timeout={0}/>*/}
                            {/*}*/}

                            {selectedTemplate ?
                                <Card className="max-w-sm">
                                    <div className="flex flex-row justify-between gap-2 items-start">
                                        <h5 className="text-sm font-bold tracking-tight text-gray-900 dark:text-white">
                                            {selectedTemplate.title}
                                        </h5>

                                        <IoIosClose
                                            onClick={()=>setSelectedTemplate(null)}
                                            className="w-8 h-8 cursor-pointer text-gray-500 hover:text-gray-900" />
                                    </div>

                                    <p className="text-sm font-normal text-gray-700 dark:text-gray-400">
                                        {selectedTemplate.description}
                                    </p>

                                    <div className="w-3/4 flex flex-row gap-2">
                                        <Badge size="xs" color="pink">
                                            {selectedTemplate.useCase}
                                        </Badge>
                                        <Badge size="xs" color="pink">
                                            {selectedTemplate.industry}
                                        </Badge>
                                    </div>


                                    {selectedTemplate.features &&
                                        <div className="w-3/4 flex flex-wrap gap-2">
                                            {selectedTemplate.features.map((feature, index) =>
                                                <Badge size="xs" key={index}>
                                                    {feature.toLowerCase()}
                                                </Badge>
                                            )}
                                        </div>
                                    }

                                    {/*<div className="flex flex-row items-center justify-end">*/}
                                    {/*    <Button text="Select Template"/>*/}
                                    {/*</div>*/}

                                    <p className="text-xs font-normal text-gray-500 dark:text-gray-400">
                                        By {selectedTemplate.developer}
                                    </p>

                                </Card>
                                :
                                // <div className="flex flex-row justify-end">
                                //     <FLButton style={{width: "140px"}} outline gradientDuoTone="pinkToOrange"
                                //               text="Template" onClick={handleOnOpenTemplateSelector}
                                //     >
                                //         Use Template
                                //     </FLButton>
                                // </div>
                                <></>
                            }

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={onCancel}/>
                                {formState.processing?
                                    <Button type="button" text="Create Agent" loading={true}/>
                                    :
                                    <Button type="submit" text="Create Agent"/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)