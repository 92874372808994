import {useState, useCallback, useEffect, useMemo} from "react";
import TextBox from "../../../components/forms/text-box";
import DisplayTexts from "../common/components/display-texts";
import SettingTip from "../common/components/setting-tip";
import { MESSAGE_PROMPT } from "../common/const/settings-type";
import { StmStateDto } from "../common/dto/stm-state-dto";
import ResponseAIModify from "../common/components/response-ai-modify-select";

export default function MessagePromptSettings({ name, onChangeData, savedSettings }) {

  const [formData, setFormData] = useState(savedSettings);

  const notifyChanges = useCallback(_formData => {
    _formData.stateType = MESSAGE_PROMPT
    onChangeData(_formData);
  }, [onChangeData]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;

    setFormData(prevFormData => {
      if (prevFormData[name] === value) return prevFormData;
      return {
        ...prevFormData,
        [name]: value
      };
    });
  }, []);

  useEffect(() => {
    console.log('formData', formData)
    notifyChanges(formData);
  }, [formData, notifyChanges]);

  const memoizedDisplayTextOptions = useMemo(() => formData.displayTextOptions, [formData.displayTextOptions]);

  return (
    <div className="flex flex-col gap-2">
      <SettingTip text="Sends a predefined message to the user during a conversation" />
      <TextBox
        label="Name"
        type="text"
        name="name"
        placeholder="State name"
        value={formData.name}
        onChange={handleChange}
      />
      <DisplayTexts
        parameterName={formData.slot}
        displayTextOptionsProps={memoizedDisplayTextOptions}
        responseModifyByAI={formData.responseModifyByAI}
        onChangeData={handleChange}
      />
    </div>
  )
}
