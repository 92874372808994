import { cn } from "../../../../helpers/utils/css-utils";

const Skeleton = ({ className, ...props }) => {
  return (
    <div role="status" className={cn("animate-pulse")} {...props}>
      <div
        className={cn(
          "h-auto bg-gray-200 dark:bg-gray-700 mb-2 rounded-full",
          className
        )}
      ></div>
    </div>
  );
};

export { Skeleton };
