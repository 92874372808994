import {post} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function textGen(appId, data) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/gen-ai/text-gen`,
            data);
        if (status !== 200) {
            throw Error('Unable to generate text')
        }
        return response;
    } catch (err) {
        console.error('textGen failed cause', err.message, err.code);
        return null;
    }
}
