import React from "react";
import InternalHeader from "../internal-header";
import { Badge, Breadcrumb, Tooltip } from "flowbite-react";
import { Link } from "react-router-dom";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

const ChildNode = (props) => {
  return React.Children.map(props.children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    const childProps = {};
    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }
    return child;
  });
};

export default function Index(props) {
  const {
    title,
    subtitle,
    headerAction,
    navigation,
    breadcrumbNav,
    titleMarker,
    titleMarkerColor = "success",
    tooltip,
  } = props;
  return (
    <div className="h-full">
      {navigation && navigation.length > 0 && (
        <InternalHeader navigation={navigation} />
      )}

      <div className="mx-auto px-6 pt-4">
        {/* <div className="mx-auto px-0 pt-4 ring-2"> */}
        {breadcrumbNav && breadcrumbNav.length > 0 && (
          <div className="pb-4">
            <Breadcrumb aria-label="breadcrumb">
              {breadcrumbNav.map((nav) => (
                <Breadcrumb.Item href="#">
                  <Link to={nav.href}>{nav.name}</Link>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
        )}

        <div className="flex flex-row px-0">
          {title && (
            <>
              <h1 className="text-2xl items-center font-semibold pr-2 text-gray-900 dark:text-white">
                {title}
              </h1>
              {tooltip && (
                <Tooltip className="z-50" content={tooltip}>
                  <QuestionMarkCircleIcon className="w-6 h-8 text-gray-600 dark:text-white" />
                </Tooltip>
              )}
            </>
          )}

          {titleMarker && (
            <div className="flex flex-row items-center pl-2 pb-2">
              <Badge color={titleMarkerColor}>{titleMarker}</Badge>
            </div>
          )}

          {headerAction && (
            <div className="flex flex-auto justify-end items-center">
              {headerAction}
            </div>
          )}
        </div>

        {subtitle && (
          <h1 className="text-md text-gray-500 dark:text-white pt-3 pb-8">
            {subtitle}
          </h1>
        )}
        <div className="max-height-wrapper px-0">
          <ChildNode>{props.children}</ChildNode>
        </div>
      </div>
    </div>
  );
}
