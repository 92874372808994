import React from 'react';
import TextMessage from '../conv-message/text-message/text-message';
import TextListMessage from '../conv-message/text-list-message/text-list-message';
import ButtonOption from '../conv-message/button-option';
import SwapCardList from '../conv-message/cards/SwapCardList';
import CustomMessage from "../conv-message/custom-message/custom-message";

export default function BotMessageFactory(props) {
  console.log('BotMessageFactory', props);
  const {type} = props;
  let comp;
  if (type === 'TEXT') {
    comp = <TextMessage {...props} />;
  } if (type === 'TEXT_LIST') {
    comp = <TextListMessage {...props} />;
  } else if (type === 'CARDS') {
    comp = <SwapCardList {...props} />;
  } else if (type === 'BUTTON_TEXT') {
    comp = <ButtonOption {...props} />;
  } else if (type === 'CARDS') {
    const newProps = {...props, message: 'Error occurred'};
    comp = <TextMessage {...newProps} />;
  } else if (type === 'CUSTOM') {
    comp = <CustomMessage {...props} />
  }
  return comp;
}
