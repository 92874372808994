import {memo, useContext, useEffect, useRef, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";
import BlockChildNode from "./BlockChildNode";

import "./main_workflow_node.scss";
import Toolbar from "./Toolbar";

const StartNode = ({id, data}) => {

    const workflowContext = useContext(WorkflowCanvasContext);

    const contextMenuRef = useRef(null);
    const [isInput, setIsInput] = useState(false);
    const [blockTitle, setBlockTitle] = useState(data.label);

    const [showToolbar, setShowToolbar] = useState(false);

    useEffect(() => {
        // Add event listener when the component is mounted
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up event listener when the component is unmounted
            document.removeEventListener("click", handleClickOutside);
            document.addEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOutside = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            setShowToolbar(false);
            workflowContext.setDeletableChildId(null)
        }
    };

    const handleToolBarVisible = e => {
        e.preventDefault();
        setShowToolbar(true);
    }

    const handleOpenDrawer = () => {
        if (data?.openNodeDrawer) {
            data.openNodeDrawer(id);
        }
    };

    return (
        <div style={{position: "relative"}}>
            <div
                className="gap-2 flex items-center bg-gray-700 dark:bg-green-500 text-white  text-sm font-medium px-6 py-2 rounded-full shadow-md"
                onClick={() => setShowToolbar(false)}
                onContextMenu={handleToolBarVisible}
                onDoubleClick={handleOpenDrawer}
                // style={{backgroundColor: "#DDE4EA", borderRadius: "4px"}}

            >
                <p>Start</p>
                <div className="">
                    <Handle
                        type="source"
                        position={Position.Right}
                        className="w-[14px] h-[14px] flex justify-center items-center absolute right-[15px] border border-gray-200 bg-gray-500"
                        id='start'
                    />
                </div>


            </div>
        </div>
    );
};

export default memo(StartNode);
