import React, { useState } from "react";
import { IoImage } from "react-icons/io5";
import TextBox from "../../../../components/forms/text-box";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";

export default function CardImageUpload({ config, onChange }) {
  const { appId } = useAppInfo();
  const [filePreviewUrl, setFilePreviewUrl] = useState(config.image);

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-4">
        {filePreviewUrl ? (
          <img
            className="inline-block h-28 max-w-full rounded-md object-contain border-indigo-200"
            src={filePreviewUrl}
            alt="card thumbnail"
          />
        ) : (  
          <div className="grid place-content-center w-full h-32 bg-indigo-50 rounded-md">
            <IoImage className="text-gray-700" fontSize={32} />
          </div>
        )}
      </div>
      <TextBox
        rootClass=""
        label="Image URL"
        type="url"
        name="image"
        placeholder="Enter Image URL"
        value={filePreviewUrl}
        onChange={event => {
          setFilePreviewUrl(event.target.value);
          onChange(event);
        }
        }
      />
    </div>
  );
};