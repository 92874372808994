import {useEffect, useState} from 'react'
import SwitchComponent from "../../../../../components/switch";
import {DOCUMENT_DEFAULT_ACTION_CONFIG,} from "../common-consts";
import {listDataSources} from "../../../../../services/knowledge";
import SelectInput from "../../../../../components/forms/select";
import TabOpen from "../../../../../components/tab-open";
import TextArea from "../../../../../components/forms/text-area";
import ActionParameters from "../../../../../components/action-parameter-row";

export default function DocumentAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=DOCUMENT_DEFAULT_ACTION_CONFIG,
                                           error
}) {
    const { appId } = config;

    const [ dataSourcesList, setDataSourcesList ] = useState([]);
    const [ dataSourcesLoadingState, setDataSourcesLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateDataSourcesLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setDataSourcesLoadingState({...processing, success, failed, message, errorMessage})
    }

    async function fetchDataSourceList() {
        updateDataSourcesLoadingState({
            processing: true
        });
        const {response} = await listDataSources(appId);
        if (response && response.data ) {
            setDataSourcesList(response.data);
        }
        updateDataSourcesLoadingState({
            processing: false
        });
    }

    useEffect( () => {
        fetchDataSourceList();
    }, []);

    useEffect( () => {
        if (actionConfig.resourceId) {
            const selectedDs = dataSourcesList.find(source=> source.dataSourceId === actionConfig.resourceId);
            if (selectedDs) {
                onActionConfigUpdate('description', { target: { value: selectedDs.descriptionForModel } })
                onActionConfigUpdate('returnDirect', { target: { value: true } })
            }
        }
    }, [actionConfig.resourceId]);

    return (
        <>
            <div className="pt-4 w-1/2">
                <SwitchComponent
                    title="Return Original"
                    subTitle="return original content"
                    enabled={actionConfig.returnOriginal}
                    onChange={state => {
                        onActionConfigUpdate('returnOriginal', { target: { value: state } })
                    }}
                />
            </div>

            <div className="flex flex-col gap-2 pt-4">
                <div className="">
                    <SelectInput
                        label="Document*"
                        rootClass=""
                        id="resourceId"
                        value={actionConfig.resourceId}
                        onChange={event => { onActionConfigUpdate('resourceId', event)}}
                        optionsComp={<>
                            <option>Select Data Source</option>
                            {dataSourcesList.map((store)=>
                                <option key={store.dataSourceId} value={store.dataSourceId}>{store.dataSourceId}</option>
                            )}
                        </>}
                        required
                    />

                    <TabOpen
                        text="Create Document"
                        url={`/app/${appId}/knowledge-base/docs/data-sources`}
                        onReload={fetchDataSourceList}
                    />
                </div>
            </div>

            <div className="pt-4 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>

            <TextArea
                rootClass="pt-4 w-1/2"
                rows={5}
                label="Input Prompt"
                subLabel="Override user's input with predefined placed holders {userInput}"
                placeholder="Eg: check leave balance of {empId}"
                value={actionConfig.inputPrompt}
                onChange={event => { onActionConfigUpdate('inputPrompt', event)}}
            />

            <TextArea
                rootClass="pt-4 w-1/2"
                rows={5}
                label="Output Prompt"
                subLabel="Provide instruction to extract relevant information"
                placeholder="Eg: Return 'Yes' if employee has available leave"
                value={actionConfig.outputPrompt}
                onChange={event => { onActionConfigUpdate('outputPrompt', event)}}
            />

            <ActionParameters actionConfig={actionConfig} setActionConfig={setActionConfig}/>

        </>
    )
}
