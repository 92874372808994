import PageContainerV2 from "../../../components/page-container/page-container-v2";
import { STATIC_KNOWLEDGE_NAVIGATION } from "../../../consts/static-knowledge-navigations";
import SectionHeading from "../../channels/_components/section-heading";
import FineTuneConfigManage from "../../fine-tune";
import { RAG_FINE_TUNE_TYPE } from "../../fine-tune/const/common-consts";

export default function FineTuneConfig() {
  return (
    <PageContainerV2
      title="Fine Tune"
      subtitle="Fine Tune"
      pageType="contents"
      pageSidebar={true}
      navigation={STATIC_KNOWLEDGE_NAVIGATION}
    >
      <div className="flex h-full flex-1 flex-grow">
        <div className="flex flex-col flex-1">
          <SectionHeading title="Fine Tune" />
          <div className="flex flex-col space-y-8 px-5 w-full">
            <FineTuneConfigManage fineTuneType={RAG_FINE_TUNE_TYPE} />
          </div>
        </div>
      </div>
    </PageContainerV2>
  )
}