import React from "react";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link, NavLink } from "react-router-dom";
import { useAppContext } from "../../helpers/hooks/common-hook";
import { createAppBasePath } from "../../consts/route-consts";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function prepHref(href, appInfo) {
  if (appInfo) {
    return `${createAppBasePath(appInfo.appId, appInfo.version)}${href}`
  } else {
    return href;
  }
}

export default function InternalHeader({ navigation = [] }) {
  const { appInfo } = useAppContext();
  return (
    <Popover className="relative border-b bg-white shadow-sm container-dark-bg dark:border-gray-700">
      <div className="flex items-center justify-between py-4 px-6 md:justify-start md:space-x-10">
        <div className="md:flex md:flex-1 md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">
            {navigation.map((nav, ni) => {
              if (nav.children) {
                return <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        )}
                      >
                        <span>{nav.name}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:max-w-3xl">
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                              {nav.children.map((item) => (
                                <Link
                                  key={item.name}
                                  to={prepHref(item.href, appInfo)}
                                  className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                                >
                                  <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                            {nav.otherLink &&
                              <div className="bg-gray-50 p-5 sm:p-8">
                                <a href={nav.otherLink.href} target="_blank" className="-m-3 flow-root rounded-md p-3 hover:bg-gray-100" rel="noreferrer">
                                  <div className="flex items-center">
                                    <div className="text-base font-medium text-gray-900">{nav.otherLink.name}</div>

                                    {nav.otherLink.tag &&
                                      <span
                                        className="ml-3 inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium leading-5 text-indigo-800">
                                        New
                                      </span>
                                    }
                                  </div>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {nav.otherLink.description}
                                  </p>
                                </a>
                              </div>
                            }
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              } else {
                return (
                  <NavLink
                    to={nav.rootLink ? nav.href : prepHref(nav.href, appInfo)}
                    className={({isActive}) => `text-base transition-colors font-medium ${isActive ? 'text-indigo-700' : 'text-gray-600 hover:text-indigo-600'}`}
                  >
                    {nav.name}
                  </NavLink>
                )
              }
            })}
          </Popover.Group>
        </div>
      </div>
    </Popover>
  );
}