import {useEffect, useState} from 'react';
import {deleteDataSet, getDataSets} from '../../../../services/model/finetune-service';
import {useAppInfo} from '../../../../helpers/hooks/common-hook';
import DataSetAdd from "../data-set-add";
import Button from "../../../../components/button/button";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import Table from "../../../../components/table";

export default function Index({fineTuneType}) {
    const { appId } = useAppInfo();
    const [ dataList, setDataList ] = useState([]);
    const [ editData, setEditData ] = useState();

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

    async function fetchConfigs() {
        updateFormState(true, false, false);
        const { data } = await getDataSets(appId);
        if (data && Array.isArray(data)) {
            setDataList(data);
        }
        updateFormState(false, true, false);
    }

    useEffect( () => {
        fetchConfigs();
    }, []);

    const handleOpenCreate = () => {
        setOpenCreateDialog(true);
        setEditData()
    }

    const handleOnCompletionCreate = async state => {
        if (state) {
            await fetchConfigs();
        }
        setOpenCreateDialog(false);
        setEditData()
    }

    const handleSetEditData = data => {
        setEditData(data);
        setOpenCreateDialog(true);
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const handleSelectDelete = id => {
        setDeletingData(id);
        setDeleteRevokeConfirmDialog(true);
    }

    const handleConfirmDelete = async confirmed => {
        setDeleteRevokeConfirmDialog(false);
        if (confirmed) {
            updateFormState(true, false, false);
            await deleteDataSet(appId, deletingData);
            setDeletingData(null);
            await fetchConfigs();
            updateFormState(false, true, false);
        }
    }

    const processDataList = dataList.map(dt=>{
        return {
            record: dt,
            columnsData: [
                { data: dt.name, isIdField: true },
                { data: dt.modifiedAt, isUpdatedField: true },
                { options: [
                       {
                            name: 'Delete Data set',
                            onClick: ()=>handleSelectDelete(dt.name)
                        }
                    ]
                },
            ]
        }
    })

    return (
        <div>
            {(openCreateDialog) &&
                <DataSetAdd
                    appId={appId}
                    savedData={editData}
                    open={openCreateDialog}
                    fineTuneType={fineTuneType}
                    onCompletion={handleOnCompletionCreate}
                />
            }

            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    appId={appId}
                    open={openDeleteConfirmDialog}
                    onCompletion={handleConfirmDelete}
                    title="Delete Confirmation"
                    message="Are you sure. Do you want to delete this Data set ?"
                />
            }

            <div className="flex justify-end">
                <div className="flex flex-row items-center gap-4">
                    <svg onClick={()=>fetchConfigs()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                    <Button onClick={handleOpenCreate} text="New Data set" />
                </div>
            </div>

            <Table
                headers={['Name']}
                items={processDataList}
                dataLoadingState={formState}
                itemsNotFoundMessage="You don't have any Data sets yet"
            />
        </div>
    )
}