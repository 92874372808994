import React, {useCallback, useEffect, useState} from "react";
import CodeEditorDialog from "../../common/components/code-editor-dialog";
import TextBox from "../../../../components/forms/text-box";
import SettingTip from "../../common/components/setting-tip";
import SentToUserSwitch from "../../common/components/send-to-user-switch";
import {RadioButtonGroup} from "../../../../components/radio-button";
import Editor from "@monaco-editor/react";
import DropDown from "../../../../components/forms/dropdown";
import {formDataRequestType, requestTypes} from "../../../../consts/common-consts";
import PauseAfterExecutionSwitch from "../../common/components/pause-after-execution-switch";
import ResponseAIModify from "../../common/components/response-ai-modify-select";
import WebhookConfigList from "./web-config-list";
import ResponseMapping from "./response-mapping";
import {ACTION_PROMPT} from "../../../action-journey-studio-v2/common/const/settings-type";

function Page({name, onChangeData, savedSettings, metadata={}}) {
    const [formData, setFormData] = useState(savedSettings);

    const [openCodeEditor, setOpenCodeEditor] = useState();

    const notifyOnchangeData = data => {
        data.stateType = ACTION_PROMPT
        onChangeData(data);
    }

    useEffect(() => {
        notifyOnchangeData(formData);
    }, [formData]);

    useEffect(() => {
        if (formData.skipNotify) {
            const newFormData = { ...formData }
            newFormData.responseModifyByAI = false
            setFormData(newFormData);
        }
    }, [formData.skipNotify]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'slot') {
            newFormData.slotToAssign = targetValue;
        }

        if (targetName === 'autoFillFromEntity') {
            newFormData.autoFillFromEntity = targetValue;
        }

        if (targetName === 'url') {
            newFormData.webhookConfig.url = targetValue;
        }

        if (targetName === 'method') {
            newFormData.webhookConfig.method = targetValue;
        }

        if (targetName === 'requestType') {
            newFormData.webhookConfig.requestType = targetValue;
        }

        if (targetName === 'responseModifyByAI') {
            newFormData.responseModifyByAI = targetValue;
        }

        setFormData(newFormData);
    };

    const onCodeEditComplete = (code) => {
        setOpenCodeEditor(false)
        if (openCodeEditor === 'request' && code) {
            formData.requestMapper.inlineScript = code;
        } else if (openCodeEditor === 'response' && code) {
            formData.responseMapper.inlineScript = code;
        }
        setFormData({...formData});
    }

    const onDynamicRowChange = useCallback((rowsData, field) => {
        rowsData = rowsData.map(raw=>raw.inputs)
        const newFormData = {...formData};
        newFormData.webhookConfig[field] = rowsData
        notifyOnchangeData(newFormData);
    }, [])

    const handleResponseModifyByAI = event => {
        if (!formData.skipNotify) {
            handleChange(event)
        }
    };

    return (
        <div className="flex flex-col gap-2">

            {(openCodeEditor === 'request') &&
                <CodeEditorDialog
                    open={true}
                    title="Request Handler"
                    code={formData.requestMapper.inlineScript}
                    onCompletion={onCodeEditComplete}/>
            }

            {(openCodeEditor === 'response') &&
                <CodeEditorDialog
                    open={true}
                    title="Response Handler"
                    code={formData.responseMapper.inlineScript}
                    onCompletion={onCodeEditComplete}/>
            }

            <SettingTip text="The API Step allows you to making custom API calls that involve retrieving data from an external database or sending data to another source"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            {/*<SlotSelector value={formData.slotToAssign} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />*/}

            <SentToUserSwitch formData={formData} setFormData={setFormData} skipNotify={formData.skipNotify}/>

            <div className="pt-4">
                <ResponseAIModify
                    responseModifyByAI={formData.responseModifyByAI}
                    onChange={handleResponseModifyByAI}/>
            </div>

            <PauseAfterExecutionSwitch formData={formData} setFormData={setFormData}
                                       pauseAfterExecution={formData.pauseAfterExecution}/>

            <div className="mt-4 pt-4 border-t-2 border-gray-100 flex flex-row justify-between items-center gap-2">
                <TextBox
                    rootClass="flex-1"
                    label="API"
                    type="url"
                    name="url"
                    placeholder="Enter URL with {slot}"
                    value={formData.webhookConfig.url}
                    onChange={handleChange}
                />

                <DropDown
                    className="pt-5"
                    placeHolder="Method"
                    name="method"
                    value={formData.webhookConfig.method}
                    options={['GET', 'POST', 'PUT', 'DELETE']}
                    onChange={handleChange}
                />
            </div>

            <WebhookConfigList
                title="Parameters"
                fieldName="parameters"
                onDynamicRowChange={onDynamicRowChange}
                savedSettings={savedSettings}
                showTopDivider={true}
                showBottomDivider={true}
            />
            <WebhookConfigList
                title="Headers"
                fieldName="headers"
                onDynamicRowChange={onDynamicRowChange}
                savedSettings={savedSettings}
                showTopDivider={false}
                showBottomDivider={false}
            />

            {formData.webhookConfig.method !== "GET" &&
                <div className="border-t-2 border-gray-100 pt-4 mt-4">
                    <div className="pb-4 flex flex-row justify-end items-center">
                        <RadioButtonGroup
                            selectedValue={formData.webhookConfig.requestType || formDataRequestType.value}
                            options={requestTypes}
                            onChange={handleChange}
                            name="requestType"/>
                    </div>

                    {formData.webhookConfig.requestType === formDataRequestType.value ?
                        <WebhookConfigList
                            title="Form Data"
                            fieldName="requestFormData"
                            onDynamicRowChange={onDynamicRowChange}
                            savedSettings={savedSettings}
                        />
                        :
                        <Editor
                            height="20vh"
                            defaultLanguage='json'
                            value={formData.webhookConfig.requestBody || '{}'}
                            theme="vs-dark"
                            onChange={value=>{
                                const newFormData = {...formData};
                                newFormData.webhookConfig.requestBody = value
                                setFormData(newFormData);
                            }}
                        />
                    }
                </div>

            }

            <ResponseMapping
                onDynamicRowChange={onDynamicRowChange}
                savedSettings={savedSettings}
                metadata={metadata}
                showTopDivider={false}
                showBottomDivider={false}
            />

        </div>
    )
}

export default Page
