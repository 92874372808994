import React from 'react';
import ImageView from "../common/ImageView";

const Toolbar = ({contextMenuRef, handleDelete, id}) => {

    return (
        <div
            ref={contextMenuRef}
            style={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "8px",
                border: "2px solid #DDE4EA",
                marginTop: "5px"
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px 20px",
            }}>
                <p style={{color: "#73767a", margin: "0", fontSize: "14px"}}>Duplicate</p>
                {/*<ImageView*/}
                {/*    onClick={() => console.log("ID", id)}*/}
                {/*    url="/assets/images/workflow_plus.svg"*/}
                {/*    style={{width: "20px", height: "20px"}}*/}
                {/*/>*/}
            </div>
            <div style={{padding: "0px 20px"}}>
                <div style={{width: '100%', height: "0.5px", backgroundColor: "#DDE4EA"}}/>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px 20px",
                }}
                onClick={() => handleDelete(id)}
            >
                <p style={{color: "#73767a", margin: "0", fontSize: "14px"}}>Delete</p>
                {/*<ImageView*/}
                {/*    url="/assets/images/workflow_delete.svg"*/}
                {/*    style={{width: "20px", height: "20px"}}*/}
                {/*/>*/}
            </div>

        </div>
    );
};

export default Toolbar;
