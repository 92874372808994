import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyLogo from '../../assets/images/icons/robot.png';
import { createAppBasePath } from '../../consts/route-consts';
const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');
const isToday = require('dayjs/plugin/isToday');
const isYesterday = require('dayjs/plugin/isYesterday');
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const formatDate = (lastEdited) => {
  const date = dayjs(lastEdited);
  if (date.isToday()) {
    return `Today at ${date.format('h:mm A')}`;
  } else if (date.isYesterday()) {
    return `Yesterday at ${date.format('h:mm A')}`;
  } else {
    return `on ${date.format('MMMM D, YYYY')}`;
  }
};

export default function Tile({ app, onDelete }) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevents the click from bubbling up to the tile click
    setShowMenu(!showMenu);
  };

  const handleDelete = (appId) => {
    onDelete(appId)
    setShowMenu(false);
  };

  const navigateToApp = () => {
    const appPath = `${createAppBasePath(app.appId)}${app.intentLess ? '/journey' : '/intent'}`;
    navigate(appPath);
  };

  return (
    <div className="bg-white group rounded-lg border cursor-pointer border-gray-300 shadow-sm hover:shadow-md transition-shadow" onClick={navigateToApp}>
      <div className="flex relative py-4 pl-4 pr-2 gap-4">
        <div className="flex justify-center shrink-0 items-center h-20 w-20 rounded-md bg-indigo-50">
          <img
            src={CompanyLogo}
            alt="Company Logo"
            className="w-2/3"
          />
        </div>
        <div className="flex-grow max-w-[180px]:">
          <div className="font-medium text-lg text-gray-900 dark:text-white mb-1 break-words">{app.name}</div>
          <p className="text-gray-600 dark:text-white text-xs">
            Last Edited {formatDate(app.modifiedAt)}
          </p>
        </div>
        <div className="relative" onClick={e => e.stopPropagation()}>
          <DotsVerticalIcon className="w-6 h-6 text-gray-600" onClick={toggleMenu} />
          {showMenu && (
            <div className="absolute right-0 mt-2 bg-white border rounded shadow-lg py-1 z-10">
              <div
                className="px-4 py-2 hover:bg-red-100 hover:text-red-700 cursor-pointer"
                onClick={() => handleDelete(app.appId)}
              >
                Delete Assistant
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}