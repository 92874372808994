import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {Input} from "antd";
import ImageView from "../common/ImageView";
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";

import "./not_node.scss";

const NoteNode = ({id, data, selected}) => {

    const textAreaRef = useRef(null); // Ref to access the TextArea
    const contextMenuRef = useRef(null);

    const [isEditable, setIsEditable] = useState(true);
    const [showToolbar, setShowToolbar] = useState(false);

    const workflowContext = useContext(WorkflowCanvasContext);

    useEffect(() => {
        // Add event listener when the component is mounted
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up event listener when the component is unmounted
            document.removeEventListener("click", handleClickOutside);
            document.addEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEditable) {
            const textArea = textAreaRef.current.resizableTextArea.textArea;
            textArea.focus();
            textArea.setSelectionRange(textArea.value.length, textArea.value.length);
        }
    }, [isEditable]);

    const handleClickOutside = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            setShowToolbar(false);
        }
    };

    const handleDoubleClick = (e) => {
        e.stopPropagation(); // Prevent the double-click event from propagating
        setIsEditable(true); // Enable editing
    };

    const handleBlur = () => {
        setIsEditable(false); // Disable editing on blur
    };

    const handleToolbarClick = (e) => {
        e.preventDefault();
        setShowToolbar(true);
    }

    const handleDelete = () => {
        const updatedData = workflowContext.nodes.filter(item => item.id !== id);
        workflowContext.setEdges(eds => eds.filter((edge) => edge.source !== id && edge.target !== id));

        workflowContext.setNodes(updatedData)
        setShowToolbar(false);

    }

    const handleTextChange = e => {

        const findNode = workflowContext.nodes.find(node => node.id === id);
        const findNodeIndex = workflowContext.nodes.findIndex(node => node.id === id);

        workflowContext.nodes[findNodeIndex] = {
            ...findNode,
            data: {
                txt: e.target.value,
            }
        }

        workflowContext.setNodes([...workflowContext.nodes]);

    }

    return (
        <div
            className="note-node"
            style={{border: selected ? "1px solid #9ED3FCFF" : "",}}
            onContextMenu={(e) => handleToolbarClick(e)}
            onDragStart={e => e.dataTransfer.setData("nodeData", JSON.stringify({nodeType: data.nodeType}))}
        >
            {/* Text Area */}
            <Input.TextArea
                ref={textAreaRef}
                onBlur={handleBlur} // Disable editing when it loses focus
                onChange={handleTextChange}
                value={data?.txt}
                rootClassName="note-node-input-txt"
                autoSize={{minRows: 1}}
                style={{cursor: isEditable ? 'text' : 'grab'}}
            />

            {/* Overlay to capture double-clicks */}
            {
                !isEditable && (
                    <div
                        className="transparent-wrapper"
                        onDoubleClick={handleDoubleClick} // Enable editing on double-click
                    />
                )
            }
            {showToolbar &&
                <div ref={contextMenuRef} style={{
                    position: "absolute",
                    top: 0,
                    left: "255px",
                    backgroundColor: "white",
                    borderRadius: "4px",

                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "12px 20px",
                        }}
                        onClick={() => handleDelete(data.id)}
                    >
                        <ImageView
                            url="/assets/images/workflow_delete.svg"
                            style={{width: "20px", height: "20px"}}
                        />
                    </div>
                </div>}

        </div>
    );
};

export default memo(NoteNode);