import { get, post } from "../../helpers/utils/http-client";
import { ACCOUNTS_ENDPOINT } from '../../consts/api-endpoints';

export async function getMyAccount() {
    try {
        const { response, status } = await get(`${ACCOUNTS_ENDPOINT}/me`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('app get-all failed cause', err.message, err.code);
        return {};
    }
}