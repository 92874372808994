import { post, get } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

export async function saveAccessKey(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/channels/facebook/accesskey`, request);
    } catch (err) {
        console.debug('fb saveAccessKey failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getAccessKey(appId) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/channels/facebook/accesskey`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('fb getAccessKey get failed cause', err.message, err.code);
        return {};
    }
}

export async function saveAccessKeyByChannel(appId, channel, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/channels/accesskey/${channel}`, request);
    } catch (err) {
        console.debug(`${channel} saveAccessKey failed cause`, err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getAccessKeyByChannel(appId, channel) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/channels/accesskey/${channel}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug(`${channel} getAccessKey get failed cause`, err.message, err.code);
        return {};
    }
}
