import { Menu, Transition } from '@headlessui/react';
import { Table } from 'flowbite-react';
import { Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT } from "../../consts/common-consts";
import { parseAndFormat } from "../../helpers/utils/date-util";
import Loader from "../form-loader/form-loader";
import NoDataMessage from '../no-data-message';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Index({
  headers = [],
  items = [],
  onChangeEnable = () => {
  },
  itemsNotFoundMessage = "Don't have any data",
  itemsNotFoundHelperMessage,
  itemsNotFoundIcon,
  modifiedColumn = true,
  selectedItems = [], // keep these items in main page and table will take care of compare and display
  selectKey = 'id', // provide a unique field to compare the records and selected records
  multiSelect = false, // control to enable checkboxes
  onRowSelect = (_) => { }, // trigger on select
  onSelectAllClick = () => { }, // trigger on select all/ deselect all click
  enableColumn = true,
  dataLoadingState = {
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  },
  filterCondition = () => true
}) {
  const navigate = useNavigate();
  const loadingMockArray = [1, 1, 1, 1];
  const isSelected = (record) => {
    return (
      selectedItems?.length > 0 &&
      selectKey in record &&
      selectedItems.some((selectedRecord) => selectedRecord[selectKey] === record[selectKey])
    );
  };
  const handleRowSelect = (rec) => {
    if (onRowSelect) {
      onRowSelect(rec);
    } else {
      return false;
    }
  }
  return (
    <>
      {dataLoadingState.processing && <div className="py-6"><Loader text="Loading..." /></div>}

      {dataLoadingState.processing &&
        <div className="pt-8 flex flex-row justify-center items-center">
          <div className="flex-1 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
            {loadingMockArray.map((i) => <>
              <div
                key={i}
                className="border border-gray-300 dark-border shadow rounded-md p-4 max-w-sm w-full mx-auto">
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div
                          className="h-2 bg-slate-200 dark:bg-slate-600 rounded col-span-2"></div>
                        <div
                          className="h-2 bg-slate-200 dark:bg-slate-600 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </>)}
          </div>
        </div>
      }

      {(!dataLoadingState.processing && items.length === 0) &&
        <NoDataMessage
          title={itemsNotFoundMessage}
          message={itemsNotFoundHelperMessage}
          icon={itemsNotFoundIcon}
        />
      }

      {(!dataLoadingState.processing && items.length > 0) &&
        <div className="pt-4">
          <div
            className="border-x border-y primary-border-color rounded-lg dark:border-0 bg-white">
            <Table className="dark:bg-gray-800">
              <Table.Head className="border-b border-b-slate-200">
                {multiSelect && (
                  <Table.HeadCell>
                    <input id="table-head-checkbox" type="checkbox" value="" class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-1 dark:bg-gray-700 dark:border-gray-600"
                      onChange={onSelectAllClick}
                      checked={selectedItems?.length === items.length}
                    />
                  </Table.HeadCell>
                )}
                {headers.map(((h, index) =>
                  <Table.HeadCell className="text-gray-950 font-medium" key={`header_${index}`}>
                    {h}
                  </Table.HeadCell>
                ))}
                {modifiedColumn &&
                  <Table.HeadCell className="text-gray-950 font-medium">
                    Updated At
                  </Table.HeadCell>
                }
                <Table.HeadCell>
                  <span className="sr-only">
                    Edit
                  </span>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {items.map((item, index) => {
                  const { record, columnsData } = item;
                  const tds = [];
                  for (const cd of columnsData) {
                    const { data, isEnableField, options, isIdField, isUpdatedField } = cd;
                    let tdElement;
                    if (options && options.length > 0) {
                      tdElement =
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-row justify-end">
                          <Menu as="div" className="absolute inline-block text-left">
                            <div>
                              <Menu.Button
                                className="flex items-center rounded-full text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                <span className="sr-only">Open options</span>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  fill="none" viewBox="0 0 24 24"
                                  strokeWidth={1.5} stroke="currentColor"
                                  className="w-7 h-7">
                                  <path strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                </svg>
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                className="absolute z-50 right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  {options.map((option, oi) =>
                                    <Menu.Item key={oi}>
                                      {({ active }) => (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                          href="#"
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm'
                                          )}
                                          onClick={() => {
                                            if (option.link) {
                                              navigate(option.link);
                                            } else {
                                              option.onClick(record);
                                            }
                                          }}
                                        >
                                          {option.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  )}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </td>
                    } else if (modifiedColumn && isUpdatedField) {
                      tdElement =
                        <Table.Cell
                          className="px-6 py-4 whitespace-nowrap dark-primary-text">
                          {parseAndFormat(data, DATE_FORMAT)}
                        </Table.Cell>
                    } else {
                      tdElement =
                        <Table.Cell className="">
                          {isIdField
                            ? <span className="text-gray-950 font-medium">{data}</span>
                            : <span>{data}</span>
                          }
                        </Table.Cell>
                    }

                    if (filterCondition(record)) {
                      tds.push(tdElement);
                    }
                  }

                  return (
                    <Table.Row key={`row_${index}`}>
                      {multiSelect && (
                        <Table.Cell>
                          <input id={`table_row_${index}_checkbox`} type="checkbox" value="" class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-1 dark:bg-gray-700 dark:border-gray-600"
                            checked={isSelected(record)}
                            onChange={() => handleRowSelect(record)}
                          />
                        </Table.Cell>
                      )}
                      {tds}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
      }
    </>
  )
}