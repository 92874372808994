import React, {memo, useContext, useEffect, useRef, useState} from 'react';

import "./note_edge_connection_node.scss";
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";
import ImageView from "../common/ImageView";

const NoteEdgeConnectionNode = ({id, data}) => {

    const [showToolbar, setShowToolbar] = useState(false);
    const contextMenuRef = useRef(null);
    const workflowContext = useContext(WorkflowCanvasContext);


    useEffect(() => {
        // Add event listener when the component is mounted
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up event listener when the component is unmounted
            document.removeEventListener("click", handleClickOutside);
            document.addEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOutside = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            setShowToolbar(false);
        }
    };

    const handleToolbarClick = (e) => {
        e.preventDefault();
        setShowToolbar(true);
    }

    const handleDelete = () => {
        const updatedData = workflowContext.nodes.filter(item => item.id !== id);
        workflowContext.setEdges(eds => eds.filter((edge) => edge.source !== id && edge.target !== id));

        workflowContext.setNodes(updatedData)
        setShowToolbar(false);

    }

    return (
        <div
            style={{position: "relative"}}
            className="note-edge-connection-node"
            onContextMenu={(e)=> handleToolbarClick(e)}
        >
            <div className="note-edge-connection-line"/>
            {showToolbar &&
                <div ref={contextMenuRef} style={{
                    position: "absolute",
                    top: 10,
                    left: 20,
                    backgroundColor: "white",
                    borderRadius: "4px",

                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "12px 20px",
                        }}
                        onClick={() => handleDelete(data.id)}
                    >
                        <ImageView
                            url="/assets/images/workflow_delete.svg"
                            style={{width: "20px", height: "20px"}}
                        />
                    </div>
                </div>}
        </div>

    );
};

export default memo(NoteEdgeConnectionNode);