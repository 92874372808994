import React, { useEffect, useState } from "react";
import { StmStateDto } from "../../common/dto/stm-state-dto";
import { QUESTION_PROMPT } from "../../common/const/settings-type";
import SlotSelector from "../../common/components/slot-selector";
import { listCodehook } from "../../../../services/model/model-config-service";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import Loader from "../../../../components/form-loader/form-loader";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";
import { createStmDefinition } from "../../common/utils/journey-definition-utils";
import { useReactFlow } from "reactflow";


function getPreviousSteps(reactFlowInstance, stepId) {
  try {
    const stateMachine = createStmDefinition(reactFlowInstance)
    console.log('getPreviousSteps stateMachine', JSON.stringify(stateMachine))

    const states = stateMachine.states;
    const previousSteps = [];

    // Helper function to find previous steps recursively
    function findPreviousSteps(currentStepId) {
      for (const [id, state] of Object.entries(states)) {
        if (state.next === currentStepId) {
          previousSteps.push({
            stepId: id,
            stepName: state.stateConfig.name
          });
          findPreviousSteps(id);
        }
      }
    }

    // Start finding previous steps from the given stepId
    findPreviousSteps(stepId);

    // Return the previous steps in reverse order to maintain sequence
    return previousSteps.reverse();

  } catch (e) {
    return []
  }
}

function Page(props) {
  const { name, onChangeData, savedSettings, metadata = {} } = props;
  const { appId } = useAppInfo();
  const reactFlowInstance = useReactFlow();

  const [formData, setFormData] = useState(savedSettings || {
    name,
    slot: '',
    type: 'custom',
    promptDataConfig: {
      loaderType: 'codehook',
      codehookId: '',
    },
    fallbackStep: '',
    autoFillFromEntity: true,
  });

  const [codehookList, setCodehookList] = useState([]);
  // const [fallbackSteps, setFallbackSteps] = useState([]);
  const [codehookListLoadingState, setCodehookListLoadingState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: '',
    errorMessage: ''
  });

  const updateCodehookListLoadingState = ({ processing, success, failed, message, errorMessage }) => {
    setCodehookListLoadingState({ processing, success, failed, message, errorMessage })
  }

  const fetchCodeHookList = async () => {
    updateCodehookListLoadingState({
      processing: true
    });
    const { data } = await listCodehook(appId)
    setCodehookList(data);
    updateCodehookListLoadingState({
      processing: false
    });
  }

  useEffect(() => {
    fetchCodeHookList()
    // setFallbackSteps(getPreviousSteps(reactFlowInstance, props.id))
  }, []);

  const handleChange = event => {
    const { name, value } = event.target;

    setFormData((preData) => {
      const newData = { ...preData };

      if (name === 'codehookId') {
        newData.promptDataConfig.codehookId = value;
      } else {
        newData[name] = value;
      }

      onChangeData(new StmStateDto({
        type: QUESTION_PROMPT,
        stateConfig: newData,
      }));
      
      return { ...newData };
    });
  };

  const handleRefreshCodeList = () => {
    fetchCodeHookList();
  }

  return (
    <div className="space-y-3">
      <SettingTip text=" Execute custom code to generate a prompt for the user. The user's response is stored in the designated slot, with the selection assigned to a chosen variable." />
      {codehookListLoadingState.processing &&
        <Loader />
      }
      <TextBox
        label="Name"
        type="text"
        name="name"
        placeholder="State name"
        value={formData.name}
        onChange={handleChange}
      />
      <SlotSelector value={formData.slot} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />
      <div className="pt-4">
        <div className="relative">
          <div className="absolute top-0 right-0 z-10">
            <div className="flex gap-2 justify-end items-center">
              <svg onClick={handleRefreshCodeList} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-900">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              <a href={`/app/${appId}/integrations/functions/create`} target="_blank" className="text-sm text-blue-700 font-semibold" rel="noreferrer">Create Function</a>
            </div>
          </div>
          <SelectInput
            label="Function"
            id="codehookId"
            name="codehookId"
            autoComplete="codehookId"
            value={formData.promptDataConfig.codehookId}
            onChange={handleChange}
            optionsComp={<>
              <option value="">Select Function</option>
              { }
              {codehookList.map((cd, si) => (
                <option key={si} value={cd.codehookId}>{cd.codehookId}</option>
              ))}
            </>}
          />
        </div>
      </div>
    </div>
  )
}

export default Page