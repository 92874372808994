import { Button, Spinner } from 'flowbite-react';
import React from "react";
import { useNavigate } from 'react-router-dom';

const customSideBarTheme = {
  "color": {
    "indigo": "text-white border border-transparent  focus:ring-4 bg-indigo-600 enabled:hover:bg-indigo-700 focus:ring-indigo-300 dark:bg-indigo-600 dark:enabled:hover:bg-indigo-700 dark:focus:ring-indigo-900",
  },
};

export default function ButtonWrapper(props) {
  const navigate = useNavigate();
  const { variant = 'button', color = 'primary', text } = props;
  let colorBtn;
  if (color === 'default') {
    colorBtn = 'light'
  } else if (color === 'error') {
    colorBtn = 'failure'
  } else {
    colorBtn = 'indigo'
  }

  const { rootClass = '', loading } = props
  if (variant === 'link') {
    return (
      <div className={rootClass}>
        <Button
          {...props}
          color={colorBtn}
          theme={customSideBarTheme}
          onClick={() => navigate(props.to)}
        >
          {text}
        </Button>
      </div>
    )
  } else {
    return (
      <div className={rootClass}>
        <div className="relative inline-flex">
          <Button
            {...props}
            color={colorBtn}
            theme={customSideBarTheme}
          >
            <div className="flex flex-row gap-2">
              {loading &&
                <Spinner size="sm" aria-label="Alternate spinner button example" />
              }
              {!!props.icon && !loading && props.icon}
              {text}
            </div>
          </Button>
          {props.isIndicated &&
            <div className="flex absolute top-0 right-0 -mt-0.5 -mr-1">
              <span className="absolute inline-flex animate-ping">
                <span className="inline-flex rounded-full h-3 w-3 bg-red-400 opacity-75"></span>
              </span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </div>
          }

        </div>
      </div>
    )
  }

}