import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {journeyList as journeyListSvc } from "../../../../services/model/journey-service";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";

function Page({name, onChangeData, savedSettings, metadata={}}) {
    console.log("metadata", metadata);
    const { appId } = useAppInfo();
    const [formData, setFormData] = useState(savedSettings);

    const [journeyList, setJourneyList] = useState([]);

    const fetchJourneys = async () => {
        let { data } = await journeyListSvc(appId);
        data = data.filter(j=>j.journeyId !== metadata.journeyId)
        setJourneyList(data);
    }

    useEffect( () => {
        fetchJourneys()
    }, []);

    useEffect(() => {
        formData.stateType = ACTION_PROMPT
        onChangeData(formData);
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}

        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'journeyId') {
            newFormData.journeyId = targetValue;
        }

        setFormData(newFormData);
    };

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="The Workflow Step allows you to start another workflow within the current workflow"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <SelectInput
                label="Journey"
                id="journeyId"
                name="journeyId"
                autoComplete="journeyId"
                value={formData.journeyId}
                onChange={handleChange}
                optionsComp={<>
                    <option value="">Select Workflow</option>
                    {}
                    {journeyList.map((cd, si)=>(
                        <option key={si} value={cd.journeyId}>{cd.name}</option>
                    ))}
                </>}
            />

        </div>
    )
}

export default Page
