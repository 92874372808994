import { get, post } from "../../helpers/utils/http-client";
import {APPS_ENDPOINT, CONFIGS_ENDPOINT} from '../../consts/api-endpoints';

export async function getConfig(type, configName) {
    try {
        const resp = await get(`${CONFIGS_ENDPOINT}/${type}/${configName}`);
        const { response, status } = resp;
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('getConfig failed cause', err.message, err.code);
        return {};
    }
}

export async function saveConfig(appId, data) {
    try {
        // `${config.API_BASE_URL}/apps/${appId}/integration
        return await post(`${APPS_ENDPOINT}/${appId}/channels/configs`, data);
    } catch (err) {
        console.debug('saveConfig failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function logoUploadUrlGet(appId, request) {
    return await post(`${APPS_ENDPOINT}/${appId}/channels/configs/logo/url`, request);
}

export async function fileUpload(preSignedUrl, file) {
    try {
        const headers = new Headers();
        const requestOptions = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
            headers,
        };
        return await fetch(preSignedUrl, requestOptions)
    } catch (err) {
        console.debug('file upload failed cause', err);
        return null;
    }
}
