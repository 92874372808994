import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../components/button/button';
import Message from "../../../components/message/message-comp";
import Loader from "../../../components/form-loader/form-loader";
import {useAppInfo} from "../../../helpers/hooks/common-hook";
import {
    getFineTunedModels, updateInferenceModel, getFineTuneState
} from "../../../services/model/finetune-service";
import SelectInput from "../../../components/forms/select";
import BaseModelProvidersSelect from "./../common/base-model-providers-select";

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

function Index({fineTuneType}) {
    const { appId } = useAppInfo();

    const [formData, setFormData] = useState({
        baseModelProvider: '',
        fineTuneModelId: '',
    });

    const [error, setError] = useState({
        baseModelProvider: false,
        fineTuneModelId: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [selectDataLoadingState, setSelectDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateSelectDataLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setSelectDataLoadingState({...processing, success, failed, message, errorMessage})
    }

    const [ baseModels, setBaseModels ] = useState([]);
    const [ selectedBaseModels, setSelectedBaseModels ] = useState([]);

    async function fetchSavedSettings() {
        updateSelectDataLoadingState({
            processing: true
        });
        const data= await getFineTuneState(appId);
        setFormData(data);
        updateSelectDataLoadingState({
            processing: false
        });
    }

    async function fetchFineTunedModels() {
        updateSelectDataLoadingState({
            processing: true
        });
        const data= await getFineTunedModels(appId, fineTuneType);
        setBaseModels(data);
        updateSelectDataLoadingState({
            processing: false
        });
    }

    async function fetchData() {
        await fetchFineTunedModels();
        await fetchSavedSettings();
    }

    useEffect( () => {
        fetchData();
    }, []);

    useEffect( () => {
        if (formData.baseModelProvider) {
            if (baseModels[formData.baseModelProvider]) {
                setSelectedBaseModels(baseModels[formData.baseModelProvider])
            } else {
                setSelectedBaseModels([]);
            }
        }
    }, [formData.baseModelProvider]);

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage}))
    }

    const validateForm = () => {
        let valid = true;

        if (formData.baseModelProvider === '') {
            error.baseModelProvider = true;
            valid = false;
        } else {
            error.baseModelProvider = false;
        }

        if (formData.fineTuneModelId === '') {
            error.fineTuneModelId = true;
            valid = false;
        } else {
            error.fineTuneModelId = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const onDataUpdate = (field, event) => {
        setFormData({...formData, [field]: event.target.value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault()
        if (validateForm()) {
            updateFormState(true, false, false)
            const { status } = await updateInferenceModel(appId, formData);
            if (status === 200) {
                updateFormState(false, true, false)
            } else {
                updateFormState(false, false, true, 'Unable to update setting. Please try again');
            }
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="pt-2 flex flex-col gap-6">

                {(formState.processing || selectDataLoadingState.processing) && <div className="p-4"><Loader/></div>}

                {formState.success &&
                    <Message text={`Successfully Executed`} color="success"/>
                }

                {formState.failed && <Message text={formState.errorMessage} color="failure"/>}

                <BaseModelProvidersSelect
                    value={formData.baseModelProvider}
                    onDataUpdate={onDataUpdate}
                    error={error.baseModelProvider}
                    updateSelectDataLoadingState={updateSelectDataLoadingState}
                    fineTuneType={fineTuneType}
                />

                <SelectInput
                    id="baseModel"
                    label="Finetuned Model"
                    required
                    value={formData.fineTuneModelId}
                    onChange={event => { onDataUpdate('fineTuneModelId', event)}}
                    error={error.fineTuneModelId}
                    optionsComp={
                        <>
                            <option>Select Finetuned Model</option>
                            {selectedBaseModels.map((model)=>
                                <option key={model.fineTuneModelId} value={model.fineTuneModelId}>{model.modelRefId}</option>
                            )}
                        </>
                    }
                />

                <div className="pt-2 w-full flex flex-row gap-2">
                    <Button color="default" text="Reset"/>
                    <Button type="submit" text="Save Settings"/>
                </div>

            </div>
        </form>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)