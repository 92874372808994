import { get, post } from "../../helpers/utils/http-client";
import { BILLING_ENDPOINT } from '../../consts/api-endpoints';

export async function getDashboardResponse() {
    try {
        return await get(`${BILLING_ENDPOINT}/bff/dashboard`);;
    } catch (err) {
        console.debug('getSubscription failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getInvoicesResponse() {
    try {
        return await get(`${BILLING_ENDPOINT}/bff/invoices`);
    } catch (err) {
        console.debug('getInvoicesResponse failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getSubscription() {
    try {
        const { response, status } = await get(`${BILLING_ENDPOINT}/subscription`);
        console.log("response", response)
        if (status === 200) {
            return response;
        } else {
            return null;
        }
    } catch (err) {
        console.debug('getSubscription failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function upgradeSubscription(plan) {
    try {
        return await post(`${BILLING_ENDPOINT}/subscription/upgrade`, {
            plan
        });
    } catch (err) {
        console.debug('upgradeSubscription failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function resumeSubscription() {
    try {
        return await post(`${BILLING_ENDPOINT}/subscription/resume`);
    } catch (err) {
        console.debug('resumeSubscription failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function cancelSubscription() {
    try {
        return await post(`${BILLING_ENDPOINT}/subscription/cancel`);
    } catch (err) {
        console.debug('cancelSubscription failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function cancelUpComingSubscription() {
    try {
        return await post(`${BILLING_ENDPOINT}/subscription/cancel-upcoming`);
    } catch (err) {
        console.debug('cancelSubscription failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getPlans() {
    try {
        return await get(`${BILLING_ENDPOINT}/plans`, { skipTrail: 1 });
    } catch (err) {
        console.debug('getSubscription failed cause', err.message, err.code);
        return { response: [], status: err.code };
    }
}

export async function getPaymentMethods(paymentMethodId) {
    try {
        return await get(`${BILLING_ENDPOINT}/payment-methods`);
    } catch (err) {
        console.debug('getPaymentMethods failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function checkoutSessionCreate(paymentMethodId='') {
    try {
        return await post(`${BILLING_ENDPOINT}/payment-methods/checkout-session`, { paymentMethodId });
    } catch (err) {
        console.debug('checkoutSessionCreate failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function attachPaymentMethod(paymentMethodId) {
    try {
        return await post(`${BILLING_ENDPOINT}/payment-methods/attach`, { paymentMethodId });
    } catch (err) {
        console.debug('attachPaymentMethod failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function detachPaymentMethod(paymentMethodId) {
    try {
        return await post(`${BILLING_ENDPOINT}/payment-methods/detach`, { paymentMethodId });
    } catch (err) {
        console.debug('detachPaymentMethod failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}