import React, { useEffect, useState } from 'react';
import Message from "../../../components/message/message-comp";
import Loader from "../../../components/form-loader/form-loader";
import { upgradeSubscription, cancelUpComingSubscription, getPlans } from '../../../services/billing';
import { Card } from 'flowbite-react';
import { AiFillCheckCircle } from 'react-icons/ai';
import Button from "../../../components/button/button";
import { Badge } from 'flowbite-react';
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import { useNavigate } from "react-router-dom";

const billing_plan_to_proceed_key = 'billing_plan_to_proceed_key';

function PlanCard({ plan, onChangePlan, onCancelUpComingSubscription, onViewUsage }) {
    return (
        <Card className={plan.currentPlan ? 'border-indigo-500 border-2' : ''}>
            <h5 className="flex flex-row items-center justify-between mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
                <span>
                    {plan.name}
                </span>
                {plan.currentPlan &&
                    <Badge color="indigo">
                        Current Plan
                    </Badge>
                }

                {plan.upcomingPlan &&
                    <Badge color="info">
                        Upcoming Plan
                    </Badge>
                }

            </h5>

            <div className="flex items-baseline text-gray-900 dark:text-white">
                <span className="text-3xl font-semibold">
                    $
                </span>
                <span className="text-5xl font-extrabold tracking-tight">
                    {plan.fixedAmountPerMonth / 100}
                </span>
                <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
                    /month
                </span>
            </div>
            <ul className="my-7 space-y-5">
                {plan.features.map((feature, index) =>
                    <li className="flex space-x-3" key={index}>
                        <AiFillCheckCircle />
                        <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                            {feature}
                        </span>
                    </li>
                )}
            </ul>
            <div className="flex flex-col justify-end h-full">
                <div className="flex flex-row justify-end space-x-2">
                    {(!plan.currentPlan && !plan.upcomingPlan) &&
                        <Button
                            rootClass=""
                            text="Select Plan"
                            onClick={() => onChangePlan(plan.name)}
                        >
                        </Button>
                    }

                    {plan.upcomingPlan &&
                        <Button
                            rootClass=""
                            text="Cancel Request"
                            onClick={onCancelUpComingSubscription}
                        >
                        </Button>
                    }

                    {plan.currentPlan &&
                        <Button
                            rootClass=""
                            text="View Usage"
                            onClick={onViewUsage}
                        >
                        </Button>
                    }
                </div>
            </div>
        </Card>
    );
}

export default function Index() {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [fetchState, setFetchState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const [openCancelConfirmDialog, setOpenCancelConfirmDialog] = useState(false);

    async function fetchData() {
        updateFetchState(true, false, false);
        const { response, status } = await getPlans();
        if (status === 200) {
            setPlans(response);
            updateFetchState(false, true, false);
        } else {
            updateFetchState(false, false, true);
        }
    }

    async function checkProceedingPlan() {
        const plan = sessionStorage.getItem(billing_plan_to_proceed_key);
        if (plan) {
            sessionStorage.removeItem(billing_plan_to_proceed_key);
            await onChangeSubscription(plan);
        }
    }

    const updateFetchState = (processing, success, failed, errorMessage = '') => {
        setFetchState({ processing, success, failed, errorMessage });
    };

    const updateFormState = (processing, success, failed, message = '') => {
        setFormState({ processing, success, failed, message });
    };

    useEffect(() => {
        fetchData();
        checkProceedingPlan();
    }, []);

    const onChangeSubscription = async plan => {
        updateFormState(true, false, false);
        const { status, response } = await upgradeSubscription(plan);
        if (status === 200) {
            updateFormState(false, true, false, `Successfully updated to ${plan} plan`);
            await fetchData();
        } else if (status === 400 && response === 'payment_method_not_found') {
            sessionStorage.setItem(billing_plan_to_proceed_key, plan);
            navigate("/user-settings/subscription/payment-methods?setup=1");
        } else {
            updateFormState(false, false, true, 'Unable to update plan');
        }
    };

    const onCancelSubscription = async () => {
        setOpenCancelConfirmDialog(true);
    };

    const onViewUsage = () => {
        navigate('/user-settings/subscription');
    };

    const handleConfirmCancelSubscription = async confirmed => {
        setOpenCancelConfirmDialog(false);
        if (confirmed) {
            updateFormState(true, false, false);
            await cancelUpComingSubscription();
            updateFormState(false, true, false);

            await fetchData();
        }
    };

    return (
        <div className="flex flex-col gap-8 h-screen">
            {fetchState.processing && <div className="py-6"><Loader /></div>}

            {formState.processing && <div className="py-6"><Loader /></div>}

            {(!fetchState.processing && fetchState.failed) &&
                <Message text="Unable to fetch Plans. Please try again" timeout={3} />
            }

            {(!formState.processing && formState.failed) &&
                <Message text="Unable to upgrade plan. Please try again" timeout={3} />
            }

            {(formState.success && formState.message) &&
                <Message text={formState.message} timeout={3} />
            }

            {openCancelConfirmDialog &&
                <ConfirmDialog
                    open={true}
                    onCompletion={handleConfirmCancelSubscription}
                    message="Are you sure? Do you want to cancel the subscription?"
                />
            }

            {(!fetchState.processing && fetchState.success) &&
                <>
                    <div className="flex flex-row gap-4 justify-start items-baseline">
                        <p className="text-gray-900 text-2xl font-bold">Plans</p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {plans.map((plan, index) =>
                            <PlanCard key={index}
                                      plan={plan}
                                      onChangePlan={onChangeSubscription}
                                      onCancelUpComingSubscription={onCancelSubscription}
                                      onViewUsage={onViewUsage}
                            />
                        )}
                    </div>
                </>
            }
        </div>
    );
}
