import React, { useState, useEffect } from 'react';
import PlusButton from "../../components/internal-router-component/channels/plus-button";
import { IoMdClose } from "react-icons/io";


const ConversationDataDisplay = ({ intents, currentIntent, slots, slotUpdate }) => {
    const [detectedIntentChange, setDetectedIntentChange] = useState(false);
    const [detectedSlotUpdate, setDetectedSlotUpdate] = useState(false);
    const [previousIntent, setPreviousIntent] = useState(currentIntent);

    useEffect(() => {
        // Detect Intent change
        if (currentIntent !== previousIntent) {
            setDetectedIntentChange(true);
            setPreviousIntent(currentIntent);
            const timer = setTimeout(() => setDetectedIntentChange(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [currentIntent]);

    useEffect(() => {
        // Detect Slot update request
        if (slotUpdate) {
            setDetectedSlotUpdate(true);
            const timer = setTimeout(() => setDetectedSlotUpdate(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [slotUpdate]);

    // Sort the intents, ensuring currentIntent appears first
    const sortedIntents = intents.sort((a, b) => (a.name === currentIntent ? -1 : (b.name === currentIntent ? 1 : 0)));

    return (
        <div className="w-full h-full">
            <div className="px-5 pr-2 dark:border-gray-700 h-14 flex items-center justify-between">
                <h4 className="font-bold text-lg dark:text-white">Session</h4>
            </div>
            <div className="px-4 py-4 max-h-[500px] overflow-y-auto">

                {/* Intent and Slots Display */}
                {sortedIntents.map((intent, idx) => (
                    <div key={idx} className={`mb-4 p-4 rounded-lg ${currentIntent === intent.name ? 'ring-2 ring-blue-400 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-700'}`}>
                        <h3 className={`text-sm font-bold ${currentIntent === intent.name ? 'text-black dark:text-gray-300' : 'text-gray-800 dark:text-gray-400'}`}>
                            {intent.name} {currentIntent === intent.name && <span className="float-right text-xs font-medium">Current</span>}
                        </h3>

                        <div className="mt-2 space-y-2">
                            {Object.entries(slots[intent.name] || {}).map(([slotName, slotValue]) => {
                                if (!slotValue) {
                                    return null
                                }
                                return <div key={slotName} className="flex justify-between items-center bg-white dark:bg-gray-500 rounded p-2 shadow-sm">
                                    <span className="text-sm font-medium text-gray-600 dark:text-gray-300">{slotName}</span>
                                    <span className="text-sm text-gray-800 dark:text-gray-300">{slotValue || 'N/A'}</span>
                                </div>
                            })}
                        </div>
                    </div>
                ))}

                {/* Intent Change Alert */}
                {/*{detectedIntentChange && (*/}
                {/*    <div className="mt-4 p-2 bg-yellow-100 text-yellow-800 rounded-lg flex items-center text-sm">*/}
                {/*        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h1m0 8h-1v-1h1m-2-12a9 9 0 110 18 9 9 0 010-18zm0 0V9m0 0h.01" />*/}
                {/*        </svg>*/}
                {/*        Intent change detected: {previousIntent} → {currentIntent}*/}
                {/*    </div>*/}
                {/*)}*/}

                {/* Slot Update Alert */}
                {/*{detectedSlotUpdate && (*/}
                {/*    <div className="mt-4 p-2 bg-blue-100 text-blue-800 rounded-lg flex items-center text-sm">*/}
                {/*        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />*/}
                {/*        </svg>*/}
                {/*        Slot update requested for {currentIntent}: {slotUpdate.slot} → {slotUpdate.value}*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </div>
    );
};

const ConversationData = ({agentConfig, journeySession}) => {
    const [workflowIdMap, setWorkflowIdMap] = useState({});
    const [currentIntent, setCurrentIntent] = useState(null);
    const [slotUpdate, setSlotUpdate] = useState(null);
    const [slots, setSlots] = useState([]);

    useEffect(() => {
        if (agentConfig && agentConfig.journeys) {
            const _workflowIdMap = {}
            for (let j of agentConfig.journeys) {
                _workflowIdMap[j.journeyId] = { name: j.name }
            }
            setWorkflowIdMap(_workflowIdMap)
        }
    }, [agentConfig]);

    useEffect(() => {
        if (journeySession) {
            const _slots = {}
            for (let journeyId of Object.keys(workflowIdMap)) {
                const wfSlots = journeySession?.slots[journeyId]
                if (wfSlots) {
                    _slots[workflowIdMap[journeyId].name] = {...wfSlots}
                }
            }
            setSlots(_slots)

            if (workflowIdMap[journeySession.journeyId]) {
                setCurrentIntent(workflowIdMap[journeySession.journeyId].name)
            }
        }
    }, [journeySession]);

    return (
        <ConversationDataDisplay
            intents={Object.values(workflowIdMap)}
            slots={slots}
            currentIntent={currentIntent}
            slotUpdate={slotUpdate}
        />
    );
};

export default ConversationData;
