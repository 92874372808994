export const ACCESS_TOKEN = "enterprise-chatbot-token";
export const TEMP_USER = "chatbot-temp-user"
export const EP_PROFILE = "ep-profile";

export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "hh:mm:ss A";

export const USER_FOLDER = "USER";

// export const QUESTION_NODE_TYPE = "QUESTION";
// export const WEBHOOK_NODE_TYPE = "WEBHOOK";
// export const REPLY_TEXT_NODE_TYPE = "REPLY_TEXT";
// export const DEV_WEBHOOK_NODE_TYPE = "DEV_WEBHOOK";
// export const CONDITION_NODE_TYPE = "CONDITION";
export const NOTE_NODE_TYPE = "NOTE";
export const NOTE_EDGE_NODE_TYPE = "NOTE_EDGE";
export const NODE_TYPE_MAP = {
    MESSAGE: "Message",
    RESPONSE: "Response",
    REPLY_TEXT: "Reply Text",
    WEBHOOK: "Webhook",
    DEV_WEBHOOK: "Dev Webhook",
    CONDITION: "Condition"
}
