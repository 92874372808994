import {get, post, del} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT, ASK_AI_ENDPOINT} from '../../consts/api-endpoints';

export async function getModelConfigs(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('model-config get failed cause', err.message, err.code);
        return {};
    }
}

export async function getModelIntentConfig(appId, intent) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('model-intent-config get failed cause', err.message, err.code);
        return {};
    }
}

export async function getModelJourneyConfig(appId, journeyId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/journey/${journeyId}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('model-journey-config get failed cause', err.message, err.code);
        return {};
    }
}

export async function intentSave(appId, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/intents`, data);
    } catch (err) {
        console.debug('model-config intent save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function intentList(appId) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/model/intents`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('intentList failed cause', err.message, err.code);
        return [];
    }
}

export async function intentGet(appId, intent) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('intentGet failed cause', err.message, err.code);
        return {};
    }
}

export async function intentEntityAssign(appId, intent, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}/entity`, data);
    } catch (err) {
        console.debug('intentEntityAssign failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function intentEntityUnAssign(appId, intent, entity) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}/entity/${entity}`);
    } catch (err) {
        console.debug('intentEntityUnAssign failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function entityList(appId) {
    try {
        const { response, status } =  await get(`${APPS_ENDPOINT}/${appId}/model/entities`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('entityList failed cause', err.message, err.code);
        return [];
    }
}

export async function entityCreate(appId, data) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/entities`, data);
    } catch (err) {
        console.debug('entitySave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function entityDelete(appId, entity) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/entities/${entity}`);;
    } catch (err) {
        console.debug('entityList failed cause', err.message, err.code);
        return [];
    }
}

export async function trainDataSave(appId, intent, data) {
    try {
        const request = {
            intent, data
        }
        return await post(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}/training-data`, request);
    } catch (err) {
        console.debug('model-config train-data save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function trainDataGetByIntent(appId, intent) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}/training-data`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('model-config train-data get failed cause', err.message, err.code);
        return {};
    }
}

export async function getParsers(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/parsers?skipDisable=true`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('model-config codehook upload url get failed cause', err.message, err.code);
        return [];
    }
}

export async function listCodehook(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/codehooks`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('list-codehook failed cause', err.message, err.code);
        return {};
    }
}
export async function getCodehookConfig(appId, codehookId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/codehooks/${codehookId}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('model-codehook-config get failed cause', err.message, err.code);
        return {};
    }
}

export async function listKnowledgeBases(appId) {
    try {
        const { response, status } = await get(`${ASK_AI_ENDPOINT}/${appId}/docs`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('listKnowledgeBases failed cause', err.message, err.code);
        return [];
    }
}

export async function knowledgeBasesCreate(appId, request) {
    try {
        return await post(`${ASK_AI_ENDPOINT}/${appId}/index`, request);
    } catch (err) {
        console.debug('knowledgeBasesCreate save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function knowledgeBasesDelete(appId, id) {
    try {
        return await del(`${ASK_AI_ENDPOINT}/${appId}/docs/${id}`);
    } catch (err) {
        console.debug('knowledgeBasesCreate delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function codehookConfigSave(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/codehooks`, request);
    } catch (err) {
        console.debug('model-config codehook-config save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function codehookUploadUrlGet(appId, codehookId, request) {
    try {
        const { response, status } = await post(`${APPS_ENDPOINT}/${appId}/model/codehooks/${codehookId}/upload-url`, request);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('model-config codehook upload url get failed cause', err.message, err.code);
        return {};
    }
}

export async function codehookUploadArtifact(preSignedUrl, file) {
    try {
        const headers = new Headers();
        headers.append("Content-Type", "application/zip");
        const requestOptions = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
            headers,
        };
        return await fetch(preSignedUrl, requestOptions)
    } catch (err) {
        console.debug('model-config codehook upload failed cause', err);
        return null;
    }
}

export async function codehookDeploy(appId, codehookId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/codehooks/${codehookId}/deploy`, request);;
    } catch (err) {
        console.debug('model-config codehook deploy failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function codehookDelete(appId, codehookId) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/codehooks/${codehookId}`);
    } catch (err) {
        console.debug('model-config codehook delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function requiredEntityConfigSave(appId, journey, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journey}/required-entity`, request);
    } catch (err) {
        console.debug('model-config required-entity save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function requiredContextConfigSave(appId, journey, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journey}/context`, request);
    } catch (err) {
        console.debug('model-config context save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function parserConfigSave(appId, journey, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/journey/${journey}/entity-parser`, request);
    } catch (err) {
        console.debug('model-config parser save failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function intentDelete(appId, intent) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/intents/${intent}`);
    } catch (err) {
        console.debug('model-config intent del failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function listAuthConnes(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/auth`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('listAuthConnes cause', err.message, err.code);
        return {};
    }
}
export async function getAuthConn(appId, id) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/auth/${id}`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('getAuthConnes failed cause', err.message, err.code);
        return {};
    }
}

export async function authConnSave(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/auth`, request);
    } catch (err) {
        console.debug('authConnSave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function authConnDelete(appId, id) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/auth/${id}`);
    } catch (err) {
        console.debug('authConnDelete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}