import React, { useEffect, useState, useCallback, useMemo } from "react";
import TextBox from "../../../components/forms/text-box";
import DisplayTexts from "../common/components/display-texts";
import SettingTip from "../common/components/setting-tip";
import SlotSelector from "../common/components/slot-selector";
import { QUESTION_PROMPT } from "../common/const/settings-type";
import { StmStateDto } from "../common/dto/stm-state-dto";

function QuestionPromptSettings({ name, onChangeData, savedSettings, metadata }) {
  const [formData, setFormData] = useState(savedSettings);

  const notifyChanges = (_formData) => {
    onChangeData(_formData);
  }

  useEffect(() => {
      formData.stateType = QUESTION_PROMPT
    notifyChanges(formData)
  }, [formData]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;

    setFormData(prevFormData => {
      if (prevFormData[name] === value) return prevFormData;
      return {
        ...prevFormData,
        [name]: value
      };
    });
  }, []);

  const memoizedDisplayTextOptions = useMemo(() => formData.displayTextOptions, [formData.displayTextOptions]);

  return (
    <div className="space-y-3">
      <SettingTip text="The prompt is sent to the user, and the response to the prompt is stored in the variables provided" />
      <TextBox
        label="Name"
        type="text"
        name="name"
        placeholder="State name"
        value={formData.name}
        onChange={handleChange}
      />
      <SlotSelector value={formData.slot} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />
      <DisplayTexts
        parameterName={formData.slot}
        displayTextOptionsProps={memoizedDisplayTextOptions}
        responseModifyByAI={formData.responseModifyByAI}
        onChangeData={handleChange}
      />

    </div>
  )
}

export default QuestionPromptSettings
