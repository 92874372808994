import { HiOutlinePlus } from "react-icons/hi";
import Tile from "../tile";

export default function TileList({ tiles, onDelete, onClickCreate }) { // Destructuring tiles from props
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 pt-8 pb-4">
      {tiles.map((tile, index) => (
        <Tile key={index} app={tile.record} onDelete={onDelete} /> // Passed the entire tile object to the Tile component
      ))}
      <div onClick={onClickCreate} className="h-28 cursor-pointer hover:border-gray-400 transition-colors border-dashed border-2 border-gray-300 rounded-lg flex justify-center items-center">
        <HiOutlinePlus size={42} className="text-gray-400" />
      </div>
    </div>
  );
}
