import PropTypes from "prop-types";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from ".";
import { createAppBasePath } from "../../../../consts/route-consts";
import { useAppInfo } from "../../../../helpers/hooks/common-hook";
import { cn } from "../../../../helpers/utils/css-utils";
import { stmNodeSelectRest } from "../../../../redux/journey-toolbox/journey-toolbox-actions";

function MenuItem({ name, href, icon, tip, active, appPath = true, stmNodeSelectRest, resetContext }) {
  const appInfo = useAppInfo();
  const { expanded } = useContext(SidebarContext);
  const navigate = useNavigate();

  let navPath = href;

  if (appPath && appInfo) {
    navPath = `${createAppBasePath(appInfo.appId)}${href}`;
  }

  const onClickNav = () => {
    stmNodeSelectRest()
    if (resetContext) {
      window.location = navPath
    } else {
      navigate(navPath)
    }
  }

  const updatedIcon = React.cloneElement(icon, {
    className: `${icon.props.className} transition-colors group-hover:text-indigo-700 ${active ? 'text-indigo-700 dark:text-indigo-400' : 'text-gray-500'}`,
  })

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      onClick={onClickNav}
      href='#'
      className={`${active ? 'bg-indigo-100 dark:bg-indigo-950' : ''} relative group flex items-center my-1.5 px-0 cursor-pointer transition-all rounded-md hover:bg-gray-100 dark:hover:bg-gray-800`}
    >
      <div className={`${active ? 'bg-indigo-100 dark:bg-indigo-950' : ''} w-9 h-9 flex flex-none items-center justify-center rounded-md`}>
        <span className="text-[24px] group-hover:text-gray-700 dark:group-hover:text-gray-100">
          {updatedIcon}
        </span>
      </div>

      <span
        className={cn(
          "w-0 font-medium overflow-hidden group-hover:text-indigo-700 dark:group-hover:text-indigo-400 text-gray-800 dark:text-gray-100 transition-all text-sm truncate",
          { "w-52 ml-3": expanded, "text-indigo-700": active }
        )}
      >
        {name}
      </span>

      {!expanded && (
        <div
          className={cn(
            `absolute text-sm left-full z-50 rounded-md py-1 px-2 ml-6 bg-indigo-100 text-indigo-700 tracking-wide
          invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`
          )}
        >
          {name}
        </div>
      )}
    </a>
  );
}

MenuItem.propTypes = {
  name: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
  tip: PropTypes.string,
  active: PropTypes.bool,
  appPath: PropTypes.bool
};

const mapsStateToProps = (state, ownProps) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    stmNodeSelectRest: (message) => {
      dispatch(stmNodeSelectRest(message));
    },
  };
}

export default connect(mapsStateToProps, mapDispatchToProps)(MenuItem)
