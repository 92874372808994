import { useState } from 'react'
import {contactUs} from "../../services/user/user-service";
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo/main-color.png';
import config from "../../config";
import Message from "../../components/message/message-comp";

export default function Component() {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: ''
  });

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }))
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      updateFormState(true, false, false);
      await contactUs({
        email, message
      });
      updateFormState(false, true, false);
    } catch (e) {
      updateFormState(false, false, true, 'Error');
    }
  }

  return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <a href="https://www.botcircuits.com/">
              <img
                  className="mx-auto h-16 w-auto"
                  src={logo}
                  alt="logo"
              />
            </a>
          </div>
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Contact Us</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              We'd love to hear from you. Send us a message!
            </p>
            <p className="mt-2 text-center text-sm text-indigo-600">
              Or email us directly at: <a href="mailto:hello@botcircuits.com" className="font-medium hover:text-indigo-500">hello@botcircuits.com</a>
            </p>
          </div>

          {formState.success && <Message text="Successfully sent message" type="success" />}
          {formState.failed &&
              <div className="flex justify-center items-center">
                <Message text='Message send failed. Email us hello@botcircuits.com' type="error" />
              </div>
          }

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                    id="message"
                    name="message"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Your message"
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div>
              <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                  disabled={formState.processing}
              >
                {formState.processing ? (
                    <>
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                      Sending...
                    </>
                ) : (
                    'Send Message'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
  )
}
