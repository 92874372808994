const environment = process.env.REACT_APP_NODE_ENV || 'development';
const port = parseInt(process.env.PORT) || 3333;

const config = Object.freeze({
  ENVIRONMENT: environment,
  IS_PRODUCTION: environment === 'production',
  IS_STAGING: environment === 'staging',
  IS_DEVELOPMENT: environment === 'development',
  IS_TEST: environment === 'test',
  PORT: port,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  REGION: process.env.REACT_APP_REGION,
  REALTIME_API_BASE_URL: process.env.REACT_APP_REALTIME_API_BASE_URL,
  WS_API_BASE_URL: process.env.REACT_APP_WS_API_BASE_URL,
  WEB_PLUGIN_URL: process.env.REACT_APP_WEB_PLUGIN_URL,
  AUTH_CALLBACK_URL: process.env.REACT_APP_AUTH_CALLBACK_URL,
  APP_NAME: process.env.REACT_APP_NAME,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  LIVE_DEMO_APP_ID: process.env.REACT_APP_LIVE_DEMO_APP_ID,
  LIVE_DEMO_APP_VERSION: process.env.REACT_APP_LIVE_DEMO_APP_VERSION,
  LIVE_DEMO_APP_ACCESS_KEY: process.env.REACT_APP_LIVE_DEMO_APP_ACCESS_KEY,
  AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY
});

export default config;
