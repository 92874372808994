// HTTP status codes
export const SUCCESS_CODE = 200;
export const CREATED = 201;
export const NO_CONTENT = 204;
export const INTERNAL_ERROR = 500;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const HTTP_CLIENT_ERROR = 503;

export const BOT_CONV_MESSAGE_TYPE = 'bot'
export const USER_CONV_MESSAGE_TYPE = 'user'

export const VEHICLE_DOMAIN = 'vehicle'
export const CLOTH_DOMAIN = 'cloth'