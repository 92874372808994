import { useEffect, useState } from 'react';
import Button from "../../../components/button/button";
import TableReloadButton from "../../../components/button/table-reload-button";
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";
import PageContainerV2 from '../../../components/page-container/page-container-v2';
import Table from "../../../components/table";
import { useAppInfo } from '../../../helpers/hooks/common-hook';
import { codehookDelete, listCodehook } from '../../../services/model/model-config-service';
import CodehookSave from "../codehook-save/codehook-save-page";
import SectionHeading from '../../channels/_components/section-heading';

export default function Index() {
  const { appId } = useAppInfo();
  const [dataList, setDataList] = useState([]);
  const [editData, setEditData] = useState();

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [deletingData, setDeletingData] = useState();
  const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState();

  async function fetchConfigs() {
    updateFormState(true, false, false);
    const { data } = await listCodehook(appId);
    if (data && Array.isArray(data)) {
      setDataList(data);
    }
    updateFormState(false, true, false);
  }

  useEffect(() => {
    fetchConfigs();
  }, []);

  const handleOpenCreate = () => {
    setOpenCreateDialog(true);
    setEditData()
  }

  const handleOnCompletionCreate = async state => {
    if (state) {
      await fetchConfigs();
    }
    setOpenCreateDialog(false);
    setEditData()
  }

  const handleSetEditData = data => {
    setEditData(data);
    setOpenCreateDialog(true);
  }

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  const handleSelectDelete = id => {
    setDeletingData(id);
    setDeleteRevokeConfirmDialog(true);
  }

  const handleConfirmDelete = async confirmed => {
    setDeleteRevokeConfirmDialog(false);
    if (confirmed) {
      updateFormState(true, false, false);
      await codehookDelete(appId, deletingData);
      setDeletingData(null);
      await fetchConfigs();
      updateFormState(false, true, false);
    }
  }

  const processDataList = dataList.map(dt => {
    return {
      record: dt,
      columnsData: [
        { data: dt.codehookId, isIdField: true },
        { data: (dt.functionInfo && dt.functionInfo.runtime) ? dt.functionInfo.runtime : '-' },
        { data: dt.modifiedAt, isUpdatedField: true },
        {
          options: [
            {
              name: 'Edit Function',
              link: `save/${dt.codehookId}`
            }, {
              name: 'Delete Function',
              onClick: () => handleSelectDelete(dt.codehookId)
            }
          ]
        },
      ]
    }
  })

  return (
    <PageContainerV2
      title="Functions"
      subtitle="Functions"
      pageType="contents"
      pageSidebar={true}
    >
      <div className="flex h-full flex-1 flex-grow">
        <div className="flex flex-col flex-1">
          <SectionHeading title="Functions">
            <div className="flex flex-row items-center gap-4">
              <TableReloadButton onClick={() => fetchConfigs()} />
              <Button
                size="sm"
                variant="link"
                to={`/app/${appId}/functions/create`}
                text="Add Function" />
            </div>
          </SectionHeading>
          <div className="flex flex-col space-y-8 px-5 w-full">
            {(openCreateDialog) &&
              <CodehookSave
                appId={appId}
                savedKnowledgeBase={editData}
                open={openCreateDialog}
                onCompletion={handleOnCompletionCreate}
              />
            }

            {openDeleteConfirmDialog &&
              <ConfirmDialog
                appId={appId}
                open={openDeleteConfirmDialog}
                onCompletion={handleConfirmDelete}
                title="Delete Confirmation"
                message="Are you sure. Do you want to delete this Codehook ?"
              />
            }

            <Table
              headers={['Title', 'Runtime']}
              items={processDataList}
              dataLoadingState={formState}
              itemsNotFoundMessage="Let's get you started with creating your first function"
            />
          </div>
        </div>
      </div>

    </PageContainerV2>
  )
}