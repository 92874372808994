/* eslint-disable */
export default {
    Auth: {
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        oauth: {
            domain: "bc-be-dev-customer-auth.auth.us-east-1.amazoncognito.com",
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
            redirectSignIn: "https://example.org",
            redirectSignOut: "https://example.org",
            responseType: "code"
        }
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_RESOURCE_BUCKET,
            region: process.env.REACT_APP_AWS_COGNITO_REGION
        }
    },
};
