import React, { useState } from "react";
import PropTypes from "prop-types";
import { BsChevronRight } from "react-icons/bs";

import { cn } from "../../../helpers/utils/css-utils";

const Accordion = ({
  title,
  subtitle,
  initial = false,
  children,
  notifyElement = null,
}) => {
  const [isOpen, setIsOpen] = useState(initial);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border rounded-lg shadow-sm my-4 dark:border-gray-800">
      <div
        className="p-5 cursor-pointer flex justify-between items-center"
        onClick={toggleCollapsible}
      >
        <div>
          <div className="flex items-center gap-4 mb-1">
            <h2 className="text-lg font-semibold dark:text-gray-100">
              {title}
            </h2>
            {notifyElement && notifyElement}
          </div>
          {subtitle && (
            <p className="text-sm text-gray-600 dark:text-gray-500">
              {subtitle}
            </p>
          )}
        </div>
        <div>
          <button
            className={cn(
              "text-gray-600 dark:text-gray-100 focus:outline-none transition-all duration-300 hover:text-gray-300 outline-none",
              {
                "rotate-90": isOpen,
              }
            )}
          >
            <BsChevronRight size={20} />
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="p-5 border-t dark:border-gray-800">{children}</div>
      )}
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  initial: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  notifyElement: PropTypes.element,
};
