export const INDUSTRIES = [
    "Agriculture",
    "Automobile",
    "B2B",
    "BFSI",
    "Ecommerce",
    "Ed-tech",
    "Energy & Utilities",
    "HR",
    "Healthcare",
    "IT",
    "Lead Generation",
    "Manufacturing",
    "Travel"
];

export const USE_CASES = [
    "Employee Engagement",
    "Customer Engagement",
    "Enterprise Automation",
    "Customer Support",
];