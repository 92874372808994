import {useEffect, useState} from 'react'
import {listCodehook} from "../../../../../services/model/model-config-service";
import SelectInput from "../../../../../components/forms/select";
import {CODEHOOK_DEFAULT_ACTION_CONFIG} from "../common-consts";
import ReturnDirectSwitch from "../../common/components/return-direct-switch";
import TabOpen from "../../../../../components/tab-open";
import ActionParameters from "../../../../../components/action-parameter-row";

export default function CodehookAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=CODEHOOK_DEFAULT_ACTION_CONFIG,
                                           error
}) {
    const { appId } = config;

    const [ codeHookList, setCodeHookList ] = useState([]);
    const [codeHookLoadingState, setCodeHookLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateCodeHookLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setCodeHookLoadingState({...processing, success, failed, message, errorMessage})
    }

    async function fetchCodeHooks() {
        updateCodeHookLoadingState({
            processing: true
        });
        const { data } = await listCodehook(appId);
        if (data && Array.isArray(data)) {
            setCodeHookList(data);
        }
        updateCodeHookLoadingState({
            processing: false
        });
    }

    useEffect( () => {
        fetchCodeHooks();
    }, []);

    return (
        <>
            <ReturnDirectSwitch actionConfig={actionConfig} onActionConfigUpdate={onActionConfigUpdate} />

            <SelectInput
                rootClass="pt-4 w-1/2"
                id="codeHook"
                label="Functions"
                required
                value={actionConfig.resourceId}
                onChange={event => { onActionConfigUpdate('resourceId', event)}}
                optionsComp={
                    <>
                        <option>Select Function</option>
                        {codeHookList.map((codehook)=>
                            <option key={codehook.codehookId} value={codehook.codehookId}>{codehook.codehookId}</option>
                        )}
                    </>
                }
            />

            <TabOpen
                text="Create Function"
                url={`/app/${appId}/functions`}
                onReload={fetchCodeHooks}
            />

            <div className="pt-4 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>

            <ActionParameters actionConfig={actionConfig} setActionConfig={setActionConfig}/>

        </>
    )
}
