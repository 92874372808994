import React from "react";

export default function Stepper({ steps = [], className, onChange, activeStep }) {
  const handleStepChange = (stepKey) => {
    if (onChange && stepKey !== activeStep) {
      if (typeof onChange === "function") {
        onChange(stepKey);
      } else {
        throw new Error("onChange must be a function");
      }
    }
  }
  if (steps.length) {
    return (
      <div className={`flex w-full gap-7 ${className}`}>
        {steps.map((step, index) => {
          const checkKey = step.id || index;
          const active = checkKey === activeStep;
          return (
            <div
              onClick={() => handleStepChange(checkKey)}
              key={index}
              className={`flex items-center gap-3 group ${
                active ? "text-indigo-700" : "text-gray-500 hover:text-gray-600 cursor-pointer"
              }`}
            >
              <div
                className={`rounded-full w-8 h-8 text-lg grid place-content-center border-2 ${
                  active ? "border-indigo-700" : "border-gray-500 group-hover:border-gray-600"
                }`}
              >
                {index + 1}
              </div>
              <div>
                <h3 className="font-semibold">
                  {step.name || `Step ${index + 1}`}
                </h3>
                {step.desc && <p className="text-sm">{step.desc}</p>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
}
