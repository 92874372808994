import React, {useState, useEffect} from 'react';
import {Button} from 'flowbite-react';
import {IoIosAdd} from "react-icons/io";
import {IoIosRemove} from "react-icons/io";
import {Accordion} from 'flowbite-react';


const customAddRowTheme = {
    "size": {
        "xs": "text-xs px-0 py-0",
    }
};

const getDividerCss = (showTopDivider, showBottomDivider) => {
    let dividerCss = 'border-gray-100';

    if (showTopDivider) {
        dividerCss = `${dividerCss} border-t-2 pt-4 mt-4`
    }

    if (showBottomDivider) {
        dividerCss = `${dividerCss} border-b-2 pb-4 mb-4`
    }

    return dividerCss;
}
const DynamicRows = ({
                         title,
                         rowsData,
                         onChange,
                         rowsTemplate,
                         className,
                         removeButtonClassName,
                         defaultThumbnail="",
                         showTopDivider = true,
                         showBottomDivider = true
                     }) => {
    const [rows, setRows] = useState([]);

    // Initialize rows from rowsData prop
    useEffect(() => {
        if (rowsData) {
            setRows(rowsData.map(rowData => ({
                id: rowData.id || Math.random(),
                inputs: rowData.inputs
            })));
        }

    }, [rowsData]);

    // Notify parent component whenever the rows state changes
    useEffect(() => {
        const data = rows.map(row => ({id: row.id, inputs: row.inputs}));
        onChange(data);
    }, [rows, onChange]);

    const handleAddRow = () => {
        const newRow = {
            id: Math.random(),
            inputs: {}
        };
        setRows([...rows, newRow]);
    };

    const handleRemoveRow = (rowId) => {
        setRows(rows.filter(row => row.id !== rowId));
    };

    const handleInputChangeByRaw = (rowId, inputName, value) => {
        const updatedRows = rows.map(row => {
            if (row.id === rowId) {
                return {...row, inputs: {...row.inputs, [inputName]: value}};
            }
            return row;
        });
        setRows(updatedRows);
    };

    const renderRow = (row) => {
        if (typeof rowsTemplate === 'function') {
            return rowsTemplate(row.inputs, (event) => {
                handleInputChangeByRaw(row.id, event.target.name, event.target.value)
            }, row.id);
        }
        return rowsTemplate;
    };

    let deliver

    return (
        <div className={className}>
            <div className={getDividerCss(showTopDivider, showBottomDivider)}>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center justify-between">
                        <label
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        >
                            {title}
                        </label>
                        <Button size="xs" theme={customAddRowTheme} color="gray" onClick={handleAddRow}>
                            <IoIosAdd className="h-6 w-6"/>
                        </Button>
                    </div>

                    <div className="flex flex-col gap-2">
                        <Accordion collapseAll={true}>
                            {rows.map((row, index) => (
                                <Accordion.Panel key={row.id}>

                                    <Accordion.Title>{row.inputs.thumbnail || `Task ${defaultThumbnail} ${(index + 1)}`}</Accordion.Title>
                                    <Accordion.Content>
                                        <div className="flex flex-col gap-2 w-full">
                                            {renderRow(row)}
                                        </div>
                                        <div className="pt-4 flex flex-row justify-end w-full">
                                            <Button size="xs" color="dark"
                                                    onClick={() => handleRemoveRow(row.id)}>
                                                Delete
                                            </Button>

                                        </div>
                                    </Accordion.Content>
                                </Accordion.Panel>
                                ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DynamicRows;
