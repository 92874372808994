import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import SlotSelector from "../../common/components/slot-selector";
import TextBox from "../../../../components/forms/text-box";
import TextArea from "../../../../components/forms/text-area";
import SettingTip from "../../common/components/setting-tip";
import DropDown from "../../../../components/forms/dropdown";
import DynamicRows from "../../../../components/dynamic-rows";
import SentToUserSwitch from "../../common/components/send-to-user-switch";

const prepareRawsData = (data=[]) => {
    return data.map(header=>({inputs: header}))
}

function QuestionPromptSettings({ name, onChangeData, savedSettings, metadata }) {
    const [formData, setFormData] = useState(savedSettings);

    const [executorFormData, setExecutorFormData] = useState({
        inputPrompt: '',
        slotToAssign: ''
    });

    const notifyOnchangeData = data => {
        data.stateType = ACTION_PROMPT
        onChangeData(data);
    }

    useEffect(() => {
        notifyOnchangeData(formData)

        if (formData.assignToSlots && formData.assignToSlots.executors && formData.assignToSlots.executors.length > 0) {
            setExecutorFormData({...formData.assignToSlots.executors[0]})
        }

    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newFormData = {...formData}
        newFormData[targetName] = targetValue;
        setFormData(newFormData);
    };

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="Generate Answer by referring uploaded Documents"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <TextArea
                label="Instruction"
                type="text"
                name="systemPrompt"
                placeholder="Eg: you are helpful chatbot"
                value={formData.systemPrompt}
                onChange={handleChange}
            />

            <TextArea
                rootClass="pt-2"
                label="Question"
                type="text"
                name="inputPrompt"
                placeholder="Eg: do you delivery to {city}"
                value={formData.inputPrompt}
                onChange={handleChange}
            />

            <DropDown
                label="Set response"
                className="pt-2"
                placeHolder="Assign To Slot"
                name="slotToAssign"
                value={formData.slotToAssign}
                options={Object.keys(metadata.slots)}
                onChange={handleChange}
            />

            <SentToUserSwitch formData={formData} setFormData={setFormData} skipNotify={formData.skipNotify}/>


        </div>
    )
}

export default QuestionPromptSettings
