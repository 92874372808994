import { HiInformationCircle } from "react-icons/hi";
import { Alert } from "flowbite-react";

const customTheme = {
  "color": {
    "custom": "text-indigo-950 bg-indigo-100 border-indigo-100 border dark:bg-slate-800 dark:border-slate-700 dark:text-slate-300",
  },
};

export default function SettingTip({ text }) {
  return (
    <div className="mb-2">
      <Alert
        color="custom"
        icon={HiInformationCircle}
        theme={customTheme}
      >
        <p className="font-medium dark:font-normal">{text}</p>
      </Alert>
    </div>
  );
}