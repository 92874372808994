import {Handle, Position} from "reactflow";
import journeyStmStore from "../common/service/journey-stm-store";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";

const getNodeData = id => {
    const storedSettings = journeyStmStore.get(id);
    if (storedSettings) {
        return storedSettings.stateConfig;
    } else {
        return null;
    }
}

const ChildNode = (props) => {
    return React.Children.map(props.children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        const childProps = {
            id: props.id,
            name: props.name,
            onChangeData: props.onChangeData,
            savedSettings: props.savedSettings,
            metadata: props.metadata
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, childProps);
        }
        return child;
    });
}

function NodeComponent(props) {
    const { data, className, nodeTypeIcon } = props;
    const { id, name, metadata } = data;

    const [isCurrentState, setIsCurrentState] = useState(false);

    const onChangeData = data => {
        journeyStmStore.add(id, data)
    }

    useEffect( () => {
        if (metadata && metadata.runningState) {
            setIsCurrentState(id === metadata.runningState)
        }
    }, [metadata]);

    const colorSpec = isCurrentState? {
        bg: 'indigo-50',
        border: 'red-50',
        ring:'ring-4 ring-blue-400 dark:ring-8 dark:ring-red-700',
        selectedBorder:'red-500',
        selectedShadow:'red-400',
        selectedRing:'red-600',
    }: {
        bg: 'indigo-50',
        border: 'blue-300',
        selectedBorder:'blue-500',
        selectedShadow:'blue-400',
        selectedRing:'blue-600',
    }

    const defaultClassName =  `bg-${colorSpec.bg} pt-2 pb-4 px-4 w-72 flex flex-col gap-1 border border-${colorSpec.border} rounded-md shadow-md ${colorSpec.ring}`
    const newClassName = className || defaultClassName;

    let stateName = name || 'New State';
    const nodeData = getNodeData(id)
    if (nodeData && nodeData.name) {
        stateName = nodeData.name;
    }

    return (
        <div className="">
            <Handle
                id="in"
                type="target"
                position={Position.Left}
                isConnectable={true}
            >
                {/*<div className="absolute right-0">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800" viewBox="0 0 20 20" fill="currentColor">*/}
                {/*        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />*/}
                {/*    </svg>*/}
                {/*</div>*/}
            </Handle>
            <div className={newClassName}>

                <div className="">
                    <div className="p-2 rounded">
                        <p className="text-lg font-medium">{props.title}</p>
                    </div>
                </div>

                <p className='hidden'>this: {id}</p>
                <p className='hidden'>runningState: {metadata.runningState}</p>


                {/*<div className="py-1 px-2 pb-4">*/}
                {/*    <ChildNode*/}
                {/*                id={id}*/}
                {/*                name={name}*/}
                {/*                metadata={metadata}*/}
                {/*               onChangeData={onChangeData}*/}
                {/*               savedSettings={getNodeData(id)}*/}
                {/*    >*/}
                {/*        {props.children}*/}
                {/*    </ChildNode>*/}
                {/*</div>*/}
                <div className="">
                    <div className="p-2 rounded-md bg-white border-2 border-blue-200">
                        <p className="text-lg">{stateName}</p>
                    </div>
                </div>

            </div>
            <Handle
                id="out"
                type="source"
                position={Position.Right}
                isConnectable={true}
            >
                {/*<div className="">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-800" viewBox="0 0 20 20" fill="currentColor">*/}
                {/*        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />*/}
                {/*    </svg>*/}
                {/*</div>*/}
            </Handle>
        </div>
    );
}

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapsStateToProps, mapDispatchToProps)(NodeComponent)
