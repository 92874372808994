import {CommonNode} from "./types/common-node";
import {QuestionPromptNode} from "./types/question-prompt-node";
import {MessagePromptNode} from "./types/message-prompt-node";
import {ImageMessageNode} from "./types/image-message-node";
import {ButtonsPromptNode} from "./types/buttons-prompt-node";
import {CardsPromptNode} from "./types/cards-prompt-node";
import {AutoCaptureSlotsPromptNode} from "./types/auto-capture-slots-prompt-node";
import {CodehookPromptNode} from "./types/codehook-prompt-node";
import {CodehookActionNode} from "./types/codehook-action-node";
import {WebhookActionNode} from "./types/webhook-action-node";
import {DocSearchActionNode} from "./types/doc-search-action-node";
import {AITaskActionNode} from "./types/ai-task-action-node";
import {JourneyActionNode} from "./types/journey-action-node";
import {IntegrationActionNode} from "./types/integration-action-node";
import {LiveAgentActionNode} from "./types/live-agent-action-node";
import {PauseActionNode} from "./types/pause-action-node";
import {ChoiceNode} from "./types/choice-node";
import {AuthNode} from "./types/auth-node";
import {
    COMMON_NODE_TYPE,
    QUESTION_PROMPT_NODE_TYPE,
    MESSAGE_PROMPT_NODE_TYPE,
    IMAGE_PROMPT_NODE_TYPE,
    BUTTONS_PROMPT_NODE_TYPE,
    CARDS_PROMPT_NODE_TYPE,
    AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE,
    WEBHOOK_PROMPT_NODE_TYPE,
    CODEHOOK_PROMPT_NODE_TYPE,
    CODEHOOK_ACTION_NODE_TYPE,
    WEBHOOK_ACTION_NODE_TYPE,
    DOC_SEARCH_ACTION_NODE_TYPE,
    JOURNEY_ACTION_NODE_TYPE,
    INTEGRATION_ACTION_NODE_TYPE,
    LIVE_AGENT_ACTION_NODE_TYPE,
    CHOICE_NODE_TYPE,
    AUTH_NODE_TYPE,
    AI_TASK_ACTION_NODE_TYPE,
    PAUSE_ACTION_NODE_TYPE
} from "../common/const/node-types";
import {WebhookPromptNode} from "./types/webhook-prompt-node";

const nodeTypes = { };
nodeTypes[COMMON_NODE_TYPE] = CommonNode
nodeTypes[QUESTION_PROMPT_NODE_TYPE] = QuestionPromptNode
nodeTypes[MESSAGE_PROMPT_NODE_TYPE] = MessagePromptNode
nodeTypes[IMAGE_PROMPT_NODE_TYPE] = ImageMessageNode
nodeTypes[BUTTONS_PROMPT_NODE_TYPE] = ButtonsPromptNode
nodeTypes[AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE] = AutoCaptureSlotsPromptNode
nodeTypes[CARDS_PROMPT_NODE_TYPE] = CardsPromptNode
nodeTypes[WEBHOOK_PROMPT_NODE_TYPE] = WebhookPromptNode
nodeTypes[CODEHOOK_PROMPT_NODE_TYPE] = CodehookPromptNode
nodeTypes[CODEHOOK_ACTION_NODE_TYPE] = CodehookActionNode
nodeTypes[WEBHOOK_ACTION_NODE_TYPE] = WebhookActionNode
nodeTypes[JOURNEY_ACTION_NODE_TYPE] = JourneyActionNode
nodeTypes[INTEGRATION_ACTION_NODE_TYPE] = IntegrationActionNode
nodeTypes[LIVE_AGENT_ACTION_NODE_TYPE] = LiveAgentActionNode
nodeTypes[DOC_SEARCH_ACTION_NODE_TYPE] = DocSearchActionNode
nodeTypes[AI_TASK_ACTION_NODE_TYPE] = AITaskActionNode
nodeTypes[PAUSE_ACTION_NODE_TYPE] = PauseActionNode
nodeTypes[AUTH_NODE_TYPE] = AuthNode
nodeTypes[CHOICE_NODE_TYPE] = ChoiceNode

export {
    nodeTypes
}