export const START = 'start';
export const QUESTION_PROMPT = 'prompt';
export const ACTION_PROMPT = 'action';
export const MESSAGE_PROMPT = 'message';
export const CHOICE = 'choice';
export const CHOICE_DEFAULT_RULE_NAME = 'default';
export const BUTTON_GO_TO_STEP_RULE_NAME = 'button_go_to_step';

export const CONDITION_OPERATORS = [
    'is',
    'is not',
    'greater than',
    'greater than or equal',
    'less than',
    'less than or equal',
    'contains',
    'not contains',
    'starts with',
    'ends with',
    'is empty',
    'is not empty',
]
