import {useEffect, useState} from "react";
import {DATA_TYPES, DEFAULT_DT} from "../../pages/agent/agent-actions/action-settings/action-param-consts";
import TextBox from "../forms/text-box";
import SelectInput from "../forms/select";
import {Switch} from "@headlessui/react";
import TextArea from "../../components/forms/text-area";
import {Badge} from "flowbite-react";
import ConfirmDialog from "../confirm-dialog/confirm-dialog";
import ParameterModal from "./parameter-modal";

function ParameterRow({ index, name, description, type, required, defaultValue, onUpdate, onDelete }) {
    const [ paramConfig, setParamConfig ] = useState({
        name,
        description,
        type: type || DEFAULT_DT.toLowerCase(),
        required: required,
        defaultValue
    })

    const onParamConfigUpdate = (field, event) => {
        const newActionConfig = {...paramConfig, [field]: event.target.value}
        setParamConfig(newActionConfig);
    };

    const onClickDelete = ()=> {
        onDelete(index);
    }

    useEffect( () => {
        if (paramConfig.name) {
            onUpdate(index, paramConfig)
        }
    }, [paramConfig]);

    return (
        <div className="flex flex-row gap-1">
            <div className="flex-grow grid grid-cols-5 gap-3">
                <TextBox
                    rootClass="col-span-1"
                    type="text"
                    label="Name"
                    placeholder="Enter parameter name"
                    value={paramConfig.name}
                    onChange={event => { onParamConfigUpdate('name', event)}}
                />
                <TextArea
                    rootClass="col-span-1"
                    type="text"
                    label="Description"
                    placeholder="Enter description"
                    value={paramConfig.description}
                    onChange={event => { onParamConfigUpdate('description', event)}}
                    rows={1}
                />
                <TextBox
                    rootClass="col-span-1"
                    type="text"
                    label="Default value"
                    placeholder="Enter default value"
                    value={paramConfig.defaultValue}
                    onChange={event => { onParamConfigUpdate('defaultValue', event)}}
                />
                <div>
                    <div className="flex flex-col text-sm">
                        <SelectInput
                            id="type"
                            name="type"
                            label="Data Type"
                            value={paramConfig.type}
                            onChange={event => { onParamConfigUpdate('type', event)}}
                            optionsComp={
                                <>
                                    {DATA_TYPES.map((dt)=>
                                        <option key={dt} value={dt.toLowerCase()}>{dt}</option>
                                    )}
                                </>}
                        />
                    </div>
                </div>

                <div>
                    <div className="flex flex-col text-sm">
                        <label className="block text-sm font-medium text-gray-500">
                            Required
                        </label>

                        <Switch
                            checked={paramConfig.required}
                            onChange={state => {
                                onParamConfigUpdate('required', { target: { value: state } })
                            }}
                            className={`${
                                paramConfig.required ? 'bg-blue-600' : 'bg-gray-200'
                            } mt-2 relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                            <span className="sr-only">Enable notifications</span>
                            <span
                                className={`${
                                    paramConfig.required ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>

                    </div>
                </div>

            </div>

            <div className="flex flex-row justify-start items-center gap-2 pt-4 underline text-sm text-gray-700 font-semibold cursor-pointer">
                <svg onClick={onClickDelete} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-600 hover:text-red-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>

        </div>

    )
}


function ParameterCard({ index, name, description, type, required, defaultValue, onUpdate, onDelete }) {
    const [ paramConfig, setParamConfig ] = useState({
        name,
        description,
        type: type || DEFAULT_DT.toLowerCase(),
        required: required,
        defaultValue
    })

    const [ openParameterDialog, setOpenParameterDialog] = useState(false);
    const [ editParameterData, setEditParameterData ] = useState({});

    const [deletingData, setDeletingData] = useState();
    const [openDeleteConfirmDialog, setDeleteRevokeConfirmDialog] = useState(false);

    useEffect( () => {
        if (paramConfig.name) {
            onUpdate(index, paramConfig)
        }
    }, [paramConfig]);

    const handleSelectDelete = () => {
        setDeletingData(index);
        setDeleteRevokeConfirmDialog(true);
    }

    const onClickDelete = ()=> {
        onDelete(index);
    }

    const handleSelectParameterData = () => {
        setEditParameterData(paramConfig);
        setOpenParameterDialog(true);
    }

    const onCompletionParamConfigUpdate = newActionConfig => {
        if (newActionConfig) {
            setParamConfig(newActionConfig);
        }
        setOpenParameterDialog(false)
    }

    return (
        <Badge size="sm" key={index} color="success" className="cursor-pointer">
            {openDeleteConfirmDialog &&
                <ConfirmDialog
                    key="context"
                    open={openDeleteConfirmDialog}
                    onCompletion={onClickDelete}
                    message="Are you sure Do you want to delete this Slot ?"
                />
            }

            {openParameterDialog &&
                <ParameterModal
                    {...paramConfig}
                    key={paramConfig.name}
                    onCompletion={onCompletionParamConfigUpdate}
                />
            }
            <div className="flex flex-row items-center gap-2">
                <p className="underline" onClick={
                    () => handleSelectParameterData()}>
                    {paramConfig.name}
                </p>
                <svg onClick={() => handleSelectDelete()}
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor"
                     className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-900">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </div>

        </Badge>
    )
}

export default function ActionParameters({className, actionConfig, setActionConfig}) {
    const onParameterAdd = () => {
        const parameters = actionConfig.parameters || [];
        parameters.push({ name: `Param ${parameters.length + 1}` })
        setActionConfig({...actionConfig, parameters });
    };

    const onParameterUpdate = (index, paramConfig) => {
        const parameters = actionConfig.parameters || [];
        parameters[index] = paramConfig;
        setActionConfig({...actionConfig, parameters });
    };

    const onParameterDelete = index => {
        let parameters = [];
        for (let parameterIndex in actionConfig.parameters) {
            if (+parameterIndex !== +index) {
                parameters.push(actionConfig.parameters[parameterIndex])
            }
        }
        setActionConfig({...actionConfig, parameters });
    };

    return (
        <div className="">
            <div className="pt-4 flex flex-col">
                <div className="flex flex-row justify-between items-center pb-2">
                    <label htmlFor="actionType" className="block text-sm font-medium text-gray-900">
                        Action Parameters
                    </label>
                    <button type="button"
                            onClick={onParameterAdd}
                            className="rounded bg-white py-1 px-2 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Add New
                    </button>
                </div>

                <div className="flex flex-col gap-2">
                    {actionConfig.parameters.length === 0?
                        <ParameterRow index={0} onUpdate={onParameterUpdate} onDelete={onParameterDelete}/>
                        :<>{actionConfig.parameters.map((paramConfig, index)=>
                            <ParameterRow index={index} key={index} onUpdate={onParameterUpdate} onDelete={onParameterDelete} {...paramConfig} />)}</>}


                </div>

                {/*<div className="flex flex-wrap gap-2">*/}
                {/*    {actionConfig.parameters.map((paramConfig, index)=>*/}
                {/*        <ParameterCard index={index} key={index} onUpdate={onParameterUpdate} onDelete={onParameterDelete} {...paramConfig} />)}*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

