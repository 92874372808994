import { useState, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import { Auth } from "aws-amplify";
import Message from "../../../components/message/message-comp";
import logo from '../../../assets/images/logo/main-color.png';
import { useQuery } from "../../../helpers/hooks/common-hook";
import FormLoader from "../../../components/form-loader/form-loader";
import TextBox from "../../../components/forms/text-box";
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';

function SigninPage() {
  const navigate = useNavigate();
  const query = useQuery();
  const signupSuccess = query.get('sign_up');
  const signupAccount = query.get('account');
  const emailQuery = query.get('email');
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [auth, setAuth] = useState({
    username: emailQuery || '',
    password: '',
    rememberMe: false
  });

  const [error, setError] = useState({
    username: false,
    password: false
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: ''
  });

  const handleChange = useCallback(event => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    if (targetName === 'username') {
      auth.username = targetValue;
    } else if (targetName === 'password') {
      auth.password = targetValue;
    }

    if (triedSubmit) {
      validateForm()
    }

    setAuth(Object.assign({}, auth));
  });

  const validateForm = () => {
    let valid = true;
    if (auth.username === '') {
      error.username = true;
      valid = false;
    } else {
      error.username = false;
    }

    if (auth.password === '') {
      error.password = true;
      valid = false;
    } else {
      error.password = false;
    }

    setError(Object.assign({}, error))
    return valid;
  }

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }))
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setTriedSubmit(true);
    if (!formState.processing && validateForm()) {
      updateFormState(true, false, false);
      try {
        await Auth.signIn(auth.username, auth.password);
        updateFormState(false, true, false);
        navigate('/');
      } catch (err) {
        console.error("signin failed cause", err);
        updateFormState(false, false, true, err.message);
      }
    }
  });

  return (
    <div className="h-screen bg-slate-100 flex justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <Link to="/">
            <img
              className="mx-auto h-16 w-auto"
              src={logo}
              alt="Workflow"
            />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">Sign in to your account</h2>
        </div>

        {(signupSuccess && !auth.username) &&
          <div className="flex justify-center items-center">
            <Message
              title="You have signed up successfully!"
              text={<span>Please check your <span className="font-extrabold underline">{signupAccount}</span> email to verify account</span>}
              type="success" timeout={0}>
            </Message>
          </div>
        }

        {formState.failed &&
          <div className="flex justify-center items-center">
            <Message text={formState.message ? formState.message : 'Signin failed. Please check your credentials'} type="error" />
          </div>
        }

        {formState.processing &&
          <div className="flex justify-center items-center">
            <FormLoader />
          </div>
        }

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white border border-slate-200 py-6 px-6 shadow-lg rounded-lg">
            <form className="space-y-4" onSubmit={handleSubmit} method="POST">
              <TextBox
                type="email"
                name="username"
                id="username"
                label="Email"
                value={auth.username}
                placeholder="Enter your email address"
                onChange={handleChange}
                error={error.username}
                autocomplete={true}
              />
              <div className='relative'>
                <TextBox
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  label="Password"
                  value={auth.password}
                  placeholder="Enter password"
                  onChange={handleChange}
                  error={error.password}
                />
                <div className="absolute right-3 bottom-2">
                  {showPassword ? <HiOutlineEye size={20} onClick={() => setShowPassword(false)} /> : <HiOutlineEyeOff size={20} onClick={() => setShowPassword(true)} />}
                </div>
              </div>
              <div className="text-sm">
                <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </Link>
              </div>
              <div className="pt-3">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
              <div className="pt-4">
                Don't have an account?
                {' '}
                <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Let's Signup
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SigninPage;
