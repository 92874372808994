import React from "react";
import PropTypes from "prop-types";

const ActionMenuTrigger = ({ children, onTrigger }) => {
  return (
    <button
      onClick={onTrigger}
      className="w-7 h-7 flex items-center justify-center bg-transparent text-gray-400 hover:text-gray-600 dark:hover:text-gray-50"
    >
      {children}
    </button>
  );
};

ActionMenuTrigger.propTypes = {
  children: PropTypes.element,
  onTrigger: PropTypes.func,
};

export default ActionMenuTrigger;
