import {useEffect, useState, useRef} from 'react';
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import Loader from '../../../../components/form-loader/form-loader'
import Button from "../../../../components/button/button";
import { save, getData } from "../../../../services/knowledge/ds-connector/text-connector";
import EditorHeader from "./editor-header";
import PageContainer from "../../../../components/page-container";
import { createReactEditorJS } from 'react-editor-js'

import './index.css';

const DATA_SOURCE_ID = 'text-editor'  // currently always have one text editor data source

export default function TextDSConnector() {
    const editorCore = useRef(null)
    const ReactEditorJS = createReactEditorJS()
    const {appId} = useAppInfo();
    const [savedEditorData, setSavedEditorData] = useState({});

    const [textEditorSubmitTracker, setTextEditorSubmitTracker] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: '',
    });

    const updateTextEditorSubmitTracker = ({processing, success, failed, message, errorMessage}) => {
        setTextEditorSubmitTracker(s => ({
            processing, success, failed, message, errorMessage
        }));
    };

    const fetchTextEditorData = async () => {
        const savedData = await getData(appId, DATA_SOURCE_ID);
        console.log("editorBlocks", savedData)
        setSavedEditorData(savedData);
    }

    useEffect( () => {
        fetchTextEditorData()
    }, []);

    const handleSave = async (event) => {
        event.preventDefault();
        await handleSubmit(true)
    }

    const handlePublish = async (event) => {
        event.preventDefault();
        await handleSubmit(false)
    }

    const handleSubmit = async (draft=false) => {
        if (!textEditorSubmitTracker.processing) {
            const editorBlocks = await editorCore.current.save();
            updateTextEditorSubmitTracker({
                processing: true,
                success: false,
                failed: false
            });
            const request = {
                dataSourceId: DATA_SOURCE_ID,
                editorBlocks: editorBlocks.blocks,
                draft
            }
            await save(appId, request);
            await fetchTextEditorData();
            updateTextEditorSubmitTracker({
                processing: false,
                success: false,
            })
        }
    }

    return (
        <PageContainer title="Knowledge"
                       subtitle="Add your contents here"
                       headerAction={<EditorHeader/>}>
            <div className="flex flex-col ">
                <div className="flex-grow flex max-height-wrapper">
                    <div className="w-full flex flex-col">
                        <div onSubmit={handleSave}
                             className='max-height-wrapper flex flex-col h-full bg-white border-gray-100 border-2 rounded-lg'>
                            <div className="flex-1 max-height-wrapper h-full overflow-y-auto p-4">

                                {!savedEditorData.editorBlocks ? (<div className="h-screen"><span className="text-gray-500 font-bold">Loading...</span></div>)
                                    :
                                    (<ReactEditorJS
                                        autofocus={true}
                                        onInitialize={instance=>editorCore.current = instance}
                                        holder="textEditor"
                                        defaultValue={{blocks: savedEditorData.editorBlocks}}
                                    >
                                        <div id="textEditor" className="px-12 h-screen" />
                                    </ReactEditorJS>)
                                }

                            </div>


                            <div className='h-12 flex flex-row px-4 mb-2 items-center gap-4'>

                                <div>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        text="Publish"
                                        isIndicated={savedEditorData.draft}
                                        onClick={handlePublish}/>
                                </div>
                                {textEditorSubmitTracker.processing &&
                                    <Loader/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    )
}

