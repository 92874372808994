import {memo, useContext, useEffect, useRef, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";
import BlockChildNode from "./BlockChildNode";

import "./main_workflow_node.scss";
import Toolbar from "./Toolbar";

const WorkflowNode = ({id, data}) => {

    const workflowContext = useContext(WorkflowCanvasContext);

    const contextMenuRef = useRef(null);
    const [isInput, setIsInput] = useState(false);
    const [blockTitle, setBlockTitle] = useState(data.label);

    const [showToolbar, setShowToolbar] = useState(false);

    useEffect(() => {
        // Add event listener when the component is mounted
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Clean up event listener when the component is unmounted
            document.removeEventListener("click", handleClickOutside);
            document.addEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOutside = (event) => {
        if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            setShowToolbar(false);
            workflowContext.setDeletableChildId(null)
        }
    };

    const handleToolBarVisible = e => {
        e.preventDefault();
        setShowToolbar(true);
    }

    const handleOpenDrawer = () => {
        if (data?.openNodeDrawer) {
            data.openNodeDrawer(id);
        }
    };

    const handleConnectedStyle = {
        width: "15px",
        height: "40px",
        background: "transparent",
        borderRadius: '0px',
        position: "absolute",
        border: "none",
    };

    const nodeContentStyle = {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "15px",
    };

    const handleDelete = (id) => {
        workflowContext.handleDelete(id)
        setShowToolbar(false)
    }

    const handleBlockTitle = () => {
        if (blockTitle) {
            // const updatedData = workflowContext.nodes.find(item => item.id === id);
            // updatedData.data.label = blockTitle;
            // workflowContext.setNodes((prev) => [...prev, updatedData])
            const newNodes = []
            for (let node of workflowContext.nodes) {
                if (node.id === id) {
                    node.data.label = blockTitle;
                }
                newNodes.push(node)
            }
            workflowContext.setNodes((prev) => newNodes)
        }
        setIsInput(false)
    }

    const isPreview = workflowContext.isPreview;

    return (
        <div style={{position: "relative"}}>
            <div
                className="workflow-node bg-slate-200 rounded-lg"
                onClick={() => setShowToolbar(false)}
                onContextMenu={handleToolBarVisible}
                onDoubleClick={handleOpenDrawer}
                // style={{backgroundColor: "#DDE4EA", borderRadius: "4px"}}

            >

                {/*<Handle*/}
                {/*    type="target"*/}
                {/*    position={Position.Left}*/}
                {/*    style={handleConnectedStyle}*/}
                {/*    id={`target-${id}`}*/}
                {/*/>*/}

                <Handle style={handleConnectedStyle} type="target" position={Position.Top} id={`target-${id}-top`}
                        className="connector top"/>

                <Handle style={handleConnectedStyle} type="target" position={Position.Bottom} id={`target-${id}-bottom`}
                        className="connector bottom"/>

                {/* Left Connectors */}
                <Handle style={handleConnectedStyle} type="target" position={Position.Left} id={`target-${id}-left-top`}
                        className="connector left-top"/>
                <Handle style={handleConnectedStyle} type="target" position={Position.Left} id={`target-${id}-left-bottom`}
                        className="connector left-bottom"/>

                {/* Right Connectors */}
                <Handle style={handleConnectedStyle} type="target" position={Position.Right} id={`target-${id}-right-top`}
                        className="connector right-top"/>
                <Handle style={handleConnectedStyle} type="target" position={Position.Right} id={`target-${id}-right-bottom`}
                        className="connector right-bottom"/>


                <div style={nodeContentStyle}>
                    {(!isPreview && isInput) ?
                        <input autoFocus type="text"
                               onBlur={handleBlockTitle}
                               className="text-gray-500 font-semibold p-1.5 rounded-sm text-sm mb-[6.5px] bg-[#d2d8df] border-none outline-none focus:outline-none focus:ring-0"
                               value={blockTitle}
                               onChange={(e) => setBlockTitle(e.target.value)}
                        /> :
                        <div style={{display: "flex", justifyContent: "start"}}
                             className="custom-drag-handle"
                        >
                            <p
                            //     style={{
                            //     marginTop: 0,
                            //     marginBottom: "6px",
                            //     color: "#7B8D9F",
                            //     fontWeight: "600",
                            //     padding: "4px 6px",
                            //     borderRadius: "2px",
                            //     fontSize: "14px",
                            //
                            // }}
                               className={"w-full block-title text-gray-500 font-semibold p-1.5 rounded-sm text-sm mb-[6.5px] border-none outline-none"}
                               onClick={() => setIsInput(true)}
                            >
                                {blockTitle}
                            </p>
                        </div>
                    }


                    <BlockChildNode
                        blockChildren={data.children}
                        id={id}
                        setDeletableChildId={workflowContext.setDeletableChildId}
                        setShowToolbar={setShowToolbar}
                    />

                </div>
            </div>

            {showToolbar &&
                <Toolbar
                    contextMenuRef={contextMenuRef}
                    id={id}
                    handleDelete={handleDelete}/>
            }
        </div>
    );
};

export default memo(WorkflowNode);
