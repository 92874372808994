import React, { useState } from 'react';
import PropTypes from 'prop-types';
import blackBoxImage from './../../../assets/images/tooltip/img.png';
import {CHOICE_NODE_TYPE} from "../common/const/node-types";

export const PopoverButton = ({ option, eventTrigger, onDragStart }) => {
  const { id, title, icon: Icon, nodes = [], event } = option;
  const [showBlackBox, setShowBlackBox] = useState(false);
  const [showNodes, setShowNodes] = useState(false);

  // const onDragStart = (event, nodeType) => {
  //   event.dataTransfer.setData('application/reactflow', nodeType);
  //   event.dataTransfer.effectAllowed = 'move';
  // };

  return (
      <div
          className="relative group"
          onMouseEnter={() => {
            setShowNodes(true);
            setShowBlackBox(true);
          }}
          onMouseLeave={() => {
            setShowNodes(false);
            setShowBlackBox(false);
          }}
      >
        <button
            id={id}
            type="button"
            className="
          p-3 py-2 flex flex-col items-center justify-center w-16 h-auto
          hover:cursor-pointer active:scale-90 transition hover:bg-white dark:hover:bg-gray-800"
            onClick={()=>eventTrigger(event)}
        >
        <span className="w-8 h-8 inline-flex items-center justify-center flex-shrink-0">
          <Icon className="w-6 h-6 text-gray-700 dark:text-gray-100 group-hover:text-indigo-600 transition" />
        </span>
          <span className="text-xs text-center text-gray-600 dark:text-gray-300 tracking-wide group-hover:text-indigo-600 ">
          {title}
        </span>
        </button>

        {/* Nodes Buttons */}
        {nodes?.length > 0 && (
            <div className="absolute top-full left-0 z-50">
              <ul
                  id={`${id}-node`}
                  aria-label="node list"
                  className="
              hidden group-hover:flex flex-col py-3 rounded-b-lg
              border border-t-transparent bg-white w-64 overflow-hidden shadow-[0_30px_60px_-12px_rgba(0,0,0,0.1)]
              dark:border-gray-700 dark:bg-gray-800
            "
              >
                {/* Render other list items */}
                {nodes.map(({ id: nodeType, text, icon: NodeIcon, defaultConfig }, index) => (
                    <li
                        key={index}
                        className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-900 hover:cursor-grab select-none w-full transition duration-300 active:ring-2 ring-inset active:ring-indigo-300 active:scale-90 active:rounded-md"
                        onDragStart={(event) => onDragStart(event, {
                            label: text,
                            nodeType,
                            stateConfig: defaultConfig
                        })}
                        draggable
                    >
                      <div className="flex items-center gap-3 text-gray-900 dark:text-gray-300">
                        <NodeIcon className="w-5 h-5 flex-shrink-0" />
                        <span className="truncate inline-block">{text}</span>
                      </div>
                    </li>
                ))}
              </ul>
              {/* Black color box with image and description below the list */}
              {showBlackBox && (
                  <div className=" mt-1 bg-gradient-to-br from-indigo-700 to-indigo-400  p-4 rounded-lg text-white flex flex-col items-center shadow-[0_30px_60px_-12px_rgba(0,0,0,0.1)] dark:border-gray-700 dark:bg-gray-800">
                    <p className="text-sm mb-1 ">Click & drag into the canvas to add a step</p>
                    {/*<img src={blackBoxImage} alt="Black Box" className="w-50 h-50 object-cover" />*/}
                  </div>
              )}
            </div>
        )}
      </div>
  );
};

PopoverButton.propTypes = {
  option: PropTypes.object,
};
