import {useEffect, useRef, useState} from 'react';
import {
    createBotMessagePayload,
    createSelfMessagePayload,
} from './utils/message-utils';
import ConvMessageContainer from './components/messenger-container';

import Amplify from '@aws-amplify/core'
import * as realTimeApi from './real-time-api-config'

import {Switch} from '@headlessui/react'
import {getMessages, setHandleBy} from "../../../services/session";
import {useAppContext} from "../../../helpers/hooks/common-hook";
import {createUniqueId} from "../../../helpers/utils/object-util";
import Loader from './components/loader';

Amplify.configure(realTimeApi.config)

const HANDLE_BY_HUMAN = 'human'
const HANDLE_BY_AI = 'ai'
const SENT_BY_BOT = 'bot'
const SENT_BY_USER = 'user'
const CHANNEL_WEB = 'web'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function isEnabledAIHandle(session) {
    return session.handleBy !== HANDLE_BY_HUMAN
}

function Page({session: sessionProp, accessKey, chatBotSetting }) {
    const { appInfo } = useAppContext();
    const { appId } = appInfo;

    const [session, setSession] = useState({sessionId: null, refId: null});
    const [question, setQuestion] = useState();
    const [botReply, setBotReply] = useState();
    const [userReply, setUserReply] = useState();

    const [messages, setMessages] = useState([]);

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: '',
    });

    const [error, setError] = useState({
        question: false,
    });

    const fetchMessages = async () => {
        if (session.sessionId) {
            setMessages([])
            updateFetchMessagesState(true, false)
            const response = await getMessages(appInfo.appId, session.sessionId);
            const _messages = []
            for (const resp of response.data) {
                if (resp.sentBy === 'bot') {
                    _messages.push(createBotMessagePayload(resp.message, resp.createdAt))
                } else {
                    _messages.push(createSelfMessagePayload(resp.message, resp.createdAt))
                }
            }
            setMessages(_messages)
            updateFetchMessagesState(false, true)
        }
    }

    useEffect( () => {
        if (sessionProp) {
            setSession(sessionProp);
        }
    }, [sessionProp]);

    useEffect(() => {
        let subscriptionBotMessage;
        let subscriptionUserMessage;

        if (session.sessionId) {
            fetchMessages();
            subscriptionBotMessage = realTimeApi.subscribeBotMessage(accessKey, appId, session.sessionId, ({data}) => {
                data = JSON.parse(data);
                setBotReply(data);
            })

            subscriptionUserMessage = realTimeApi.subscribeUserMessage(accessKey, appId, session.sessionId, ({data}) => {
                data = JSON.parse(data);
                setUserReply(data);
            })
        }
        return () => {
            if (subscriptionBotMessage) {
                subscriptionBotMessage.unsubscribe()
            }

            if (subscriptionUserMessage) {
                subscriptionUserMessage.unsubscribe()
            }
        }
    }, [session.sessionId])

    useEffect(() => {
        if (botReply) {
            appendMessage(createBotMessagePayload(botReply.message));
        }
    }, [botReply]);

    useEffect(() => {
        if (userReply) {
            appendMessage(createSelfMessagePayload(userReply.message));
        }
    }, [userReply]);

    const appendMessage = (message) => {
        const newMessages = Object.assign([], messages);
        newMessages.push(message);
        setMessages(newMessages);
    };

    const validateForm = () => {
        let valid = true;
        if (!question) {
            error.question = true;
            valid = false;
        } else {
            error.question = false;
        }

        setError(Object.assign({}, error));
        return valid;
    };

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(
            Object.assign({}, {processing, success, failed, errorMessage})
        );
    };

    const onChangeQuestion = (event) => {
        setQuestion(event.target.value);
    };

    const handleOnAskSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);

            console.log("handleOnAskSubmit !!!!")
            const dataRequest = {
                appId: appInfo.appId,
                sessionId: session.sessionId,
                messageId: createUniqueId(),
                type: "FULFILLMENT",
                message: {
                    type: 'TEXT',
                    content: question,
                },
                channel: CHANNEL_WEB,
                handleBy: HANDLE_BY_HUMAN,
                sentBy: SENT_BY_BOT,
            };

            await realTimeApi.sendBotMessage(accessKey, appId, session.sessionId, JSON.stringify(dataRequest))

            updateFormState(false, true, false);
            setQuestion('');
        }
    };

    const handleOnChangeHandleBy = async checked => {
        const handleBy = checked? HANDLE_BY_AI: HANDLE_BY_HUMAN
        updateChangeAIHandleState(true)
        await setHandleBy(appInfo.appId, session.sessionId, handleBy)
        updateChangeAIHandleState(false, true)
        setSession({...session, handleBy})
    }

    const [fetchMessagesState, setFetchMessagesState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateFetchMessagesState = (processing, success, failed, errorMessage) => {
        setFetchMessagesState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const [changeAIHandleState, setChangeAIHandleState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateChangeAIHandleState = (processing, success, failed, errorMessage) => {
        setChangeAIHandleState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    return (
        <div className="col-span-3 max-height-wrapper overflow-y-auto bg-gray-200 px-4 py-4">
            <div className='flex flex-col h-full bg-white border-gray-200 border-2 rounded-xl rounded-b-xl'>

                {/* Header */}
                <div className='h-14 flex items-center justify-between shadow-md'>
                    <div className='px-4 flex-shrink-0'>
                        <label
                            htmlFor='questionTxt'
                            className='block text-xl font-medium text-gray-700'
                        >
                            Session - {session.refId}
                        </label>
                    </div>

                    <div className='px-4 flex-shrink-0'>
                        {changeAIHandleState.processing?
                            <Loader/>
                            :
                            <Switch.Group as="div" className="flex items-center gap-2">
                                <Switch.Label as="span" className="ml-3">
                                    <span className="text-sm font-medium text-gray-500">AI Bot</span>
                                </Switch.Label>
                                <Switch
                                    checked={isEnabledAIHandle(session)}
                                    onChange={handleOnChangeHandleBy}
                                    className={classNames(
                                        isEnabledAIHandle(session) ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                    )}
                                >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        isEnabledAIHandle(session) ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                                </Switch>
                            </Switch.Group>
                        }

                    </div>
                </div>

                {messages.length > 0 &&
                    <div className='flex-1 max-height-wrapper overflow-y-auto my-2 p-4'>
                        {messages.map((message, mi) => {
                            return <ConvMessageContainer key={mi} {...message} />;
                        })}
                    </div>
                }

                {fetchMessagesState.processing &&
                    <div className='flex-1 max-height-wrapper overflow-y-auto flex flex-row justify-center items-center'>
                        <Loader/>
                    </div>
                }

                {/* Send  Button */}
                <div className='mt-auto'>
                    <form
                        className='flex flex-auto flex-col border-t'
                        onSubmit={handleOnAskSubmit}
                    >
          <textarea
              rows={2}
              name='inputText'
              className='w-full p-3 outline-none resize-none'
              placeholder='Your Answer...'
              value={question}
              onChange={onChangeQuestion}
              maxLength='90'
          />

                        <button
                            type='submit'
                            className='
            w-full py-3 text-white border-transparent shadow-sm
            text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2
            bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-600'
                        >
                            {formState.processing ? (
                                <div className='flex flex-row justify-center items-center'>
                                    <svg
                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                    >
                                        <circle
                                            className='opacity-25'
                                            cx='12'
                                            cy='12'
                                            r='10'
                                            stroke='currentColor'
                                            stroke-width='4'
                                        ></circle>
                                        <path
                                            className='opacity-75'
                                            fill='currentColor'
                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                        ></path>
                                    </svg>
                                    <p>Processing</p>
                                </div>
                            ) : (
                                <>Send Message</>
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Page;
