import { post } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

export async function analyticsQuery(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/analytics/query`, request);
    } catch (err) {
        console.debug('analyticsQuery failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}