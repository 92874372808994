import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CodehookAction from "./codehook-action";
import WorkflowAction from "./workflow-action";
import WebhookAction from "./webhook-action";
import DocumentAction from "./document-action";
import FineTunedKnowledgeBaseAction from "./fine-tuned-knowledge-base-action";
import ShopifyAction from "./shopify-action";
import Loader from "../../../../components/form-loader/form-loader";
import Message from "../../../../components/toast-message";
import {InformationCircleIcon} from "@heroicons/react/solid";
import {Link, useNavigate} from "react-router-dom";
import PageContainer from "../../../../components/page-container";
import {
    DEFAULT_ACTION_FACTORY,
    ACTION_TYPES_DISPLAY,
    CODEHOOK_ACTION_TYPE,
    WORKFLOW_ACTION_TYPE,
    DOCUMENT_ACTION_TYPE,
    FINE_TUNED_KNOWLEDGE_ACTION_TYPE,
    LIVE_AGENT_CONNECT_ACTION_TYPE,
    SHOPIFY_ACTION_TYPE,
    WEBHOOK_ACTION_TYPE,
    WEBHOOK_DEFAULT_ACTION_CONFIG, MESSAGE_ACTION_TYPE
} from "./common-consts";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {useParams} from "react-router";
import {getAgentAction, saveAgentActionSettings} from "../../../../services/prompt-ai";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import TextArea from "../../../../components/forms/text-area";
import Button from "../../../../components/button/button";
import {Button as FlowbiteButton} from "flowbite-react";
import SwitchComponent from "../../../../components/switch";
import LiveAgentConnectAction from "./live-agent-connect-action";
import {validateActionName} from "../../../../helpers/utils/text-util";
import FormLoader from "../../../../components/form-loader/form-loader";
import ConfirmDialog from "../../../../components/confirm-dialog/confirm-dialog";
import { FaRegDotCircle as WorkflowIcon } from "react-icons/fa";
import MessageAction from "./message-action";

export default function ActionSettings() {
    const { appId } = useAppInfo();
    const { actionId } = useParams();
    const navigate = useNavigate();

    const [ actionConfig, setActionConfig ] = useState(WEBHOOK_DEFAULT_ACTION_CONFIG)

    const [ error, setError ] = useState({
        name: false,
        description: false,
        actionType: false,
        resourceId: false,
    });

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [openRestConfirmDialog, setResetConfirmDialog] = useState();

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoading(Object.assign({}, {processing, success, failed, message}))
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    async function fetchActionSettings() {
        updateDataLoadingState(true, false, false);
        const resp = await getAgentAction(appId, actionId);
        console.log("fetchChatBotSettings", resp)
        if (resp) {
            setActionConfig(resp);
        } else {
            setActionConfig(WEBHOOK_DEFAULT_ACTION_CONFIG);
        }
        updateDataLoadingState(false, true, false);
    }

    useEffect( () => {
        if (actionId) {
            fetchActionSettings()
        }
    }, [actionId]);

    useEffect(() => {
        if (!actionId && formState.success && (formState.message === 'Successfully saved action')) {
            setTimeout(() => {
                navigate(`/app/${appId}/agent/actions`);
            }, 1500)
        }
    }, [formState.success]);

    const doValidation = () => {
        const newError = {...error};
        console.log("doValidation", actionConfig)
        newError.name = !actionConfig.name || !validateActionName(actionConfig.name);
        newError.description = !actionConfig.description;
        newError.actionType = !actionConfig.actionType;
        newError.resourceId = !actionConfig.resourceId;
        setError(newError);
        return !(newError.name || newError.description || newError.actionType || newError.resourceId)
    };

    const handleOnSave = async event => {
        event.preventDefault()
        if (doValidation()) {
            updateFormState(true, false, false);
            const { status } = await saveAgentActionSettings(appId, actionConfig);
            if (status === 200) {
                updateFormState(false, true, false, 'Successfully saved action');
                return true;
            } else {
                updateFormState(false, false, true, 'Unable to save action settings');
                return false
            }
        } else {
            return false
        }
    };

    const onActionTypeUpdate = (event) => {
        const { name, description } = actionConfig;
        const newDefaultConfig = DEFAULT_ACTION_FACTORY[event.target.value];
        setActionConfig({...newDefaultConfig, name, description });
    };

    const onActionConfigUpdate = (field, event) => {
        setActionConfig({...actionConfig, [field]: event.target.value});
    };

    const onOpenWorkFlow = async event => {
        const status = await handleOnSave(event)
        if (status && actionConfig.name && actionConfig.actionType) {
            navigate(`/app/${appId}/agent/actions/settings/${actionConfig.name}/workflow`)
        }
    };

    const onReset = async event => {
        setResetConfirmDialog(true)
    };

    const handleConfirmRest = async confirmed => {
        if (confirmed) {
            setActionConfig(WEBHOOK_DEFAULT_ACTION_CONFIG)
        }
        setResetConfirmDialog(false)
    }

    return (
        <>
            {dataLoadingState.processing?
                <FormLoader fullScreen={true} relative={true} text="Loading..."/>
                :
                <PageContainer
                    title={actionId? `Edit Action ( ${actionId} )`: "New Action"}
                    breadcrumbNav={[
                        {name: 'Actions', href: `/app/${appId}/agent/actions`},
                        {name: 'Settings', href: '#'},
                    ]}
                    headerAction={<div className="flex flex-row gap-2">

                        <Button
                            color="default"
                            type="button"
                            onClick={onReset}
                            text="Reset"
                        />

                        {actionConfig.actionType === WORKFLOW_ACTION_TYPE &&
                            <FlowbiteButton
                                onClick={onOpenWorkFlow}
                                // gradientDuoTone="purpleToBlue"
                                color="light"
                            >
                                <p>
                                    Open Workflow Editor
                                </p>
                                <WorkflowIcon className="ml-2 h-3 w-3 text-gray-500" />
                            </FlowbiteButton>
                        }

                        {formState.processing ?
                            <Button
                                type="submit"
                                text="Save Action"
                                loading={formState.processing}
                            />
                            :
                            <Button
                                type="submit"
                                text="Save Action"
                                onClick={handleOnSave}
                            />
                        }

                    </div>}
                >
                    <form className="pt-4">

                        {(formState.failed && formState.message) &&
                            <Message text={formState.message}
                                     type="failure"/>}

                        {(formState.success && formState.message) &&
                            <Message text={formState.message}
                                     type="success"/>}

                        {dataLoadingState.processing &&
                            <FormLoader fullScreen={true} text="Loading..."/>
                        }

                        {openRestConfirmDialog &&
                            <ConfirmDialog
                                appId={appId}
                                open={openRestConfirmDialog}
                                onCompletion={handleConfirmRest}
                                title="Reset Confirmation"
                                message="Are you sure. Do you want to reset settings ?"
                            />
                        }

                        <div className="flex flex-col items-left gap-2">
                            <SelectInput
                                rootClass="pt-4 w-1/2"
                                id="actionType"
                                label="Action type"
                                value={actionConfig.actionType}
                                onChange={onActionTypeUpdate}
                                optionsComp={<>
                                    <option value="" disabled={true}>Select Action Type</option>
                                    {Object.keys(ACTION_TYPES_DISPLAY).map((actionType)=>
                                        <option key={actionType} value={actionType}>{ACTION_TYPES_DISPLAY[actionType]}</option>
                                    )}
                                </>}
                                required
                            />

                            <TextBox
                                rootClass="pt-4 w-1/2"
                                type="text"
                                label="Name*"
                                subLabel="Enter action name"
                                placeholder="my-action"
                                value={actionConfig.name}
                                onChange={event => { onActionConfigUpdate('name', event)}}
                                error={error.name}
                                errorMessage="Name must only contain letters, numbers, underscores (_), hyphens (-). no any spaces"
                            />
                        </div>


                        <TextArea
                            rootClass="pt-4 w-1/2"
                            rows={5}
                            label="Description*"
                            subLabel="Describe to the AI the purpose of the action"
                            placeholder="Eg: useful for cancel order"
                            value={actionConfig.description}
                            onChange={event => { onActionConfigUpdate('description', event)}}
                            error={error.description}
                        />

                        {/*{actionConfig.preInstructionDisplay &&*/}
                        {/*    <TextArea*/}
                        {/*        rootClass="pt-4"*/}
                        {/*        rows={5}*/}
                        {/*        label="Pre-Instruction"*/}
                        {/*        subLabel="Instructions to control the behavior of the agent prior to executing the action."*/}
                        {/*        placeholder="Eg: get confirmation from the user before placing the order"*/}
                        {/*        value={actionConfig.preInstruction}*/}
                        {/*        onChange={event => { onActionConfigUpdate('preInstruction', event)}}*/}
                        {/*    />*/}
                        {/*}*/}

                        {actionConfig.authOptionDisplay &&
                            <div className="pt-4 w-1/2">
                                <SwitchComponent
                                    title="Auth Required"
                                    subTitle="User needs to login before perform the task"
                                    enabled={actionConfig.authRequired}
                                    onChange={state => {
                                        onActionConfigUpdate('authRequired', { target: { value: state } })
                                    }}
                                />
                            </div>
                        }

                        {actionConfig.actionType === WEBHOOK_ACTION_TYPE &&
                            <WebhookAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === CODEHOOK_ACTION_TYPE &&
                            <CodehookAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === WORKFLOW_ACTION_TYPE &&
                            <WorkflowAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                handleOnSave={handleOnSave}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === DOCUMENT_ACTION_TYPE &&
                            <DocumentAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === FINE_TUNED_KNOWLEDGE_ACTION_TYPE &&
                            <FineTunedKnowledgeBaseAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === SHOPIFY_ACTION_TYPE &&
                            <ShopifyAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === LIVE_AGENT_CONNECT_ACTION_TYPE &&
                            <LiveAgentConnectAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                error={error}
                            />
                        }

                        {actionConfig.actionType === MESSAGE_ACTION_TYPE &&
                            <MessageAction
                                config={{
                                    appId,
                                }}
                                actionConfig={actionConfig}
                                setActionConfig={setActionConfig}
                                onActionConfigUpdate={onActionConfigUpdate}
                                handleOnSave={handleOnSave}
                                error={error}
                            />
                        }

                    </form>
                </PageContainer>
            }
        </>
    )
}
