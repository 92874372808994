import {del, get, post, put} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function getFineTuneState(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/fine-tune/state`);
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('journey get failed cause', err.message, err.code);
        return {};
    }
}

export async function setFineTuneState(appId, request) {
    try {
        const { status } =  await post(`${APPS_ENDPOINT}/${appId}/model/fine-tune/state`, request);
        if (status !== 200) {
            return null;
        }
        return status;
    } catch (err) {
        console.debug('journey create failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function getDataSets(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/fine-tune/training/data-set`);
        if (status !== 200) {
            return {
                data: []
            };
        }
        return response;
    } catch (err) {
        console.debug('journey get failed cause', err.message, err.code);
        return {
            data: []
        };
    }
}

export async function saveDataSetMetaData(appId, request) {
    const { status } =  await post(`${APPS_ENDPOINT}/${appId}/model/fine-tune/training/data-set`, request);
    if (status !== 200) {
        return null;
    }
    return status;
}

export async function dataSetUploadUrlGet(appId, request) {
    return await post(`${APPS_ENDPOINT}/${appId}/model/fine-tune/training/data-set/upload/url`, request);
}

export async function dataSetFileUpload(preSignedUrl, file) {
    try {
        const headers = new Headers();
        headers.append("Content-Type", "application/zip");
        const requestOptions = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
            headers,
        };
        return await fetch(preSignedUrl, requestOptions)
    } catch (err) {
        console.debug('file upload failed cause', err);
        return null;
    }
}

export async function deleteDataSet(appId, name) {
    try {
        const { status } =  await del(`${APPS_ENDPOINT}/${appId}/model/fine-tune/training/data-set/${name}`);
        if (status !== 200) {
            return null;
        }
        return status;
    } catch (err) {
        console.debug('deleteDataSet failed cause', err);
        return null;
    }

}

export async function getTrainingJobs(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models`);
        if (status !== 200) {
            return {
                data: []
            };
        }
        return response;
    } catch (err) {
        console.debug('getTrainingJobs failed cause', err.message, err.code);
        return {
            data: []
        };
    }
}

export async function getTrainingBaseModels(appId, fineTuneType) {
    try {
        const { response, status } =
            await get(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models/training-base-models`,{ fineTuneType });
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('getTrainingBaseModels failed cause', err.message, err.code);
        return {};
    }
}

export async function getFineTunedModels(appId, fineTuneType) {
    try {
        const { response, status } =
            await get(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models/fine-tuned-models`, { fineTuneType });
        if (status !== 200) {
            return {};
        }
        return response;
    } catch (err) {
        console.debug('getTrainingBaseModels failed cause', err.message, err.code);
        return {};
    }
}

export async function getBaseModelProviders(appId, fineTuneType) {
    try {
        const { response, status } =
            await get(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models/base-model-providers`, { fineTuneType });
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('getBaseModelProviders failed cause', err.message, err.code);
        return [];
    }
}

export async function deleteModel(appId, { baseModelProvider, modelId} ) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models/${baseModelProvider}/${modelId}`);
    } catch (err) {
        console.debug('deleteModel failed cause', err.message, err.code);
        return {status: 500};
    }
}

export async function updateInferenceModel(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models/update-inference-model`, request);
    } catch (err) {
        console.debug('updateInferenceModel failed cause', err.message, err.code);
        return {status: 500};
    }
}

export async function trainingCancel(appId, { baseModelProvider, modelId} ) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/fine-tune/models/${baseModelProvider}/${modelId}/training-cancel`);
    } catch (err) {
        console.debug('deleteModel failed cause', err.message, err.code);
        return {status: 500};
    }
}

export async function startTrain(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/model/fine-tune/training`, request);
    } catch (err) {
        console.debug('startTrain failed cause', err.message, err.code);
        return {status: 500};
    }
}