import { Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import Button from '../../../components/button/button';
import Loader from "../../../components/form-loader/form-loader";
import SelectInput from "../../../components/forms/select";
import TextBox from "../../../components/forms/text-box";
import PageContainer from "../../../components/page-container";
import Message from "../../../components/toast-message";
import { APPS_ENDPOINT } from "../../../consts/api-endpoints";
import { useAppInfo } from '../../../helpers/hooks/common-hook';
import { fetchFrontAppChannels, getByAppId, liveAgentSettingsSave } from '../../../services/app/app-service';
import { APP_NAVIGATION } from "../consts/navigations";
import {INTEGRATION_NAVIGATION} from "../../../consts/integration-navigations";

const defaultSettings = {
  provider: 'frontapp',
  channel: '',
  accessKey: ''
}
function Index() {
  const { appId } = useAppInfo();

  const [formData, setFormData] = useState(defaultSettings);

  const [channels, setChannels] = useState([]);

  const [error, setError] = useState({
    provider: false,
    channel: false,
    accessKey: false,
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: ''
  });

  const validateForm = () => {
    let valid = true;
    if (formData.provider === '') {
      error.provider = true;
      valid = false;
    } else {
      error.provider = false;
    }

    if (formData.accessKey === '') {
      error.accessKey = true;
      valid = false;
    } else {
      error.accessKey = false;
    }

    if (formData.channel === '') {
      error.channel = true;
      valid = false;
    } else {
      error.channel = false;
    }

    setError(Object.assign({}, error))
    return valid;
  }

  async function fetchConfig() {
    updateFormState(true, false, false);
    const response = await getByAppId(appId);
    if (response) {
      setFormData(response.liveAgentSettings || defaultSettings);
      updateFormState(false, true, false);
    } else {
      updateFormState(false, false, true, 'Unable to fetch data');
    }
  }

  async function fetchChannels() {
    try {
      updateFormState(true, false, false);
      const { response, status } = await fetchFrontAppChannels(appId, formData.provider, formData.accessKey);
      console.log("response", response)
      if (status === 200 && response) {
        setChannels(response)
        updateFormState(false, true, false);
      } else {
        updateFormState(false, false, true, 'Unable to fetch channels');
      }
    } catch (e) { }
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }))
  }

  const handleOnChange = (event) => {
    const newFormData = { ...formData };
    newFormData[event.target.name] = event.target.value;
    setFormData(newFormData);
  }

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    if (!formState.processing && validateForm()) {
      updateFormState(true, false, false);
      const { response, status } = await liveAgentSettingsSave(appId, formData);
      if (status === 200) {
        updateFormState(false, true, false, "Successfully saved settings");
      } else {
        updateFormState(false, false, true, "Unable to saved settings");
      }
    }
  };

  const [copySuccess, setCopySuccess] = useState();
  const copyToClipboard = async (textVal) => {
    if ('clipboard' in navigator) {
      console.log("copyToClipboard navigator");
      await navigator.clipboard.writeText(textVal);
    } else {
      console.log("copyToClipboard execCommand")
      document.execCommand('copy', true, textVal);
    }
    setCopySuccess(true);
  };

  const callBackUrl = `${APPS_ENDPOINT}/${appId}/integrations/live-agents/events/${formData.provider}`;

  return (
    <PageContainer
      title="Support Center Integration Settings"
      subtitle="Allows human intervention in the bot's conversations." navigation={INTEGRATION_NAVIGATION}>
      <div className="w-full max-w-2xl">
        <Card>
          <form className="pt-4 flex flex-col gap-4" onSubmit={handleOnSubmit}>

            {formState.processing && <div className="p-4"><Loader /></div>}
            {formState.failed &&
              <div className="">
                <Message text={formState.message} type="failure" />
              </div>
            }

            {(formState.success && formState.message) &&
              <div className="">
                <Message text="Successfully saved settings" type="success" />
              </div>
            }

            {copySuccess &&
              <div className="">
                <Message type="success" text="Copied" />
              </div>
            }

            <SelectInput
              rootClass="w-full"
              label="Provider"
              id="provider"
              name="provider"
              value={formData.provider}
              onChange={handleOnChange}
              optionsComp={<>
                <option value="frontapp">FrontApp</option>
              </>}
            />

            <TextBox
              label="Access Key"
              type="password"
              name="accessKey"
              id="accessKey"
              value={formData.accessKey}
              placeholder="Enter FrontApp AccessKey"
              onChange={handleOnChange}
              error={error.accessKey}
            />

            <div className="flex flex-row justify-end w-1/2">
              <span className="text text-sm underline text-blue-600 hover:text-blue-700 font-bold cursor-pointer"
                onClick={fetchChannels}
              >Fetch Channels</span>
            </div>

            <SelectInput
              rootClass="w-full"
              label="Channel"
              id="channel"
              name="channel"
              value={formData.channel}
              onChange={handleOnChange}
              optionsComp={<>
                <option value={null}>Select Channel</option>
                {channels.map((channel, index) =>
                  <option key={index} value={channel.id}>{channel.name}</option>
                )}
              </>}
            />

            <TextBox
              rootClass="pt-2"
              label="Webhook Url"
              type="text"
              name="webhook_url"
              id="webhook_url"
              value={callBackUrl}
            />

            <div className="flex flex-row justify-end">
              <span className="text text-sm underline text-blue-600 hover:text-blue-700 font-bold cursor-pointer"
                onClick={() => copyToClipboard(callBackUrl)}
              >Copy Webhook Url</span>
            </div>


            <div className="flex justify-end gap-2">
              <Button color="default" variant="button" type="submit" text="Reset" />
              <Button variant="button" type="submit" text="Save" />
            </div>
          </form>
        </Card>
      </div>

    </PageContainer>)
}

export default Index;
