import {useEffect, useState, useCallback, useContext} from 'react';
import { connect } from 'react-redux';
import Button from '../../../../components/button/button';
import Message from "../../../../components/toast-message";
import Loader from "../../../../components/form-loader/form-loader";
import { appCreateNotify } from "../../../../redux/app/app-actions";
import PageContainer from "../../../../components/page-container";
import {Link, useNavigate } from "react-router-dom";
import config from "../../../../config";
import {useParams} from "react-router";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {INTEGRATION_NAVIGATION} from "../../../../consts/integration-navigations";
import {getShopifyConn, shopifyConnSave} from "../../../../services/model/integration-shopify-service";
import TextBox from "../../../../components/forms/text-box";

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        appCreateNotifyHandle: (appId) => {
            dispatch(appCreateNotify(appId));
        },
    };
}

function Index() {
    const navigate = useNavigate();
    const { appId } = useAppInfo();
    const { id } = useParams();

    const [savedId, setSavedId] = useState();
    const [formData, setFormData] = useState({
        id,
        storeAddress: '',
        accessKey: ''
    });

    const [error, setError] = useState({
        id: false,
        storeAddress: false,
        accessKey: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const [copySuccess, setCopySuccess] = useState();

    const goBack = () => {
        navigate(`/app/${appId}/integrations/shopify`)
    }

    useEffect(() => {
        if (savedId) {
            setTimeout(()=>{
                goBack();
            }, 1500)
        }
    }, [savedId]);

    useEffect(() => {
        if (copySuccess) {
            setTimeout(()=>{
                setCopySuccess(false);
            }, 2000)
        }
    }, [copySuccess]);

    const fetchData = async () => {
        if (id) {
            updateFormState(true, false, false);
            const savedAuthConn = await getShopifyConn(appId, id);
            setFormData(savedAuthConn);
            updateFormState(false, true, false);
        }
    }

    useEffect( () => {
        fetchData()
    }, [id]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newFormData = {...formData};
        newFormData[targetName] = targetValue;
        setFormData(newFormData);
    });

    const validateForm = () => {
        let valid = true;
        if (formData.id === '') {
            error.id = true;
            valid = false;
        } else {
            error.id = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, { processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const { response, status } = await shopifyConnSave(appId, formData);
            if (status === 200) {
                setSavedId(formData.id);
                updateFormState(false, true, false, "Successfully created");
            } else {
                updateFormState(false, false, true, response);
            }
        }
    };

    const copyToClipboard = async e => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(config.AUTH_CALLBACK_URL);
        } else {
            document.execCommand('copy', true, config.AUTH_CALLBACK_URL);
        }
        setCopySuccess(true);
    };

    return (
        <PageContainer title="New Shopify Connection"
                       breadcrumbNav={[
                           {name: 'Auth Connectors', href: `/app/${appId}/integrations/shopify`},
                           {name: 'Settings', href: '#'},
                       ]}
                       headerAction={<div className="flex flex-row items-center justify-end gap-2">
                           <Button
                               variant="button"
                               color="default"
                               text="Reset"
                           />
                           <Button variant="button" text="Save Document"
                                   onClick={handleOnSubmit}/>
                       </div>}
        >
            <div className="pt-4 flex flex-col gap-4 pb-4">
                {formState.processing && <div className="p-4"><Loader/></div>}

                {formState.success && <Message text="Successfully Saved Configs" type="success"/>}

                {formState.failed && <Message text={formState.message} type="failure"/>}

                <div className="w-1/2 flex flex-col gap-4">
                    <TextBox
                        label="Connector Id"
                        type="text"
                        name="id"
                        id="id"
                        value={formData.id}
                        placeholder="Enter connector id"
                        onChange={handleChange}
                        required
                    />

                    <TextBox
                        label="Store Address"
                        type="text"
                        name="storeAddress"
                        id="storeAddress"
                        value={formData.storeAddress}
                        placeholder="Add Store Url"
                        onChange={handleChange}
                    />

                    <TextBox
                        label="Access Key"
                        type="password"
                        name="accessKey"
                        id="accessKey"
                        value={formData.accessKey}
                        placeholder="Add shopify admin access key"
                        onChange={handleChange}
                    />
                </div>

            </div>
        </PageContainer>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)