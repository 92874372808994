import React from "react";
import PropTypes from "prop-types";
import { cn } from "../../../helpers/utils/css-utils";

const TextLabel = ({ id, className, children, ...props }) => {
  // const labelClass = props.error
  //   ? "block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
  //   : "block mb-2 text-sm font-medium text-gray-900 dark:text-white";

  return (
    <label
      htmlFor={id}
      className={cn(
        "inline-block leading-4 text-sm font-medium text-gray-800 dark:text-white",
        {
          "text-red-700 dark:text-red-500": props?.error,
        },
        className
      )}
    >
      {children}
    </label>
  );
};

export default TextLabel;

TextLabel.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
};
