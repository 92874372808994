import {Alert} from "flowbite-react";
import {HiInformationCircle} from 'react-icons/hi';
import {useEffect, useState} from "react";

export default function Message({color, text, timeout= 5000}) {
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        if (timeout > 0) {
            setTimeout(()=>{
                setIsOpen(false);
            }, timeout)
        }
    }, []);

    if (!isOpen) {
        // exit
        return <></>;
    }

    return (
        <Alert
            color={color}
            icon={HiInformationCircle}
        >
      <span>
        <p>
          <span className="font-medium">
            {text}
          </span>
        </p>
      </span>
        </Alert>
    )
}