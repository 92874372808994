import './App.css';
import { connect } from 'react-redux';
import { AppContext, getDefaultContext } from './context/app-context';
import MainRouter from './pages/main-router';
import Amplify, { Auth, Hub } from "aws-amplify";
import awsExports from './aws-exports';
import { loadAuthUserInfo } from './services/auth/auth-service';
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import config from "./config";
import {useNavigate} from "react-router-dom";
import {Flowbite} from 'flowbite-react';

const stripePromise = loadStripe(config.STRIPE_KEY);

Amplify.configure(awsExports);
Auth.configure({
    // storage: window.sessionStorage
});

const mapsStateToProps = (state, ownProps) => {
    return {
        appInfoMessage: state.appInfoMessage
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

function App({appInfoMessage}) {
    const navigation = useNavigate();
    const [authUserInfo, setAuthUserInfo] = useState();
    const [appInfo, setAppInfo] = useState();

    async function loadContext() {
        const authUser = await loadAuthUserInfo();
        if (authUser) {
            setAuthUserInfo(authUser);
        }
    }

    async function init() {
        // How listens
        Hub.listen('auth', async (data) => {
            const event = data.payload.event;
            if (event === "signIn") {
                await loadContext();
            } else if (event === "signOut") {
                // TODO
            }
        });
        await loadContext();
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (appInfoMessage.data) {
            setAppInfo(appInfoMessage.data);
        }
    }, [appInfoMessage]);

    return (
        <AppContext.Provider value={{ ...getDefaultContext(), authUserInfo, appInfo }}>
            <Elements stripe={stripePromise}>
                <Flowbite>
                    <MainRouter/>
                </Flowbite>
            </Elements>
        </AppContext.Provider>
    );
}

export default connect(mapsStateToProps, mapDispatchToProps)(App);
