import {useEffect, useState, useCallback} from 'react';
import { IoMdAdd } from "react-icons/io";
import { FaRegCopy } from "react-icons/fa";
import { useAppInfo } from "../../../helpers/hooks/common-hook";
import Message from "../../../components/toast-message";
import PageContainerV2 from "../../../components/page-container/page-container-v2";
import {
  saveAccessKeyByChannel,
  getAccessKeyByChannel,
} from "../../../services/integrate/fb-integrate-service";
import FormLoader from "../../../components/form-loader/form-loader";
import TextBox from "../../../components/forms/text-box";
import config from "../../../config";
import SectionHeading from "../_components/section-heading";

function IntegrationChannelPage({
  channel,
  titlePrefix,
  subTitle,
  callbackUrlLabel = "Callback URL",
  verifyTokenEnable = true,
  verifyTokenLabel = "Verify token",
  accessKeyLabel = "Access Token",
}) {
  const { appId } = useAppInfo();

  const callBackUrl = `${config.API_BASE_URL}/apps/${appId}/integration/${channel}/webhook`;
  const verifyToken = appId;

  const [formData, setFormData] = useState({
    accessKey: "",
  });

  const [error, setError] = useState({
    accessKey: false,
  });

  const [dataLoadingState, setDataLoading] = useState({
    processing: false,
    success: false,
    failed: false,
    message: null,
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: "",
  });

  const [copySuccess, setCopySuccess] = useState();
  const [isSetAccessKey, setIsSetAccessKey] = useState(false);

  async function fetchAccessKey() {
    updateDataLoadingState(true, false, false);
    const response = await getAccessKeyByChannel(appId, channel);
    setIsSetAccessKey(response.isSetAccessKey);
    updateDataLoadingState(false, false, false);
  }

  useEffect(() => {
    fetchAccessKey();
  }, []);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }
  }, [copySuccess]);

  const updateDataLoadingState = (processing, success, failed, message) => {
    setDataLoading(Object.assign({}, { processing, success, failed, message }));
  };

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(
      Object.assign({}, { processing, success, failed, errorMessage })
    );
  };

  const validateForm = () => {
    let valid = true;
    if (formData.accessKey) {
      error.accessKey = false;
    }

    setError(Object.assign({}, error));
    return valid;
  };

  const handleChange = useCallback((event) => {
    const targetName = event.target.name;
    const targetValue = event.target.value;

    if (targetName === "accessKey") {
      formData.accessKey = targetValue;
    }

    setFormData(Object.assign({}, formData));
  });

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    if (!formState.processing && validateForm()) {
      updateFormState(true, false, false);
      const { response, status } = await saveAccessKeyByChannel(
        appId,
        channel,
        formData
      );
      if (status === 200) {
        updateFormState(false, true, false);
      } else {
        updateFormState(false, false, true, response);
      }
    }
  };

  const copyToClipboard = async (textVal) => {
    if ("clipboard" in navigator) {
      console.log("copyToClipboard navigator");
      await navigator.clipboard.writeText(textVal);
    } else {
      console.log("copyToClipboard execCommand");
      document.execCommand("copy", true, textVal);
    }
    setCopySuccess(true);
  };

  // const accessKey = formData.accessKey? formData.accessKey: (isSetAccessKey? appId: '');

  if (dataLoadingState.processing) {
    return <FormLoader fullScreen={true} text="Loading..." />;
  }

  return (
    <PageContainerV2
      title={`${titlePrefix} Integration`}
      pageType="channels"
      subtitle={subTitle}
      pageSidebar={true}
      // navigation={INTEGRATIONS_NAVIGATION}
    >
      {formState.failed && (
        <Message text={formState.errorMessage} type="failure" />
      )}
      {formState.success && (
        <Message text="Successfully save access key" type="success" />
      )}
      {copySuccess && (
        <div className="flex flex-row justify-center py-2">
          <Message type="success" text="Copied" />
        </div>
      )}

      <div className="flex h-full flex-1 flex-grow">
        <div className="flex flex-col flex-1">
          <SectionHeading title={titlePrefix} />
          <div className="flex flex-col space-y-8 px-5 pt-6 w-full max-w-screen-md">
            <div className="flex items-center gap-2">
              <TextBox
                rootClass="w-full"
                label={callbackUrlLabel}
                type="text"
                name="name"
                id="name"
                placeholder="App name"
                value={callBackUrl}
                readOnly
              />
              <div className="mt-5 h-full flex flex-row items-center">
                <button
                  type="button"
                  className="h-10 w-10 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  <FaRegCopy
                    onClick={() => copyToClipboard(callBackUrl)}
                    className="w-5 h-5"
                  />
                  <span className="sr-only">Icon description</span>
                </button>
              </div>
            </div>

            {verifyTokenEnable && (
              <div className="flex items-center gap-2">
                <TextBox
                  rootClass="w-full"
                  label={verifyTokenLabel}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="App name"
                  value={verifyToken}
                  readOnly
                />
                <div className="mt-5 h-full flex flex-row items-center">
                  <button
                    type="button"
                    className="h-10 w-10 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                  >
                    <FaRegCopy
                      onClick={() => copyToClipboard(verifyToken)}
                      className="w-5 h-5"
                    />
                    <span className="sr-only">Icon description</span>
                  </button>
                </div>
              </div>
            )}

            <div className="flex items-center gap-2">
              <TextBox
                rootClass="w-full"
                label={accessKeyLabel}
                type="password"
                name="accessKey"
                id="accessKey"
                placeholder="Add your page access token"
                onChange={handleChange}
              />
              <div className="mt-5 h-full flex flex-row items-center">
                <button
                  type="button"
                  className="h-10 w-10 text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  <IoMdAdd onClick={handleOnSubmit} className="w-5 h-5" />
                  <span className="sr-only">Icon description</span>
                </button>
              </div>
            </div>

            {error.accessKey && (
              <Message text="AccessKey cannot be empty" type="failure" />
            )}
          </div>
        </div>
      </div>
    </PageContainerV2>
  );
}

export default IntegrationChannelPage;