import { get, post, del } from "../../helpers/utils/http-client";
import { APPS_ENDPOINT } from '../../consts/api-endpoints';

const SELECTED_APP_ID_KEY = 'elizn_nlu_app';

export async function getAllApps() {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}`);
        if (status !== 200) {
            return [];
        }
        return response;
    } catch (err) {
        console.debug('app get-all failed cause', err.message, err.code);
        return [];
    }
}

export async function getByAppId(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}`);
        if (status !== 200) {
            return null;
        }
        return response;
    } catch (err) {
        console.debug('app get-by-app-id failed cause', err.message, err.code);
        return null;
    }
}

export async function createApp(request) {
    try {
        return await post(`${APPS_ENDPOINT}`, request);
    } catch (err) {
        console.debug('app create failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function deleteApp(appId) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}`);
    } catch (err) {
        console.debug('app delete failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function fetchTemplate(filterFiled='key', filterVal='val') {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/templates/fetch?${filterFiled}=${filterVal}`);
        if (status !== 200) {
            throw new Error('Api failed')
        }
        return response;
    } catch (err) {
        console.debug('fetchTemplate failed cause', err.message, err.code);
        return { pagination: {}, data: [] };
    }
}

export async function publishToTemplate(appId) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/templates/publish`);
    } catch (err) {
        console.debug('publishToTemplate failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function fetchFrontAppChannels(appId, provider, accessKey) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/live-agent-settings/frontapp/channels`, {
            provider, accessKey
        });
    } catch (err) {
        console.debug('fetchFrontAppChannels failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function liveAgentSettingsSave(appId, request) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/live-agent-settings`, request);
    } catch (err) {
        console.debug('liveAgentSettingsSave failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export async function publish(appId) {
    try {
        return await post(`${APPS_ENDPOINT}/${appId}/publish`, {});
    } catch (err) {
        console.debug('app publish failed cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}

export function setSelectedApp(appId) {
    localStorage.setItem(SELECTED_APP_ID_KEY, appId);
}

export function unsetSelectedApp() {
    localStorage.removeItem(SELECTED_APP_ID_KEY);
}

export function getSelectedApp() {
    const s = localStorage.getItem(SELECTED_APP_ID_KEY);
    console.log("saved app", s);
    return localStorage.getItem(SELECTED_APP_ID_KEY);
}