import { Routes, Route } from 'react-router-dom';
import { useAppContext } from '../helpers/hooks/common-hook';

import SigninPage from './auth/signin/signin-page';
import SignupPage from "./auth/signup/signup-page";

import ForgotPasswordPage from "./auth/forgot-password/forgot-password-page";

// import DashboardPage from "./dashboard/backup-dashboard-page";
import DashboardPage from "./dashboard/dashboard-page";
import ContactUsPage from "./contact-us/contact-us-page";
import RequestEarlyAccess from "./request-early-access";

const MainRouter = () => {
    const { authUserInfo } = useAppContext();
    if (authUserInfo) {
        // logged user
        return <DashboardPage />
    } else {
        // not logged
        return (
            <Routes>
                <Route path='/' element={<SigninPage />} />
                <Route path='/signin' element={<SigninPage />} />
                <Route path='/signup' element={<SignupPage />} />
                <Route path='/forgot-password' element={<ForgotPasswordPage />} />
                <Route path='/request-access' element={<RequestEarlyAccess />} />
                <Route path='/contact-us' element={<ContactUsPage />} />
            </Routes>
        );
    }
};

export default MainRouter;
