import React from "react";
import { cn } from "../../../../helpers/utils/css-utils";

const Button = React.forwardRef(
  ({ className, children, variant = "default", ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          `inline-flex items-center justify-center gap-2 text-sm rounded-md transition py-2.5 px-4 whitespace-nowrap`,
          {
            "bg-indigo-600 hover:bg-indigo-700 hover:ring-4 ring-indigo-200 dark:ring-indigo-900 text-white":
              variant === "default",
            "bg-transparent border-2 border-indigo-600 hover:bg-transparent hover:ring-4 ring-indigo-200 text-indigo-600":
              variant === "outline",
            "bg-transparent border border-gray-300 hover:border-opacity-40 hover:bg-gray-50 text-gray-700 hover:ring-4 ring-gray-200 dark:border-gray-700 dark:text-gray-50 dark:hover:bg-gray-700 dark:hover:ring-gray-800":
              variant === "ghost",
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

const ButtonIcon = React.forwardRef(
  ({ className, children, variant = "default", ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          `w-9 h-9 rounded-md bg-transparent inline-flex items-center justify-center text-gray-500 hover:text-gray-600 hover:bg-gray-100 transition  active:ring-2 active:ring-gray-200`,
          {
            "border border-gray-300 text-gray-500 ": variant === "outline",
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export { Button, ButtonIcon };
