import React from "react";
import { cn } from "../../../helpers/utils/css-utils";

const SectionHeading = ({ title = "", className, children }) => {
  return (
    <div
      className={cn(
        "px-5 justify-between dark:border-gray-700 h-14 flex items-center flex-none",
        className
      )}
    >
      <h4 className="font-bold text-lg dark:text-white inline-block whitespace-nowrap">
        {title}
      </h4>
      {children && children}
    </div>
  );
};

export default SectionHeading;
