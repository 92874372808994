import React from "react";

const IconButton = ({ children, ...props }) => {
  return (
    <button
      className="
     w-10 h-10 rounded-md bg-transparent inline-flex items-center justify-center text-gray-600 dark:text-gray-200
    hover:text-indigo-700 hover:bg-indigo-100 dark:hover:bg-indigo-900 ring-0 
    focus:ring-offset-0 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 transition-all"
      {...props}
    >
      {children}
    </button>
  );
};

export default IconButton;
