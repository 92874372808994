import { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from '../../../../components/button/button';
import Message from "../../../../components/message/message-comp";
import Loader from "../../../../components/form-loader/form-loader";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {Modal} from "flowbite-react";
import TextBox from "../../../../components/forms/text-box";
import {dataSetUploadUrlGet, dataSetFileUpload, saveDataSetMetaData} from "../../../../services/model/finetune-service";
import TextArea from "../../../../components/forms/text-area";
import {INTENT_CLASSIFY_FINE_TUNE_TYPE, RAG_FINE_TUNE_TYPE} from "../../const/common-consts";
import {validateActionName} from "../../../../helpers/utils/text-util";
import {NAME_VALIDATION_ERROR} from "../../../../consts/error-messages";

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

function Index({ open, onCompletion, onCancel, savedData={}, fineTuneType }) {
    const rootRef = useRef(null);
    const { appId } = useAppInfo();
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        fileExt: '',
        name: '',
        systemPrompt: '',
        fineTuneType,
    });

    const [uploadFile, setUploadFile] = useState({});

    const [uploadUrl, setUploadUrl] = useState();
    const [fileObj, setFileObj] = useState();

    const [error, setError] = useState({
        name: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    useEffect(() => {
        if (formState.success) {
            setTimeout(()=>{
                setIsOpen(false);
                onCompletion(true);
            }, 1500)
        }
    }, [formState.success]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData};
        newFormData[targetName] = targetValue;

        setFormData(newFormData);
    });

    const validateForm = () => {
        let valid = true;
        if (!formData.name) {
            error.name = true;
            valid = false;
        } else {
            error.name = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage}))
    }

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        if (onCancel) {
            onCancel();
        } else {
            onCompletion();
        }
    });

    const [fileUrlGenState, setFileUrlGenState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateFileUrlGenState = (processing, success, failed, errorMessage) => {
        setFileUrlGenState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const createUploadUrl = async () => {
        if (formData.name) {
            updateFileUrlGenState(true, false, false);
            try {
                const formDataRequest = {...formData};
                const {response, status} = await dataSetUploadUrlGet(appId, formDataRequest);
                if (status === 200) {
                    const {uploadUrl, file} = response;
                    setUploadUrl(uploadUrl);
                    setUploadFile({...file});
                    updateFileUrlGenState(false, true, false);
                } else {
                    throw new Error(response)
                }
            } catch (err) {
                console.error("get-utl call failed cause", err);
                updateFileUrlGenState(false, false, true, 'Upload failed');
            }
        }
    }

    useEffect( () => {
        if (formData.name && !validateActionName(formData.name)) {
            setError({...error, name: true})
            setUploadUrl(null)
        } else {
            setError({...error, name: false})
            createUploadUrl()
        }
    }, [formData]);

    const handleSelectFile = async (event) => {
        console.log("event.target.files", event.target.files)
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            setFileObj(file)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (fileObj) {
            updateFormState(true, false, false)
            let response = await dataSetFileUpload(uploadUrl, fileObj);
            if (!response) {
                updateFormState(false, false, true, 'Upload failed');
            }
            formData.file = uploadFile;
            response = await saveDataSetMetaData(appId, formData)
            if (!response) {
                updateFormState(false, false, true, 'Upload failed');
            }

            updateFormState(false, true, false)
        }
    };

    const enableUpload = uploadUrl && formData.name;

    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={handleOnCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-6">

                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">New Data Set</h3>

                            {formState.processing && <div className="p-4"><Loader/></div>}

                            {formState.success &&
                                <Message text={`Successfully ${formData.name?'Updated':'Saved'} Data Set`} color="success"/>
                            }

                            {formState.failed && <Message text={formState.errorMessage} color="failure"/>}

                            <TextBox
                                label="Data set name"
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                placeholder="Enter data set name"
                                onChange={handleChange}
                                error={error.name}
                                errorMessage={NAME_VALIDATION_ERROR}
                            />

                            {fineTuneType === RAG_FINE_TUNE_TYPE &&
                                <TextArea
                                    rootClass=""
                                    rows={5}
                                    name="systemPrompt"
                                    id="systemPrompt"
                                    label="System Prompt"
                                    subLabel="The system instruction can give high level instructions and style for the conversation"
                                    placeholder="Eg: You are a helpful assistant"
                                    value={formData.systemPrompt}
                                    onChange={handleChange}
                                />
                            }

                            <div className="w-full">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                       htmlFor="fileContainer">
                                    <span>Upload document ( .json )</span>
                                </label>

                                {!enableUpload?
                                    <input
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                        aria-describedby="user_avatar_help" id="fileContainer" type="file"
                                        accept="application/json"
                                    />
                                    :
                                    <input
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                        aria-describedby="user_avatar_help" id="fileContainer" type="file" accept="application/json"
                                        onChange={handleSelectFile}
                                    />
                                }

                            </div>

                            <a className="text-sm text-blue-500 underline" target="_blank" href={`https://resources-platform.botcircuits.com/${fineTuneType===INTENT_CLASSIFY_FINE_TUNE_TYPE? 'intent-classification-finetune-dataset.json': 'rag-finetune-dataset.json'}`}>download sample data set file</a>

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={handleOnCancel}/>
                                <Button type="submit" text="Add Data set"/>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)