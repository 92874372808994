import React, {memo, useContext} from 'react';
import {Input, Modal} from "antd";
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";

const ConditionNodeModal = () => {

    const workflowCanvasContext = useContext(WorkflowCanvasContext);

    return (
        <div>
            <Modal
                title={
                    <p style={{fontSize: "18px", textAlign: "center", marginTop: 0}}>
                        New Rule
                    </p>
                }
                open={workflowCanvasContext.edgeLabelModalVisible}
                onCancel={workflowCanvasContext.closeEdgeLabelModal}
                onOk={workflowCanvasContext.handleEdgeLabelAdd}
                closeIcon={null}
            >
                <p>Name</p>
                <Input
                    placeholder="Rule Name"
                    onChange={e => workflowCanvasContext.handleEdgeLabelChange(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default memo(ConditionNodeModal);