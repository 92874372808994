import {Switch} from "@headlessui/react";
import React from "react";

export default function ResponseAIModify({responseModifyByAI, onChange}) {
    return <div className="flex flex-row justify-between items-center">
        <label htmlFor="slot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Response modify by AI
        </label>
        <Switch
            checked={responseModifyByAI}
            onChange={val => onChange({ target: { name: 'responseModifyByAI', value: val } })}
            className={`${responseModifyByAI ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
            <span className="sr-only">Auto Fill</span>
            <span
                className={`${responseModifyByAI ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
        </Switch>
    </div>
}