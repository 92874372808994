import React from "react";
import PropTypes from "prop-types";
import { Popover } from "@headlessui/react";
import { SketchPicker } from "react-color";

const ColorPicker = ({
  key,
  field,
  fieldTitle,
  chatBotSetting,
  handleChangeComplete,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col w-min relative">
        <Popover>
          <Popover.Button>
            <span
              title={fieldTitle}
              className="flex items-center justify-center h-11 w-14 rounded-md border border-gray-400 outline-indigo-500"
              style={{ background: chatBotSetting[field] }}
            />
          </Popover.Button>
          <Popover.Panel className="absolute top-0 left-full z-10">
            <SketchPicker
              color={chatBotSetting[field]}
              onChangeComplete={(color) => {
                if (color && color.hex) {
                  handleChangeComplete(field, color.hex);
                }
              }}
            />
          </Popover.Panel>
        </Popover>
      </div>
    </div>
  );
};

export default ColorPicker;

ColorPicker.propTypes = {
  key: PropTypes.string,
  chatBotSetting: PropTypes.any,
  field: PropTypes.string,
  fieldTitle: PropTypes.string,
  handleChangeComplete: PropTypes.func,
};
