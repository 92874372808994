
import { useState, useCallback, useEffect } from 'react'
import {requestEarlyAccess} from "../../services/user/user-service";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/form-loader/form-loader";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/solid";

const channels = [
    "Search Engine",
    "Linkedin",
    "Facebook",
    "Instagram",
    "Reddit",
    "YouTube",
    "Other"
]
export default function Index() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        channel: '',
        channelOther: '',
        message: ''
    });

    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        message: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    useEffect(() => {
        if (formState.success) {
            setTimeout(()=>{
                navigate(`/`)
            }, 3000)
        }
    }, [formState]);

    const handleChange = useCallback((event) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData};
        newFormData[targetName] = targetValue;

        setFormData(newFormData);
    });

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, { processing, success, failed, message}))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing) {
            updateFormState(true, false, false);
            const { response, status } = await requestEarlyAccess(formData);
            if (status === 200) {
                updateFormState(false, true, false, "Successfully sent request");
            } else {
                updateFormState(false, false, true, response);
            }
        }
    };

    return (
        <div className="isolate bg-white px-6 sm:py-16 md:py-32 lg:px-8">
            <div
                className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                aria-hidden="true"
            >
                <div
                    className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Request Early Access</h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                    Looking to build the next generation intelligent apps. Try BotCircuits
                </p>
            </div>
            <form onSubmit={handleOnSubmit} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-10">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="firstName" className="block text-sm font-semibold leading-6 text-gray-900">
                            First name*
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                autoComplete="given-name"
                                value={formData.firstName}
                                onChange={handleChange}
                                required={true}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                            Last name*
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                autoComplete="family-name"
                                value={formData.lastName}
                                onChange={handleChange}
                                required={true}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                            Company*
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="company"
                                id="company"
                                required={true}
                                autoComplete="organization"
                                value={formData.company}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                            Email*
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                                required={true}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                            Could you provide details on how you intend to use BotCircuits*
                        </label>
                        <div className="mt-2.5">
                            <textarea
                              name="message"
                              id="message"
                              rows={4}
                              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              defaultValue={''}
                              value={formData.message}
                              required={true}
                              onChange={handleChange}
                          />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="channel" className="block text-sm font-semibold leading-6 text-gray-900">
                            How did your here about us*
                        </label>

                        <select
                            id="channel"
                            name="channel"
                            required
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={formData.channel}
                            onChange={handleChange}
                        >
                            {channels.map((channel, ci)=>
                                <option key={ci} value={channel}>{channel}</option>
                            )}

                        </select>
                        {formData.channel === 'Other' &&
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    name="channelOther"
                                    id="channelOther"
                                    value={formData.channelOther}
                                    onChange={handleChange}
                                    required={true}
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        }

                    </div>

                </div>
                {formState.processing &&
                    <div className="pt-6">
                        <Loader/>
                    </div>

                }

                {formState.success &&

                    <div className="pt-6">
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">Request sent</h3>
                                <div className="mt-2 text-sm text-green-700">
                                    <p>Thank you for submitting your request. Our team will review it and grant access as soon as possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                }

                {formState.failed &&
                    <div className="pt-6">
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">Unable to make your request. Please try again</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <ul role="list" className="list-disc space-y-1 pl-5">
                                            <li>{formState.message}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }

                <div className="mt-10">
                    {formState.processing ?
                        <div
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            ...
                        </div>
                    :
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Submit
                        </button>
                    }

                </div>
            </form>
        </div>
    )
}
