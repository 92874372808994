import React, {memo, useCallback, useContext, useEffect, useState} from 'react';
import {WorkflowCanvasContext} from "../../contexts/WorkflowCanvasContextProvider";
import {moveConditionToLast, swapPositions} from "../../helpers/Utils";
import {Position, useUpdateNodeInternals} from "reactflow";
import BlockChildHandle from "./handle/BlockChildHandle";
import {CHOICE_NODE_TYPE} from "../../pages/action-journey-studio/common/const/node-types";
import {createTimeBasedUniqueNumber} from "../../helpers/utils/object-util";

const BlockChildNode = ({blockChildren, id, setShowToolbar, setDeletableChildId}) => {

    const updateNodeInternals = useUpdateNodeInternals();

    const workflowCanvasContext = useContext(WorkflowCanvasContext);
    const [isDraggable, setIsDraggable] = useState(true);

    const selectedStepId = workflowCanvasContext.selectedStepId

    const [droppedItems, setDroppedItems] = useState(blockChildren);

    /*
    * ToDo:
    *  Infinite loop need to fix
    * */
    // useEffect(() => {
    //     updateNodeInternals(id);
    //
    // }, [workflowCanvasContext.nodes])

    useEffect(() => {
        if (workflowCanvasContext.nodes) {
            const node = workflowCanvasContext.nodes.find(item => item.id === id);
            if (node) {
                setDroppedItems(node.data.children);
            }
        }
    }, [workflowCanvasContext.nodes]);


    const onDragOver = useCallback((event) => {
        event.preventDefault()
    }, []);

    const onDrop = (event, nodeData) => {

        event.preventDefault()

        const type = event.dataTransfer.getData("application/reactflow");
        const parseNodeData = JSON.parse(event.dataTransfer.getData('nodeData'));
        const draggedItemId = event.dataTransfer.getData("text/plain");

        if (type) {

            const parseNodeData = JSON.parse(event.dataTransfer.getData('nodeData'));

            setDroppedItems([...droppedItems, {
                id: new Date().getMilliseconds(),
                label: parseNodeData.label,
                nodeType: parseNodeData.nodeType
            }]);

            const nodeChildId = +createTimeBasedUniqueNumber();

            const _nodes = workflowCanvasContext.nodes.map(node => {
                if (node.id === id) {
                    if (parseNodeData.nodeType === CHOICE_NODE_TYPE) {
                        const existNode = node.data.children.find(item => item.nodeType === parseNodeData.nodeType)
                        if (existNode) {
                            return node;
                        }

                        const updatedData = {
                            ...node,
                            data: {
                                ...node.data,
                                children: [...node.data.children, {
                                    id: nodeChildId,
                                    label: parseNodeData.label,
                                    nodeType: parseNodeData.nodeType,
                                    stateConfig: {
                                        choices: []
                                    }
                                }],
                            }
                        }

                        return {
                            ...updatedData,
                            data: {
                                ...updatedData.data,
                                children: moveConditionToLast(updatedData.data.children),
                            }
                        };

                    }


                    const addedData = {
                        ...node,
                        data: {
                            ...node.data,
                            children: [
                                ...node.data.children,
                                {
                                    id: nodeChildId,
                                    label: parseNodeData.label,
                                    nodeType: parseNodeData.nodeType,
                                    stateConfig: parseNodeData.stateConfig
                                }
                            ],
                        }
                    }


                    return {
                        ...addedData,
                        data: {
                            ...addedData.data,
                            children: moveConditionToLast(addedData.data.children),
                        }
                    };
                }
                return node;
            });

            workflowCanvasContext.setNodes([..._nodes]);

        } else if (Number(id) !== Number(parseNodeData.blockId)) {

            let selectedItem;
            let targetBlock;

            nodeData.forEach(item => {
                if (Number(item.id) === Number(id)) {
                    targetBlock = item;
                }
                item.data.children.forEach(singleItem => {

                    if (singleItem.id === Number(draggedItemId.split(":")[2])) {
                        selectedItem = singleItem
                    }
                })
            });

            let isTargetConditional;
            targetBlock.data.children.forEach(item => {
                if (item.nodeType === CHOICE_NODE_TYPE) {
                    isTargetConditional = true;
                }
            })

            console.log({selectedItem});

            if (isTargetConditional === true && selectedItem.nodeType === CHOICE_NODE_TYPE) {

            } else {

                const _nodes = nodeData.map(node => {

                    if (node.id === id) {
                        return (
                            {
                                ...node,
                                data: {
                                    ...node.data,
                                    children: moveConditionToLast([...node.data.children, selectedItem]),
                                }
                            }
                        )
                    } else if (Number(node.id) === Number(parseNodeData.blockId)) {

                        const updatedNodes = node.data.children.filter(item => item.id !== selectedItem.id)

                        if (updatedNodes.length > 0) {
                            return (
                                {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        children: moveConditionToLast(updatedNodes),
                                    }
                                }
                            )
                        }
                        return undefined;
                    }

                    return node;

                }).filter(node => node !== undefined);

                workflowCanvasContext.setNodes(_nodes);
            }


        }
    }

    const onDragStart = useCallback((event, itemId, blockItems) => {

        event.dataTransfer.setData("text/plain", `steps:existing:${itemId}`);

        const selectedItem = blockItems.find(item => item.id === itemId);

        event.dataTransfer.setData('nodeData', JSON.stringify({
            label: selectedItem.label,
            nodeType: selectedItem.nodeType,
            blockId: id,
            childrenId: itemId
        }));

    }, []);

    const onDragEnd = useCallback(() => {
        // setDraggedItemId(null);
        // setDragOverItemId(null);
    }, []);

    const onItemDragOver = useCallback((event, targetId) => {
        event.preventDefault();
        const draggedItemId = event.dataTransfer.getData("text/plain");
        if (
            draggedItemId.startsWith("steps:existing:") &&
            draggedItemId.split(":")[1] !== targetId
        ) {
            event.dataTransfer.dropEffect = "move";
        }
    }, []);

    const onItemDragLeave = useCallback(() => {
        // setDragOverItemId(null);
    }, []);


    const onItemDrop = useCallback((event, targetId, blockItems, nodeData) => {

        event.preventDefault();

        const draggedItemId = event.dataTransfer.getData("text/plain");

        const parseNodeData = JSON.parse(event.dataTransfer.getData('nodeData'));
        const targetChild = blockItems.find(item => item.id === targetId);
        const draggedChild = blockItems.find(item => item.id === draggedItemId);

        if (Number(id) === Number(parseNodeData.blockId) && targetChild?.nodeType !== CHOICE_NODE_TYPE && draggedChild?.nodeType !== CHOICE_NODE_TYPE) {

            if (draggedItemId.startsWith("steps:existing:")) {

                const actualDraggedId = draggedItemId.split(":")[2];

                if (actualDraggedId !== targetId) {

                    const updatedArray = swapPositions(blockItems, Number(actualDraggedId), Number(targetId));

                    const _nodes = nodeData.map(node => {
                        if (node.id === id) {
                            return (
                                {
                                    ...node,
                                    data: {
                                        ...node.data,
                                        children: updatedArray,
                                    }
                                }
                            )
                        }
                        return node;
                    });

                    workflowCanvasContext.setNodes([..._nodes]);
                    setDroppedItems(updatedArray);

                }
            }
        }

    }, []);

    const handleToolbarMenu = (e, childId) => {
        e.stopPropagation()
        e.preventDefault();
        workflowCanvasContext.setSelectedStepId(childId)
        setDeletableChildId(childId)
        setShowToolbar(true)
    }

    const isPreview = workflowCanvasContext.isPreview;
    return (
        <div
            className="flex flex-col gap-2"
            onDragOver={!isPreview? onDragOver: undefined}
            onDrop={(e) => !isPreview?onDrop(e, workflowCanvasContext.nodes): undefined}
        >

            {
                droppedItems.map((item, index) => (
                    <div
                        // style={{
                        //     border: "1px solid #DDE4EA",
                        //     borderRadius: "4px",
                        //     margin: "10px 0px",
                        //     position: "relative",
                        //     backgroundColor: "white"
                        // }}
                        className={`relative rounded-lg bg-white ${item.id === workflowCanvasContext.selectedStepId? ' border-2 border-blue-300': ' border-1 border-gray-300'} ${isPreview && (workflowCanvasContext.currentJourneySession.runningState == item.id)? 'ring-4 ring-blue-400 dark:ring-4 _dark:ring-red-700':''}`}
                        key={item.id}
                        onClick={() => !isPreview? workflowCanvasContext.openNodeDrawer(item, id): undefined}
                        draggable={!isPreview && isDraggable}
                        onDragStart={(e) => onDragStart(e, item.id, droppedItems)}
                        onDragEnd={onDragEnd}
                        onDragOver={(e) => onItemDragOver(e, item.id)}
                        onDragLeave={onItemDragLeave}
                        onContextMenu={(e) => handleToolbarMenu(e, item.id)}
                        onDrop={(e) => onItemDrop(e, item.id, droppedItems, workflowCanvasContext.nodes)}
                    >
                        {
                            item?.stateConfig?.choices ? <>
                                {
                                    item?.stateConfig?.choices?.length === 0 ? <p style={{
                                        color: "#969b9f",
                                        fontSize: "14px",
                                        padding: "12px 20px",
                                        margin: "0"
                                    }}>
                                        Add Conditional Path
                                    </p> : item?.stateConfig?.choices?.map((node) => (
                                        <div key={node.id} style={{
                                            borderBottom: "1px solid #DDE4EA",
                                            position: "relative"
                                        }}>
                                            {/*<BlockChildHandle
                                                type="target"
                                                position={Position.Left}
                                                id={`target-${node.id}`}
                                                onMouseOver={() => setIsDraggable(false)}
                                                onMouseLeave={() => setIsDraggable(true)}
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    position: "absolute",
                                                    alignItems: "center",
                                                    background: "transparent",
                                                    border: "none"
                                                }}
                                            />*/}

                                            {
                                                node?.operator !== "DEFAULT" ?
                                                    <>
                                                        <p style={{
                                                            color: "#393a3a",
                                                            fontSize: "14px",
                                                            padding: "12px 26px 12px 20px",
                                                            margin: "0"
                                                        }}>
                                                            {
                                                                node?.expressionList?.length > 0 ?
                                                                    node?.expressionList?.map(expression => `${expression?.variable ? `{${expression?.variable}}` : ""} ${expression?.operator || ""} ${expression?.value || ""}`).join(` ${node.operator} `) :
                                                                    <span
                                                                        style={{color: "#969b9f"}}>Empty Condition</span>
                                                            }
                                                        </p>
                                                        <BlockChildHandle
                                                            type="source"
                                                            position={Position.Left}
                                                            id={`source-condition-${item.id}-${node.id}-left`}
                                                            onMouseOver={() => setIsDraggable(false)}
                                                            onMouseLeave={() => setIsDraggable(true)}
                                                            style={{
                                                                width: "14px",
                                                                height: "14px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                position: "absolute",
                                                                right: "15px",
                                                                alignItems: "center",
                                                                background: "transparent",
                                                                border: "none",
                                                                borderRadius: '0px',
                                                            }}
                                                        />
                                                        <BlockChildHandle
                                                            type="source"
                                                            position={Position.Right}
                                                            id={`source-condition-${item.id}-${node.id}-right`}
                                                            onMouseOver={() => setIsDraggable(false)}
                                                            onMouseLeave={() => setIsDraggable(true)}
                                                            style={{
                                                                width: "14px",
                                                                height: "14px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                position: "absolute",
                                                                right: "15px",
                                                                border: "1px solid #DDE4EA",
                                                                alignItems: "center",
                                                                background: "#969b9f"
                                                            }}
                                                        />
                                                    </> : node.active ? <>
                                                        <p style={{
                                                            color: "#393a3a",
                                                            fontSize: "14px",
                                                            padding: "12px 26px 12px 20px",
                                                            margin: "0"
                                                        }}>
                                                            Else
                                                        </p>
                                                        <BlockChildHandle
                                                            type="source"
                                                            position={Position.Left}
                                                            id={`source-else-condition-${item.id}-left`}
                                                            onMouseOver={() => setIsDraggable(false)}
                                                            onMouseLeave={() => setIsDraggable(true)}
                                                            style={{
                                                                width: "14px",
                                                                height: "14px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                position: "absolute",
                                                                right: "15px",
                                                                alignItems: "center",
                                                                background: "transparent",
                                                                border: "none",
                                                                borderRadius: '0px',
                                                            }}
                                                        />
                                                        <BlockChildHandle
                                                            type="source"
                                                            position={Position.Right}
                                                            id={`source-else-condition-${item.id}-right`}
                                                            onMouseOver={() => setIsDraggable(false)}
                                                            onMouseLeave={() => setIsDraggable(true)}
                                                            style={{
                                                                width: "14px",
                                                                height: "14px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                position: "absolute",
                                                                right: "15px",
                                                                border: "1px solid #DDE4EA",
                                                                alignItems: "center",
                                                                background: "#969b9f"
                                                            }}
                                                        />
                                                    </> : null
                                            }

                                        </div>
                                    ))}
                            </> : <p style={{color: "#393a3a", fontSize: "14px", padding: "12px 20px", margin: "0"}}>
                                {item?.stateConfig?.name ? item.stateConfig.name :
                                    <span style={{color: "#969b9f"}}>Enter your text!</span>}
                            </p>
                        }

                        {/*<BlockChildHandle
                            type="target"
                            position={Position.Left}
                            id={`target-${id}-${item.id}`}
                            onMouseOver={() => setIsDraggable(false)}
                            onMouseLeave={() => setIsDraggable(true)}
                            style={{
                                width: "16px",
                                height: "16px",
                                display: "flex",
                                justifyContent: "center",
                                position: "absolute",
                                left: "-10px",
                                alignItems: "center",
                                background: "transparent",
                                border: "none"
                            }}
                        />*/}

                        {droppedItems.length - 1 === index && !item?.stateConfig?.choices && <>

                            <BlockChildHandle
                                type="source"
                                position={Position.Left}
                                id={`source-${id}-${item.id}-left`}
                                onMouseOver={() => setIsDraggable(false)}
                                onMouseLeave={() => setIsDraggable(true)}
                                style={{
                                    width: "14px",
                                    height: "14px",
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "absolute",
                                    right: "15px",
                                    alignItems: "center",
                                    background: "transparent",
                                    border: "none",
                                    borderRadius: '0px',
                                }}
                            />

                            <BlockChildHandle
                                type="source"
                                position={Position.Right}
                                id={`source-${id}-${item.id}-right`}
                                onMouseOver={() => setIsDraggable(false)}
                                onMouseLeave={() => setIsDraggable(true)}
                                style={{
                                    width: "14px",
                                    height: "14px",
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "absolute",
                                    right: "15px",
                                    border: "1px solid #DDE4EA",
                                    alignItems: "center",
                                    background: "#969b9f",

                                }}
                            />
                        </>}
                    </div>
                ))
            }
        </div>
    );
};

export default memo(BlockChildNode);

