import React from "react";
import PropTypes from "prop-types";
import { cn } from "../../../helpers/utils/css-utils";

const TextBox = ({
  id,
  type,
  name,
  value,
  placeholder,
  onChange,
  ...props
}) => {
  return (
    <input
      id={id}
      type={type}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      className={cn(
        "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md outline-indigo-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500",
        {
          "text-red-900 placeholder-red-700  focus:ring-red-500 focus:border-red-500 outline-red-500  dark:text-red-500 dark:placeholder-red-500 dark:border-red-500":
            props?.error,
        }
      )}
      {...props}
    />
  );
};

export default TextBox;

TextBox.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
