import { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from '../../../../components/button/button';
import Message from "../../../../components/message/message-comp";
import Loader from "../../../../components/form-loader/form-loader";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {Modal} from "flowbite-react";

import {
    getDataSets,
    getBaseModelProviders,
    getTrainingBaseModels,
    startTrain
} from "../../../../services/model/finetune-service";
import SelectInput from "../../../../components/forms/select";
import BaseModelProvidersSelect from "../../common/base-model-providers-select";
import TextBox from "../../../../components/forms/text-box";

const mapsStateToProps = (state, ownProps) => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

const trainingSteps = Array.from({ length: 50 }, (_, index) => index + 1);

function Index({ open, onCompletion, onCancel, savedData={}, fineTuneType }) {
    const rootRef = useRef(null);
    const { appId } = useAppInfo();
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        label: '',
        baseModelProvider: '',
        baseModel: '',
        dataSetName: '',
        fineTuneType,
        trainingSteps: 10,
    });

    const [error, setError] = useState({
        label: false,
        baseModelProvider: false,
        baseModel: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [selectDataLoadingState, setSelectDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateSelectDataLoadingState = ({processing, success, failed, message, errorMessage}) => {
        setSelectDataLoadingState({...processing, success, failed, message, errorMessage})
    }

    const [ baseModels, setBaseModels ] = useState([]);
    const [ selectedBaseModels, setSelectedBaseModels ] = useState([]);
    const [ dataSets, setDataSets ] = useState([]);

    async function fetchBaseModes() {
        updateSelectDataLoadingState({
            processing: true
        });
        const data = await getTrainingBaseModels(appId, fineTuneType);
        setBaseModels(data);
        updateSelectDataLoadingState({
            processing: false
        });
    }

    async function fetchDatasets() {
        updateSelectDataLoadingState({
            processing: true
        });
        const {data}= await getDataSets(appId);
        setDataSets(data);
        updateSelectDataLoadingState({
            processing: false
        });
    }

    useEffect( () => {
        fetchBaseModes();
        fetchDatasets()
    }, []);

    useEffect( () => {
        if (formData.baseModelProvider) {
            if (baseModels[formData.baseModelProvider]) {
                setSelectedBaseModels(baseModels[formData.baseModelProvider])
            } else {
                setSelectedBaseModels([]);
            }
        }
    }, [formData.baseModelProvider]);

    useEffect(() => {
        if (formState.success) {
            setTimeout(()=>{
                setIsOpen(false);
                onCompletion(true);
            }, 1500)
        }
    }, [formState.success]);

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage}))
    }

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        if (onCancel) {
            onCancel();
        } else {
            onCompletion();
        }
    });

    const validateForm = () => {
        let valid = true;

        if (formData.label === '') {
            error.label = true;
            valid = false;
        } else {
            error.label = false;
        }

        if (formData.baseModel === '') {
            error.baseModel = true;
            valid = false;
        } else {
            error.baseModel = false;
        }

        if (formData.baseModelProvider === '') {
            error.baseModelProvider = true;
            valid = false;
        } else {
            error.baseModelProvider = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const onDataUpdate = (field, event) => {
        setFormData({...formData, [field]: event.target.value});
    };


    const handleSubmit = async (event) => {
        event.preventDefault()
        if (validateForm()) {
            updateFormState(true, false, false)
            const { status } = await startTrain(appId, formData);
            if (status === 200) {
                updateFormState(false, true, false)
            } else {
                updateFormState(false, false, true, 'Unable to start training. Please try again');
            }
        }

    };

    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={handleOnCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-6 gap-2">

                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Training</h3>

                            {(formState.processing || selectDataLoadingState.processing) && <div className="p-4"><Loader/></div>}

                            {formState.success &&
                                <Message text={`Successfully Executed`} color="success"/>
                            }

                            {formState.failed && <Message text={formState.errorMessage} color="failure"/>}

                            <TextBox
                                label="Label"
                                type="text"
                                name="label"
                                id="label"
                                value={formData.label}
                                placeholder="Identifier for your model"
                                onChange={event => { onDataUpdate('label', event)}}
                                error={error.label}
                            />

                            <BaseModelProvidersSelect
                                value={formData.baseModelProvider}
                                onDataUpdate={onDataUpdate}
                                error={error.baseModelProvider}
                                updateSelectDataLoadingState={updateSelectDataLoadingState}
                                fineTuneType={fineTuneType}
                            />

                            <SelectInput
                                id="baseModel"
                                label="Base Model"
                                required
                                value={formData.baseModel}
                                onChange={event => { onDataUpdate('baseModel', event)}}
                                error={error.baseModel}
                                optionsComp={
                                    <>
                                        <option>Select Base Model</option>
                                        {selectedBaseModels.map((model)=>
                                            <option key={model.fineTuneModelId} value={model.fineTuneModelId}>{model.fineTuneModelId}</option>
                                        )}
                                    </>
                                }
                            />

                            <SelectInput
                                id="dataSetName"
                                label="Data Set"
                                required
                                value={formData.dataSetName}
                                onChange={event => { onDataUpdate('dataSetName', event)}}
                                optionsComp={
                                    <>
                                        <option>Select Data Set</option>
                                        {dataSets.map((dt)=>
                                            <option key={dt.name} value={dt.name}>{dt.name}</option>
                                        )}
                                    </>
                                }
                            />

                            <TextBox
                                label="Training Steps"
                                type="number"
                                min={1}
                                max={100}
                                name="trainingSteps"
                                id="trainingSteps"
                                value={formData.trainingSteps}
                                placeholder="Enter training steps count. 1 - 100"
                                onChange={event => { onDataUpdate('trainingSteps', event)}}
                            />

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={handleOnCancel}/>
                                <Button type="submit" text="Start Training"/>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default connect(mapsStateToProps, mapDispatchToProps)(Index)