export const STATIC_KNOWLEDGE_NAVIGATION = [
    {
        name: 'Documents',
        href: '/knowledge-base/docs/data-sources',
        current: false,
    },
    // {
    //     name: 'Fine Tune',
    //     href: '/knowledge-base/fine-tune',
    //     current: false,
    // }
]