import React, { useCallback } from "react";
import TextBox from "../../../../components/forms/text-box";
import DynamicRows from "../../../../components/dynamic-rows";
import DropDown from "../../../../components/forms/dropdown";

export default function ResponseMapping({ onDynamicRowChange, savedSettings, metadata = {} }) {
    const onChangeData = useCallback((rowsData = []) => {
        onDynamicRowChange(rowsData, 'responseMapping');
    }, [onDynamicRowChange]);

    const renderRow = useCallback(
        (valuesMap, handleInputChange) => (
                    <div className="flex flex-row items-center gap-2">
                <TextBox
                    rootClass=""
                    label=""
                    type="text"
                    name="key"
                    placeholder="Json Path"
                    value={valuesMap.key}
                    onChange={handleInputChange}
                />
                <DropDown
                    className=""
                    placeHolder="Apply To"
                    name="slot"
                    value={valuesMap.slot}
                    options={Object.keys(metadata.slots)}
                    onChange={handleInputChange}
                />
            </div>
        ),
        []
    );

    const data = savedSettings.webhookConfig.responseMapping || [];

    return (
        <DynamicRows
            title="Response Mapping"
            className=""
            data={data}
            onChange={onChangeData}
            showTopDivider={false}
            showBottomDivider={false}
            rowsTemplate={renderRow}
        />
    );
}
