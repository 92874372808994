import React, {useRef, useEffect} from 'react';
import {BOT_CONV_MESSAGE_TYPE} from '../../consts/common-consts';
import BotMessageFactory from '../messenger-elements/bot-message/bot-message-factory'

function MessageBot(props) {
  return (
    <div className='w-full px-4 my-4 flex gap-2 xborder border-dashed'>
      {/*<div className='bot-icon w-10 h-10 flex-shrink-0'>*/}
      {/*  <img*/}
      {/*    src={AIbotUrl}*/}
      {/*    alt='ai-bot'*/}
      {/*    className='w-full h-full object-cover rounded-full'*/}
      {/*  />*/}
      {/*</div>*/}
      <div className='bot-icon w-10 h-10 flex-shrink-0 text-gray-500'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
        </svg>

      </div>
      <div className='message-text overflow-x-auto'>
        <small className='ml-1 block text-[13px] capitalize text-gray-300'>
          Bot
        </small>
        <BotMessageFactory {...props} />
      </div>
    </div>
  );
}

function MessageSelf(props) {
  return (
    <div
      className='w-full px-4 my-0 flex flex-row-reverse
     gap-2 xborder border-dashed'
    >
      {/*<div className='bot-icon w-10 h-10 mt-auto flex-shrink-0'>*/}
      {/*  <img*/}
      {/*    src={UsrUrl}*/}
      {/*    alt='user'*/}
      {/*    className='w-full h-full object-cover rounded-full'*/}
      {/*  />*/}
      {/*</div>*/}

      <div className='bot-icon w-10 h-10 mt-auto flex-shrink-0 text-indigo-500'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
          <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </div>

      <div className='message-text text-right'>
        <small className='mr-2 text-[13px] capitalize text-gray-300'>you</small>
        <BotMessageFactory {...props} />
      </div>
    </div>
  );
}

export default function MessengerContainer(props) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({behavior: 'smooth', block: 'end'});
    }
  }, []);
  return (
    <div ref={ref}>
      {props.senderType === BOT_CONV_MESSAGE_TYPE ? (
        <MessageBot {...props} />
      ) : (
        <MessageSelf {...props} />
      )}
    </div>
  );
}
