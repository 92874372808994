import {useCallback, useEffect, useState} from 'react';
import {useAppInfo} from '../../../helpers/hooks/common-hook';
import {
    codehookConfigSave,
    codehookDeploy,
    codehookUploadArtifact,
    codehookUploadUrlGet,
    getCodehookConfig
} from '../../../services/model/model-config-service';
import Message from "../../../components/toast-message";
import Loader from "../../../components/form-loader/form-loader";
import Button from '../../../components/button/button';
import {
    CODEHOOK_FUNCTION_ARCHIVE_CODE_TYPE,
    CODEHOOK_FUNCTION_INLINE_CODE_TYPE,
    FunctionInfo
} from "../../../dto/bot-model-config";
import {useParams} from "react-router";
import PageContainer from "../../../components/page-container";
import TextBox from "../../../components/forms/text-box";
import SelectInput from "../../../components/forms/select";
import {Label, Tabs} from "flowbite-react";
import Editor from "@monaco-editor/react";
import {validateActionName} from "../../../helpers/utils/text-util";
import {NAME_VALIDATION_ERROR} from "../../../consts/error-messages";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const DEFAULT_FUNC_INFO = new FunctionInfo({});

const CODEHOOK_RUNTIMES = {
    'nodejs': [
        'nodejs18.x',
        'nodejs16.x',
        'nodejs14.x'
    ],
    'python': [
        'python3.10',
        'python3.9',
        'python3.8',
        'python3.7',
        'python3.6',
        'python2.7',
    ],
    'ruby': [
        'ruby3.2',
        'ruby2.7',
    ],
    'java': [
        'java17',
        'java11',
        'java8.al2',
        'java8',
    ],

    'go': [
        'go1.x',
    ]
    ,
    'dotnet': [
        'dotnetcore3.1',
        'dotnetcore2.1'
    ]
};
const runtimes = Object.values(CODEHOOK_RUNTIMES).flat();

let defaultFuncContent =
    `exports.handler = async (event) => {
  // TODO implement
  const { context, slots } = event;
  const { appId, sessionId, auth } = context;
  const response = {
    // TODO your payload
  };
  return response;
};
`;

function LineBreak() {
    return (
        <div className="hidden sm:block" aria-hidden="true">
            <div className="py-5">
                <div className="border-t border-gray-200"/>
            </div>
        </div>)
}

function ErrorTip({text}) {
    return (
        <label className="block text-sm font-bold text-red-500">
            {text}
        </label>
    )
}


function EnvKey({index, envMap, handleOnDelete, handleOnChange}) {
    const createChangeVal = (index, type, event) => {
        return {index, type, data: event.target.value}
    }
    return (
        <div className="flex gap-2 items-center">
            <TextBox
                name={`${index}-envmap-key`}
                type="text"
                label="Key"
                placeholder="Enter Key"
                rootClass="w-full"
                value={envMap.key || ''}
                onChange={event => {
                    handleOnChange(createChangeVal(index, 'key', event));
                }}
            />
            <TextBox
                name={`${index}-envmap-val`}
                type="text"
                label="Value"
                placeholder="Enter Value"
                rootClass="w-full"
                value={envMap.val || ''}
                onChange={event => {
                    handleOnChange(createChangeVal(index, 'val', event));
                }}
            />
            <div className="flex flex-row items-center pt-6">
                <button onClick={() => handleOnDelete(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="h-5 w-5 text-gray-600 hover:text-gray-700 cursor-pointer" viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clipRule="evenodd"/>
                    </svg>
                </button>
            </div>

        </div>
    )
}

function getSavedCodehookConfig(codeHookConfig, codehookType) {
    return codeHookConfig && codeHookConfig.functionInfo ?
        codeHookConfig.functionInfo : DEFAULT_FUNC_INFO;
}

function isCodeDeployEnable(savedIntentData) {
    try {
        return savedIntentData.code.bucket || savedIntentData.code.name;
    } catch (err) {
        return {};
    }
}

function createEnvValObj(key, val) {
    return {key, val};
}

function initEnvKeys(environmentVariables) {
    if (environmentVariables) {
        const envKeys = [];
        for (const key in environmentVariables) {
            envKeys.push(createEnvValObj(key, environmentVariables[key]));
        }

        if (envKeys.length === 0) {
            envKeys.push(createEnvValObj());
        }

        return envKeys;
    } else {
        return [createEnvValObj()];
    }
}

function FunctionDeployComp({
                                appId,
                                intentLess,
                                title,
                                description,
                                codehookConfig
                            }) {
    const codehookType = 'fullfillment';
    const {codehookId: codehookParam} = useParams();
    const [savedIntentData, setSavedIntentData] = useState(DEFAULT_FUNC_INFO);
    const [codeUploadIndicate, setCodeUploadIndicate] = useState(false);

    const [formData, setFormData] = useState({
        codehookId: codehookParam,
        codehookType,
        runtime: '',
        handler: '',
        codeFrom: '',
        inlineCode: '',
        environmentVariables: {}
    });

    const [error, setError] = useState({
        codehookId: false,
        runtime: false,
        handler: false
    });

    const [dataLoadingState, setDataLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: null
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        successMessage: '',
        errorMessage: ''
    });

    const [codeUploadState, setCodeUploadState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const codehookId = formData.codehookId;
    const [configSaved, setConfigSaved] = useState();
    const [codeUploadUrl, setCodeUploadUrl] = useState();
    const [codeUploadErrorLabel, setCodeUploadErrorLabel] = useState();

    const [envKeys, setEnvKeys] = useState([createEnvValObj()]);

    async function fetchCodehookUploadUrl() {
        const {url} = await codehookUploadUrlGet(appId, codehookId, {codehookId, codehookType});
        setCodeUploadUrl(url);
    }

    async function checkCodeDeployEnable(showLoader) {
        updateDataLoadingState(showLoader && true, false, false);
        const resp = await getCodehookConfig(appId, codehookId, {codehookId, codehookType});
        updateDataLoadingState(showLoader && false, false, false);

        const configData = getSavedCodehookConfig(resp, codehookType)
        setSavedIntentData(configData);
        return isCodeDeployEnable(configData)
    }

    useEffect(() => {
        checkCodeDeployEnable(false);
        fetchCodehookUploadUrl();
    }, [codehookId, codehookType]);

    useEffect(() => {
        if ((savedIntentData.codehookId)) {
            setConfigSaved(true);
        }

        setFormData({
            ...formData,
            runtime: savedIntentData.runtime,
            handler: savedIntentData.handler,
            codeFrom: savedIntentData.code.type,
            inlineCode: savedIntentData.code.inlineCode || defaultFuncContent,
            environmentVariables: savedIntentData.environmentVariables,
        });
    }, [savedIntentData]);

    useEffect(() => {
        if (formData && formData.codehookId) {
            validateForm()
            setCodeUploadErrorLabel();
        } else {
            setCodeUploadErrorLabel("Please set Function name before upload code");
        }
    }, [formData]);

    useEffect(() => {
        if (savedIntentData.environmentVariables) {
            setEnvKeys(initEnvKeys(savedIntentData.environmentVariables));
        }
    }, [savedIntentData.environmentVariables]);

    useEffect(() => {
        if (configSaved) {
            setCodeUploadErrorLabel();
        }
    }, [configSaved]);

    useEffect(() => {
        let interval;
        if (codeUploadState.success) {
            setCodeUploadIndicate(true);
            interval = setInterval(async () => {
                const isDeployEnable = await checkCodeDeployEnable(false);
                if (isDeployEnable) {
                    clearInterval(interval);
                }
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [codeUploadState.success]);

    useEffect(() => {
        let interval;
        if (formState.success && formData.codeFrom === CODEHOOK_FUNCTION_INLINE_CODE_TYPE) {
            interval = setInterval(async () => {
                const isDeployEnable = await checkCodeDeployEnable(false);
                if (isDeployEnable) {
                    clearInterval(interval);
                }
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [formState]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        if (targetName === 'codehookId') {
            formData.codehookId = targetValue;
        }

        if (targetName === 'runtime') {
            formData.runtime = targetValue;
        }

        if (targetName === 'handler') {
            formData.handler = targetValue;
        }

        if (targetName === 'codeFrom') {
            formData.codeFrom = targetValue;
        }

        if (targetName === 'inlineCode') {
            formData.inlineCode = targetValue;
        }

        setFormData(Object.assign({}, formData));
    });

    const validateForm = () => {
        let valid = true;

        if (formData.codehookId && validateActionName(formData.codehookId)) {
            error.codehookId = false;
        } else {
            error.codehookId = true;
            valid = false;
        }

        if (formData.runtime) {
            error.runtime = false;
        } else {
            error.runtime = true;
            valid = false;
        }

        if (formData.handler) {
            error.handler = false;
        } else {
            error.handler = true;
            valid = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoading(Object.assign({}, {processing, success, failed, message}))
    }

    const updateFormState = (processing, success, failed, successMessage, errorMessage) => {
        setFormState(Object.assign({}, {processing, success, failed, successMessage, errorMessage}))
    }

    const updateCodeUploadState = (processing, success, failed, errorMessage) => {
        setCodeUploadState(Object.assign({}, {processing, success, failed, errorMessage}))
    }

    const prepEnvironmentVariables = () => {
        const filterdList = envKeys.filter(ev => ev.key);
        const environmentVariables = {};
        filterdList.forEach(ev => {
            environmentVariables[ev.key] = ev.val;
        })
        return environmentVariables;
    }

    const handleCodeUpload = async (event) => {
        setCodeUploadErrorLabel();
        updateCodeUploadState(true, false, false);
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            const response = await codehookUploadArtifact(codeUploadUrl, file);
            if (response) {
                updateCodeUploadState(false, true, false);
            } else {
                setCodeUploadErrorLabel("Upload failed. Please try again");
                updateCodeUploadState(false, false, true);
            }
        }

    }

    const handleOnClickSave = async () => {
        await handleConfigSave();
    }

    const handleConfigSave = async () => {
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);

            formData.environmentVariables = prepEnvironmentVariables();

            const {response, status} = await codehookConfigSave(appId, formData);
            if (status === 200) {
                setCodeUploadIndicate(false);
                updateFormState(false, true, false, "Successfully saved configurations");
                setConfigSaved(true);
            } else {
                updateFormState(false, false, true, null, response);
            }
        }
    }

    const handleCodeDeploy = async (event) => {
        if (!formState.processing) {
            updateFormState(true, false, false);
            const {response, status} = await codehookDeploy(appId, codehookId, {codehookType});
            if (status === 200) {
                updateFormState(false, true, false, "Successfully deployed codehook");
                setConfigSaved(true);
            } else {
                updateFormState(false, false, true, null, response);
            }
        }
    }

    const handleOnDeleteEnvVal = delIndex => {
        const newEnvKeys = [];
        for (let index in envKeys) {
            index = +index;
            if (index !== delIndex) {
                newEnvKeys.push(envKeys[index]);
            }
        }

        if (newEnvKeys.length === 0) {
            newEnvKeys.push(createEnvValObj());
        }

        setEnvKeys(newEnvKeys);
    }

    const handleOnChangeEnvVal = event => {
        const {index: eventIndex, type, data} = event;
        const newEnvKeys = [];
        for (let index in envKeys) {
            index = +index;
            const d = envKeys[index];
            if (index === eventIndex) {
                d[type] = data
            }
            newEnvKeys.push(d);
        }
        setEnvKeys(newEnvKeys);
    }

    const handleOnClickAddNewEnvVal = () => {
        setEnvKeys([...envKeys, createEnvValObj()]);
    }

    const codeDeployEnable = codeUploadIndicate && isCodeDeployEnable(savedIntentData);

    return (
        <PageContainer
            title={codehookId ? `Edit Function ( ${codehookId} )` : "New Function"}
            breadcrumbNav={[
                {name: 'Functions', href: `/app/${appId}/functions`},
                {name: 'Settings', href: '#'},
            ]}
            titleMarker={savedIntentData.arn? 'deployed': 'not-deployed'}
            titleMarkerColor={savedIntentData.arn? 'success': 'warning'}
            headerAction={
                <div className="flex flex-row items-center justify-end gap-2">
                    <Button
                        variant="button"
                        color="default"
                        text="Reset"
                    />

                    {formState.processing ?
                        <Button variant="button" text="Save & Deploy" loading={formState.processing} />
                        :
                        <Button variant="button" text="Save & Deploy" isIndicated={codeDeployEnable}
                                onClick={handleOnClickSave}/>
                    }



                </div>

            }
        >
            <div className="pt-4">

                {dataLoadingState.processing && <Loader text="Loading..." relative={true} fullScreen={true}/>}

                {codeUploadState.processing && <Loader text="Uploading..." relative={true} fullScreen={true}/>}

                {formState.failed &&
                    <div className="col-span-6"><Message text={formState.errorMessage}
                                                         type="failure"/></div>}

                {formState.success &&
                    <div className="col-span-6"><Message text={formState.successMessage}
                                                         type="success"/></div>}

                {codeUploadState.success &&
                    <div className="col-span-6"><Message text="Successfully uploaded code"
                                                         type="success"/></div>}


                <Tabs.Group
                    aria-label="Pills"
                    style="underline"
                    theme={{
                        "tablist": {
                            "tabitem": {
                                "styles": {
                                    "underline": {
                                        "base": "rounded-t-lg",
                                        "active": {
                                            "on": "text-indigo-600 rounded-t-lg border-b-2 border-indigo-600 active dark:text-indigo-500 dark:border-indigo-500",
                                            "off": "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
                                        }
                                    }
                                }
                            }
                        }
                    }
                    }
                >

                    <Tabs.Item
                        active
                        title="Configurations"
                    >
                        <>
                            <TextBox
                                rootClass="pt-4"
                                type="text"
                                name="codehookId"
                                id="codehookId"
                                label="Function Name"
                                autoComplete="codehookId"
                                value={codehookId}
                                onChange={handleChange}
                                placeholder="Enter function name"
                                readOnly={!!codehookParam}
                                error={error.codehookId}
                                errorMessage={NAME_VALIDATION_ERROR}
                            />

                            <TextBox
                                rootClass="pt-4"
                                type="text"
                                name="handler"
                                id="handler"
                                label="Handler"
                                autoComplete="handler"
                                value={formData.handler}
                                onChange={handleChange}
                                placeholder="index.handler"
                                error={error.handler}
                            />

                            <SelectInput
                                rootClass="pt-4"
                                id="runtime"
                                name="runtime"
                                label="Runtime"
                                autoComplete="runtime"
                                value={formData.runtime}
                                onChange={handleChange}
                                error={error.runtime}
                                optionsComp={
                                    <>
                                        <option disabled selected>Select Runtime</option>
                                        {runtimes.map(r => <option value={r}>{r}</option>)}
                                    </>
                                }
                            />

                            <div className="pt-4">
                                <Label className="block text-sm font-medium text-gray-500">
                                    Environment Variables
                                </Label>

                                {envKeys.map((envMap, index) => {
                                    return (<EnvKey
                                        key={index}
                                        index={index}
                                        envMap={envMap}
                                        handleOnChange={handleOnChangeEnvVal}
                                        handleOnDelete={handleOnDeleteEnvVal}
                                    />)
                                })}

                                <div className="pt-4">
                                    <button onClick={handleOnClickAddNewEnvVal}
                                            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="h-5 w-5 text-gray-600 hover:text-gray-700 cursor-pointer"
                                             viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd"
                                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </>
                    </Tabs.Item>

                    <Tabs.Item title="Code">

                        <div className="flex flex-col gap-6">
                            <SelectInput
                                rootClass="w-1/3"
                                label="Code from"
                                id="codeFrom"
                                name="codeFrom"
                                value={formData.codeFrom}
                                onChange={handleChange}
                                optionsComp={
                                    <>
                                        <option value={CODEHOOK_FUNCTION_INLINE_CODE_TYPE}>Inline Code</option>
                                        <option value={CODEHOOK_FUNCTION_ARCHIVE_CODE_TYPE}>File</option>
                                    </>
                                }
                            />

                            {formData.codeFrom === CODEHOOK_FUNCTION_ARCHIVE_CODE_TYPE &&
                                <div className="w-1/3">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                           htmlFor={`codehook-file-${codehookType}`}>
                                        <>
                                            {codeUploadState.processing ?
                                                <span>Uploading...</span> :
                                                <span>Upload Code Artifact (.zip)</span>
                                            }
                                        </>
                                    </label>

                                    {codeUploadState.processing || !formData.codehookId?
                                        <input
                                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                            aria-describedby="user_avatar_help" id="user_avatar" type="file"
                                            accept="application/zip"
                                        />
                                        :
                                        <input
                                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                            aria-describedby="user_avatar_help" id="user_avatar" type="file"
                                            accept="application/zip"
                                            onChange={handleCodeUpload}
                                        />
                                    }

                                    <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">
                                        Upload your code as .zip file
                                    </div>

                                    {codeUploadErrorLabel &&
                                        <div className="pt-2">
                                            <ErrorTip text={codeUploadErrorLabel}/>
                                        </div>
                                    }

                                </div>
                            }

                            {formData.codeFrom === CODEHOOK_FUNCTION_INLINE_CODE_TYPE &&
                                <div>
                                    <Editor
                                        height="30vh"
                                        defaultLanguage="javascript"
                                        value={formData.inlineCode || defaultFuncContent}
                                        onChange={value => handleChange({ target: { value, name: 'inlineCode' } })}
                                        theme="vs-dark"
                                    />
                                </div>
                            }

                        </div>


                    </Tabs.Item>

                </Tabs.Group>

            </div>
        </PageContainer>
    )
}

export default function CodeHookFunctionManagePage(props) {
    const appInfo = useAppInfo();
    return (
        <>
            <FunctionDeployComp
                {...appInfo}
                {...props}/>
        </>
    )
}