import {del, get, post} from "../../helpers/utils/http-client";
import {APPS_ENDPOINT} from "../../consts/api-endpoints";

export async function templatesList(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/integrations/make/templates`);
        if (status !== 200) {
            return []
        }
        return response;
    } catch (err) {
        console.debug('templatesList cause', err.message, err.code);
        return [];
    }
}

export async function scenariosList(appId) {
    try {
        const { response, status } = await get(`${APPS_ENDPOINT}/${appId}/integrations/make/scenarios`);
        if (status !== 200) {
            return []
        }
        return response;
    } catch (err) {
        console.debug('scenariosList cause', err.message, err.code);
        return [];
    }
}

export async function scenariosDelete(appId, senarioId) {
    try {
        return await del(`${APPS_ENDPOINT}/${appId}/integrations/make/scenarios/${senarioId}`);
    } catch (err) {
        return { response: err.displayError, status: err.code };
    }
}

export async function flowInit(appId, request) {
    try {
        return (await post(`${APPS_ENDPOINT}/${appId}/integrations/make/flows/init`, request));
    } catch (err) {
        console.debug('flowInit cause', err.message, err.code);
        return { response: err.displayError, status: err.code };
    }
}