import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {QUESTION_PROMPT} from "../../common/const/settings-type";
import Button from "../../../../components/button/button";
import SlotSelector from "../../common/components/slot-selector";
import CodeEditorDialog from "../../common/components/code-editor-dialog";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";

function Page({name, onChangeData, savedSettings, metadata={}}) {
    const [formData, setFormData] = useState(savedSettings || {
        name,
        slot: '',
        type: 'custom',
        promptDataConfig: {
            loaderType: 'webhook',
            webhookConfig: {
                url: '',
                method: 'GET'
            },
            requestMapper: {
                type: 'inline_script',
                inlineScript: ''
            },
            responseMapper: {
                type: 'inline_script',
                inlineScript: ''
            }
        },
        autoFillFromEntity: false,
    });

    const [openCodeEditor, setOpenCodeEditor] = useState();

    useEffect(() => {
        onChangeData(new StmStateDto({
            type: QUESTION_PROMPT,
            stateConfig: formData
        }));
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'slot') {
            newFormData.slot = targetValue;
        }

        if (targetName === 'autoFillFromEntity') {
            newFormData.autoFillFromEntity = targetValue;
        }

        if (targetName === 'displayText') {
            newFormData.displayText = targetValue;
        }

        if (targetName === 'url') {
            newFormData.promptDataConfig.webhookConfig.url = targetValue;
        }

        if (targetName === 'method') {
            newFormData.promptDataConfig.webhookConfig.method = targetValue;
        }

        setFormData(newFormData);
    };

    const onClickOpenCodeEditor = type => {
        setOpenCodeEditor(type)
    }

    const onCodeEditComplete = (code) => {
        setOpenCodeEditor(false)
        if (openCodeEditor === 'request' && code) {
            formData.promptDataConfig.requestMapper.inlineScript = code;
        } else if (openCodeEditor === 'response' && code) {
            formData.promptDataConfig.responseMapper.inlineScript = code;
        }
        setFormData({...formData});
    }

    return (
        <div className="flex flex-col gap-2">

            {(openCodeEditor === 'request') &&
                <CodeEditorDialog
                    open={true}
                    title="Request Handler"
                    code={formData.promptDataConfig.requestMapper.inlineScript}
                    onCompletion={onCodeEditComplete}/>
            }

            {(openCodeEditor === 'response') &&
                <CodeEditorDialog
                    open={true}
                    title="Response Handler"
                    code={formData.promptDataConfig.responseMapper.inlineScript}
                    onCompletion={onCodeEditComplete}/>
            }

            <SettingTip text="The API Step allows you to create prompt by making custom API calls. The prompt sent to the user, answer is stored in the slot"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <SlotSelector value={formData.slot} autoFillFromEntity={formData.autoFillFromEntity} slots={metadata.slots} onChange={handleChange} />

            <TextBox
                label="URL"
                type="url"
                name="url"
                placeholder="API Url"
                value={formData.promptDataConfig.webhookConfig.url}
                onChange={handleChange}
            />

            <SelectInput
                label="Method"
                id="method"
                name="method"
                autoComplete="method"
                value={formData.promptDataConfig.webhookConfig.method}
                onChange={handleChange}
                optionsComp={<>
                    <option disabled>Method</option>
                    {['GET', 'POST', 'PUT', 'DELETE'].map((method, si)=>(
                        <option key={si} value={method}>{method}</option>
                    ))}
                </>}
            />

            <div className="flex flex-row gap-2 pt-4">
                <div className="grid grid-cols-1">
                    <Button text="Request Mapper" color="default" onClick={()=>onClickOpenCodeEditor('request')} />
                </div>

                <div className="grid grid-cols-1">
                    <Button text="Response Mapper" color="default" onClick={()=>onClickOpenCodeEditor('response')} />
                </div>
            </div>



        </div>
    )
}

export default Page