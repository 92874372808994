import React, { useState, useEffect } from 'react';
import { Accordion, Button } from 'flowbite-react';
import { IoIosAdd, IoIosRemove } from "react-icons/io";

export const customAddRowTheme = {
    "size": {
        "xs": "text-xs px-0 py-0",
    }
};

const getDividerCss = (showTopDivider, showBottomDivider) => {
    let dividerCss = 'border-gray-100';

    if (showTopDivider) {
        dividerCss = `${dividerCss} border-t-2 pt-4 mt-4`;
    }

    if (showBottomDivider) {
        dividerCss = `${dividerCss} border-b-2 pb-4 mb-4`;
    }

    return dividerCss;
};

const DynamicRows = ({
                         title,
                         data = [],
                         onChange,
                         rowsTemplate,
                         className,
                         removeButtonClassName,
                         defaultThumbnail="",
                         showTopDivider = true,
                         showBottomDivider = true,
                         rowAccordingPanel = false,
                     }) => {
    const [rows, setRows] = useState([]);

    // Prepare rows data with IDs
    const prepareRowsData = (data) => {
        return data.map((rowData, index) => ({
            id: rowData.id || index,
            inputs: rowData.inputs || rowData,
        }));
    };

    // Initialize rows from data prop
    useEffect(() => {
        if (data) {
            setRows(prevRows => {
                const preparedData = prepareRowsData(data);
                // Preserve existing rows and IDs
                const newRows = preparedData.map(rowData => {
                    const existingRow = prevRows.find(row => row.id === rowData.id);
                    return existingRow || rowData;
                });
                return newRows;
            });
        }
    }, [data]);

    const notifyChanges = (newRows) => {
        const data = newRows.map(row => ({ id: row.id, inputs: row.inputs }));
        onChange(data);
    };

    const handleAddRow = () => {
        const newRow = {
            id: Math.random(),
            inputs: {}
        };
        const newRows = [...rows, newRow];
        setRows(newRows);
        notifyChanges(newRows);
    };

    const handleRemoveRow = (rowId) => {
        const newRows = rows.filter(row => row.id !== rowId);
        setRows(newRows);
        notifyChanges(newRows);
    };

    const handleInputChangeByRow = (rowId, inputName, value) => {
        const updatedRows = rows.map(row => {
            if (row.id === rowId) {
                return { ...row, inputs: { ...row.inputs, [inputName]: value } };
            }
            return row;
        });
        setRows(updatedRows);
        notifyChanges(updatedRows);
    };

    const renderRow = (row) => {
        if (typeof rowsTemplate === 'function') {
            return rowsTemplate(row.inputs, (event) => {
                handleInputChangeByRow(row.id, event.target.name, event.target.value);
            });
        }
        return rowsTemplate;
    };

    return (
        <div className={className}>
            <div className={getDividerCss(showTopDivider, showBottomDivider)}>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center justify-between">
                        <label className="block text-sm font-medium text-gray-900 dark:text-white">
                            {title}
                        </label>
                        <Button size="xs" theme={customAddRowTheme} color="gray" onClick={handleAddRow}>
                            <IoIosAdd className="h-6 w-6" />
                        </Button>
                    </div>

                    {rowAccordingPanel ?
                        <div className="flex flex-col gap-2 mt-2">
                            <Accordion collapseAll={true}>
                                {rows.map((row, index) => (
                                    <Accordion.Panel >
                                        <Accordion.Title className="py-2">{row.inputs.thumbnail || `${defaultThumbnail} ${(index + 1)}`}</Accordion.Title>
                                        <Accordion.Content>
                                            <div className="flex flex-col gap-2 w-full">
                                                {renderRow(row)}
                                            </div>
                                            <div className="pt-4">
                                                <Button size="md" color="light" className="text-red-600 w-full hover:bg-red-400 hover:text-red-700"
                                                        onClick={() => handleRemoveRow(row.id)}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                ))}
                            </Accordion>
                        </div>:
                        <div className="flex flex-col gap-2">
                            {rows.map((row, index) => (
                                <div key={index} className="flex flex-row items-center gap-2">
                                    <div className="flex flex-1 flex-row gap-2 items-center">
                                        {renderRow(row)}
                                    </div>
                                    <div className={removeButtonClassName || "pt-4 flex-row justify-end"}>
                                        <Button size="xs" theme={customAddRowTheme} color="gray" onClick={() => handleRemoveRow(row.id)}>
                                            <IoIosRemove className="h-6 w-6" />
                                        </Button>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(DynamicRows);
