import {BOT_CONV_MESSAGE_TYPE} from '../../../../consts/common-consts';
import './text-list-message.css';
import ChatDate from "../../../chat-date";

// relative time https://day.js.org/docs/en/plugin/relative-time
export default function TextListMessage({senderType, message, date}) {
  let theme =
    senderType === BOT_CONV_MESSAGE_TYPE
      ? 'conv-text-message-bot'
      : 'conv-text-message-user';
  return (
      <div className="flex flex-col gap-2">
          {message.map((msg, mi)=>
              <div key={mi} className={`mt-2 flex flex-col rounded-xl ${theme} p-2 max-w-xs`}>
                  <p className='px-1 pb-1'>{msg}</p>
                  <ChatDate date={date}/>
              </div>
          )}
      </div>
  );
}
