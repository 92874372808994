import React, { useState, useEffect, useRef } from 'react';

const MultiSelect = (props) => {
    const labelClass = props.error
        ? "block text-sm font-medium text-red-700 dark:text-red-500"
        : "block text-sm font-medium text-gray-900 dark:text-white";
    const [selectedOptions, setSelectedOptions] = useState(props.selectedOptions || []);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (props.onChangeOptions) {
            props.onChangeOptions(selectedOptions)
        }
    }, [selectedOptions]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOptions((prevSelected) => {
            if (prevSelected.includes(option.id)) {
                return prevSelected.filter((id) => id !== option.id);
            } else {
                return [...prevSelected, option.id];
            }
        });
    };

    const isSelected = (optionId) => selectedOptions.includes(optionId);

    console.log('selectedOptions', selectedOptions)

    return (
        <div className={props.rootClass}>
            <div ref={dropdownRef} className="flex flex-col gap-2">

                <label htmlFor={props.id} className={labelClass}>
                    {props.label}
                    {props.optional &&
                        <span className="pl-2 text-gray-400 italic">- Optional</span>
                    }
                </label>
                <div
                    className="border border-gray-300 bg-gray-50 rounded p-2 cursor-pointer rounded-lg text-sm py-3"
                    onClick={toggleDropdown}
                >
                    {selectedOptions.length > 0
                        ? `Selected: ${selectedOptions.length}`
                        : 'Select Options'}
                </div>
                {isDropdownOpen && (
                    <div className="absolute mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                        <ul>
                            {props.options.map((option) => (
                                <li
                                    key={option.id}
                                    className={`flex flex-row items-center p-2 cursor-pointer hover:bg-gray-200 ${
                                        isSelected(option.id) ? 'bg-gray-100' : ''
                                    }`}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={isSelected(option.id)}
                                        readOnly
                                        className="mr-2 rounded-md"
                                    />
                                    <p className="text-base text-gray-900">{option.label}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultiSelect;
