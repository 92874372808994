import './style.css';
import NodeComponent from "../node";
import {SettingNodeIcon} from "./icons";

export function CommonNode({ id, data }) {
    return (
        <NodeComponent title="Question" id={id} data={data} nodeTypeIcon={<SettingNodeIcon/>}>
            <div></div>
        </NodeComponent>
    );
}

