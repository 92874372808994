import PropTypes from "prop-types";
import { BsSearch } from "react-icons/bs";
import { cn } from "../../../../helpers/utils/css-utils";
import { Input } from "../../ui/input";

export const SearchInput = ({ onChangeParam, className }) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none ">
        <BsSearch size={17} className="text-gray-400" />
        <span className="sr-only">search journey</span>
      </div>
      <Input
        type="search"
        placeholder="Search..."
        onChange={onChangeParam}
        className={cn("ps-10", className)}
      />
    </div>
  );
};

SearchInput.propTypes = {
  onChangeParam: PropTypes.func,
};
