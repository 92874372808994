import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import SlotSelector from "../../common/components/slot-selector";
import TextBox from "../../../../components/forms/text-box";
import TextArea from "../../../../components/forms/text-area";
import SettingTip from "../../common/components/setting-tip";
import DropDown from "../../../../components/forms/dropdown";
import DynamicRows from "./dynamic-rows-ai-task";

const prepareRawsData = (data=[]) => {
    return data.map(header=>({inputs: header}))
}

function AITaskSettings({ name, onChangeData, savedSettings, metadata }) {
    const [formData, setFormData] = useState(savedSettings);

    const notifyOnchangeData = data => {
        data.stateType = ACTION_PROMPT
        onChangeData(data);
    }

    useEffect(() => {
        notifyOnchangeData(formData)
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newFormData = {...formData}
        newFormData[targetName] = targetValue;
        console.log("handleChange", newFormData)
        setFormData(newFormData);
    };

    const onChangeResponseMapper = (rowsData=[]) => {
        rowsData = rowsData.map(raw=>raw.inputs)
        const newFormData = {...formData};
        newFormData.assignToSlots.executors = rowsData
        notifyOnchangeData(newFormData);
    }

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="Generate answer and assign to Slot based on a prompt that you provide"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <TextArea
                label="System Prompt"
                type="text"
                name="systemPrompt"
                placeholder="Eg: you are helpful chatbot"
                value={formData.systemPrompt}
                onChange={handleChange}
                rows={6}
            />

            <DynamicRows
                title="Set to slot"
                className="pt-2"
                rowsData={prepareRawsData(formData.assignToSlots.executors)}
                onChange={onChangeResponseMapper}
                showBottomDivider={false}
                rowsTemplate={(valuesMap, handleInputChange) => (
                    <div className="flex flex-col gap-4">
                        <TextArea
                            rootClass=""
                            label="Prompt"
                            type="text"
                            name="inputPrompt"
                            placeholder="recommend me a {dish} that's easy to cook?"
                            value={valuesMap.inputPrompt}
                            onChange={handleInputChange}
                            rows={6}
                        />
                        <DropDown
                            className=""
                            placeHolder="Apply To"
                            label="Slot"
                            name="slotToAssign"
                            value={valuesMap.slotToAssign}
                            options={Object.keys(metadata.slots)}
                            onChange={handleInputChange}
                        />
                    </div>
                )}
            />

        </div>
    )
}

export default AITaskSettings
