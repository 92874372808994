function createNumberArray(size) {
  const array = [];
  for (let i = 0; i < size; i++) {
    array.push(i);
  }
  return array;
}

export default function TemplateCardList({ size = 4 }) {
  const loadingMockArray = createNumberArray(size);
  return <div className="pt-8 flex flex-row justify-center items-center">
    <div className="flex-1 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4 h-28">
      {loadingMockArray.map((i, key) => (
        <div className="border border-gray-300 dark:border shadow rounded-md p-4">
          <div className="animate-pulse flex space-x-4">
            <div className="flex w-full gap-4">
              <div className="flex justify-center items-center h-20 w-20 rounded-md bg-slate-300" />
              <div className="flex-grow">
                <div className="h-4 w-full bg-slate-300 mb-2 rounded" />
                <div className="h-2 w-full bg-slate-300 rounded" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
}