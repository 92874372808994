import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT, QUESTION_PROMPT} from "../../common/const/settings-type";
import TextBox from "../../../../components/forms/text-box";
import SettingTip from "../../common/components/setting-tip";
import DisplayTexts from "../../common/components/display-texts";
import {AUTO_CAPTURE_SLOTS_SLOT} from "../../common/const/settings-const";
import {useEventBus} from "../../../../helpers/hooks/event-bus";
import DropDown from "../../../../components/forms/dropdown";
import DynamicRows from "../../../../components/dynamic-rows";

const prepareRawsData = (data=[]) => {
    return data.map(header=>({inputs: header}))
}

const tempFormState = {
    displayTextOptions: [],
    slots: []
}

function Page({id: stepId, name, onChangeData, savedSettings, metadata}) {
    const eventBus = useEventBus();
    const [formData, setFormData] = useState(savedSettings || {
        name,
        displayText: '',
        displayTextOptions: [],
        slots: [],
        slot: AUTO_CAPTURE_SLOTS_SLOT,
        type: 'auto_capture_slots',
        autoFillFromEntity: false,
    });

    const notifyOnchangeData = data => {
        data.stateType = ACTION_PROMPT
        onChangeData(data);
    }

    useEffect(() => {
        notifyOnchangeData(formData);
    }, [formData]);

    useEffect(() => {
        if (savedSettings) {
            tempFormState.displayTextOptions = savedSettings.displayTextOptions
            tempFormState.slots = savedSettings.slots
        }
    }, [savedSettings]);


    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}
        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        if (targetName === 'displayText') {
            newFormData.displayText = targetValue;
        }

        setFormData(newFormData);
    };

    const handleDisplayTextsChange = data => {
        const newFormData = {...formData, slots: tempFormState.slots, displayTextOptions: data}
        tempFormState.displayTextOptions = data
        notifyOnchangeData(newFormData);
    }

    const onChangeSlots = (rowsData=[]) => {
        const slots = rowsData.map(raw=>raw.inputs)
        const newFormData = {...formData, displayTextOptions: tempFormState.displayTextOptions, slots}
        tempFormState.slots = slots
        notifyOnchangeData(newFormData);
    }

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="Capture slots values from user input"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <DisplayTexts
                parameterName={formData.slot}
                displayTextOptionsProps={formData.displayTextOptions}
                onChangeData={handleDisplayTextsChange}
            />

            <DynamicRows
                title="Set to slot"
                className="pt-2"
                removeButtonClassName="pt-0 flex-row justify-end"
                rowsData={prepareRawsData(formData.slots)}
                onChange={onChangeSlots}
                showBottomDivider={false}
                rowsTemplate={(valuesMap, handleInputChange) => (
                    <div className="flex flex-row items-center gap-2 w-full pr-4">
                        <DropDown
                            className="w-full"
                            placeHolder="Select Slot"
                            name="slot"
                            value={valuesMap.slot}
                            options={Object.keys(metadata.slots)}
                            onChange={handleInputChange}
                        />
                    </div>
                )}
            />

        </div>
    )
}

export default Page
