import {createUniqueId} from "../../../../../helpers/utils/object-util";
import {MESSAGE_ACTION_CONFIG} from "../common-consts";
import {useNavigate} from "react-router-dom";
import ParameterRow from "../../../../../components/action-parameter-row";
import TextArea from "../../../../../components/forms/text-area";
import ReturnDirectSwitch from "../../common/components/return-direct-switch";
import ActionParameters from "../../../../../components/action-parameter-row";

export default function MessageAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=MESSAGE_ACTION_CONFIG,
                                           handleOnSave,
                                           error
                                       }) {

    return (
        <>
            <ReturnDirectSwitch actionConfig={actionConfig} onActionConfigUpdate={onActionConfigUpdate} />

            <ActionParameters actionConfig={actionConfig} setActionConfig={setActionConfig}/>

            <TextArea
                rootClass="pt-4 w-1/2"
                rows={5}
                label="Message"
                subLabel="Enter the message that needs to be sent to the user."
                placeholder="Eg: Hello, How can i help you ?"
                value={actionConfig.message}
                onChange={event => { onActionConfigUpdate('message', event)}}
            />

        </>
    )
}
