import {WORKFLOW_ACTION_CONFIG} from "../common-consts";
import ActionParameters from "../../../../../components/action-parameter-row";

export default function WorkflowAction({
                                           config,
                                           onActionConfigUpdate,
                                           setActionConfig,
                                           actionConfig=WORKFLOW_ACTION_CONFIG,
                                           handleOnSave,
                                           error
                                       }) {
    return (
        <>
            <ActionParameters actionConfig={actionConfig} setActionConfig={setActionConfig}/>
        </>
    )
}
