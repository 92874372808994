import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import {listAuthConnes} from "../../../../services/model/model-config-service";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import Loader from "../../../../components/form-loader/form-loader";
import TextBox from "../../../../components/forms/text-box";
import SelectInput from "../../../../components/forms/select";
import SettingTip from "../../common/components/setting-tip";

function Page({name, onChangeData, savedSettings, metadata={}}) {
    const { appId } = useAppInfo();
    const [formData, setFormData] = useState(savedSettings);

    const [ authConnectors, setAuthConnectors ] = useState([]);
    const [authConnectorsLoadingState, setAuthConnectorsLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
        errorMessage: ''
    });

    const updateAuthConnectorsLoading = ({processing, success, failed, message, errorMessage}) => {
        setAuthConnectorsLoadingState({processing, success, failed, message, errorMessage})
    }

    const onActionConfigUpdate = (field, event) => {
        setFormData({...formData, [field]: event.target.value});
    };

    async function fetchConnectors() {
        updateAuthConnectorsLoading({
            processing: true
        });
        const { data } = await listAuthConnes(appId);
        if (data && Array.isArray(data)) {
            setAuthConnectors(data);
        }
        updateAuthConnectorsLoading({
            processing: false
        });
    }

    useEffect( () => {
        fetchConnectors();
    }, []);

    useEffect(() => {
        formData.stateType = ACTION_PROMPT
        onChangeData(formData);
    }, [formData]);

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="The authentication step asks the user to authenticate to continue the conversation"/>

            {authConnectorsLoadingState.processing &&
                <Loader/>
            }

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={event => { onActionConfigUpdate('name', event)}}
            />

            <SelectInput
                label="Auth Connector"
                id="authConnector"
                name="authConnector"
                autoComplete="authConnector"
                value={formData.authConnector}
                onChange={event => { onActionConfigUpdate('authConnector', event)}}
                optionsComp={<>
                    <option value="">Select Connector</option>
                    {}
                    {authConnectors.map((conn, si)=>(
                        <option key={si} value={conn.id}>{conn.id}</option>
                    ))}
                </>}
            />

            <div className="flex flex-row justify-between">
                <a href={`/app/${appId}/integrations/auth-connectors`} target="_blank" className="text-sm text-blue-700 font-bold underline">Create auth connector</a>
                <div className="flex flex-row justify-between pr-2">
                    <svg onClick={async ()=>{await fetchConnectors()}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </div>
            </div>

        </div>
    )
}

export default Page
