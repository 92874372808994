import {useCallback, useEffect, useRef, useState} from 'react';
import Button from '../../../../components/button/button';
import {createApp} from '../../../../services/app/app-service';
import {Checkbox, Label, Modal, Radio} from "flowbite-react";
import Message from "../../../../components/toast-message";
import {useNavigate} from "react-router-dom";
import {activePhoneNumbersList, connectPhoneNumber} from "../../../../services/integrate/twilio-integrate-service";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import SelectInput from "../../../../components/forms/select";
import Loader from "../../../../components/form-loader/form-loader";

function CapabilityCheckBox({field, label, disabled, checkBoxHandleChange, defaultChecked}) {
    return <div className="flex items-center gap-2">
        <Checkbox defaultChecked={defaultChecked} id={field} name={field} onChange={checkBoxHandleChange} disabled={disabled} />
        <Label htmlFor={field} className="flex" disabled={disabled}>
            {label}
        </Label>
    </div>
}

function ConnectPhoneNumber({onCancel}) {
    const { appId } = useAppInfo();
    const rootRef = useRef(null);
    const navigate = useNavigate();

    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [voiceEnabled, setVoiceEnabled] = useState(false);

    const [dataLoadingState, setDataLoadingState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const [formData, setFormData] = useState({
        phoneNumberSid: '',
        sms: true,
        voice: true,
    });

    const [error, setError] = useState({
        phoneNumberSid: false,
        smsOrVoice: false
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        formData[targetName] = targetValue
        setFormData({...formData});
    });

    const checkBoxHandleChange = useCallback(event => {
        console.log("event.target.value", event)
        event.target.value = event.target.checked
        handleChange(event)
    });

    const validateForm = () => {
        let valid = true;
        if (!formData.phoneNumberSid) {
            error.phoneNumberSid = true;
            valid = false;
        } else {
            error.phoneNumberSid = false;
        }

        if (!formData.sms && !formData.voice) {
            error.smsOrVoice = true;
            valid = false;
        } else {
            error.smsOrVoice = false;
        }

        setError({...error})
        return valid;
    }

    const updateDataLoadingState = (processing, success, failed, message) => {
        setDataLoadingState(Object.assign({}, {processing, success, failed, message}))
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    async function fetchPhoneNumbers() {
        updateDataLoadingState(true, false, false);
        const { phoneNumbers, voiceEnabled } = await activePhoneNumbersList(appId)
        setPhoneNumbers(phoneNumbers);
        setVoiceEnabled(voiceEnabled)
        updateDataLoadingState(false, false, false);
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const {status} = await connectPhoneNumber(appId, formData);
            if (status === 200) {
                updateFormState(false, true, false, "Successfully connected");
            } else {
                updateFormState(false, false, true, "Unable to connect phone number. Please try again");
            }
        }
    };

    useEffect(() => {
        fetchPhoneNumbers();
    }, []);

    useEffect(() => {
        if (formState && formState.success) {
            onCancel(true)
        }
    }, [formState]);

    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={onCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleOnSubmit}>
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Connect Phone Number</h3>

                            {formState.success && <Message text="Successfully connected" type="success"/>}

                            {formState.failed && <Message text={formState.message} type="failure"/>}

                            {error.smsOrVoice && <Message text="Please select either the SMS or Voice option." type="failure"/>}

                            {dataLoadingState.processing && <Loader fullScreen={true} text="Loading..." />}

                            <SelectInput
                                rootClass=""
                                name="phoneNumberSid"
                                label="Active Phone Numbers"
                                value={formData.phoneNumberSid}
                                error={error.phoneNumberSid}
                                onChange={handleChange}
                                optionsComp={<>
                                    <option value="" disabled={true}>Select Phone Numbers</option>
                                    {phoneNumbers.map((number, index)=>
                                        <option key={index} value={number.sid}>{number.friendlyName}</option>
                                    )}
                                </>}
                            />

                            <fieldset className="flex max-w-md flex-col gap-4">
                                <Label className="">Capabilities</Label>
                                <div className="flex flex-col gap-4">
                                    <CapabilityCheckBox defaultChecked={formData.sms}  field="sms" label="SMS" checkBoxHandleChange={checkBoxHandleChange}  />
                                    <CapabilityCheckBox defaultChecked={formData.voice} field="voice" label="Voice" disabled={!voiceEnabled} checkBoxHandleChange={checkBoxHandleChange}  />
                                </div>
                            </fieldset>

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={()=>onCancel()}/>
                                {formState.processing?
                                    <Button type="button" text="Save Settings" loading={true}/>
                                    :
                                    <Button type="submit" text="Save Settings"/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ConnectPhoneNumber