import {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT, MESSAGE_PROMPT} from "../../common/const/settings-type";
import TextBox from "../../../../components/forms/text-box";
import SettingTip from "../../common/components/setting-tip";
import { MdOutlineHideImage } from "react-icons/md";
import { Storage } from 'aws-amplify';
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import {useParams} from "react-router";
import {createUniqueId} from "../../../../helpers/utils/object-util";
import Message from "../../../../components/toast-message";
import FormLoader from "../../../../components/form-loader/form-loader";
import Button from "../../../../components/button/button";
import {fileUpload, uploadUrlGet, viewUrlGet} from "../../../../services/model/journey-service";

export default function ImageMessageSettings({name, onChangeData, savedSettings}) {
    const { appId } = useAppInfo();
    const { journeyId } = useParams();

    const [formData, setFormData] = useState(savedSettings);

    const [imageUrlLoadingState, setImageUrlLoading] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
    });

    const [imageUploadState, setImageUploadState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: '',
    });

    const [displayImageUrl, setDisplayImageUrl] = useState('');

    const [fileObj, setFileObj] = useState(null);
    const [fileUploadUrl, setFileUploadUrl] = useState(null);
    const [fileKey, setFileKey] = useState(null);

    const updateImageUploadState = (processing, success, failed, message) => {
        setImageUploadState({processing, success, failed, message})
    }

    const updateImageUrlLoading = (processing, success, failed, message) => {
        setImageUrlLoading({processing, success, failed, message})
    }

    useEffect(() =>  {
        formData.stateType = MESSAGE_PROMPT
        onChangeData(formData);

        if (formData.fileKey) {
            genViewUrl()
        }

    }, [formData]);

    useEffect(() =>  {
        if (fileObj) {
            // set for image preview
            const reader = new FileReader();
            reader.onload = (event) => {
                setDisplayImageUrl(event.target.result);
            };
            reader.readAsDataURL(fileObj);
            // end set for image preview

            genUploadUrl()
        }
    }, [fileObj]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;
        const newFormData = {...formData}
        newFormData[targetName] = targetValue;
        setFormData(newFormData);
    };

    const handleSelectFile = async (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const file = files[0];
            setFileObj(file)
        }
    }

    const genUploadUrl = async () => {
        try {
            updateImageUrlLoading(true, false, false)
            const fileExt = fileObj.name.split('.').pop();
            const resp = await uploadUrlGet(appId, journeyId, {
                fileExt
            })
            setFileUploadUrl(resp.uploadUrl);
            setFileKey(resp.fileKey);
            updateImageUrlLoading(false, true, false)
        } catch (e) {
            updateImageUrlLoading(false, false, true, 'Unable to select image')
        }
    }

    const genViewUrl = async () => {
        try {
            updateImageUrlLoading(true, false, false)
            const resp = await viewUrlGet(appId, journeyId, {
                fileKey: formData.fileKey
            })
            setDisplayImageUrl(resp.viewUrl);
            updateImageUrlLoading(false, true, false)
        } catch (e) {
            updateImageUrlLoading(false, false, true, 'Unable to select image')
        }
    }

    const handleUploadImage = async () => {
        try {
            if (!fileUploadUrl) {
                return
            }

            updateImageUploadState(true, false, false)
            await fileUpload(fileUploadUrl, fileObj);
            handleChange({
                target: {
                    name: 'fileKey',
                    value: fileKey
                }
            })
            setFileKey(null)
            setFileObj(null)
            updateImageUploadState(false, true, false, 'Successfully uploaded image')
        } catch (err) {
            updateImageUploadState(false, false, true, 'Unable to upload image');
        }
    }

    return (
        <div className="flex flex-col gap-2">
            {(!imageUploadState.processing && imageUploadState.failed && imageUploadState.message) &&
                <Message text={imageUploadState.message} type="failure"/>
            }

            {(!imageUploadState.processing && imageUploadState.success && imageUploadState.message) &&
                <Message text={imageUploadState.message} type="success"/>
            }

            <SettingTip text="Image message sent to a user during an conversation"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                       htmlFor="fileContainer">
                    <span>Upload Image ( png , jpg )</span>
                </label>

                <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="user_avatar_help"
                    id="fileContainer"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleSelectFile}
                />

            </div>

            {imageUrlLoadingState.processing?
                <div className="pt-8 flex flex-row justify-center items-center">
                    <div className="flex-1 grid grid-cols-3 gap-5">
                        <div></div>
                        <div
                            className="border border-gray-300 dark-border shadow rounded-md p-4 max-w-sm w-full mx-auto">
                            <div className="animate-pulse flex space-x-4">
                                <div className="flex-1 space-y-6 py-1">
                                    <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                                    <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                                    <div className="h-2 bg-slate-200 dark:bg-slate-600 rounded"></div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                :<div className="pt-4 flex flex-row justify-center">
                    {displayImageUrl?
                        <img className="h-36 w-36" src={displayImageUrl} alt="" />
                        :<MdOutlineHideImage className="h-36 w-36"/>}
                </div>}




            {(fileObj && fileKey) &&
                <div className="flex flex-row justify-center pt-4">
                    {imageUploadState.processing ?
                        <Button
                            loading={true}
                            color="default"
                            type="button"
                            text="Uploading"
                        />:
                        <Button
                            color="default"
                            type="button"
                            onClick={handleUploadImage}
                            text="Upload"
                        />
                    }
                </div>
            }


        </div>
    )
}
