import {useEffect, useState} from 'react';
import {useAppContext} from "../../../helpers/hooks/common-hook";
import {getSession} from "../../../services/session";
import {fromNow} from "../../../helpers/utils/date-util";

export default function SessionsList({onSelectSession}) {
    const { appInfo } = useAppContext();
    const [sessions, setSessions] = useState([]);

    const fetchSessions = async () => {
        if (appInfo.appId) {
            updateFetchSessionsState(true, false)
            const response = await getSession(appInfo.appId, appInfo.version);
            setSessions(response.data)
            updateFetchSessionsState(false, true)
        }
    }

    useEffect( () => {
        fetchSessions();
    }, [appInfo]);

    const [fetchSessionsState, setFetchSessionsState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const updateFetchSessionsState = (processing, success, failed, errorMessage) => {
        setFetchSessionsState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    return (
        <div className="col-span-1 max-height-wrapper overflow-y-auto bg-gray-200 px-4 py-4">

            <div className='flex flex-col h-full bg-white border-gray-200 border-2 '>

                <div className='h-14 flex items-center justify-between shadow-md'>
                    <div className='px-4 flex-shrink-0'>
                        <label
                            htmlFor='questionTxt'
                            className='block text-xl font-medium text-gray-700'
                        >
                            Sessions
                        </label>
                    </div>

                </div>

                <div className='flex-1 max-height-wrapper overflow-y-auto my-2 p-4'>
                    {! fetchSessionsState.processing && sessions.length > 0 &&
                        <ul role="list" className="divide-y divide-gray-200">
                            {sessions.map((session) => (
                                <li
                                    key={session.refId}
                                    className="relative bg-white py-5 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
                                >
                                    <div className="flex justify-between space-x-3">
                                        <div className="min-w-0 flex-1">
                                            <a href="#" onClick={()=>onSelectSession(session)} className="block focus:outline-none">
                                                <span className="absolute inset-0" aria-hidden="true"/>
                                                <p className="truncate text-sm font-medium text-gray-900">{session.refId}</p>

                                            </a>
                                        </div>
                                        <time dateTime={fromNow(session.modifiedAt)}
                                              className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                                            {fromNow(session.modifiedAt)}
                                        </time>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-gray-600 line-clamp-2">{session.displayMessage}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    }
                    {fetchSessionsState.processing &&
                        <span>Loading...</span>
                    }

                    {!fetchSessionsState.processing && sessions.length === 0 &&
                        <div className="flex flex-col">
                            <div className="border-l-4 border-blue-400 bg-blue-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 text-blue-400">
                                            <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm text-blue-700">
                                            Don't have any session started
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="h-screen"></div>
                        </div>

                    }
                </div>

            </div>


        </div>
    )
}
