import PropTypes from "prop-types";
import noDataImage from "../../assets/images/icons/no-data.svg";

const NoDataMessage = (props) => {
  const {
    image = noDataImage,
    icon,
    title = 'No Data Found',
    message,
    height = 500
  } = props;
  return (
    <div className="relative bg-gray-50 flex h-full w-full border mt-4" style={{ minHeight: height }}>
      <div className="absolute inset-0 flex gap-2 justify-center items-center flex-col">
        {!icon && <img className="w-1/12 block mb-6" src={image} alt={title} />}
        {icon && icon}
        {title && (
          <div className="text-slate-950 dark:text-slate-50 text-2xl font-medium text-center">
            {title}
          </div>
        )}
        {message && (
          <div className="text-slate-700 dark:text-slate-400 text-center">
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

NoDataMessage.propTypes = {
    image: PropTypes.any,
    icon: PropTypes.element,
    title: PropTypes.string,
    message: PropTypes.string,
    height: PropTypes.number,
}

export default NoDataMessage;
