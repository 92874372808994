import {useCallback, useEffect, useRef, useState} from 'react';
import Button from '../../components/button/button';
import {journeyGet, journeySlotSave} from '../../services/model/journey-service';
import Message from "../../components/toast-message";
import Loader from "../../components/form-loader/form-loader";
import {Modal} from "flowbite-react";
import TextBox from "../../components/forms/text-box";
import DropDown from "../forms/dropdown";
import {
    SLOT_DATA_TYPE_CAPTURE_BY_AI,
    SLOT_DATA_TYPE_REGEX, SLOT_DATA_TYPE_VALUES,
    SLOT_DATA_TYPES
} from "../../pages/action-journey-studio/common/const/slot-data-type";
import {TextAreaContent, ValuesContent} from "./slot-data-type-content";
import {Switch} from "@headlessui/react";
import {SLOT_LABEL} from "../../consts/common-label";
import MultiSelect from "../forms/multi-select-drop-down";

function Page({ appId, journeyId, savedData={}, open, onCompletion }) {
    const [isOpen, setIsOpen] = useState(open);
    const cancelButtonRef = useRef(null);

    const [formData, setFormData] = useState({
        slot: savedData.slot,
        // captureFromUserInput: savedData.captureFromUserInput,
        // Every slot will be captureFromUserInput=true. user change that on settings page
        captureFromUserInput: true,
        dataType: savedData.dataType,
        content: savedData.content,
        dependencies: savedData.dependencies || []
    });

    const [journey, setJourney] = useState({
        slots: {},
        contextConfig: {
            inputContexts: []
        }
    });

    const [error, setError] = useState({
        slot: false,
        dataType: false,
        content: false,
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    const [dataLoadingFormState, setDataLoadingFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        errorMessage: ''
    });

    async function fetchSavedJourney() {
        updateDataLoadingFormState(true, false, false)
        const savedJourney = await journeyGet(appId, journeyId);
        setJourney(savedJourney);
        updateDataLoadingFormState(false, false, false)
    }

    useEffect(() => {
        fetchSavedJourney()
    }, []);

    useEffect(() => {
        if (formState.success) {
            setTimeout(()=>{
                setIsOpen(false);
                onCompletion(true);
            }, 1500)
        }
    }, [formState.success]);

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        formData[targetName] = event.target.value;
        setFormData(Object.assign({}, formData));
    });

    const validateForm = () => {
        let valid = true;
        if (formData.slot) {
            error.slot = false;
        } else {
            error.slot = true;
            valid = false;
        }

        if (formData.dataType) {
            error.dataType = false;
        } else {
            error.dataType = true;
            valid = false;
        }

        setError({...error})
        return valid;
    }

    const updateFormState = (processing, success, failed, errorMessage) => {
        setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const updateDataLoadingFormState = (processing, success, failed, errorMessage) => {
        setDataLoadingFormState(Object.assign({}, { processing, success, failed, errorMessage }))
    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const slots = journey.slots || {};
            slots[formData.slot] = {...formData};
            const { response, status } = await journeySlotSave(appId, journey.journeyId, {slots});
            if (status === 200) {
                updateFormState(false, true, false);
                onCompletion(true);
            } else {
                updateFormState(false, false, true, response);
            }
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsOpen(false);
        onCompletion();
    });

    const handleOnChangeDependencies = dependencies => {
        console.log('dependencies', dependencies)
        handleChange({
            target: {
                name: 'dependencies',
                value: dependencies
            }
        })
    }

    // slot dependencies
    const slotDependencies = Object.keys(journey.slots)
        .filter(slot => slot !== formData.slot)
        .filter(slot => {
            if (
                journey.slots[slot]
                && journey.slots[slot].dependencies
                && !journey.slots[slot].dependencies.includes(formData.slot)){
                return true
            } else {
                return false
            }
        }) // not allow cycle dependencies
        .map(slot=>({ id: slot, label: slot }))


    const rootRef = useRef(null);
    return (
        <div ref={rootRef}>
            <Modal show={true} size="md" popup onClose={handleOnCancel} root={rootRef.current ?? undefined}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleOnSubmit} >
                        <div className="space-y-6">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">{savedData.slot? 'Edit Variable': 'New Variable'}</h3>

                            {formState.failed && <Message text={formState.errorMessage} type="failure" />}

                            {formState.success && <Message text="Successfully saved Variable" type="success" />}

                            {dataLoadingFormState.processing && <Loader fullScreen={true} />}
                            {dataLoadingFormState.failed && <Message text="Unable to load Variables" type="failure" />}

                            <p className="text-sm text-gray-500">{SLOT_LABEL}</p>

                            <TextBox
                                label="Variable"
                                type="text"
                                name="slot"
                                id="slot"
                                value={formData.slot}
                                placeholder="Enter Variable Name"
                                onChange={handleChange}
                                disabled={!!savedData.slot}
                                error={error.slot}
                            />

                            {/*<div className="flex flex-row justify-between items-center pt-2">*/}
                            {/*    <label htmlFor="slot" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">*/}
                            {/*        Capture from user input*/}
                            {/*    </label>*/}
                            {/*    <Switch*/}
                            {/*        checked={formData.captureFromUserInput}*/}
                            {/*        onChange={val=>handleChange({target: {name: 'captureFromUserInput', value: val}})}*/}
                            {/*        className={`${*/}
                            {/*            formData.captureFromUserInput ? 'bg-blue-600' : 'bg-gray-200'*/}
                            {/*        } relative inline-flex h-6 w-11 items-center rounded-full`}*/}
                            {/*    >*/}
                            {/*        <span className="sr-only">Auto Fill</span>*/}
                            {/*        <span*/}
                            {/*            className={`${*/}
                            {/*                formData.captureFromUserInput ? 'translate-x-6' : 'translate-x-1'*/}
                            {/*            } inline-block h-4 w-4 transform rounded-full bg-white transition`}*/}
                            {/*        />*/}
                            {/*    </Switch>*/}
                            {/*</div>*/}

                            <DropDown
                                className=""
                                selectedOptionClassName="capitalize"
                                optionClassName="capitalize"
                                label="Data Type"
                                placeHolder="Not Set"
                                name="dataType"
                                value={formData.dataType}
                                options={SLOT_DATA_TYPES}
                                onChange={handleChange}
                                error={error.dataType}
                            />

                            {formData.dataType === SLOT_DATA_TYPE_CAPTURE_BY_AI &&
                                <TextAreaContent
                                    label="Description"
                                    placeholder="Describe the slot to the AI"
                                    content={formData.content}
                                    onChange={handleChange}
                                    error={error.content}
                                />
                            }

                            {formData.dataType === SLOT_DATA_TYPE_REGEX &&
                                <TextAreaContent
                                    label="Regex"
                                    placeholder="Enter regx"
                                    content={formData.content}
                                    onChange={handleChange}
                                    error={error.content}
                                />
                            }

                            {formData.dataType === SLOT_DATA_TYPE_VALUES &&
                                <ValuesContent
                                    content={formData.content}
                                    onChange={handleChange}
                                />
                            }

                            <MultiSelect
                                label="Dependent Slots"
                                optional={true}
                                options={slotDependencies}
                                selectedOptions={formData.dependencies}
                                onChangeOptions={handleOnChangeDependencies}
                            />

                            <div className="w-full flex flex-row gap-2 justify-end">
                                <Button color="default" text="Cancel" onClick={handleOnCancel}/>
                                {formState.processing?
                                    <Button type="button" text="Save Variable" loading={true}/>
                                :
                                    <Button type="submit" text="Save Variable"/>
                                }

                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Page;