import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button} from "flowbite-react";
import {IoIosAdd, IoIosRemove} from "react-icons/io";
import isEqual from 'lodash/isEqual';

const customAddRowTheme = {
    "size": {
        "xs": "text-xs px-0 py-0",
    }
};

const getDividerCss = (showTopDivider, showBottomDivider) => {
    let dividerCss = 'border-gray-100';

    if (showTopDivider) {
        dividerCss = `${dividerCss} border-t-2 pt-4 mt-4`
    }

    if (showBottomDivider) {
        dividerCss = `${dividerCss} border-b-2 pb-4 mb-4`
    }

    return dividerCss;
}

const DynamicRow = ({ data=[], handleOnChange, addRow, removeRow, renderComponent, removeButtonClassName }) => {
    return (
        <div className="flex flex-col gap-2">
            {data.map((items, rowIndex) => (
                <div key={rowIndex} className="flex flex-row items-center">
                    <div className="flex flex-1 flex-row gap-2 items-center">
                        {items.map((item, colIndex) =>
                            renderComponent({
                                key: `${rowIndex}-${colIndex}`,
                                item,
                                onChange: (e) => handleOnChange(e, rowIndex, colIndex)
                            })
                        )}
                    </div>
                    <div className={removeButtonClassName || "pt-4 flex-row justify-end"}>
                        <Button size="xs" theme={customAddRowTheme} color="gray" onClick={() => {
                            removeRow(rowIndex)
                        }}
                        >
                            <IoIosRemove className="h-6 w-6" />
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    )
};

const DynamicRowManager = React.memo(({
                               initialData,
                               setInitialData,
                               renderComponent,
                               rowSpec=[] ,
                               title,
                               className,
                               removeButtonClassName,
                               showTopDivider=true,
                               showBottomDivider=true,
}) => {
    const [rowData, setRowData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!isEqual(initialData, rowData)) {
          setRowData(initialData)
        }
    }, [initialData]);

    // Sync the initialData with the component state if initialData changes
    useEffect(() => {
        setData(rowData.map((_d, index)=>{
          return rowSpec.map((info, infoIndex)=>({
              label: info.label,
              field: info.field,
              value: _d[info.field],
              name: `${index}${infoIndex}`
          }))
        }))
    }, [rowData]);

    const prevData = useRef(data);
    useEffect(() => {
        if (!isEqual(data, prevData.current)) {
            setInitialData(data.map((_d)=>{
                const newData = {}
                for (let __d of _d) {
                    newData[__d.field] = __d.value
                }
                return newData
            }))
        }
    }, [data]);

    const handleOnChange = (e, row, col) => {
        const newData = data.map((d, i) => {
            if (i === row) {
                d[col].value = e.target.value;
            }
            return d;
        });
        setData(newData);
    };

    const addRow = () => {
        const newData = [
            ...data,
            rowSpec.map((rowInfo, index)=>({
                label: rowInfo.label,
                field: rowInfo.field,
                value: "",
                name: `${data.length}${index}`
            }))
        ];
        setData(newData);
    };

    const removeRow = (index) => {
        const _data = [...data];
        _data.splice(index, 1);
        setData(_data);
    };

    const memoizedRows = useMemo(
        () => (
            <DynamicRow
                data={data}
                handleOnChange={handleOnChange}
                removeRow={removeRow}
                renderComponent={renderComponent}
                removeButtonClassName={removeButtonClassName}
            />
        ),
        [data, handleOnChange, addRow, removeRow, renderComponent]
    );

    return (
        <div className={className}>
            <div className={getDividerCss(showTopDivider, showBottomDivider)}>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row items-center justify-between">
                        <label
                            className="block text-sm font-medium text-gray-900 dark:text-white"
                        >
                            {title}
                        </label>
                        <Button size="xs" theme={customAddRowTheme} color="gray" onClick={addRow}>
                            <IoIosAdd className="h-6 w-6" />
                        </Button>
                    </div>

                    <div className="flex flex-col gap-2">
                        {memoizedRows}
                    </div>

                </div>
            </div>
        </div>
    )
});

export default DynamicRowManager;

