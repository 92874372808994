import React from "react";
import { cn } from "../../../helpers/utils/css-utils";

const Switch = React.forwardRef(
  ({ id = "toggle1", value, className, onChange, ...props }, ref) => {
    return (
      <div className="flex items-center">
        <input
          ref={ref}
          id={id}
          type="checkbox"
          checked={value}
          onChange={onChange}
          className={cn("peer hidden", className)}
          {...props}
        />

        <label
          htmlFor={id}
          className="flex h-6 w-12 cursor-pointer items-center rounded-full bg-gray-300 dark:bg-gray-700 p-1 text-white peer-checked:bg-purple-600 peer-focus:pl-4 peer-focus:ring-2 peer-focus:ring-purple-600
          peer-checked:pl-7 transition-all duration-300"
        >
          <div className="h-4 w-4 transform rounded-full bg-white shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-12" />
        </label>
      </div>
    );
  }
);

export { Switch };
