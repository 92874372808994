import { useEffect, useState } from 'react';
import Button from '../../../components/button/button';
import Message from "../../../components/toast-message";
import Loader from "../../../components/form-loader/form-loader";
import { useAppInfo } from '../../../helpers/hooks/common-hook';
import { getByAppId, publishToTemplate } from '../../../services/app/app-service';
import PageContainer from "../../../components/page-container";
import { APP_NAVIGATION } from "../consts/navigations";
import TextBox from "../../../components/forms/text-box";
import TextArea from "../../../components/forms/text-area";
import { Button as ButtonReact, Card } from 'flowbite-react';
import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog";

function Index() {
  const { appId } = useAppInfo();
  const [app, setApp] = useState({});
  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const [publishToTemplateFormState, setPublishToTemplateFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    errorMessage: ''
  });

  const [openTemplatePublishConfirmDialog, setOpenTemplatePublishConfirmDialog] = useState(false);

  async function fetchAppData() {
    updateFormState(true, false, false);
    const response = await getByAppId(appId);
    if (response) {
      setApp(response);
      updateFormState(false, true, false);
    } else {
      updateFormState(false, false, true, 'Unable to fetch data');
    }
  }

  const openPublishToTemplate = () => {
    setOpenTemplatePublishConfirmDialog(true)
  }

  async function submitPublishToTemplate() {
    updatePublishToTemplateFormState(true, false, false)
    await publishToTemplate(appId);
    updatePublishToTemplateFormState(false, true, false)
    setOpenTemplatePublishConfirmDialog(false)
  }

  useEffect(() => {
    fetchAppData();
  }, []);

  const updateFormState = (processing, success, failed, errorMessage) => {
    setFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  const updatePublishToTemplateFormState = (processing, success, failed, errorMessage) => {
    setPublishToTemplateFormState(Object.assign({}, { processing, success, failed, errorMessage }))
  }

  return (
    <PageContainer
      title="Application Information"
      subtitle="General settings of application." navigation={APP_NAVIGATION}>
      {formState.failed && <Message text={formState.errorMessage} type="error" />}
      {publishToTemplateFormState.success && <Message text="Successfully Published" type="success" />}
      {openTemplatePublishConfirmDialog &&
        <ConfirmDialog
        appId={appId}
        open={openTemplatePublishConfirmDialog}
        onCompletion={submitPublishToTemplate}
        title="Publish Confirmation"
        message="Do you want to publish to templates?"
        />
      }
      <div className="w-full max-w-2xl">
        <Card>
          {publishToTemplateFormState.processing && <div><Loader fullScreen={true} /></div>}
          {formState.processing && <div className="p-4"><Loader /></div>}
          <div className="flex flex-col gap-4">
            <TextBox
              label="Name"
              type="text"
              name="name"
              id="name"
              placeholder="App name"
              value={app.name}
              readOnly
            />
            <TextArea
              label="Description"
              rows="3"
              type="text"
              name="description"
              id="description"
              placeholder="About you app"
              value={app.description}
            />

            <div className="flex justify-end gap-2">
              <Button color="default" variant="button" type="submit" text="Reset" />
              <Button variant="button" type="submit" text="Save" />
            </div>
          </div>

          <div className="w-1/2 border-t border-gray-200 pt-4 mt-6">
            <h3 className="text-2xl font-semibold text-gray-900 pb-3 dark:text-white">Advanced</h3>
            <p className="text-sm text-gray-500 dark:text-white pb-2">Advanced settings.</p>
          </div>

          <div className="py-4 w-1/2 sm:grid sm:grid-cols-3 sm:gap-4 items-center">
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ButtonReact style={{ width: "200px" }} color="failure">Delete Application</ButtonReact>
            </dd>
          </div>
        </Card>
      </div>

    </PageContainer>)
}

export default Index;