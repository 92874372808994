import ChatDate from "../../../chat-date";

export default function CustomMessage({senderType, message, date}) {
  return (
    <div className={`mt-2 flex flex-col rounded-xl conv-text-message-bot max-w-xs`}>
      <p className='px-1 pb-1 text-yellow-600 text-sm'>Custom Message</p>
      <p className='px-1 pb-1'><pre>{JSON.stringify(message, null, 2)}</pre></p>
      <ChatDate date={date}/>
    </div>
  );
}
