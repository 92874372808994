import React from "react";
import PropTypes from "prop-types";
import { cn } from "../../../helpers/utils/css-utils";

const FieldGroup = ({ name = "Field", children, className }) => {
  return (
    <div id={name} className={cn("flex gap-4 box-border", className)}>
      {children}
    </div>
  );
};

export default FieldGroup;

FieldGroup.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};
