import {useCallback, useEffect, useState} from 'react';
import Button from '../../../components/button/button';
import Message from "../../../components/toast-message";
import Loader from "../../../components/form-loader/form-loader";
import PageContainer from "../../../components/page-container";
import TextBox from "../../../components/forms/text-box";
import {getMyUserProfile, updateUserProfile} from "../../../services/user/user-service";

function UserProfile() {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
    });

    const [error, setError] = useState({
        name: false,
    });

    const [fetchState, setFetchState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    const [formState, setFormState] = useState({
        processing: false,
        success: false,
        failed: false,
        message: ''
    });

    async function fetchData() {
        updateFetchState(true, false, false);
        const {response, status} = await getMyUserProfile();
        if (status === 200) {
            setFormData(response);
            updateFetchState(false, true, false);
        } else {
            updateFetchState(false, false, true, 'Unable to fetch data');
        }
    }

    const handleChange = useCallback(event => {
        const targetName = event.target.name;
        formData[targetName] = event.target.value;
        setFormData({...formData});
    });

    useEffect(() => {
        fetchData();
    }, []);

    const updateFetchState = (processing, success, failed, message) => {
        setFetchState(Object.assign({}, {processing, success, failed, message}))
    }

    const updateFormState = (processing, success, failed, message) => {
        setFormState(Object.assign({}, {processing, success, failed, message}))
    }

    const validateForm = () => {
        let valid = true;


        if (!formData.name) {
            error.name = true;
            valid = false;
        } else {
            error.name = false;
        }

        setError(Object.assign({}, error))
        return valid;
    }

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (!formState.processing && validateForm()) {
            updateFormState(true, false, false);
            const {response, status} = await updateUserProfile(formData);
            if (status === 200) {
                updateFormState(false, true, false);
            } else {
                console.error("updateUserProfile failed cause", response);
                updateFormState(false, false, true, response);
            }
        }
    });

    return (
        <PageContainer
            title="User Profile"
            subtitle="Your personal account">

            <form className="pt-4 flex flex-col gap-4" onSubmit={handleSubmit}>
                {(fetchState.processing || formState.processing) && <div className="p-4"><Loader/></div>}
                {fetchState.failed && <Message text={fetchState.errorMessage} type="failure"/>}
                {formState.failed && <Message text={formState.errorMessage} type="failure"/>}
                {formState.success && <Message text="Successfully updated the profile" type="success"/>}

                <TextBox
                    rootClass="w-1/2"
                    label="Email"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={formData.email}
                    readOnly
                />


                <TextBox
                    rootClass="w-1/2"
                    label="Name"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                />

                <div className="w-1/2 flex justify-end gap-2">
                    <Button color="default" variant="link" to="/forgot-password" text="Reset Password"/>
                    <Button variant="button" type="submit" text="Update Profile"/>
                </div>
            </form>

        </PageContainer>)
}

export default UserProfile;