import API, { graphqlOperation } from '@aws-amplify/api'
import appConfig from "../../config";

export const config = {
    "aws_appsync_graphqlEndpoint": appConfig.REALTIME_API_BASE_URL,
    "aws_appsync_region": appConfig.REGION,
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "null",
}

export const subscribeRuntimeDebugNotifyDoc = /* GraphQL */ `
    subscription Subscribe($appId: String!, $sessionId: String!) {
        subscribeRuntimeDebugNotify(appId: $appId, sessionId: $sessionId) {
            data
            appId
            sessionId
        }
    }
`

export function subscribeRuntimeDebugger(authToken, appId, sessionId, next, error) {
    return API.graphql(graphqlOperation(subscribeRuntimeDebugNotifyDoc, { appId, sessionId }, authToken)).subscribe({
        next: ({ provider, value }) => {
            console.log("subscribeRuntimeDebugNotify", value)
            next(value.data.subscribeRuntimeDebugNotify, provider, value)
        },
        error: error || console.log,
    })
}
